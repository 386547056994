'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
//import CheckBox from "./checkboxselection";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';

import {verifyext} from "../common/verifyext";

// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class CarsPlanning extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
            //CheckBox:CheckBox,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        // statusPanels: [
        //   {
        //   statusPanel: "agTotalAndFilteredRowCountComponent",
        //   align: "left"
        //   },
        //   {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "center"
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" }
        // ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            // {
            // id: "columns",
            // labelDefault: "Columns",
            // labelKey: "columns",
            // iconKey: "columns",
            // toolPanel: "agColumnsToolPanel"
            // },
            // {
            // id: "filters",
            // labelDefault: "Filters",
            // labelKey: "filters",
            // iconKey: "filter",
            // toolPanel: "agFiltersToolPanel"
            // }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        loadFile : "",
        processErrMsg : "",
        processErrShow : "",
        transactionId : "",
        droppedList : [],
        statusCode : "",
        pinnedRegionBottomRowData:[],
        totalCars : 0,
        carDetails : [],
        excarDetails : [],
        columns : [],
        rowdefs : [],
        transportersList : [],
        lpList: [],
        selectedRows : [],
        loading_plant : "",
        pageType : 1,
        transaction_id:"",
        storage_plan_id: "",
        rowData:[]
        //components : {datepicker:getDatePicker()}
    }
    // this.onLoadPageData = this.onLoadPageData.bind(this);
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
      
async componentDidMount(){
    this.logPageView();
    var currentURL =  window.location.pathname
    var checkURL = currentURL.split("/")
    var fetchURL = checkURL[checkURL.length-1]
    var transaction_id = window.atob(fetchURL)
    this.setState({
        "storage_plan_id": transaction_id
    })
    var params ={
        "storage_plan_id": transaction_id 
    }
    redirectURL.post("/arms/getDataforcarrecommendation",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data,"changes")
        this.setState({
            rowData:response.data
        })
    }
    })
}



updateGridData = e =>{
    let cars_selected = JSON.parse(localStorage.getItem("restricted_models"))
    console.log(cars_selected,"cars_selected")
    this.gridApi.forEachNode(node => {
        console.log(node,"node")
        if(node.data.reco_restricted == 1){
            node.setSelected(true);
        }
    });
}

onGridReady = params => {
    this.gridApi = params.api;  
    console.log("check-1")
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    setTimeout(() => {
        this.selectData();
    }, 500)
};
selectData = () => {
    
    let cars_selected = JSON.parse(localStorage.getItem("restricted_models"))
    console.log(cars_selected,"cars_selected")
    this.gridApi.forEachNode((node) => {
        console.log("check-2");
        console.log(node.data.car_model, "check-22");
        console.log(cars_selected.includes(String(node.data.car_model)), "check");
        if (cars_selected.includes(String(node.data.car_model))) {
            node.setSelected(true);
        }
    });
}
onGridState = () =>{
    //console.log(this.gridApi);

    /*Get  Current Columns State and Store in this.colState */
    this.colState = this.gridColumnApi.getColumnState();

    /*Get Current RowGroup Columns State and Store in this.rowGroupState */
    this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

    /*Get Current Pivot Columns State and Store in this.pivotColumns, 
    * User should enable the Pivot mode.*/
    this.pivotColumns = this.gridColumnApi.getPivotColumns();
            
    /*Get Current Filter State and Store in window.filterState */
    window.filterState = this.gridApi.getFilterModel();
    this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
    //window.location.href="/lmloadsummary";
}

onCellClicked(e)
{
    if(e.colDef.field == "container_no")
    {
        console.log(e.data.container_no);
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{vehicle_cap:"",min_capacity_cmt:"",max_capacity_cmt:"",min_courier:"",nodes_per_truck:"",allowed_distance:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate-40p"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}
fixProcess = () => {
    this.setState({
        processErrMsg : "",
        processErrShow : 0,
        loadFile:''
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
}


changeFileHandler = async (e) => {
    console.log(e);
    this.setState({
        loadFile : e.target.files[0]
    });  
    
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

changePlantHandler = async (e) => {
    var loading_plant = $("#loading_plant").val();
    await this.setState({
        loading_plant : loading_plant,
    })
    console.log(loading_plant,loading_plant)
    await this.onLoadPageData();
}

uploadBulkFormHandler(){
  
    var selectedRows = this.state.selectedRows;
    console.log(selectedRows,"selectedRows")
    var car_models = []
    selectedRows.map((e)=>{
        car_models.push(e.car_model)
    }) 
    console.log(car_models,"car_models")
    // if(car_models.length >0){
        var params = {
            "car_models":car_models,
            "storage_plan_id": this.state.storage_plan_id
        }
        redirectURL.post("/arms/addrestrictionforcarmodels",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            if(response.data.message == "success"){
                this.setState({
                    show:true,
                    basicTitle:"Restriction Added for models",
                    basicType:"success"
                })
                var encryptedstring = window.btoa(this.state.storage_plan_id)
                window.location.href="/railloadconfiguratorsummary/"+encryptedstring
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Restriction Not Added to the models",
                    basicType:"warning"
                })
            }
        }
        })
    // }else{
    //     this.setState({
    //         show:true,
    //         basicTitle:"Please Select Car Models",
    //         basicType:"warning"
    //     })
    // }
   
};




onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

onCellUpdateData = async(event) => {
    let clusterShares = [];
    this.gridApi.forEachNode(node => clusterShares.push(node.data));
    console.log(clusterShares,"clusterShares")
    var totalCars = 0;
    clusterShares.map(function(r,index){
        totalCars = parseInt(totalCars)+parseInt(r.total_cars)
    })
    var pinnedBottomRowData = {}
    pinnedBottomRowData['car_model'] = "";
    pinnedBottomRowData['family_code'] = "Total";
    pinnedBottomRowData['total_cars'] = Math.round(totalCars);
    this.setState({
        pinnedRegionBottomRowData : [pinnedBottomRowData],
    })
}
changeTransporterItem(){
    var transporter_code = $("#transporter_code").val();
    if(transporter_code == "APL")
    {
        this.setState({
            rakeDivShow : "show-m"
        });
    }
    else
    {
        this.setState({
            rakeDivShow : "show-n"
        });
    }
}


onRowSelection(event){
    var selectedRows=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        selectedRows.push(item.data)
    });
    console.log(selectedRows,"selectedRows")
    this.setState({
        selectedRows:selectedRows
    })
}
onClickBack =()=>{
    var storage_plan_id = this.state.storage_plan_id
    var encryptedstring = window.btoa(storage_plan_id)
    window.location.href="/railloadconfiguratorsummary/"+encryptedstring
}
  render() {
    
    var columnDefs = [   
        // {
        //     pinned:"left",
        //     headerName : "Update",
        //     field:"_id",
        //     width:150,
        //     resizable : true,
        //     editable : false,
        //     cellRendererSelector:function(params){       
        //         var rendComponent = {
        //             component: 'CheckBox'
        //         };
        //         return rendComponent;
        //     },
        // }, 
        {
            headerName: "Car Name",
            field: "car_model",
            width:180,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Family Code",
            field: "family_code",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
            hide:false,
        },
        // {
        //     headerName: "Total Cars",
        //     field: "total_cars",
        //     width:150,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        //     cellEditor: NumericCellEditor,
        // },  
        {
            headerName: "Restrict For Recommendation",
            field: "",
            colId: "",
            width: 180,
            //pinned: 'left',
            filter: false,
            resizable: true,
            headerCheckboxSelection: false,
            //headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true
        },
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card-arms col-xl-12">
            
            <div class="card-header-arms">
                <h5>
                    <i className="icofont icofont-car cus-i"></i> 
                    <span>Cars Model Restrictions - {this.state.storage_plan_id}</span>
                </h5>
            </div>
           
             <div>
             <button className="btn btn-warning" style={{float:"right",marginLeft:"2%",borderRadius:"5px"}} onClick={this.uploadBulkFormHandler.bind(this)}>Configure Cars</button>
             <button className="btn btn-arms" style={{float:"right",marginLeft:"2%",borderRadius:"5px"}} onClick={this.onClickBack.bind(this)}>
                           Back
                            </button>
             </div>
            <div className="col-xl-12 col-lg-12">
                
                </div>
                <div className='grid-container col-xl-12 col-lg-12'>
                <div id="myGrid" style={{width:"100%",height:"460px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    stopEditingWhenGridLosesFocus= {true}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    // stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={false}
                    pinnedBottomRowData={this.state.pinnedRegionBottomRowData}
                    gridOptions={{
                        context: { componentParent: this },
                        getRowStyle: function (params) {
                                if (params.node.rowPinned) {
                            return { 'font-weight': 'bold','font-size':'16px' };
                            }
                        },
                        suppressRowTransform: true,
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    //onCellClicked={this.onCellClicked}
                    onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    rowSelection={this.state.rowSelection}
                    onRowSelected={this.onRowSelection.bind(this)}
                    suppressRowClickSelection={true}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}


function uniqueArray2(arr) {
    var a = [];
    for (var i=0, l=arr.length; i<l; i++)
        if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
            a.push(arr[i]);
    return a;
}