"use strict";

import React, { Component, useDebugValue } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { render } from "react-dom";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { MenuModule } from "@ag-grid-enterprise/menu";
// import Consignmentforceclose from '../m';
import CSVFileValidator from "csv-file-validator";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import {
  AllModules,
  GridOptionsWrapper,
} from "@ag-grid-enterprise/all-modules";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from "react-countup";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import $ from "jquery";
// import ColumnGroup from "./columngroupComponent";
import {
  getDDMMYYYY,
  getDDMMYYYYHHMMSS,
  getYYYYMMDD,
  getYYYYMMDDHHMMSS,
  getHyphenYYYYMMDDHHMMSS,
  getHyphenDDMMMYYYYHHMM,
  getHyphenYYYYMMDD,
  getHyphenDDMMYYYYHHMMSS,
  getHyphenDDMMYYYY,
  getDDMMMYYYYHHMMDefault,getDDMMMYYYYDefault} from "../common/utils";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import BatchDownload from "./batchdownload";
import { isBuffer } from "lodash";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require("../redirectURL");
var moment = require("moment");
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var validatedBy = "";

export default class AutoMailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        width: 160,
        resizable: true,
      },
      eventAction: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      loadshow: "show-n",
      overly: "",
      rowdata: [],
      sliderbulkupload: "",
      rowModelhighchartoptionsType: "enterprise",
      frameworkComponents: {
        BatchDownload: BatchDownload,
      },
      rowGroupPanelShow: "always",
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 50,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      dept_code: "",
      pagetitle: "",
      csvData: 0,
      overly: "show-n",
      loadshow: "show-n",
      pagetitle: "",
      eventGridAction: "gridAction",
      csvcontent: [],
      file: "",
      batch_name: "",
      cluster_breaks: "2",
      cluster_radius: "0.5",
      notification_emails: "",
      isChecked: false,
      isPopupVisible: false,
      setEditable: 0,
      rowId: "",
      container_type: "",
      bulkslide: '',
      bulkslide1: '',
      edit_mailName : '',
      to_mail:[],
      cc_mail:[],
      mailerName:'',
      edit_to_emails: [],
      edit_cc_emails: [],
      selected_to_mails:[],
      selected_cc_mails:[],
      remove_to_mail:[],
      remove_cc_mail:[],
      
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    // loadDateTimeScript()
    redirectURL.post("/arms/automaileremails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response) => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
      console.log(response.data,"adfadfa")
      var resp = response.data
        if(resp.length >0){
          console.log("check-1232")
            this.setState({
                rowData:resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        }else{
          console.log("check-235423")
            this.setState({
                rowData:[],
                loadshow:"show-n",
                overly:"show-n"
            })
        }
    }
      });
  }

  closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
  };



  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
      bulkslide: "",
      bulkslide1: "",

    });
  }
  handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) {
      this.onClickHideAll();
    }
  };
  onCellClicked(e) {
    if (e.colDef.field === "edit") {
      var checkdata = e.data;
      var to_mails=e.data.to_emails
      to_mails=to_mails.map((each)=>({label:each,value:each}))

      var cc_mails=e.data.cc_emails
      cc_mails=cc_mails.map((each)=>({label:each,value:each}))
      console.log(checkdata, 'checkdata');
      this.setState({
        bulkslide: "slider-translate-30p",
        mailerName: checkdata.automailer_name,
        edit_to_emails: Array.isArray(checkdata.to_emails) ? checkdata.to_emails : [checkdata.to_emails],
        edit_cc_emails: Array.isArray(checkdata.cc_emails) ? checkdata.cc_emails : [checkdata.cc_emails],
        rowId: checkdata._id,
        selected_to_mails:to_mails,
        selected_cc_mails:cc_mails,

        overly: "show-m"
      });
    }
  }

  onChangetomails = (newValue) => {
    console.log(newValue, "192");
    if (newValue && newValue.length === 0) {
      this.setState({
        edit_to_emails: [],
        selected_to_mails: [],
      });
    } else {
      var selected_to_mails = this.state.selected_to_mails || [];
      if (newValue && newValue.length > 0) {
      selected_to_mails = selected_to_mails.filter((each) => each.value !== newValue[0].value);
      }
      // Add a new email to the options if it doesn't already exist
      var options = this.state.edit_to_emails || [];
      if (newValue && newValue.length > 0 && !options.some((email) => email.value === newValue[0].value)) {
        options.push({ label: newValue[0].value, value: newValue[0].value });
      }
  
      this.setState({
        edit_to_emails: options,
        selected_to_mails: newValue,
      });
    }
  };
  

  onChangeccmails = (newValue) => {
    console.log(newValue, "198");
    if (newValue && newValue.length === 0) {
      this.setState({
        edit_cc_emails: [],
        selected_cc_mails: [],
      });
    } else {
      var selected_cc_mails = this.state.selected_cc_mails || [];
      if (newValue && newValue.length > 0) {
        selected_cc_mails = selected_cc_mails.filter((each) => each.value !== newValue[0].value);
      }
  
      // Add a new email to the options if it doesn't already exist
      var options = this.state.edit_cc_emails || [];
      if (newValue && newValue.length > 0 && !options.some((email) => email.value === newValue[0].value)) {
        options.push({ label: newValue[0].value, value: newValue[0].value });
      }
  
      this.setState({
        edit_cc_emails: options,
        selected_cc_mails: newValue,
      });
    }
  };
  
  onClickShowFilterData(event) {
    event.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
    
    var to_mail = this.state.selected_to_mails ? this.state.selected_to_mails.map((email) => email.value) : [];
var cc_mail = this.state.selected_cc_mails ? this.state.selected_cc_mails.map((email) => email.value) : [];

    var rowId = this.state.rowId;
  
    var params = {
      to_mail: to_mail,
      cc_mail: cc_mail,
      rowId: rowId,
    };
    console.log(params,'params----')
    redirectURL.post("/arms/editautomaileremails",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response) => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data,'data----');
      if (response.data.message === "success") {
        this.setState({
          basicTitle: "Data updated successfully.",
          basicType: "success",
          show: true,
          rowIds: [],
          loadshow: "show-n",
          overly: "show-n",
          bulkslide: "",
        });
        this.componentDidMount()
        // this.onloadPageData();
      } else {
        this.setState({
          basicTitle: response.data.message,
          basicType: "warning",
          show: true,
          loadshow: "show-n",
          overly: "show-n",
          bulkslide: "",
        });
        this.componentDidMount()
        // this.onloadPageData();
      }
    }
      
    });
  }
  removemail = () => {
    this.setState({
      bulkslide1: "slider-translate-30p",
      // rowId: rowId,
      overly:"show-m"
    });
  };

  onChangeremovetomails = (removemail) => {
    console.log(removemail,'tomailremovemail')
this.setState({
remove_to_mail:removemail
})
  }
  onChangeremoveccmails = (removemail) => {
    console.log(removemail,'ccmailremovemail')
this.setState({
  remove_cc_mail:removemail
})
  }

  onClickRemoveFilterData(event) {
    event.preventDefault();
    this.setState({
      loadshow: "show-m",
      overly: "show-m",
    });
  
    var remove_to_mail = this.state.remove_to_mail ? this.state.remove_to_mail.map((email) => email.value) : [];
    var remove_cc_mail = this.state.remove_cc_mail ? this.state.remove_cc_mail.map((email) => email.value) : [];
  
    var params = {
      remove_to_mail: remove_to_mail,
      remove_cc_mail: remove_cc_mail,
    };
  
    // console.log(remove_to_mail.length !== [] && remove_cc_mail.length !== [], '343');
    if (remove_to_mail.length !== 0 || remove_cc_mail.length !== 0) {
      var cnf = window.confirm("Data once submitted will not be retrieved. Do you want to submit?");
      if (cnf === true) {
        console.log(params, 'params----removed');
        redirectURL.post("/arms/removeautomaileremails", params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response, 'data----remove');
          if (response.data.message === "success") {
            this.setState({
              basicTitle: "Data updated successfully.",
              basicType: "success",
              show: true,
              rowIds: [],
              loadshow: "show-n",
              overly: "show-n",
              bulkslide1: "",
            });
            this.componentDidMount();
            // this.onloadPageData();
            this.setState({
              remove_to_mail: [], // Clearing the remove_to_mail state
              remove_cc_mail: [], // Clearing the remove_cc_mail state
            });
          } else {
            this.setState({
              basicTitle: response.data.message,
              basicType: "warning",
              show: true,
              loadshow: "show-n",
              overly: "show-n",
              bulkslide: "",
            });
            this.componentDidMount();
            // this.onloadPageData();
            this.setState({
              remove_to_mail: [], // Clearing the remove_to_mail state
              remove_cc_mail: [], // Clearing the remove_cc_mail state
            });
          }
        }
          
        });
      }
    } else {
      alert("Enter Mails to Remove");
    }
  }
  render() {
    const batchwisecolumns = [
        {
            pinned:"left",
            headerName : "",
            field:"edit",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){      
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
          headerName: "Automailer Name",
          field: "automailer_name",
          width: 350,
        },
        {
            headerName: "To emails",
            field: "to_emails",
            width: 350,
           
          },
     
      {
        headerName: "CC emails",
        field: "cc_emails",
        width: 350,
      },
    
     
    ];
    return (
      <div class="container-fluid">
             <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h5>
                  <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                  <span>Auto Mailer Screen</span>
                <button
                className="btn btn-arms"
                style={{ float: "right" }}
                onClick={this.removemail.bind(this)}
              >
                Remove Mail
              </button>
                </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "478px" }}
                    className="ag-theme-balham"
                  >
                    <AgGridReact
                      modules={this.state.modules}
                      columnDefs={batchwisecolumns}
                      defaultColDef={this.state.defaultColDef}
                      rowData={this.state.rowData}
                      enableCharts={false}
                      // autoGroupColumnDef={this.state.autoGroupColumnDef}
                      onGridReady={this.onGridReady}
                      onGridState={this.onGridState}
                      frameworkComponents={this.state.frameworkComponents}
                      rowClassRules={this.state.rowClassRules}
                      // statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      stopEditingWhenGridLosesFocus={true}
                      onCellClicked={this.onCellClicked.bind(this)}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      gridOptions={{
                        context: { componentParent: this },
                      }}
                      // components={this.state.components}
                      //   onRowSelected={this.onSelectedCheckbox.bind(this)}
                      enableRangeSelection={true}
                      // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
          className={"slide-r " + this.state.bulkslide}
          style={{ overflow: "scroll" }}
        >
          <h3 className="subHarms">
            Auto Mailer Update
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>
          <div className="col-xl-12 col-lg-12 " style={{margin:'20px  20px 0px 20px'}}>
                  <label className="col-xl-12 col-lg-12">
                  Auto Mailer Name :
                  </label>
                  <input
                    type="text"
                  
                    value={this.state.mailerName}
                  />
                </div>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.onClickShowFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >



            <div className="form-group col-xl-12 col-lg-12">
  <label>To Mail:</label>
  <CreatableSelect
    isMulti={true}
    isClearable
    placeholder="Enter To Mails"
    id='to_mail'
    name='to_mail'
    value={this.state.selected_to_mails}
    options={this.state.edit_to_emails.map(email => ({ label: email,value: email }))}
    onChange={this.onChangetomails.bind(this)}
    required
    
    className="basic-multi-select"
  />
</div>
<div className="form-group col-xl-12 col-lg-12">
  <label>CC Mail:</label>
  <CreatableSelect
    isMulti={true}
    isClearable
    value={this.state.selected_cc_mails}
    placeholder="Enter CC Mails"
    id='cc_mail'
    name='cc_mail'
    options={this.state.edit_cc_emails.map(email => ({ label: email, value: email }))}
    onChange={this.onChangeccmails.bind(this)}
    required
  />
</div>
<div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-arms">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={"slide-r " + this.state.bulkslide1}
          style={{ overflow: "scroll" }}
        >
          <h3 className="subHarms">
            Remove Mail 
            <span
              className="float-right fclose"
              style={{
                marginRight: "12px",
                padding: "1px 8px",
                marginTop: "-2px",
              }}
              onClick={this.onClickHideAll.bind(this)}
            >
              x
            </span>
          </h3>

          <div className="slide-r-body" style={{ position: "relative" }}>
            <div className="container-fluid">
              <form
                method="post"
                onSubmit={this.onClickRemoveFilterData.bind(this)}
                autocomplete="off"
                className="row col-xl-12 col-lg-12"
              >
            <div className="form-group col-xl-12 col-lg-12">
  <label>To Mail:</label>
  <CreatableSelect
    isMulti={true}
    isClearable
    placeholder="Enter To Mails"
    id='remove_to_mail'
    name='remove_to_mail'
    // value={this.state.selected_to_mails}
    // options={this.state.edit_to_emails.map(email => ({ label: email,value: email }))}
    onChange={this.onChangeremovetomails.bind(this)}
    required
    
    className="basic-multi-select"
  />
</div>
<div className="form-group col-xl-12 col-lg-12">
  <label>CC Mail:</label>
  <CreatableSelect
    isMulti={true}
    isClearable
    // value={this.state.selected_cc_mails}
    placeholder="Enter CC Mails"
    id='remove_cc_mail'
    name='remove_cc_mail'
    // options={this.state.edit_cc_emails.map(email => ({ label: email, value: email }))}
    onChange={this.onChangeremoveccmails.bind(this)}
    required
  />
</div>
<div className="form-group col-xl-3 col-lg-3">
                  <button type="submit" className="btn btn-arms">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        </div>
        <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>
    );
  }
}
