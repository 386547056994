import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import $ from "jquery";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import Select from 'react-select';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Map from './mapLocationComponent';

import redirectURL from '../redirectURL';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
}from 'react-accessible-accordion';



export default class ForceCloseSideBar extends Component {

    constructor(props) {
        super(props);
        this.state={
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
			rowData: null,
			animateRows: true,
            reasonforceclose : "",
            
            selectedReason : "",
            displayReasonField : "show-n"

        };
       // this.showRelatedData()
       
    } 
    componentDidMount(){
        console.log("sidebar");
    }
    componentWillReceiveProps(){
        console.log()
    }
    selectReason(){
        
        try{
        let arr=[
            {value:"GPS Data Not Available",label:"GPS Data Not Available"},
            {value:"Consignee location not updated",label:"Consignee location not updated"},
            {value:"Consignment unloaded at different/secondary location",label:"Consignment unloaded at different/secondary location"},
            {value:"Transshipment",label:"Transshipment"},
            {value:"NCR Consignments",label:"NCR Consignments"},
            {value:"Others",label:"Others"},

        ];
        if(this.props.deptcode == "SNDG")
        {
            arr.splice(4,0,{value:"TVP-B: Chennai Stockyard Consignment",label:"TVP-B: Chennai Stockyard Consignment"})
        }
        if(this.props.deptcode == "LOG-PRT")
        {
            arr.splice(4,0,{value:"Pass Through Courier - Direct Delivery Project",label:"Pass Through Courier - Direct Delivery Project"},{value:"Same GR - Multi Truck Dispatch",label:"Same GR - Multi Truck Dispatch"},
            {value:"North East Trans-shipment",label:"North East Trans-shipment"},)
        }
        return arr;
        }
        catch(e){

        }
        
    }
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({reasonforceclose:value});
    }
    selecetedReason(params) {
        //console.log(params);
        try{
            this.setState({
                selectedReason : params.value
            });
            //console.log(params.value);
            if(params.value == "Others"){
                this.setState({
                    displayReasonField : "show-m"
                })
            }
            else{
                this.setState({
                    displayReasonField : "show-n"
                })
            }
        }catch(e){}

    }
    handleSubmit(){
        var valuesArr = [this.state.reasonforceclose,this.state.selectedReason]
        this.props.context.handleInput(valuesArr);
    }

    render(){
    
        return ( 
                <div className="col-xl-12 co-xl-12">
                    <div className="form-group col-xl-12 col-lg-12">
                            <label>Select Reason </label>
                            <Select 
                           onChange={this.selecetedReason.bind(this)} 
                            closeMenuOnSelect={true}
                            className={"border-radius-0"}
                            style={{borderRadius:"0px"}}
                            options={this.selectReason()} />            
                    </div>
                    {/* <div className="form-group col-xl-12 col-lg-12"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >                 */}
                            <div className={"form-group col-xl-12 col-lg-12 "+(this.state.displayReasonField)}>
                                <label className="col-xl-12 col-lg-12">Reason for closure</label>
                                <textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} value={this.props.context.reasonForClose} rows="5" className="form-control col-xl-12 col-lg-12"></textarea>
                            </div>
                            {/* <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
                            </div> */}
                    {/* </div> */}
                    {/* <div className="form-group col-xl-12 col-lg-12"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >                 */}
                            <div className="form-group col-xl-12 col-lg-12">
                                <button type="submit" className="btn btn-success" onClick={this.handleSubmit.bind(this)}>Submit</button>
                            </div>
                    {/* </div> */}
                </div>
			);
		}
}