'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class PlantwiseModelCategory extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        transportersList : [],
        rakesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        plantList : [],
        sbvhList : [],
        sbvh_code :"",
        plant:"",
        load_factor:"",
        color_code:"",
        color_type:"",
        edit_color_code:"",
        edit_color_type:"",
        edit_car_model : "",
        edit_sbvh_code : "",
        edit_load_factor : "",
        edit_plant:"",
        rowId:"",
        sbvhList:[],
        modelcatgList:[],
        model_catg:"",
        sbvh_codes:[],
        edit_sbvh_codes:[],
        addPlant:"",
        edit_addPlant:"",
        plantList:[],

        //components : {dapicker:getDatePicker()}
    }
    // this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFortemHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  
  
componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
    redirectURL.post("/arms/getdistinctmodels").then((response)=>{
        var resp1 = response.data.modelcatgList
        var resp2 = response.data.sbvhList
        var resp3 = response.data.plantList
        console.log(resp1,resp2,resp3,"184")
        var sbvhList = []
        var modelcatgList = []
        var plantList =[]
        resp1.map((e)=>{
            modelcatgList.push({
                value:e,
                label:e
            })
        })
        resp2.map((b)=>{
            sbvhList.push({
                value:b,
                label:b
            })
        })
        resp3.map((e)=>{
            plantList.push({
                value:e.plant_code,
                label:e.plant_name
            })
        })
        this.setState({
            modelcatgList : modelcatgList,
            sbvhList : sbvhList,
            plantList:plantList
        })
    })
}

onLoadPageData(){
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    redirectURL.post("/arms/getplantwisemodelcategorydetails").then((response)=>{
        console.log(response.data);
        this.setState({
            rowData : response.data,
            loadshow:"show-n",
            overly:"show-n"
        })
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    this.setState({
        show: false
    });
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            user_name: localStorage.getItem('username'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteModelCategoryDetails",qry).then((response)=>{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                         overly:"show-n"
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        }else{
            this.setState({
                loadshow:"show-n",
                overly:"show-n"
            })
        }       
    }
    if(e.colDef.field == "update"){
        var sbvh_codes = e.data.sbvh_codes
        console.log(sbvh_codes,"309")
        var editSbvhCodes = []
        sbvh_codes.map(async(f)=>{
            editSbvhCodes.push(
                {"value":f,"label":f}
            )
        })
        this.setState({
            edit_sbvh_codes:editSbvhCodes,
            bulkslide2:"slider-translate",
            overly:"show-m",
            edit_addPlant:{"label":e.data.plant_name,"value":e.data.plant_code},
            rowId:e.data._id
        })
        $("#edit_model_category").val(e.data.model_catg)
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide1:"",
    bulkslide2:"",
    sbvh_codes :[],
    addPlant:"",
    bulkslide1:""
})
$("#model_category").val("")
    
}
onClickHideAll1(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        bulkslide1:"",
        bulkslide2:"",
        sbvh_codes :[],
        addPlant:"",
        bulkslide1:""
    })
    $("#model_category").val("")
}
updateLmStates(params)
{}
addNewRow()
{
    // console.log(this.gridApi);
    this.setState({
        bulkslide1:"slider-translate",
        overly:"show-m"
    })
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['color_code', 'color_code']
    };
    this.gridApi.exportDataAsCsv(params);
}
onClickBulkDelete(){
    var rowIds = this.state.rowIds;
    console.log(rowIds,"rowIds")
    if(rowIds.length > 0)
    {
        var qry = {
            rowIds:rowIds,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_role: localStorage.getItem('roles'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteBulkCarDetails",qry).then((response)=>{
                console.log(response.data);
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true,
                        rowIds : []
                    })
                    
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }).catch((e)=>{
                console.log(e);
            })
        } 
    }
    else
    {
        this.setState({
            basicTitle:"Please select the Row(s) to continue.",
            basicType : "warning",
            show : true
        })
    }
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}

onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
selectSbvhCode = (e)=>{
    this.setState({
        sbvh_code :e
    })
}
editselectSbvhCode = (e)=>{
    this.setState({
        edit_sbvh_code :e
    })
}
selectplant = (e)=>{
    this.setState({
        plant :e
    })
}
editselectplant= (e)=>{
    this.setState({
        edit_plant :e
    })
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
    submitModelCategoryDetails = (event)=>{
        event.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var plant_name = this.state.addPlant.label
        var plant_code = this.state.addPlant.value
        var sbvhcodes = this.state.sbvh_codes
        var model_category = $("#model_category").val()
        var sbvhValues = []
        sbvhcodes.map((e)=>{
            sbvhValues.push(e.value)
        })
         var params = {
            created_by : localStorage.getItem("username"),
            plant_name:plant_name,
            plant_code:plant_code,
            sbvh_codes:sbvhValues,
            model_catg:model_category
         }
        redirectURL.post("/arms/addmodelmappingdetails",params).then((response)=>{
            if(response.data.message == "Success"){
                this.setState({
                    basicTitle:"Data Suceessfully Updated",
                    basicType:"success",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n",
                    sbvh_codes :[],
                    addPlant:"",
                    bulkslide1:""
                })
                $("#model_category").val("")
                this.onLoadPageData()
            }else{
                this.setState({
                    basicTitle:response.data.message,
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n",
                    sbvh_codes :[],
                    addPlant:"",
                    bulkslide1:""
                })
                $("#model_category").val("")
            }
        })
           
    }
    editModelCategoryDetails = (event) =>{
        event.preventDefault()
        var model_category = $("#edit_model_category").val()
        var plant_name = this.state.edit_addPlant.label
        var plant_code = this.state.edit_addPlant.value
        var sbvhcodes = this.state.edit_sbvh_codes
        var sbvhValues = []
        sbvhcodes.map((e)=>{
            sbvhValues.push(e.value)
        })
        var updated_by = localStorage.getItem("username")
        var rowId = this.state.rowId
         var params = {
            rowId : rowId,
            updated_by : model_category,
            plant_name : plant_name,
            plant_code :plant_code,
            sbvh_codes :sbvhValues,
            model_catg:model_category,
            updated_by:updated_by
         }
        redirectURL.post("/arms/editmodelmappingdetails",params).then((response)=>{
            if(response.data.message == "Success"){
                this.setState({
                    basicTitle:"Data Suceessfully Updated",
                    basicType:"success",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n",
                    bulkslide2:""
                })
                this.onLoadPageData()
            }else{
                this.setState({
                    basicTitle:response.data.message,
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n",
                    bulkslide2:""
                })
            }
        })
           
    }
    selectsbvhcodes =(e) =>{
        this.setState({
            sbvh_codes:e
        })
    }
    editselectsbvhcodes =(e) =>{
        this.setState({
            edit_sbvh_codes:e
        })
    }
    
    selectplant =(e)=>{
        this.setState({
           addPlant: e,
        })
    }
    editselectplant =(e)=>{
        this.setState({
            edit_addPlant: e,
         })
    }
    changeHandler = (event) => {
        console.log("Select", event.target.value);
    
        var name = event.target.name;
        var value = event.target.value;
        const re = /^[0-9]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }
    
        console.log(name);
    }
  render() {
    var columnwithDefs = [
        {
            headerName: "Plant",
            field: "plant_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,      
        },   
        {
            headerName: "Model Categorty",
            field: "model_catg",
            width:150,
            filter: true,
            resizable: true,
            editable:false,      
        },
        {
            headerName: "SBVH",
            field: "sbvh_codes",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            pinned:"left",
            headerName : "Update",
            field:"update",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },       
        // {
        //     pinned:"left",
        //     headerName: "id",
        //     field: "id",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card-arms col-xl-12">
                <div className="card-header-arms">
                    <h5>
                        <i className="icofont icofont-car cus-i"></i> <span>Model Category Mapping</span>
                        
                        {/* <button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickBulkDelete.bind(this)}>Delete</button> */}
                    </h5>
                </div>

            <div>
            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Add Model Category Details</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
            </div>
            <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row"> */}
                            
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>
                            </div>
                        </form> */}
                    </div>
            <div className="grid-container col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Model Category Details <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitModelCategoryDetails}>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label>Plant:</label>
                                    <Select
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        value={this.state.addPlant}
                                        onChange={this.selectplant.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.plantList} required
                                    />
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                      <label className="col-xl-12 col-lg-12">Model Category : </label>
                                      <input type="number" name="model_category" id="model_category" className="form-control"
                                       onChange={this.changeHandler.bind(this)} required />
                                  </div>  
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>SBVH Codes:</label>
                                        <Select
                                            placeholder={"Select SBVH Codes"}
                                            closeMenuOnSelect={true}
                                            value={this.state.sbvh_codes}
                                            isMulti ={true}
                                            onChange={this.selectsbvhcodes.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.sbvhList} required
                                        />
                                </div>
                               
                        <div className="form-group">
                            <button type="submit" className="btn btn-arms">Submit</button>
                            {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                        </div>
                    </form>			
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Edit Color Type Details <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                <form method="POST" id="upform" className="theme-form" onSubmit={this.editModelCategoryDetails}>
                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Plant:</label>
                                    <Select
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        value={this.state.edit_addPlant}
                                        onChange={this.editselectplant.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.plantList} required
                                    />
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                      <label className="col-xl-12 col-lg-12">Model Category : </label>
                                      <input type="number" name="edit_model_category" id="edit_model_category" className="form-control"
                                       onChange={this.changeHandler.bind(this)} required />
                                  </div>  
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>SBVH Codes:</label>
                                        <Select
                                            placeholder={"Select SBVH Codes"}
                                            closeMenuOnSelect={true}
                                            value={this.state.edit_sbvh_codes}
                                            isMulti ={true}
                                            onChange={this.editselectsbvhcodes.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.sbvhList} required
                                        />
                                </div>
                               
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>			
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}
$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
  $(document).on("input", ".alfa-num-cls", function () {
    this.value = this.value.replace(/^[a-zA-Z0-9\s-]*$/gi, '');
  });

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};