/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { Link } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RailSchedulingSummary extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            remarks_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            request_id:"",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            plan_month:"",
            plan_month_upload:"",
            targetdetailstabactive:"btn-arms",
            railscheduledsactive:"",
            summarytabactive:"",
            railschedulingtabstatusactive:"",
            targetdetailsshow:"show-m",
            railscheduledshow:"show-n",
            summaryshow:"show-n",
            railschedulingshow:"show-n",
            pagetitle :"Target Details"+" - "+window.atob(this.props.match.params.transaction_id),
            weekheadername:"Month",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            transaction_id:"",
            detailCellRendererParams:{},
            remarks_history_cols:[],
            columnwithDefs:[]
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        await loadDateTimeScript();
        console.log(this.props.match.params , "params_1729")
         var transaction_id = window.atob(this.props.match.params.transaction_id)
        //  var stage = window.atob(this.props.match.params.stage)
        //  var stage = parseInt(stage)
         console.log(transaction_id,"307")
         this.setState({
            transaction_id : transaction_id,
            // stage : stage
         })
         await this.onloadDataforTab1()
         await this.onloadDataforTab3()
    }
    onloadDataforTab1(){
        this.setState({
            loadshow:"show-m",
            overly :"show-m"
         })
         var params = {
            transaction_id : this.state.transaction_id
         }
         redirectURL.post("/arms/getDatafortargetdetails",params,{"headers":
         {											
             'Content-Type': 'application/json;charset=UTF-8', 
             'Authorization': `Bearer ${localStorage.getItem("token")}`
     }
     }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            // console.log(response.data.data,"275")
            var respData = response.data.data
            if(respData.length >0){
                var resp = JSON.parse(respData[0].clustershare)
                console.log(resp,"281")
                var rowData = resp 
                    var columns =[]
                    // columns.push({
                    //     headerName:"FOR Code",
                    //     field:"city_code",
                    //     editable:false,
                    //     width:150
                    // })
                    columns.push({
                        headerName:"Unloading Cluster",
                        field:"unloading_cluster",
                        editable:false,
                        width:150
                    })
                    var rdData =[]
                    if(rowData.length > 0){
                        console.log(rowData,"298")
                        var plantNames  = groupBy(rowData,rData=>rData.loading_cluster)
                        plantNames.forEach((pname,pkey)=>{
                                columns.push({
                                    headerName:pkey,
                                    field:pkey,
                                    editable:false,
                                    width:150,
                                    valueGetter:function(params){
                                        if(["Bidadi", "Haryana", "Gujarat"].includes(pkey)){
                                            if(params.data[pkey] == undefined){
                                                return 0
                                            }else{
                                                return params.data[pkey]
                                            }
                                        }
                                    }
                                })
                        })
                        var planData = groupBy(rowData,rdata=>rdata.city_code)
                        planData.forEach((pMonths,mKey)=>{
                            let temp_row = {"city_code": mKey}
                            pMonths.map((pm)=>{
                                temp_row[pm.loading_cluster] = pm.cars
                                temp_row['unloading_cluster'] = pm.unloading_cluster
                            });
                            rdData.push(temp_row);
                        })
                    }
                    console.log(columns,"2473")
                    console.log(rdData,"2486")
                    // console.log(resp,"1994")
                    if(resp != undefined && resp != ""){
                        this.setState({
                            rowData : rdData,
                            columnwithDefs:columns,
                            loadshow:"show-n",
                            overly :"show-n"
                        })
                    }else{
                        this.setState({
                            rowData :[],
                            columnwithDefs:[]
                        })
                    }
                // console.log(checkData)   
            }else{
                this.setState({
                    rowData : [],
                    columnwithDefs:[],
                    loadshow:"show-n",
                    overly :"show-n"
                })
            }
        }
         })
    }
    onloadDataforTab3(){
        this.setState({
            loadshow:"show-m",
            overly :"show-m"
         })
         var params = {
            transaction_id : this.state.transaction_id
         }
         console.log(params,"367")
         redirectURL.post("/arms/getDataforrailschedule",params,{"headers":
         {											
             'Content-Type': 'application/json;charset=UTF-8', 
             'Authorization': `Bearer ${localStorage.getItem("token")}`
     }
     }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            // console.log(response.data.data,"275")
            var checkData = response.data.data
            console.log(checkData,checkData.length,"307")
            if(checkData.length >0){
                this.setState({
                    rowData2 : checkData,
                    loadshow:"show-n",
                    overly :"show-n"
                })
            }else{
                this.setState({
                    rowData2 : [],
                    loadshow:"show-n",
                    overly :"show-n"
                })
            }
        }
         })
    }
    
    onClickHideAll1() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
        });
    }
    
    onClickShowTabView(tab){
        if(tab == "targetdetails")
        {
            this.setState({
                targetdetailstabactive:"btn-arms",
                summarytabactive:"",
                railscheduledsactive:"",
                targetdetailsshow:"show-m",
                railscheduledshow:"show-n",
                summaryshow:"show-n",
                pagetitle:"Target Details"+" - "+this.state.transaction_id
            })
            this.onloadDataforTab1()
        }
        if(tab == "summary")
        {
            this.setState({
                targetdetailstabactive:"",
                summarytabactive:"btn-arms",
                railscheduledsactive:"",
                targetdetailsshow:"show-n",
                railscheduledshow:"show-n",
                summaryshow:"show-m",
                pagetitle:"Summary"+" - "+this.state.transaction_id
            })
        }
        if(tab == "railscheduled")
        {
            this.setState({
                targetdetailstabactive:"",
                summarytabactive:"",
                railscheduledsactive:"btn-arms",
                targetdetailsshow:"show-n",
                railscheduledshow:"show-m",
                summaryshow:"show-n",
                pagetitle:"Rail Schedule"+" - "+this.state.transaction_id,
            })
            this.onloadDataforTab3()
        } 
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onClickBack=()=>{
        window.location.href="/railscheduling"
    }
    render() {
        // console.log(this.state.stage , "this.state.stage")
        const columnwithDefs = [
            {
                headerName: "Plant",
                field: "loading_cluster",
                width: 150,
              editable:false,
              filter: "agSetColumnFilter",
            },
            {
                headerName: "FOR Code",
                field: "city_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Unloading Cluster",
                field: "unloading_cluster",
                width: 150,
              editable:false,
            },
            // {
            //     headerName: "Depot Code",
            //     field: "depot_code",
            //     width: 150,
            //   editable:false,
            //   filter: "agSetColumnFilter",
              
            // },
            {
                headerName: "Vins",
                field: "cars",
                width: 80,
              editable:false,
              filter: "agSetColumnFilter",
            },
            
        ]
        const columnwithDefs2 =[
            {
                headerName: "Vehicle Id",
                field: "rake_name",
                width:100,
                filter: true,
                resizable: true,
                editable:false,   
            },
            {
                headerName: "Transporter",
                field: "transporter_code",
                width:110,
                filter: true,
                resizable: true,
                editable:false,     
            },
            {
                headerName: "Loading Plant",
                field: "loading_plant",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Source Station",
                field: "loading_yard",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Destination Name",
                field: "unloading_yard",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Unloading Circuit",
                field: "unloading_circuit",
                width:150,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName: "Trip Started Date",
                field: "start_time",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.start_time !=undefined && params.data.start_time !='')
                    {
                        return getHyphenDDMMMYYYY(params.data.start_time);
                    }
                }
            },
            {
                headerName: "Trip End Date",
                field: "end_time",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                valueGetter: function(params){
                    if(params.data.end_time !=undefined && params.data.end_time !='')
                    {
                        return getHyphenDDMMMYYYY(params.data.end_time);
                    }
                }
            },
            {
                headerName: "Demand",
                field: "no_of_cars",
                width:90,
                filter: true,
                resizable: true,
                editable:false
            },
            // {
            //     headerName: "Distance",
            //     field: "distance",
            //     width:90,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
            // {
            //     headerName: "Comments",
            //     field: "comments",
            //     width:140,
            //     filter: true,
            //     resizable: true,
            //     editable:false
            // },
        ]
        const columnwithDefs3 =[
                {
                    pinned:"left",
                   headerName: "View Details",
                   field: "history_view_data",
                   width:140,
                   filter: true,
                   resizable: true,
                   editable:false,
                   cellRenderer:function(params){
                       var htmloption = '<button class="btn btn-arms label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                       return htmloption
                   } 
               },{
                   pinned:"left",
                   headerName: "View Target Details",
                   field: "view_target_details",
                   width:150,
                   filter: true,
                   resizable: true,
                   editable:false,
                   cellRenderer:function(params){
                       var htmloption = '<button class="btn btn-danger label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                       return htmloption
                   } 
               },
               {
                   pinned:"left",
                   headerName: "Generate Plan",
                   field: "run_plan",
                   width:180,
                   filter: true,
                   resizable: true,
                   editable:false,
                   cellRenderer:function(params){
                       var htmloption = '<button class="btn btn-success label label-success" id="buttonStyling"><i class="icofont-play-circle"></i>Run New Plan</button>';
                       return htmloption
                   } 
               },
               {
                   headerName: "Request Id",
                   field: "request_id",
                   width:160,
                   filter: true,
                   resizable: true,
                   editable:false,
               },
               {
                   headerName: "Month",
                   field: "plan_month",
                   width:120,
                   filter: true,
                   resizable: true,
                   editable:false,
               },
               {
                   headerName: "Week",
                   field: "week",
                   width:120,
                   filter: true,
                   resizable: true,
                   editable:false,
                   
               },
               {
                   headerName: "Revision",
                   field: "revision",
                   width:140,
                   filter: true,
                   resizable: true,
                   editable:false
               },
               {
                   headerName : "Approved By",
                   field:"approved_by",
                   width:160,
                   resizable : true,
                   editable : false,
                   
               },
               {
                   headerName : "Approved On",
                   field:"approved_on",
                   width:160,
                   resizable : true,
                   editable : false,
                   valueGetter:function(params){
                       return getHyphenYYYYMMDDHHMMSS(params.data.approved_on)
                   }
               },
               {
                   headerName : "Plan Type",
                   field:"plan_type",
                   width:160,
                   resizable : true,
                   editable : false,
                   
               }
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
                <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.targetdetailstabactive)} onClick={this.onClickShowTabView.bind(this,"targetdetails")}>Target Details</button>
                        {/* <button type="button" className={"btn "+(this.state.summarytabactive)} onClick={this.onClickShowTabView.bind(this,"summary")}>Summary</button> */}
                        <button type="button" className={"btn "+(this.state.railscheduledsactive)} onClick={this.onClickShowTabView.bind(this,"railscheduled")}>Rail Schedule</button>
                </div>
                
                    <div className={"col-xl-12 col-lg-12 "+(this.state.targetdetailsshow)}>
                    <div className="card-arms col-xl-12">
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                                </h5>
                            </div>
                            <div>
                            <button className="float-right f15 custom-btn btn-arms" style={{ padding: "6px 11px", marginRight: "2%",borderRadius:"5px",position:"relative" }} onClick={this.onClickBack.bind(this)}>
                           Back
                            </button> 
                            </div>

                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.summaryshow)}>
                    <div className="card-arms col-xl-12">
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                                </h5>
                            </div>
                            {/* <div>
                            <Link to={{
                                    pathname: '/targetpreparation',
                                    state: { propName: 'tab_4' } // Replace propName and propValue with your actual props
                                }} className="btn btn-arms" style={{ float: "right", borderRadius: "5px" }}>
                                    Back
                                </Link>                            </div> */}

                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData2}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    </div> 
                    <div className={"col-xl-12 col-lg-12 "+(this.state.railscheduledshow)}>
                    <div className="card-arms col-xl-12">
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                                </h5>
                            </div>
                            <div>
                            <button className="float-right f15 custom-btn btn-arms" style={{ padding: "6px 11px", marginRight: "2%",borderRadius:"5px",position:"relative" }} onClick={this.onClickBack.bind(this)}>
                           Back
                            </button> 
                            </div>

                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData2}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    
               
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});