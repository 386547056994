'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class RakePlanningTransporters extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        bulkslide2:"",
        transporter_code:'',
        transporter_short_name:'',
        transporter_name:'',
        // transporter_code2:'',
        // transporter_short_name2:'',
        // transporter_name2:'',
        rowId :'',
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",

        //components : {datepicker:getDatePicker()}
    }
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  

componentDidMount(){
    document.body.addEventListener("click", this.handleClickOutside);
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
}

onLoadPageData(){
    redirectURL.post("/arms/getRakeTransporters",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            transporter_code : e.data.transporter_code,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_role: localStorage.getItem('roles'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteRakeTransporters",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    window.location.reload();
                    this.onGridState();
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
    if(e.colDef.field == "edit"){
        var data = e.data;
        var transporter_code2 = $("#edit_transporter_code").val(data.transporter_code)
        var transporter_short_name2 = $("#edit_transporter_short_name").val(data.transporter_short_name)
        var transporter_name2 = $("#edit_transporter_name").val(data.transporter_name)
        this.setState({
            bulkslide2:"slider-translate-30p",
            rowId :data._id,
            overly:"show-m"
        })
       
    } 
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide1:"",
    bulkslide2:"",
    });
    $("#transporter_code").val("")
    $("#transporter_short_name").val("")
    $("#transporter_name").val("")
}
onClickHideAll1(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        sliderRso:"",
        sliderUpdate:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:"",
       
    }); 
}
handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) { // Close the slider when clicking outside of it 
       this.onClickHideAll(); 
   }
};


addNewRow()
{
     this.setState({
        bulkslide1:"slider-translate-30p",
        overly:"show-m"
    })
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['transporter_code', 'transporter_short_name', 'transporter_name']
    };
    this.gridApi.exportDataAsCsv(params);
}

resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}
onClickShowFilterData(event) {
    event.preventDefault();
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var transporter_code = $("#transporter_code").val()
    var transporter_short_name = $("#transporter_short_name").val()
    var transporter_name = $("#transporter_name").val()
    if(transporter_code != undefined && transporter_code != null && transporter_code != ""){
        if(transporter_short_name != undefined && transporter_short_name != null && transporter_short_name != ""){
            if(transporter_name != undefined && transporter_name != null && transporter_name != ""){
                var params ={
                    transporter_code : transporter_code,
                    transporter_short_name : transporter_short_name,
                    transporter_name : transporter_name,
                    username : localStorage.getItem("username")
                }
                console.log(params,"557")
            
                redirectURL.post("arms/getaddrakeplan" , params,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then(response=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response)
                    if(response.data.message == "Success")
                        {
                            this.setState({
                                basicTitle:response.data.message,
                                basicType : "success",
                                show : true,
                                rowIds : [],
                                loadshow:"show-n",
                                overly:"show-n",
                                bulkslide1:""
                            })
                            $("#transporter_code").val("")
                            $("#transporter_short_name").val("")
                            $("#transporter_name").val("")
                           
                           
                            //  this.loadData(); 
                            //window.location.reload();
                            this.onLoadPageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:response.data.message,
                                basicType : "warning",
                                show : true,
                                loadshow:"show-n",
                                overly:"show-n",
                                bulkslide1:""
                            })
                        }
                    }
                })
            }else{
                this.setState({
                    basicTitle:"Please Add Transporter Name",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
            
        }else{
            this.setState({
                basicTitle:"Please Add Transporter Short Name",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
            
    }else{
        this.setState({
            basicTitle:"Please Add Transporter Code",
            basicType:"warning",
            show:true,
            loadshow:"show-n",
            overly:"show-n"
        })
    }
    
    

}
onClickShowFilterData2(event) {
    event.preventDefault();
    var transporter_code2 = $("#edit_transporter_code").val()
    var transporter_short_name2 = $("#edit_transporter_short_name").val()
    var transporter_name2 = $("#edit_transporter_name").val()
    var rowId = this.state.rowId
       
    if(transporter_code2 != undefined && transporter_code2 != null && transporter_code2 != ""){
        if(transporter_short_name2 != undefined && transporter_short_name2 != null && transporter_short_name2 !=""){
            if(transporter_name2 != undefined && transporter_name2 != null && transporter_name2 != ""){
                var params ={
                    transporter_code:transporter_code2,
                    transporter_short_name:transporter_short_name2,
                    transporter_name:transporter_name2,
                    username : localStorage.getItem("username")
                }
                console.log(params,"params")
                redirectURL.post("arms/geteditrakeplan" , { params ,rowId},{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then(response=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response)
                    if(response.data.message == "success")
                        {
                            this.setState({
                                basicTitle:"Update Data Successfully.",
                                basicType : "success",
                                show : true,
                                rowIds : [],
                                bulkslide2:""
                            })
                            $("#transporter_code2").val("")
                            $("#transporter_short_name2").val("")
                            $("#transporter_name2").val("")
                           
                            
                            //window.location.reload();
                            this.onLoadPageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:response.data.message,
                                basicType : "warning",
                                show : true,
                                bulkslide2:""
                            })
                        }
                    }
                })
            }else{
                this.setState({
                    basicTitle:"Please Add Transporter Name",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
            
        }else{
            this.setState({
                basicTitle:"Please Add Transporter Short Name",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
        
    }else{
        this.setState({
            basicTitle:"Please Add Transporter Code",
            basicType:"warning",
            show:true,
            loadshow:"show-n",
            overly:"show-n"
        })
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
changeHandler1 = (event) => {
    console.log("Select", event.target.value);

    var {value , name} = event.target

    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(value) || value === '') {
      this.setState({
        [name] :value
      })
    }

}


onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}

  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [   
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:250,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Transporter Short Name",
            field: "transporter_short_name",
            width:250,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Transporter Name",
            field: "transporter_name",
            width:200,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            pinned:"left",
            headerName : "",
            field:"edit",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
        {this.state.alert}
        <div className="row">
        <div className="col-xl-12 col-lg-12">
            <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Transporters Setup</span>
                    </h5>
                    </div>
                    <h5>
                </h5>
            <div>
            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Add Transporter</button>
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
            </div>
            {/* <div className="card-body col-xl-12 col-lg-12"> */}
            <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                {/* <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}> */}
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
            </div>
        </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Add Transporter Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll1.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Code :</label>
                                            <input type="text" name="transporter_code" id="transporter_code" value = {this.state.transporter_code} className="form-control" onChange={this.changeHandler1} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Short Name :</label>
                                            <input type="text" name="transporter_short_name" id="transporter_short_name" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Name:</label>
                                            <input type="text" name="transporter_name" id="transporter_name" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="form-group">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit Transporter Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                            <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                            <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Code :</label>
                                            <input type="text" name="edit_transporter_code" id="edit_transporter_code" 
                                            value ={this.state.edit_transporter_code} className="form-control" onChange={this.changeHandler1} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Short Name :</label>
                                            <input type="text" name="edit_transporter_short_name" id="edit_transporter_short_name" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Transporter Name:</label>
                                            <input type="text" name="edit_transporter_name" id="edit_transporter_name" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                  
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

      </div>

      

    );
  }
}
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".alfa-num-cls", function () {
    this.value = this.value.replace(/[^a-zA-Z0-9]*$/gi, '');
  });

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    // script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};