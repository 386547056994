/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { withRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class MonthShareTarget extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            remarks_data:[],
            target_summary_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            option:{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "3" },
            targetoption:{label: "PPC + Stock", value: "3"},
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            plan_month:"",
            plan_month_upload:"",
            targettabactive:"",
            railtargettabstatusactive:"",
            summarydashboardtabactive:"",
            railschedulingtabstatusactive:"",
            targetpreparationshow:"show-m",
            railtargetshow:"show-n",
            summarydashboardshow:"show-n",
            railschedulingshow:"show-n",
            pagetitle :"Stage 1 PPC + Stock Target Preparation Data",
            week:{ label: "All Month", value: "all" },
            weekheadername:"Month",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            plan_type:{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "3" },
            location:{label: "Haryana", value: "H" },
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            share_month:"",
            shareMonthList:[],
            checkData : []
            
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        
        await loadDateTimeScript();
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var month_year = moment.parseZone(new Date()).format("YYYY-MM")
        // console.log(month_year,"244")
        this.setState({
            plan_month_rail : month_year,
            plan_month_target : month_year,
            month_rakescheduling : month_year
        })
        var selected_option = this.state.option.value
        // var source_option = this.state.targetoption.value
        // var source_label =  this.state.targetoption.label
        var plan_month = this.state.plan_month_target

        var params = {}
        params.option = selected_option
        // if(source_option != undefined && source_option != null && source_option != "" && source_label != undefined && source_label != null && source_label != ""){
        //     params.source_label = source_label
        //     params.source = source_option
        // }
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = moment.parseZone(new Date(plan_month)).format("MMM-YYYY")
        }
        console.log(params,"240")
        // getRailTargetByMonth
        await redirectURL.post("/arms/getmonthlytargetsharedata", params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log("response",response.data);
            let respData = JSON.parse(response.data.data)
            var shareData = response.data.getsharemonths
            
            var shareMonth = shareData[0]
            var initialData = JSON.parse(respData.data)
            var filteredinitialData = initialData.filter((k)=> k.month == shareMonth)
            var shareMonthList = []
            shareData.map((e)=>{
                shareMonthList.push({
                    label : moment.parseZone(new Date(e)).format("MMM-YYYY"),
                    value:e
                })
            })
            this.setState({
                rowData : filteredinitialData,
                checkData : respData,
                loadshow : "show-n",
                overly :"show-n",
                shareMonthList : shareMonthList,
                share_month :{"value":shareMonth,"label":moment.parseZone(new Date(shareMonth)).format("MMM-YYYY")}
            })
        }
        })
       
       
    }
    
    onLoadPageData = () => {
        this.setState({
            loadshow : "show-m",
            overly :"show-m",
            shareMonthList :[],
            share_month :"",
            rowData : []
        })
        var selected_option = this.state.option.value
        // var source_option = this.state.targetoption.value
        // var source_label =  this.state.targetoption.label
        var plan_month = this.state.plan_month_target
        // var revision =  this.state.revisionforTP.value
        var params = {}
        params.option = selected_option
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = moment.parseZone(new Date(plan_month)).format("MMM-YYYY")
        }
        // if(source_option != undefined && source_option != null && source_option != ""){
            if(selected_option != undefined && selected_option != null && selected_option != ""){
                if(plan_month != undefined && plan_month != null && plan_month != "" && plan_month != "NaN-NaN"){
                    // console.log(params,"240")
                    // getRailTargetByMonth
                    redirectURL.post("/arms/getmonthlytargetsharedata", params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response) => {
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        // console.log("response", JSON.parse(response.data.data));
                        let respData = JSON.parse(response.data.data)
                        var shareData = response.data.getsharemonths
                        var shareMonth = shareData[0]
                        var initialData = JSON.parse(respData.data)
                        var filteredinitialData = initialData.filter((k)=> k.month == shareMonth)
                        var shareMonthList = []
                        shareData.map((e)=>{
                            shareMonthList.push({
                                label : moment.parseZone(new Date(e)).format("MMM-YYYY"),
                                value:e
                            })
                        })
                        this.setState({
                            rowData : filteredinitialData,
                            checkData : respData,
                            loadshow : "show-n",
                            overly :"show-n",
                            shareMonthList : shareMonthList,
                            share_month :{"value":shareMonth,"label":moment.parseZone(new Date(shareMonth)).format("MMM-YYYY")}
                        })
                    }
                    })
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Month",
                        basicType:"warning",
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
                
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please Select Target",
                    basicType:"warning",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
             
        // }else{
        //     this.setState({
        //         show:true,
        //         basicTitle:"Please Select Source",
        //         basicType:"warning",
        //         loadshow:"show-n",
        //         overly:"show-n"
        //     })
        // }
        
            
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onFirstDataRendered = (event) => {
        event.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: 4,
          columns: ['trip_per_rake'],
        });
      };

    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }

    selectoption = (e) => {
        this.setState({
            option: e,
        })
    }
    selectsharemonth = (e) =>{
        console.log(e,"417")
        this.setState({
            share_month:e
        })
        var data  = this.state.checkData
        var data2 = JSON.parse(data.data)
        console.log(data2,"433")
        var filteredData = data2.filter((f)=>f.month == e.value)
        this.setState({
            rowData : filteredData
        })
    }
    onClickHideAll1() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month_target: plan_month
        });
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            bulkslide3:"",
            bulkslide4:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
    onCellClickedGrid=(e)=>{}
    render() {
        const columnwithDefs = [
            { 
                headerName: "",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Type",
                        field: "type",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                            }
                        }
                    },
                    {
                        headerName: "FOR",
                        field: "for_code",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "City",
                        field: "for_city",
                        width: 140,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        valueGetter:function(params){
                            if(params.data.for_city != undefined && params.data.for_city != null && params.data.for_city != "" && params.data.for_city != 0){
                                return params.data.for_city
                            }else {
                                return ""
                            }
                        },
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }
                ]
            },
            {
                headerName: "Haryana",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "MSIL",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }                                                
                ]
            },
            {
                headerName: "Gujarat",
                headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "SMG",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }
                ]
            },
            {
                headerName: "Bidadi",
                headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                        
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "BNG",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.bidadi_carrier+params.data.bidadi_rail+params.data.bidadi_road
                        }
                    }                             
                  
                ]
            },
            {
                headerName: "Total",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        field: "",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_carrier+params.data.gujarat_carrier+params.data.bidadi_carrier
                        }
                    },
                    {
                        headerName: "RAIL",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_rail+params.data.gujarat_rail+params.data.bidadi_rail
                        }
                    },
                    {
                        headerName: "ROAD",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_road+params.data.gujarat_road+params.data.bidadi_road
                        }
                    },
                    {
                        headerName: "Total",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_total+params.data.gujarat_total+params.data.bidadi_carrier+params.data.bidadi_rail+params.data.bidadi_road
                        }
                    },
                ]
            },
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
                
               
                    <div className="card-arms col-xl-12">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-train cus-i"></i> <span>Monthly Target Share</span>
                            </h5>
                        </div>

                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Source:</label>
                                    <Select
                                                    placeholder={"Select Source"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.targetoption}
                                                    onChange={this.selectotheroption.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[
                                                    { label: "PPC", value: "1" },{label: "Stock", value: "2"},{label: "PPC + Stock", value: "3"}]} required
                                                />
                                </div> */}
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Target:</label>
                                    <Select
                                                    placeholder={"Select option"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.option}
                                                    onChange={this.selectoption.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
                                                    { label: "nth month of previous year", value: "2" },{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "3" }]} required
                                                />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.plan_month_target}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTime.bind(this)}
                                    />
                                </div>
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>

                                </div>
                            </form>
                            {(this.state.shareMonthList.length >0)?
                                <div className='col-xl-12 col-lg-12 '>
                                <div className="col-xl-3 col-lg-3 form-group">
                                        <label>Share Month:</label>
                                        {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                        <Select
                                                        placeholder={"Select Share Month"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.share_month}
                                                        onChange={this.selectsharemonth.bind(this)}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.shareMonthList} required
                                                    />
                                    </div>
                                </div>
                            
                            :""
                            }
                            
                            <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "600px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                        </div>

                        
                    </div>
                
                
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}

export default withRouter(MonthShareTarget)

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});