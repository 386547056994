import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Redirect} from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types'
import { isArray } from 'highcharts';
import $ from 'jquery';
// import CryptoJS from 'crypto-js';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class AuthCallBack extends Component{
    constructor(props) {
        super(props);
        var pageTitle = "Login";
        this.state = {
            //pageTitle: pageTitle,
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            style: {},
            email:'',
            password:'',
            loginMessage:'',
            currentDepartmentCode: null,
            firstname : "", 
            lastname : "", 
            email : "", 
            is_load_management : 1,
            tokenGenerated:false,
            loadshow:"show-m",
            overly:"show-m"
        };
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }

    async componentDidMount(){
        var ctr = 0;
        while(ctr == 0){
            const storagekeys = Object.keys(localStorage);
            var key = storagekeys.filter(e => e.includes("idToken"));
            console.log(localStorage.getItem(key), "key ")
            let token = localStorage.getItem(key);
            if(token != undefined && token != ""){
                localStorage.setItem("token", token)
                ctr = ctr +1
                break
            }
            await sleep(1000);
        }
        // console.log("before wating")
        // // await sleep(12000)
        // console.log("after waiting")

        let token=localStorage.getItem("token")
        var decoded_token=jwtDecode(token)
        var check_email=decoded_token.payload.email
        var msil_user=check_email.split("@")[0]
        var msil_user_firstname=msil_user.split(".")[0]
        var msil_user_lastname=msil_user.split(".")[1]
        localStorage.setItem('msiluserfirstname', msil_user_firstname);
        localStorage.setItem('msiluserlastname',msil_user_lastname);
        console.log("decoded_token",decoded_token.payload.email,decoded_token,decoded_token.payload.identities[0].userId,)
		var email = "admin123123@enmovil.in";

		var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrpwd = ppwwdd("maruti123!")
		//console.log("ppwwdd ", usrpwd)

		var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usremail = ppwwdd(email)
		const fdata = {
            adem:ppwwdd(check_email),
			em:usremail,
			a:usrpwd,
            t:ppwwdd(token)
		}

        await redirectURL.post("/adfslogin",fdata).then(async(response)=>{
            console.log(response,"login response")

            var userloggparams = {
                token: token,
                userId: decoded_token.payload.identities[0].userId,
                email:decoded_token.payload.email,
                user_type:decoded_token.payload.identities[0].providerType
                
            }
            // await redirectURL.post("/manage/saveuserlogged", userloggparams)
            //     .then((resp) => {
            //         console.log("resp ", resp)
            //     })
                const secretKey = 'enmovil@123';
                // const dataToEncrypt = JSON.stringify(user);
                var encryptedUserData = response.data.user

                console.log('Encrypted:', encryptedUserData);
    
                // Decrypt the data
                // var decryptedUserData = CryptoJS.AES.decrypt(encryptedUserData, secretKey).toString(CryptoJS.enc.Utf8);
                
                // decryptedUserData=JSON.parse(decryptedUserData)

                // console.log('Decrypted:',decryptedUserData);
                console.log('response.data',response.data)
                localStorage.setItem('userid', response.data.userid);
                localStorage.setItem('is_admin', response.data.user.is_admin);
                localStorage.setItem('roles', "rail");
                localStorage.setItem('firstname',msil_user_firstname);
                localStorage.setItem('username',msil_user_firstname);
                localStorage.setItem('lastname', msil_user_lastname);
                localStorage.setItem('email', decoded_token.payload.email);
                // localStorage.setItem('user_type', decryptedUserData.user_type);
                localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
                localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                localStorage.setItem('dept_code',JSON.stringify(["RAIL"]));
                // localStorage.setItem('roles', decryptedUserData.roles);
                var md = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                var m = md(JSON.stringify(response.data.user))
                sessionStorage.setItem('ref', 1);

        })

        // await redirectURL.post('/menus/getmenus', {}, {
        //  headers: {
        //      'Content-Type': 'application/json'
        //  }
        // })
        //  .then(response => {
        //      //console.log("Menus ",response);
        //      if (response.statusText == 'OK') {
        //          //console.log("In  SIDEBAR LoginForm MENUS response.data = ", response.data);
        //          let menuText = JSON.stringify(response.data);
        //          localStorage.setItem('menuText', menuText);

        //      } else {
        //          this.setState({
        //              username: '',
        //              email: '',
        //              password: '',
        //              loginMessage: response.data.msg
        //          });
        //      }
        //      let MENUITEMS = getMenuLocalStorage();

        //      //console.log("IN Sidebar componentWillMount, From DB MENUITEMS menuList ", MENUITEMS);
        //  })
        //  .catch(function (error) {
        //      console.log(error);
        //  });

        this.setState({tokenGenerated:true,loadshow:"show-m",
        overly:"show-m"})
        // this.logPageView(); // Log page view to GA   
    };

    // getUserDashboardUrl(pathToMatch = null) {
 
    //  var uroles = localStorage.getItem("roles");
    //  var arr = []
                
        
    //  // redirectURL.post("/menus/roleinfo",{role:uroles})
    //  // .then((res) => {
    //  //  //console.log("repson ", response.data)
    //  //  var rec = res.data
    //  //  if(rec.length > 0)
    //  //  {
    //  //      rec.map((item) => {
    //  //          if(item.dashboard_url != '' && item.dashboard_url != undefined)
    //  //          {
    //  //              arr.push(item.dashboard_url)
    //  //          }
    //  //      })
                
    //  //  }
    //  //  else
    //  //  {
    //  //      arr.push("/dashboard/default")
    //  //  }
    //  //  if(arr.length > 0)
    //  //  {
                
    //  //      this.setState({
    //  //          landingpage:arr[0]
    //  //      });
    //  //  }
    //  //  else
    //  //  {
    //  //      this.setState({
    //  //          landingpage:"/dashboard/default"
    //  //      });
    //  //  }
    //  //  //return dashboardUrl;
    //  // })

        
    //  let dashboardUrl = "/lmwelcome";
    //  let currentDepartmentCode = null;
    //  let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

    //  //console.log("IN render, departmentCode ", departmentCode);
    //  if (Array.isArray(departmentCode)) {
    //      currentDepartmentCode = departmentCode[0]; 
    //  } else {
    //      currentDepartmentCode = departmentCode; 
    //  }

    //  //console.log("IN render, currentDepartmentCode ", currentDepartmentCode);
    //  //console.log("localStorage roles ", localStorage.getItem("roles"))
        
    //  console.log("dashboardUrl Login", dashboardUrl)
    //  return dashboardUrl;
    // }

    componentWillReceiveProps(){
        var pageTitle = "Login";
        this.setState({
            pageTitle: pageTitle,
        });
    };
    changeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
    formHandler = (event) => {
        console.log(this.state.login_id);
        console.log(this.state.password);
    }

    
    render(){
        console.log(localStorage.getItem('token'),"token140")
        if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
        {
            let dashboardUrl ="/targetpreparation";
            return <Redirect to={dashboardUrl} push={true} />
        }
        return (
            <>
                <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div class="loader-box">
            <div class="loader-box">
              <div class="rotate dashed colored"></div>
            </div>
          </div>
        </div>

        <div className={"overlay-part " + this.state.overly}></div>
            </>
        )
    }
};

function getMenuLocalStorage() {
    let menuText = localStorage.getItem('menuText');
    let MENUITEMS = null;
    //   console.log("deptCodes = ", deptCodes);
    //   console.log("Type of deptCodes = ", typeof deptCodes);
       try {
         MENUITEMS = JSON.parse(menuText);
       } catch (error) {
           console.log('Error while Parsing menuText', error);
       }
       return MENUITEMS;
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


function jwtDecode(t) {
    let token = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
  }