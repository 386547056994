'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
//import DeleteButton from "./deleteshipmentDSR";
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;   
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class PlantwiseLoadTruckType extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
         // DeleteButton:DeleteButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:'',
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        rowId : "",
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        cluster_yard  : [],
        rail_yard : [],
        state :[],
        loading_cluster : [],
        unloading_cluster : [],
        baseStations : [],
        addState:"",
        edit_addState:"",
        addCluster:"",
        edit_addCluster:"",
        addPlant:"",
        edit_addPlant:"",
        statesdetails : [],
        clusterdetails:[],
        plantsdetails:[],
        selectloadingcluster:"",
        selectunloadingcluster:"",
        selectbasestation:"",
        edit_selectloadingcluster:"",
        edit_selectunloadingcluster:"",
        edit_selectbasestation:"",
        showloadinginputTab :"",
        showunloadinginputTab :"",
        showbasestationinputTab :"",
        plantdetails :[],
        vehicle_type:"",
        min_load_factor:"",
        max_load_factor:"",
        showloadingeditinputTab:"",
        showunloadingeditinputTab:"",
        showbasestationeditinputTab:"",
        vehicleList:[],
        columnDefs:[],
        transporter:"",
        transporterList:[],
        vehicleTypes:[],
        edit_transporter:""
        //components : {datepicker:getDatePicker()}
    }
    // this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    // this.onLoadPageData();
    redirectURL.post("/arms/getTransportercodesforrakeyarddetails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
})    
    .then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
        var states = response.data.states;
        var  clusters  = response.data.cluster;
        var plants = response.data.plants;
        var transporters = response.data.transporters
        var statesdetails = []
        var clusterdetails = []
        var plantsdetails = []
        var transporterList=[]
        states.map((e)=>{
            statesdetails.push({
                value : e,
                label : e
            })
        })
        clusters.map((j)=>{
            clusterdetails.push({
                value : j,
                label : j
            })
        })
        plants.map((b)=>{
            plantsdetails.push({
                value:b,
                label:b
            })
        })
        transporters.map((c)=>{
            transporterList.push({
                value:c.transporter_code,
                label:c.transporter_name
            })
        })
        //console.log("records ", records)
        this.setState({
            statesdetails : statesdetails,
            clusterdetails : clusterdetails,
            plantsdetails : plantsdetails,
            transporterList:transporterList
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
    var plant = this.state.addPlant.value
    this.onLoadPageData(plant)
}

onLoadPageData(plant){
    this.setState({
        loadshow:"show-m",
            overly:"show-m"
    })
    var params = {
        plant : plant
    }
        redirectURL.post("/arms/getcolumnsdatabasedonplant",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data.vehicleTypeList);
            var resp = response.data.vehicleTypeList
            var checkResp = response.data.data
            console.log(checkResp,"257")
            var columns = []
            columns.push(
                {
                    pinned:"left",
                    headerName : "Edit",
                    field:"edit",
                    width:110,
                    resizable : true,
                    editable : false,
                   
                    cellRenderer:function(params){       
                        var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                        return rendComponent;
                    },
                    filter:false,
                },
                {
                    pinned:"left",
                    headerName : "Delete",
                    field:"delete",
                    width:80,
                    resizable : true,
                    editable : false,
                    cellRenderer:function(params){       
                        return '<i class="icofont icofont-ui-delete"></i>';
                    },
                    filter:false,
                },    
                {
                    headerName: "Transporter Name",
                    field: "transporter_name",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,
                    cellEditor: 'agSelectCellEditor',
                    // cellEditorParams: {
                    //     values: ["Yes","No"] ,
                    // },
                },
                {
                    headerName: "Transporter Code",
                    field: "transporter_code",
                    width:150,
                    filter: true,
                    resizable: true,
                    editable:false,
                    cellEditor: 'agSelectCellEditor',
                    // cellEditorParams: {
                    //     values: ["Yes","No"] ,
                    // },
                },
            )
            resp.map((e)=>{
                columns.push(
                    {
                        headerName: e,
                        field: e,
                        width:100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellEditor: 'agSelectCellEditor',
                        // cellEditorParams: {
                        //     values: ["Yes","No"] ,
                        // },
                    },
                )
            })
            this.setState({
                columnDefs:columns,
                rowData : checkResp,
                vehicleList : resp,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
        })
    
    
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var rowId = e.data._id;
        var plant = e.data.plant
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            user_name: localStorage.getItem('username'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteplantandlspwiseloadtrucktype",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                    this.onLoadPageData(plant);
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
    if(e.colDef.field == "edit"){
        var transporter  = e.data.transporter_name
        var transporter_code = e.data.transporter_code
        var min_load_factor = e.data.min_load_factor
        var max_load_factor = e.data.max_load_factor
        var rowId = e.data._id
        var plant = e.data.plant
        this.setState({
            bulkslide2:"slider-translate",
            overly:"shom-m",
            edit_transporter:{value:transporter_code,label:transporter},
            rowId : rowId
        })
        this.state.vehicleList.map((fc)=>{
            console.log(fc)
            $("edit_"+fc).val(e.data[fc])
            this.setState({
                ["edit_"+fc]:e.data[fc]
            })
        })
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    vehicle_type:"",
    min_load_factor:"",
    max_load_factor:""
    });
  $("#vehicle_type").val("")
    $("#min_load_factor").val("")
   $("#max_load_factor").val("")
}
async onClickHideAll1(){
    await this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide1:"",
    bulkslide2:"",
    transporter:"",
    }); 
    this.state.vehicleList.map((cl)=>{
        console.log(cl,"cl")
        $("#"+cl).val("")
        this.setState({
            [cl]:""
        })
    })
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{plant:"",vehicle_type:"",min_load_factor:"",max_load_factor:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
// onClickDataExport(){
//     var params = {
//         columnKeys: ['station_code', 'vehicle_type', 'min_load_factor', 'max_load_factor', 'state', 'cluster_name','loading_cluster','unloading_cluster','base_station', 'bcacbm_eligible','max_loadings','max_unloadings','max_loadings_unloadings','max_sevice_days','plant']
//     };
//     this.gridApi.exportDataAsCsv(params);
// }

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}


checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            bulkUploadID:'',
            file:""
        });
    }
}

onclickslider=(e)=>{
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
    
}
selectplant =(e)=>{
    this.setState({
       addPlant: e,
    })
    this.onLoadPageData(e.value)
}
selecteditplant = (e) => {
    this.setState({
        edit_addPlant: e,
    })
}
selecttransporter =(e)=>{
    this.setState({
        transporter: e,
    })
}
selectedittransporter=(e)=>{
    this.setState({
        edit_transporter: e,
    })
}
validateInput = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue, "inputValue")
    const characterRegex = /^[a-zA-Z0-9]*$/;
    console.log(!characterRegex.test(inputValue),"910")
    if (!characterRegex.test(inputValue)) {
        if(inputValue == ""){
            this.setState({
                vehicle_type: "",
              });
        }
     
    } else {
      this.setState({
        vehicle_type: inputValue,
      });
    }
  };
  validateInput2 = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue, "inputValue")
    const characterRegex = /^[a-zA-Z0-9]*$/;
    console.log(!characterRegex.test(inputValue),"910")
    if (!characterRegex.test(inputValue)) {
        if(inputValue == ""){
            this.setState({
                edit_vehicle_type: "",
              });
        }
     
    } else {
      this.setState({
        edit_vehicle_type: inputValue,
      });
    }
  };
  validateminloadfactorInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            min_load_factor: inputValue,
        });
        }
    }else{
        this.setState({
            min_load_factor: "",
        });
    }
  };
  changehandlertrucktype=(event,item)=>{
    console.log("alert")
    // alert("event", item, "event", event)
    console.log(event,item)
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    console.log(floatRegex.test(inputValue),"658")
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            item : inputValue
        });
        }
    }else{
        this.setState({
            item:""
        });
    }
  };
  validatemaxloadfactorInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                max_load_factor: inputValue,
            });
        }
    }
    else
    {
        this.setState({
            max_load_factor: "",
        });
    }
  };
  validateEditmaxloadfactorInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                edit_max_load_factor : inputValue
            });
        }
    }
    else
    {
        this.setState({
            edit_max_load_factor : ""
        });
    }
  };
submitlspwiseloadallocation=(event)=>{
    event.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
        var transporter_name = this.state.transporter.label
        var transporter_code = this.state.transporter.value
        var plant = this.state.addPlant.value
        var truckTypeList = this.state.vehicleList
        var created_by = localStorage.getItem("username")
        var params ={
            transporter_name :transporter_name,
            transporter_code : transporter_code,
            plant : plant,
            created_by : created_by,
        }
        truckTypeList.map((cl)=>{
            params[cl] = parseFloat($("#"+cl).val())
        })
        console.log(params,"735")
        if(plant !=undefined && plant != null && plant != ""){
            redirectURL.post("/arms/addplantandlspwiseloadtrucktype",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                if(response.data.message == "Success"){
                    this.setState({
                        bulkslide1:"",
                        transporter:"",
                        loadshow:"show-n",
                        overly:"show-n",
                        basicTitle:"Data Successfully Updated",
                        basicType:"success",
                        show:true
                    })
                    this.state.vehicleList.map((cl)=>{
                        console.log(cl,"cl")
                        $("#"+cl).val("")
                        this.setState({
                            [cl]:""
                        })
                    })
                     this.onLoadPageData(plant)
                }else{
                    this.setState({
                        bulkslide1:"",
                        transporter:"",
                        loadshow:"show-n",
                        overly:"show-n",
                        basicTitle:"Data Not Updated",
                        basicType:"warning",
                        show:true
                    })
                    this.state.vehicleList.map((cl)=>{
                        console.log(cl,"cl")
                        $("#"+cl).val("")
                        this.setState({
                            [cl]:""
                        })
                    })
                }
            }
            })
        }else{
            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                basicTitle:"Please Select Plant",
                basicType:"warning",
                show:true,
                bulkslide1:"",
            transporter:"",
            })
        }
       
}
editloadallocation=(event)=>{
    event.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
        var transporter_name = this.state.edit_transporter.label
        var transporter_code = this.state.edit_transporter.value
        var plant = this.state.addPlant.value
        var truckTypeList = this.state.vehicleList
        var created_by = localStorage.getItem("username")
        var rowId = this.state.rowId
        
        var params ={
            transporter_name :transporter_name,
            transporter_code : transporter_code,
            plant : plant,
            updated_by : created_by,
        }
        truckTypeList.map((cl)=>{
            params[cl] = parseFloat($("#edit_"+cl).val())
        })
        console.log(params,"929")
        redirectURL.post("/arms/editplantandlspwiseloadtrucktype",{params,rowId},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"797")
            if(response.data.message == "Success"){
                console.log("check-1")
                this.setState({
                    bulkslide2:"",
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Data Successfully Updated",
                    basicType:"success",
                    show:true
                })
                this.onLoadPageData(plant)
                // window.location.href="/plantlspwiseloadtrucktypes"
            }else{
                console.log("check-2")
               this.setState({
                    bulkslide2:"",
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:response.data.message,
                    basicType:"warning",
                    show:true
               }) 
            }
        }
    })
   
}
onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
changeHandler = (item,event) => {
    const inputValue = event.target.value.trim();
    var numberRegex = /[\d.]+/g;

    // Use the match method to find all matches in the text
    var matches = inputValue.match(numberRegex);
    console.log(matches, "matches")
    // If there are matches, return them as an array; otherwise, return an empty array
    if(matches){
         this.setState({[item]: matches})
    }else{
        this.setState({
            [item]: ""
        })
    }
}
changeHandler1 = (item,event) => {
    const inputValue = event.target.value.trim();
    var numberRegex = /[\d.]+/g;

    // Use the match method to find all matches in the text
    var matches = inputValue.match(numberRegex);
    console.log(matches, "matches")
    // If there are matches, return them as an array; otherwise, return an empty array
    if(matches){
         this.setState({["edit_"+item]: matches})
    }else{
        this.setState({
            ["edit_"+item]: ""
        })
    }
}

  render() {
    
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
        <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Plantwise Load Allocation</span>
                    </h5>
                    </div>
                    <h5>
                   
                    {/*<button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickBulkDelete.bind(this)}>Delete</button> */}
                </h5>
                <div>
                {(this.state.addPlant.value != "" && this.state.addPlant.value != undefined)?
                    <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider}>Add Load Truck Type</button>
                :""}
                
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    {/* <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                </div> 
                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row"> */}
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>Plant:</label>
                                    <Select
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        value={this.state.addPlant}
                                        onChange={this.selectplant.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.plantsdetails} required
                                    />
                            </div>
                            {/* <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>
                            </div>
                        </form> */}
                    </div>

                <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
            {/* <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}> */}
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        </div>
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Lsp Wise Load Truck Type<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitlspwiseloadallocation}>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter:</label>
                                        <Select
                                            placeholder={"Select Transporter"}
                                            closeMenuOnSelect={true}
                                            value={this.state.transporter}
                                            onChange={this.selecttransporter.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.transporterList} required
                                        />
                                </div>
                                {console.log(this.state, "state vars")}
                                {this.state.vehicleList.map((item,idx)=>(
                                      <div className="col-xl-12 col-lg-12 form-group">
                                      <label className="col-xl-12 col-lg-12">{item} : </label>
                                      <input type="text" name={item} id={item} className="form-control" value={this.state[item]}
                                       onChange={this.changeHandler.bind(this,item)} required />
                                  </div>    
                                ))}
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Edit Load Allocation<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.editloadallocation}>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter:</label>
                                        <Select
                                            placeholder={"Select Transporter"}
                                            closeMenuOnSelect={true}
                                            value={this.state.edit_transporter}
                                            onChange={this.selectedittransporter.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.transporterList} required
                                        />
                                </div>
                                {this.state.vehicleList.map((item,idx)=>(
                                      <div className="col-xl-12 col-lg-12 form-group">
                                      <label className="col-xl-12 col-lg-12">{item} : </label>
                                      <input type="text" name={"edit_"+item} id={"edit_"+item} className="form-control" value={this.state["edit_"+item]}
                                       onChange={this.changeHandler1.bind(this,item)} required />
                                  </div>    
                                ))}
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};