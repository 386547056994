'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class RpRakeDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        baseStationList: ["Ahmedabad","Chennai","NCR"],
        rakesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        transporter_code:"",
        transporter_shortname:"",
        base_station:"",
        station_code:"",
        state:"",
        edit_transporter_code:"",
        edit_transporter_shortname:"",
        edit_base_station:"",
        edit_station_code:"",
        edit_state:"",
        rowId:"",
        transportersList : [],
        station_codes:[],
        states:[],
        transportercodedetails:[],
        basestationList:[],
        rake_type:"",
        rakeTypeList:[],
        edit_rake_type:""
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
    var params ={}
    var tpt_code = localStorage.getItem("transportercode")
    if(tpt_code != undefined && tpt_code != null && tpt_code != ""){
        params.transporter_code = tpt_code
    }
    console.log(params,"180")
    redirectURL.post("/arms/getTransportercodesforrakedetails",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var transporters = response.data.transporters;
        var basestations = response.data.basestations;
        var rakeTypes = response.data.rakeTypeList;
        var basestationList =[]
        var transportersList = [];
        var rakeTypeList = []
        transporters.map((item) =>{
            transportersList.push({
                value : item,
                label : item
            })
        })
        basestations.map((j)=>{
            basestationList.push({
                value :j,
                label :j
            });
        })
        rakeTypes.map((e)=>{
            rakeTypeList.push({
                value :e,
                label :e
            })
        })
        this.setState({
            transportersList : transportersList,
            basestationList : basestationList,
            rakeTypeList:rakeTypeList
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}

onLoadPageData(){
    var params ={}
    var transporter_code = localStorage.getItem("transportercode")
    console.log(transporter_code,"204")
    if(transporter_code != undefined && transporter_code != "" && transporter_code != null){
        params.transporter_code = transporter_code
    }
    redirectURL.post("/arms/getRakeDetails",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            transporter_code : e.data.transporter_code,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_role: localStorage.getItem('roles'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteRakeDetails",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    window.location.reload();
                    this.onGridState();
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            }) 
        }        
    }
    if(e.colDef.field == "edit"){
        console.log("Hit--2")
        var rake_no  = e.data.rake_no
        var transporter_code = e.data.transporter_code
        var transporter_short_name = e.data.transporter_short_name
        var base_station = e.data.base_station
        var station_code = e.data.station_code
        var state = e.data.state
        var rowId = e.data._id
        var rake_max_load_capacity = e.data.rake_max_load_capacity
        var rake_type = e.data.rake_type
        this.setState({
            bulkslide2:"slider-translate",
            overly:"shom-m",
            edit_transporter_code:{value:transporter_code,label:transporter_code},
            edit_transporter_shortname :{value:transporter_short_name,label:transporter_short_name},
            edit_base_station :{value:base_station,label:base_station},
            edit_station_code :{value:station_code,label:station_code},
            edit_state:{value:state,label:state},
            edit_rake_type :{value:rake_type,label:rake_type},
            rowId : rowId
        })
        $("#edit_rake_no").val(rake_no)
        $("#edit_load_capacity").val(rake_max_load_capacity)
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    transporter_code:"",
    transporter_shortname:"",
    base_station:"",
    station_code:"",
    state :"",
    });
    $("#rake_no").val("")
    $("#add_load_capacity").val("")
}
onClickHideAll1(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide1:"",
    transporter_code:"",
    transporter_shortname:"",
    base_station:"",
    station_code:"",
    state :"",
    });
    $("#rake_no").val("")
    $("#add_load_capacity").val("")
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{rake_no:"",transporter_code:"",base_station:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['rake_no', 'transporter_code', 'transporter_short_name','base_station','station_code','state']
    };
    this.gridApi.exportDataAsCsv(params);
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        bulkslide1:'',
        bulkslide2:"",
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'rake_no',
                inputName: 'rake_no',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'transporter_code',
                inputName: 'transporter_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'transporter_short_name',
                inputName: 'transporter_short_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'station_code',
                inputName: 'station_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'base_station',
                inputName: 'base_station',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'state',
                inputName: 'state',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        console.log(csvData)
        this.setState({
            csvData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    //this.checkFileData();
    //console.log("e.target.files[0].type ", e.target.files[0]);
    var check = verifyext(e.target.files[0].name);
    //console.log("Check ", check)
    if(check == true)
    {
        if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
        {
            // console.log(fileData);
            // console.log(typeof(fileData))
            
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    else{
        e.target.value = null;
        this.setState({
            uploadFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}


onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
onclickslider=()=>{

    this.setState({
        bulkslide1:"slider-translate",
        overly:"show-m"
    })
}
selecttransportercode=(e)=>{
    this.setState({
        transporter_code : e
    })
    console.log(e,"632")
    var params ={
        "transporter_code":e.value
    }
    redirectURL.post("/arms/getRakeDetailstransportershortname",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data.transporters_name,"637")

        var resp = response.data.transporters_name
        var transportercodedetails = []
        resp.map((e)=>{
            transportercodedetails.push({
                value : e,
                label : e
            })
        })
        this.setState({
            transporter_shortname : {value : resp[0] ,label : resp[0]},
            transportercodedetails : transportercodedetails
        })
    }
    })
}
selectedittransportercode=(e)=>{
    this.setState({
        edit_transporter_code : e
    })
    console.log(e,"632")
    var params ={
        "transporter_code":e.value
    }
    redirectURL.post("/arms/getRakeDetailstransportershortname",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data.transporters_name,"637")

        var resp = response.data.transporters_name
        var transportercodedetails = []
        resp.map((e)=>{
            transportercodedetails.push({
                value : e,
                label : e
            })
        })
        this.setState({
            edit_transporter_shortname : {value : resp[0] ,label : resp[0]},
            transportercodedetails : transportercodedetails
        })
    }
    })
}
selecttransportershortname=(e)=>{
    this.setState({
        transporter_shortname:e
    })
    console.log(e,"657")
}
selectedittransportershortname=(e)=>{
    this.setState({
        edit_transporter_shortname:e
    })
    console.log(e,"657")
}
selectbasestation=(e)=>{
    this.setState({
        base_station:e
    })
    var params ={
        "railyard_name":e.value
    }
    redirectURL.post("/arms/getstationcodeandstate",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var stationCodes = response.data.stationcodes
        var state = response.data.states
        var station_codes = []
        var states = []
        stationCodes.map((e)=>{
            station_codes.push({
                value : e,
                label : e
            })
        })
        state.map((b)=>{
            states.push({
                value : b,
                label : b
            })
        })
        this.setState({
            station_codes : station_codes,
            states : states,
            state : {value : state[0],label:state[0]},
            station_code : {value : stationCodes[0],label : stationCodes[0]}
        })
    }
    })
}
selecteditbasestation=(e)=>{
    this.setState({
        edit_base_station:e
    })
    var params ={
        "railyard_name":e.value
    }
    redirectURL.post("/arms/getstationcodeandstate",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var stationCodes = response.data.stationcodes
        var state = response.data.states
        var station_codes = []
        var states = []
        stationCodes.map((e)=>{
            station_codes.push({
                value : e,
                label : e
            })
        })
        state.map((b)=>{
            states.push({
                value : b,
                label : b
            })
        })
        this.setState({
            station_codes : station_codes,
            states : states,
            edit_state : {value : state[0],label:state[0]},
            edit_station_code : {value : stationCodes[0],label : stationCodes[0]}
        })
    }
    })
}

selectstationcode=(e)=>{
    this.setState({
        station_code:e
    })
}
selecteditstationcode=(e)=>{
    this.setState({
        edit_station_code:e
    })
}
selectstate=(e)=>{
    this.setState({
        state:e
    })
}
selecteditstate=(e)=>{
    this.setState({
        edit_state:e
    })
}
selectraketype = (e)=>{
    this.setState({
       rake_type:e
    })
}
selecteditraketype= (e)=>{
    this.setState({
       edit_rake_type:e
    })
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
submitRakedetails=(event)=>{
    event.preventDefault()
    this.setState({
        loadshow :"show-m",
        overly:"show-m"
    })
    var rake_no = $("#rake_no").val()
    var transporter_code = this.state.transporter_code.value
    var transporter_short_name = this.state.transporter_shortname.value
    var base_station = this.state.base_station.value
    var station_code = this.state.station_code.value
    var state = this.state.state.value
    var rake_max_load_capacity = $("#add_load_capacity").val()
    var rake_type = this.state.rake_type.value
    var reqparams ={
        rake_no : rake_no,
        transporter_code : transporter_code,
        transporter_shortname : transporter_short_name,
        base_station : base_station,
        station_code : station_code,
        state : state,
        rake_max_load_capacity : rake_max_load_capacity,
        username :localStorage.getItem("username")
    }
    if(rake_type != undefined && rake_type != null && rake_type != ""){
        reqparams.rake_type = rake_type
    }
    console.log(reqparams,"745")
    if(rake_no != undefined && rake_no != null && rake_no != ""){
        if(transporter_code != undefined && transporter_code != null && transporter_code != ""){
            if(base_station != undefined && base_station != null && base_station != ""){
                redirectURL.post("/arms/submitrakeDetails",reqparams,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    if(response.data.message == "Success"){
                        this.setState({
                            basicTitle:response.data.message,
                            basicType :"success",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n",
                            bulkslide1:"",
                            transporter_code:"",
                            transporter_shortname:"",
                            base_station:"",
                            station_code:"",
                            state :"",
                        })
                        $("#rake_no").val("")
                        $("#add_load_capacity").val("")
                        this.onLoadPageData()
                    }else{
                        this.setState({
                            basicTitle:response.data.message,
                            basicType :"warning",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }
                }
                })
            }else{
                this.setState({
                    basicTitle:"Please Select Base Station",
                    basicType :"warning",
                    show:true,
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }
            
        }else{
            this.setState({
                basicTitle:"Please Select Transporter",
                basicType :"warning",
                show:true,
                overly:"show-n",
                loadshow:"show-n"
            })
        }
        
    }else{
        this.setState({
            basicTitle:"Please enter Rake No",
            basicType :"warning",
            show:true,
            overly:"show-n",
            loadshow:"show-n"
        })
    }
   
}
editRakedetails=(event)=>{
    event.preventDefault()
    var rake_no  = $("#edit_rake_no").val()
    var transporter_code = this.state.edit_transporter_code.value
    var transporter_short_name = this.state.edit_transporter_shortname.value
    var base_station = this.state.edit_base_station.value
    var station_code = this.state.edit_station_code.value
    var state = this.state.edit_state.value
    var rowId = this.state.rowId
    var rake_type = this.state.edit_rake_type.value
    var rake_max_load_capacity = $("#edit_load_capacity").val()
    var reqparams ={
        rake_no : rake_no,
        transporter_code : transporter_code,
        transporter_shortname : transporter_short_name,
        base_station : base_station,
        station_code : station_code,
        state : state,
        rowId : rowId,
        rake_max_load_capacity : rake_max_load_capacity,
        username : localStorage.getItem("username")
    }
    if(rake_type != undefined && rake_type != "" && rake_type != null){
        reqparams.rake_type = rake_type
    }
    console.log(reqparams,"900")
    if(rake_no != undefined && rake_no != null && rake_no != ""){
        if(transporter_code != undefined && transporter_code != "" && transporter_code != null){
            if(base_station != undefined && base_station != null && base_station != ""){
                redirectURL.post("/arms/editrakeDetails",reqparams,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    if(response.data.message == "Success"){
                        this.setState({
                            basicTitle:response.data.message,
                            basicType :"success",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n",
                            bulkslide2:"",
                        })
                        this.onLoadPageData()
                    }else{
                        this.setState({
                            basicTitle:response.data.message,
                            basicType :"warning",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }
                }
                })
            }else{
                this.setState({
                    basicTitle:"Please Select Base Station",
                    basicType :"warning",
                    show:true,
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }
        }
        else{
                this.setState({
                    basicTitle:"Please Select Transporter",
                    basicType :"warning",
                    show:true,
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }
    }else{
        this.setState({
            basicTitle:"Please enter Rake No",
            basicType :"warning",
            show:true,
            overly:"show-n",
            loadshow:"show-n"
        })
    }
       
}
  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [   
        {
            headerName: "Rake No",
            field: "rake_no",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: this.state.rakesList,
            // },           
        },
        {
            headerName: "Transporter Code",
            field: "transporter_code",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: this.state.transportersList,
            // },  
        },
        {
            headerName: "Transporter Short Name",
            field: "transporter_short_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Max Rake Load Capacity (VINs)",
            field: "rake_max_load_capacity",
            width:150,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Base Station",
            field: "base_station",
            width:200,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: this.state.baseStationList,
            // },  
        },
        {
            headerName: "Station Code",
            field: "station_code",
            width:150,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "State",
            field: "state",
            width:150,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            headerName: "Rake Type",
            field: "rake_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false, 
        },
        {
            pinned:"left",
            headerName : "Edit",
            field:"edit",
            width:110,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
           
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },       
        // {
        //     pinned:"left",
        //     headerName: "id",
        //     field: "id",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
            <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rake Details</span>
                    </h5>
                    </div>
                   
                    <h5>

                    </h5>
                    <div>
                    <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider.bind(this)}>Add Rake Details</button>
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                    </div>
            <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
               
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Rake Details
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitRakedetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Rake No:</label>
                                            <input type="text" name="rake_no" id="rake_no" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Code:</label>
                                    <Select
                                                    placeholder={"Select Transporter Code"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.transporter_code}
                                                    onChange={this.selecttransportercode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transportersList} required
                                                />
                                </div>
                                {(this.state.transportercodedetails.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Short Name:</label>
                                    <Select
                                                    placeholder={"Select Transporter Short Name"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.transporter_shortname}
                                                    onChange={this.selecttransportershortname.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transportercodedetails} required
                                                />
                                </div>
                                :""}
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station:</label>
                                    <Select
                                        placeholder={"Select Base Station"}
                                        closeMenuOnSelect={true}
                                        value={this.state.base_station}
                                        onChange={this.selectbasestation.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.basestationList} required
                                    />
                                </div>
                                {(this.state.station_codes.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Station Code:</label>
                                    <Select
                                                    placeholder={"Select Station Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.station_code}
                                                    onChange={this.selectstationcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.station_codes} required
                                                />
                                </div>
                                 :""
                                } 
                                {(this.state.states.length>0)?
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>State:</label>
                                <Select
                                                placeholder={"Select State"}
                                                closeMenuOnSelect={true}
                                                value={this.state.state}
                                                onChange={this.selectstate.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.states} required
                                            />
                            </div>

                                :""
                                } 
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Max Load Capacity (VINs):</label>
                                            <input type="number" name="add_load_capacity" id="add_load_capacity" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                <label>Rake Type:</label>
                                <Select
                                                placeholder={"Select Rake Type"}
                                                closeMenuOnSelect={true}
                                                value={this.state.rake_type}
                                                onChange={this.selectraketype.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.rakeTypeList} required
                                            />
                            </div>

                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
        <h3 className="subHarms">Edit Rake Details
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.editRakedetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Rake No:</label>
                                            <input type="text" name="edit_rake_no" id="edit_rake_no" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Code:</label>
                                    <Select
                                                    placeholder={"Select Transporter Code"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.edit_transporter_code}
                                                    onChange={this.selectedittransportercode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transportersList} required
                                                />
                                </div>
                                {(this.state.transportercodedetails.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Short Name:</label>
                                    <Select
                                                    placeholder={"Select Transporter Short Name"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_transporter_shortname}
                                                    onChange={this.selectedittransportershortname.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transportercodedetails} required
                                                />
                                </div>:""
                                }
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station:</label>
                                    <Select
                                                    placeholder={"Select Base Station"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_base_station}
                                                    onChange={this.selecteditbasestation.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.basestationList} required
                                                />
                                </div>
                                {(this.state.station_codes.length>0)?
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Station Code:</label>
                                    <Select
                                                    placeholder={"Select Station Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_station_code}
                                                    onChange={this.selecteditstationcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.station_codes} required
                                                />
                                </div>:""}
                                {(this.state.states.length>0)?
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>State:</label>
                                    <Select
                                                    placeholder={"Select State"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_state}
                                                    onChange={this.selecteditstate.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.states} required
                                                />
                                </div>:""}
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Max Load Capacity (VINs):</label>
                                            <input type="number" name="edit_load_capacity" id="edit_load_capacity" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                <label>Rake Type:</label>
                                <Select
                                                placeholder={"Select Rake Type"}
                                                closeMenuOnSelect={true}
                                                value={this.state.edit_rake_type}
                                                onChange={this.selecteditraketype.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.rakeTypeList} required
                                            />
                            </div>
                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};