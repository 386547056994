import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
// import _, { flatMap, values } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import $ from 'jquery';
import Modal from 'react-responsive-modal';


var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class NewOrdervsStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true,
                enableValue: true,
                // allow every column to be grouped
                enableRowGroup: true,
                // allow every column to be pivoted
                enablePivot: true,
                filter: true,
 
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
            csvcontent: [],
            billing_month: "",
            columnwithDefs1: [],
            columnwithDefs2: [],
            columnwithDefs3: [],
            rowData3:[],
            orderData:[],
            stockData:[],
            summaryData:[],
            plant:"",
            plant_slide:[{"value":"ALL","label":"ALL"}],
            plant_second:[{"value":"ALL","label":"ALL"}],
            clusterOptions:[],
            clusterOptions1:[],
            cluster:"",
            cluster_slide:[{"value":"ALL","label":"ALL"}],
            cluster_second:[{"value":"ALL","label":"ALL"}],
            rowData1:[],
            rowData2:[],
            status:[{"value":"ALL","label":"ALL"}],
            status_slide:[{"value":"ALL","label":"ALL"}],
            status_second:[{"value":"ALL","label":"ALL"}],
            bulkuploadsliderforreport:"",
            to_email:"",
            cc_email:"",
            ordervsstockdata:"btn-arms",
            ordervsstocksummarydata:"",
            ordervsstocktabshow:"show-m",
            ordervsstocksummaryshow:"show-n",
            pagetitle:"Order vs Stock",
            subheader1:"",
            subheader2:"",
            subheader3:"",
            columnDefs:[],
        };
    }

    componentDidMount() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n"
        })
        var getcurrent_date = moment.parseZone(new Date()).format("DD-MMM-YYYY")
        var getcurrent1_date = moment.parseZone(new Date()).add(1,"days").format("DD-MMM-YYYY")
        var getcurrent2_date = moment.parseZone(new Date()).add(2,"days").format("DD-MMM-YYYY")
        console.timeLog(getcurrent1_date,getcurrent2_date,getcurrent_date,"gdsgs")
        redirectURL.post("/arms/getDistinctdataforclusters",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var resp = response.data.cluster_data
            var clusterOptions =[]
            var clusterOptions1 =[]
            clusterOptions1.push({
                "value":"ALL",
                "label":"ALL"
            })
            var clusterOptions2=[]
            resp.map((e)=>{
                clusterOptions2.push({
                    "value":e,
                    "label":e
                })
            })
            clusterOptions = clusterOptions1.concat(clusterOptions2)
            this.setState({
                clusterOptions1 : clusterOptions,
                clusterOptions : clusterOptions2,
                subheader1:getcurrent_date,
                subheader2:getcurrent1_date,
                subheader3:getcurrent2_date
            })
        }
        })
        
    };
    onLoadPageData = (event) => {
        event.preventDefault();
        
        var plant_code = this.state.plant.value
        var cluster = this.state.cluster.value
        var status =  this.state.status
        var lp_check_Value = $("#lp_check").is(':checked')
        var lp_check = 1
        if(lp_check_Value == true){
            lp_check = 0
        }else{
            lp_check = 1
        }
        console.log(status,"141")
        var status_list = []
        if(status != undefined && status != null && status != ""){
            if(status.length > 0){
                status.map((e)=>{
                    status_list.push(e.value)
                })
            }else{
                status_list =[]
            }
        }else{
            status = []
        }
        
        console.log(status_list,"146")
        var status_list1 =[]
        console.log(status_list.indexOf("ALL"),"heeeeeee")
        if(status_list != undefined && status_list != null && status_list != ""){
            if(status_list.length > 0){
                if(status_list.indexOf("ALL") >= 0){
                    console.log("check-1")
                    status_list1 = ["ALL"]
                }else{
                    console.log("check-2")
                    status_list1 = status_list
                }
            }
        }
       
        console.log(status_list1,"153")
        console.log(lp_check,"check-123")
        console.log(lp_check != undefined && lp_check != null && lp_check != "" ,"prm")
        console.log(lp_check == 0,"prs")
        if (![undefined , "" , null , "undefined"].includes(plant_code) && ![undefined , "" , null , "undefined"].includes(cluster)) {
            if(status_list1 .length > 0){
                var params = {
                    plant_code: plant_code,
                    unloading_point: cluster,
                    status:status_list1
                }
                if(lp_check == 0){
                    params.lp_check = lp_check
                }   
            console.log(params , "params")
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            redirectURL.post("/arms/newgetordervsstockdata", params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                // console.log(response.data, "8975")
                var resp = JSON.parse(response.data.data)
                // console.log(resp,"resp")
                // console.log(resp.status,"status")
                var response1 = eval(resp.df)
                var response3 = eval(resp.matched_stock_data)
                console.log(response3,"256")
                // console.log(response1,"188")
                var resp2 = JSON.parse(response.data.data2)
                var rowData2 = eval(resp2.stock_df)
                // console.log(rowData2,"191")
                if(resp.status != "Failure"){
                    var columns =[]
                    var rowData1=[] 
                    if(response1.length > 0){
                        columns.push({
                            headerName: "",
                            headerClass: ["cellstylegridTP", "cellTopHeader"],
                            field: "",
                            width: 100,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "Model Name",
                                    headerClass: "cellstylegridTP",
                                    field: "car_model",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    editable: false,
                                    pinned: "left",
                                    cellClass: "cellstylegridTP",
                                    cellStyle: function (params) {
                                        if (params.data.car_model.split("-")[0]=="Total") {
                                            return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                        }
                                    },
                                },
                            ]
                  })  
                        var totals_data_total = {
                            car_model : "Total"
                        }
                       
                        var groupByDays  = groupBy(response1,data=>data.days)
                            groupByDays.forEach((daysValue,daysKeys)=>{
                                columns.push({
                                    headerName: `Day ${daysKeys}`,
                                    headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                                    field: "",
                                    width: 360,
                                    // chartDataType: "excluded",
                                    children: [
                                        {
                                        headerName: "Orders",
                                        headerClass: "cellstylegridTPFM",
                                        field: `day_${daysKeys}_vins_count_ORDER`,
                                        width: 120,
                                        filter: true,
                                        resizable: true,
                                        editable: false,
                                        cellClass: "cellstylegridTPFM",
                                        cellStyle: function (params) {
                                            if (params.data.car_model.split("-")[0]=="Total") {
                                                return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                                }
                                            },
                                        },
                                        {
                                            headerName: "Matching Stock",
                                            headerClass: "cellstylegridTPFM",
                                            field: `day_${daysKeys}_vins_count_DIFF`,
                                            width: 120,
                                            filter: true,
                                            resizable: true,
                                            editable: false,
                                            cellClass: "cellstylegridTPFM",
                                            cellStyle: function (params) {
                                                if (params.data.car_model.split("-")[0]=="Total") {
                                                    return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                                    }
                                                },
                                            },
                                            {
                                                headerName: "Total Stock",
                                                headerClass: "cellstylegridTPFM",
                                                field: `day_${daysKeys}_vins_count_STOCK`,
                                                width: 120,
                                                filter: true,
                                                resizable: true,
                                                editable: false,
                                                cellClass: "cellstylegridTPFM",
                                                cellStyle: function (params) {
                                                    if (params.data.car_model.split("-")[0]=="Total") {
                                                        return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                                        }
                                                    },
                                                },
                                    ]
                                })
        
                                var order_count = 0
                                var stock_count= 0 
                                var diff_count = 0
        
                                daysValue.map(ir => {
                                    order_count+=ir.vins_count_ORDER
                                    stock_count+=ir.vins_count_STOCK
                                    diff_count +=ir.vins_count_DIFF
                                })
                                totals_data_total[`day_${daysKeys}_vins_count_ORDER`] = order_count
                                totals_data_total[`day_${daysKeys}_vins_count_STOCK`] = stock_count
                                totals_data_total[`day_${daysKeys}_vins_count_DIFF`] = diff_count
        
        
                            })
                        // var group_bY_days = groupBy(resteonse1,data=>data.days)
                        // // console.log(group_bY_days,"141")
                        // group_bY_days.forEach((items,keys)=>{
                        //     // console.log(items,keys,"170")
                        // })
                        var group_by_rakeType = groupBy(response1, data => data.type)
                        // console.log(group_by_rakeType,"175")
                        group_by_rakeType.forEach((Rakevalues, rakekeys) => {
                            // console.log(Rakevalues,"176")
                            // console.log(rakekeys,"176")
                            
                            var totals_data = {
                                car_model: "Total"+"-"+rakekeys
                            }
                            
                            var group_by_days_for_totals = groupBy(Rakevalues , data =>data.days)
                            group_by_days_for_totals.forEach((values , keys ) =>{
                                var order_count = 0
                                var stock_count = 0
                                var diff_count = 0 
                                values.map(ir => {
                                    order_count+=ir.vins_count_ORDER
                                    stock_count+=ir.vins_count_STOCK
                                    diff_count +=ir.vins_count_DIFF
                                })
                                totals_data[`day_${keys}_vins_count_ORDER`] = order_count
                                totals_data[`day_${keys}_vins_count_STOCK`] = stock_count
                                totals_data[`day_${keys}_vins_count_DIFF`] = diff_count
        
                            })
        
        
                            var group_by_models = groupBy(Rakevalues, data => data.car_model)
                            // console.log(group_by_models,"178")
                            
        
                            group_by_models.forEach((carValues, carkeys) => {
                                // console.log(carkeys,"181")
                                var data = {}
                                data["car_model"] = carkeys
                                
                                var group_by_days = groupBy(carValues, data => data.days)
                                // console.log(group_by_days,carkeys,"183")
                                group_by_days.forEach((daysValues, dayskeys) => {
                                    // console.log(daysValues,"184")
                                    var total_vins_count_order = 0
                                    var total_vins_count_stock = 0
                                    var total_vins_count_diff = 0
                                    daysValues.map((cl) => {
                                        total_vins_count_order += cl.vins_count_ORDER
                                        total_vins_count_stock += cl.vins_count_STOCK
                                        total_vins_count_diff += cl.vins_count_DIFF
                                    })
                                    data[`day_${dayskeys}_vins_count_ORDER`] = total_vins_count_order
                                    data[`day_${dayskeys}_vins_count_STOCK`] = total_vins_count_stock
                                    data[`day_${dayskeys}_vins_count_DIFF`] = total_vins_count_diff
                                })
                                // console.log(data,"198")
                                rowData1.push(data)
                            })
                            if(totals_data.car_model == "Total-BCACBM"){
                                rowData1.push(totals_data)
                            }
                            
                        })
                        rowData1.push(totals_data_total)
                        // console.log(rowData1, "204")
                        // console.log(columns, "265")
                        var columns3 =[]
                        columns3.push( {
                            headerName: "Order Details",
                            headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                            field: "",
                            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                            // chartDataType: 'series',
                            width: 200,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "Order No",
                                    headerClass:"cellstylegridTPFM",
                                    field: "order_number",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM",
                                },
                                {
                                    headerName: "FOR Code",
                                    headerClass:"cellstylegridTPFM",
                                    field: "for_code",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                },
                                {
                                    headerName: "Quantity",
                                    headerClass:"cellstylegridTPFM",
                                    field: "quantity",
                                    width: 90,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                },
                                {
                                    headerName: "SBVH Code",
                                    headerClass:"cellstylegridTPFM",
                                    field: "sbvh_x",
                                    width: 110,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                },
                                {
                                    headerName: "Sales Model",
                                    headerClass:"cellstylegridTPFM",
                                    field: "sales_model_code",
                                    width: 110,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                },
                                {
                                    headerName: "Color",
                                    headerClass:"cellstylegridTPFM",
                                    field: "color",
                                    width: 80,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                },
                                {
                                    headerName: "Production Model Code",
                                    headerClass:"cellstylegridTPFM",
                                    field: "production_model",
                                    width: 160,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPFM"
                                }                                                 
                            ]
                        },
                        {
                            headerName: "Stock Details",
                            headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                            field: "",
                            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                            // chartDataType: 'series',
                            width: 200,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "Vin No",
                                    headerClass:"cellstylegridTPSMG",
                                    field: "vin_no",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPSMG"
                                },
                                {
                                    headerName: "Production Model Code",
                                    headerClass:"cellstylegridTPSMG",
                                    field: "production_model",
                                    width: 160,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPSMG"
                                },
                                {
                                    headerName: "Quantity",
                                    headerClass:"cellstylegridTPSMG",
                                    field: "quantity",
                                    width: 90,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPSMG"
                                },
                                {
                                    headerName: "Color",
                                    headerClass:"cellstylegridTPSMG",
                                    field: "color",
                                    width: 80,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPSMG"
                                },
                                {
                                    headerName: "Parking Location",
                                    headerClass:"cellstylegridTPSMG",
                                    field: "parking_zone",
                                    width: 140,
                                    filter: true,
                                    resizable: true,
                                    cellClass:"cellstylegridTPSMG"
                                }
                            ]
                        },)
                        this.setState({
                            columnwithDefs1: columns,
                            columnwithDefs3:columns3,
                            orderData: rowData1,
                            rowData3:response3,
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        this.setState({
                            columnwithDefs1: [],
                            columnwithDefs3:[],
                            orderData: [],
                            rowData3:[],
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }
                   
    
                    ////////////////////////////////////// second grid ////////////////////////////////////////////////////
                    var records2 = rowData2
                    if (records2.length > 0) {
                        var distinct_car_models = []
                        var distinct_days = []
                        var disticnt_api_type = []
                        var distinct_status = []
                        records2.map(e => {
                            var keys = Object.keys(e)
                            var keys = keys.filter(e => !["status", "days", "plant_code", "api_type"].includes(e))
                            keys.map(key => {
                                if (!distinct_car_models.includes(key)) {
                                    distinct_car_models.push(key)
                                }
                            })
    
                            if (!distinct_days.includes(e.days)) {
                                distinct_days.push(e.days)
                            }
    
                            if (!disticnt_api_type.includes(e.api_type)) {
                                disticnt_api_type.push(e.api_type)
                            }
    
                            if (!distinct_status.includes(e.status)) {
                                distinct_status.push(e.status)
                            }
                        })
    
                        var lastest_row_data = []
                        distinct_car_models.map(e => {
                            var data = {
                                car_model: e
                            }
                            var group_by_days = groupBy(records2, data => data.days)
                            group_by_days.forEach((values_days, keys_days) => {
                                var group_by_api_type = groupBy(values_days, data => data.api_type)
                                group_by_api_type.forEach((values_apitype, keys_apitype) => {
                                    var group_by_status = groupBy(values_apitype, data => data.status)
                                    group_by_status.forEach((values_status, keys_status) => {
                                        var model_count = 0
                                        values_status.map(status => {
                                            model_count += parseInt(status[e])
                                        })
                                        data[`day_${keys_days}_${keys_apitype}_${keys_status}`] = model_count
                                    })
    
                                })
    
                            })
                            // })
                            lastest_row_data.push(data)
                        })
                        // console.log(lastest_row_data, "lastest_row_data_503")
    
    
                        var check_keys2 = Object.keys(records2[0])
                        var orginal_keys2 = check_keys2.filter(e => !["days",
                            "plant_code",
                            "api_type",
                            "status"].includes(e))
                        var rowData2 = []
                        var columns2 = []
                        var uniqueStatus2 = []
                        var uniqueapitype2 = []
                        var group_by_date2 = groupBy(records2, data => data.days)
                        group_by_date2.forEach((values3, keys3) => {
                            var data2 = {
                                days: keys3
                            }
                            orginal_keys2.map(f => {
                                // console.log( values[0],f, values[0][`${f}`] , "f_1729")
                                var group_by_model2 = groupBy(values3, data => data[`${f}`])
                                group_by_model2.forEach((values4, keys4) => {
                                    var group_by_apitype2 = groupBy(values4, data => data.api_type)
                                    group_by_apitype2.forEach((values6, keys6) => {
                                        var group_by_status2 = groupBy(values6, data => data.status)
                                        if (!uniqueapitype2.includes(keys6)) {
                                            uniqueapitype2.push(keys6)
                                        }
                                        var total_model_count2 = 0
                                        group_by_status2.forEach((values5, keys5) => {
                                            values5.map(z => {
                                                total_model_count2 = total_model_count2 + z[`${f}`]
                                            })
                                            if (!uniqueStatus2.includes(keys5)) {
                                                uniqueStatus2.push(keys5)
                                            }
    
    
                                            data2[`${f}_${keys6}_${keys5}`] = total_model_count2
                                        })
                                    })
    
                                })
                            })
                            rowData2.push(data2)
                        })
                        columns2.push(
                            {
                                headerName: "",
                                headerClass: ["cellstylegridTP", "cellTopHeader"],
                                field: "",
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                // chartDataType: 'series',
                                width: 150,
                                chartDataType: "excluded",
                                children: [
                                    {
                                        headerName: "",
                                        headerClass: ["cellstylegridTP", "cellTopHeader"],
                                        field: "",
                                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                        // chartDataType: 'series',
                                        width: 100,
                                        chartDataType: "excluded",
                                        children: [{
                                            headerName: "Car Model",
                                            headerClass: "cellstylegridTP",
                                            field: "car_model",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable: false,
                                            pinned: "left",
                                            cellClass: "cellstylegridTP",
                                            cellStyle: function (params) {
                                                if (params.data.plant_name == "Total") {
                                                    return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                                }
                                            },
                                        },]
                                    }
                                ]
                            }
                        )
                        distinct_days.map((e, index) => {
                            columns2.push({
                                headerName: `Day ${e}`,
                                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                                field: "",
                                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                // chartDataType: 'series',
                                width: 450,
                                chartDataType: "excluded",
    
                                children: []
                            }
                            )
                            disticnt_api_type.map((l) => {
                                var children = []
                                columns2[index + 1].children.push(
                                    {
                                        headerName: l,
                                        headerClass: ["cellstylegridTPFM", "cellTopHeader1"],
                                        field: "",
                                        width: 110,
                                        filter: true,
                                        resizable: true,
                                        editable: false,
                                        cellClass: "cellstylegridTPFM",
                                        cellStyle: function (params) {
                                            if (params.data.plant_name == "Total") {
                                                return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                            }
                                        },
                                        children: children
                                    },
                                )
    
                                distinct_status.map((m) => {
                                    children.push(
                                        {
                                            headerName: m,
                                            headerClass: "cellstylegridTPFM",
                                            field: `day_${e}_${l}_${m}`,
                                            width: 110,
                                            filter: true,
                                            resizable: true,
                                            editable: false,
                                            cellClass: "cellstylegridTPFM",
                                            cellStyle: function (params) {
                                                if (params.data.plant_name == "Total") {
                                                    return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                                }
                                            },
                                            valueGetter: function (params) {
                                                if (![undefined, null, ""].includes(params.data[`day_${e}_${l}_${m}`])) {
                                                    return params.data[`day_${e}_${l}_${m}`]
                                                } else {
                                                    return 0
                                                }
                                            }
                                        },
                                    )
                                })
    
                            })
    
                        })
                        this.setState({
                            columnwithDefs2: columns2,
                            stockData: lastest_row_data,
                            loadshow: "show-n",
                            overly: "show-n"
                        })
                    } else {
                        this.setState({
                            columnwithDefs2: [],
                            stockData: [],
                            loadshow: "show-n",
                            overly: "show-n"
                        })
                    }
                }else{
                    this.setState({
                        columnwithDefs1: [],
                            orderData: [],
                            rowData3:[],
                            columnwithDefs3:[],
                        columnwithDefs2: [],
                        stockData:[],
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            }
                })
            }else{
                this.setState({
                    show: true,
                    basicType: 'warning',
                    basicTitle: "Please Select Status"
                })
            }
                
            /////////////////////////////////////////////--second GRID ---/////////////////////////////////////////////
            
        } else {
            this.setState({
                show: true,
                basicType: 'warning',
                basicTitle: "Please Select Both Options"
            })
        }


    }
    

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        params.api.closeToolPanel()
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: "",
            bulkuploadsliderforreport:""
        });

    }
    selectplant = (e) => {
        this.setState({
            plant: e,
        })
    }
    selectplantsecond = (e) => {
        this.setState({
            plant_second: e,
        })
    }
    selectplantslide = (e) => {
        this.setState({
            plant_slide: e,
        })
    }
    selectcluster = (e) => {
        this.setState({
            cluster: e,
        })
    }
    selectclustersecond = (e) => {
        this.setState({
            cluster_second: e,
        })
    }
    selectclusterslide = (e) =>{
        this.setState({
            cluster_slide : e,
        })
    }
    selectstatus = (e) =>{
        this.setState({
            status:e
        })
    }
    selectstatussecond = (e) =>{
        this.setState({
            status_second:e
        })
    }
    selectstatusslide = (e) =>{
        this.setState({
            status_slide:e
        })
    }
    changeHandler = (event) => {
		// console.log(event.target.name,"Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
        this.setState({
            [name]:value
        });
    	
		// this.setState({ [name]: value });
	}
    onclickopengeneratereportslider =(e)=>{
        this.setState({
            bulkuploadsliderforreport :'slider-translate',
                overly:"show-m",
        })
    }

    onclickgeneratereport =(e)=>{
        e.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var status =  this.state.status_slide
        var cluster = this.state.cluster_slide
        var plant  = this.state.plant_slide
        var to_emails = this.state.to_email
        var cc_emails = this.state.cc_email
        var lp_check_Value = $("#lp_check_slide").is(':checked')
        var clusters =[]
        if(cluster != undefined && cluster != null && cluster != ""){
            if(cluster.length>0){
                cluster.map((e)=>{
                    clusters.push(e.value)
                })
            }else{
                cluster =[]
            }
        }else{
            clusters=[]
        }
        var cluster_slide = []
        if(clusters != undefined && clusters != null && clusters != ""){
            if(clusters.length > 0){
                if(clusters.indexOf("ALL")>=0){
                    cluster_slide = ["ALL"]
                }else{
                    cluster_slide = clusters
                }
            }else{
                clusters = []
            }
        }else{
            clusters =[]
        }
        
        var plants = []
        if(plant != undefined && plant != null && plant != ""){
            if(plant.length>0){
                plant.map((e)=>{
                    plants.push(e.value)
                })
            }else{
                plant =[]
            }
        }else{
            plants =[]
        }
        var plant_slide = []
        if(plants != undefined && plants != null && plants != ""){
            if(plants.length > 0){
                if(plants.indexOf("ALL")>=0){
                    plant_slide = ["ALL"]
                }else{
                    plant_slide = plants
                }
            }else{
                plant_slide =[]
            }
        }else{
            plants = []
        }
        
        var to_email_id =[]
        if(to_emails != undefined && to_emails != null && to_emails != ""){
            if(to_emails.includes(",")==true){
                to_email_id = to_emails.split(",")
            }
            else{
                to_email_id = [to_emails]
            }
        }else{
            to_email_id =[]
        }
        
        var cc_email_id =[]
        if(cc_emails != undefined && cc_emails != null && cc_emails != ""){
            if(cc_emails.includes(",")==true){
                cc_email_id = cc_emails.split(",")
            }
            else{
                cc_email_id = [cc_emails]
            }
        }else{
            cc_email_id=[]
        }
       
        var lp_check = 1
        if(lp_check_Value == true){
            lp_check = 0
        }else{
            lp_check = 1
        }
        // console.log(status,cluster,plant,to_emails,cc_emails,lp_check_Value,"fdaigfdfafdd")
        var status_list = []
        if(status != undefined && status != null && status != ""){
            if(status.length > 0){
                status.map((e)=>{
                    status_list.push(e.value)
                })
            }else{
                status_list =[]
            }
        }else{
            status = []
        }
        
        // console.log(status_list,"146")
        var status_list1 =[]
        // console.log(status_list.indexOf("ALL"),"heeeeeee")
        if(status_list != undefined && status_list != null && status_list != ""){
            if(status_list.length > 0){
                if(status_list.indexOf("ALL") >= 0){
                    // console.log("check-1")
                    status_list1 = ["ALL"]
                }else{
                    // console.log("check-2")
                    status_list1 = status_list
                }
            }
        }
        // console.log(status_list1,to_email_id,cc_email_id,lp_check,plants,clusters,"sfgsfgsfgsfgf")
        var params ={
            plant_code:plant_slide,
            unloading_point:cluster_slide,
            status:status_list1,
            to_emails:to_email_id,
            cc_emails:cc_email_id,
            created_by:localStorage.getItem("username")
        }
        if(lp_check == 0){
            params.lp_check = lp_check
        }
        console.log(params,"aghfjsgghafjgskh")
        if(plant_slide.length > 0){
            if(cluster_slide.length > 0){
                if(status_list1.length > 0){
                    if(to_email_id.length > 0){
                        if(cc_email_id.length > 0){
                            redirectURL.post("/arms/generatereportforordervsstock",params,{"headers":
                            {											
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                            }).then((response)=>{
                                console.log(response.data,"dagdgdgdsfhdfshfdhghfg")
                                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                    this.setState({
                                        show:true,
                                        basicTitle:"Session Expired",
                                        basicType:"warning",
                                        overly: "show-n",
                                        loadshow: "show-n"
                                    })
                                }else{
                                    if(response.data.message == "success"){
                                        this.setState({
                                            bulkuploadsliderforreport:"",
                                            plant_slide:"",
                                            cluster_slide:"",
                                            status_slide:"",
                                            to_email:"",
                                            cc_email:"" ,
                                            overly:"show-n",
                                            loadshow:"show-n",
                                            basicTitle:"An Email will be sent shortly",
                                            basicType:"success",
                                            show:true,
    
                                        })
                                        $("#lp_check_slide").prop('checked',false)
                                    }else{
                                        this.setState({
                                            bulkuploadsliderforreport:"",
                                            plant_slide:"",
                                            cluster_slide:"",
                                            status_slide:"",
                                            to_email:"",
                                            cc_email:"" ,
                                            overly:"show-n",
                                            loadshow:"show-n",
                                            basicTitle:"Report Not Sent",
                                            basicType:"warning",
                                            show:true,
    
                                        })
                                        $("#lp_check_slide").prop('checked',false)
                                    }
                                }
                            })
                                
                        }else{
                            this.setState({
                                basicTitle:"Please Add CC Emails",
                                basicType:"warning",
                                show:true,
                                loadshow:"show-n",
                                overly:"show-n"
                            })
                        }
                    }else{
                        this.setState({
                            basicTitle:"Please Add To Emails",
                            basicType:"warning",
                            show:true,
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                    }
                }
                else{
                    this.setState({
                        basicTitle:"Please Select Status",
                        basicType:"warning",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            }else{
                this.setState({
                    basicTitle:"Please Select Clusters",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }else{
            this.setState({
                basicTitle:"Please Select Plants",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
    }
    
    onClickShowTabView(tab){
        if(tab == "ordervsstock")
        {
            this.setState({
                ordervsstockdata:"btn-arms",
                ordervsstocksummarydata:"",
                ordervsstocktabshow:"show-m",
                ordervsstocksummaryshow:"show-n",
                pagetitle:"Order vs Stock",
            })
            // this.onloadpagedata1()
        }
        if(tab == "ordervsstocksummary")
        {
            this.setState({
                ordervsstockdata:"",
                ordervsstocksummarydata:"btn-arms",
                ordervsstocktabshow:"show-n",
                ordervsstocksummaryshow:"show-m",
                pagetitle:"Order vs Stock Summary",
            })
        //   this.onloadpagedata2()
        }
       
    }
    onLoadPageData2 =(event)=>{
        event.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var status =  this.state.status_second
        var cluster = this.state.cluster_second
        var plant  = this.state.plant_second
        var lp_check_Value = $("#lp_check_second").is(':checked')
        var clusters =[]
        if(cluster != undefined && cluster != null && cluster != ""){
            if(cluster.length>0){
                cluster.map((e)=>{
                    clusters.push(e.value)
                })
            }else{
                cluster =[]
            }
        }else{
            clusters=[]
        }
        var cluster_slide = []
        if(clusters != undefined && clusters != null && clusters != ""){
            if(clusters.length > 0){
                if(clusters.indexOf("ALL")>=0){
                    cluster_slide = ["ALL"]
                }else{
                    cluster_slide = clusters
                }
            }else{
                clusters = []
            }
        }else{
            clusters =[]
        }
        
        var plants = []
        if(plant != undefined && plant != null && plant != ""){
            if(plant.length>0){
                plant.map((e)=>{
                    plants.push(e.value)
                })
            }else{
                plant =[]
            }
        }else{
            plants =[]
        }
        var plant_slide = []
        if(plants != undefined && plants != null && plants != ""){
            if(plants.length > 0){
                if(plants.indexOf("ALL")>=0){
                    plant_slide = ["ALL"]
                }else{
                    plant_slide = plants
                }
            }else{
                plant_slide =[]
            }
        }else{
            plants = []
        }
        var lp_check = 1
        if(lp_check_Value == true){
            lp_check = 0
        }else{
            lp_check = 1
        }
        // console.log(status,cluster,plant,to_emails,cc_emails,lp_check_Value,"fdaigfdfafdd")
        var status_list = []
        if(status != undefined && status != null && status != ""){
            if(status.length > 0){
                status.map((e)=>{
                    status_list.push(e.value)
                })
            }else{
                status_list =[]
            }
        }else{
            status = []
        }
        
        // console.log(status_list,"146")
        var status_list1 =[]
        // console.log(status_list.indexOf("ALL"),"heeeeeee")
        if(status_list != undefined && status_list != null && status_list != ""){
            if(status_list.length > 0){
                if(status_list.indexOf("ALL") >= 0){
                    // console.log("check-1")
                    status_list1 = ["ALL"]
                }else{
                    // console.log("check-2")
                    status_list1 = status_list
                }
            }
        }
        // console.log(status_list1,to_email_id,cc_email_id,lp_check,plants,clusters,"sfgsfgsfgsfgf")
        var params ={
            plant_code:plant_slide,
            unloading_point:cluster_slide,
            status:status_list1,
            created_by:localStorage.getItem("username"),
            request_type:"screen"
        }
        if(lp_check == 0){
            params.lp_check = lp_check
        }
        if(plant_slide.length > 0){
            if(cluster_slide.length >0){
                if(status_list1.length >0){
                    console.log(params,"params2-second")
                    redirectURL.post("/arms/getordervsstocksummaryData",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                    }).then((response)=>{
                        console.log(response.data,"dagdgdgdsfhdfshfdhghfg")
                        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                            this.setState({
                                show:true,
                                basicTitle:"Session Expired",
                                basicType:"warning",
                                overly: "show-n",
                                loadshow: "show-n"
                            })
                        }else{
                            if(response.data.message == "success"){
                                console.log(response.data)
                                var data1 = JSON.parse(response.data.data)
                                var data2= eval(data1.response)
                                var sendData = data2.slice(2)
                                console.log(sendData,"fsdf")
                                var keys = Object.keys(sendData[0])
                                console.log(keys,"dasfd")
                                var columns =[]
                                keys.forEach((z,k)=>{
                                    console.log(z,"yedhaw")
                                    if(z == "source"){
                                        columns.push({
                                            headerName : "Source",
                                            field: "source",
                                            width: 100,
                                            filter: true,
                                            cellClass: function(params){
                                                if(params.data.source != "")
                                                {
                                                        return ["cellstylegridY","pstBg"]
                                                }
                                            },
                                            rowSpan: function(params){
                                                if(params.data.source != "")
                                                {
                                                        return 3;
                                                }
                                            },
                                        })
                                    }
                                    if(z == "destination"){
                                        columns.push({
                                            headerName : "Destination",
                                            field: "destination",
                                            width: 110,
                                            filter: true,
                                            cellClass: function(params){
                                                if(params.data.source != "")
                                                {
                                                        return ["cellstylegridY","pstBg"]
                                                    
                                                }
                                            },
                                            rowSpan: function(params){
                                                if(params.data.source != "")
                                                {
                                                        return 3;
                                                }
                                                
                                            },
                                        })
                                    }
                                   
                                })
                                columns.push({
                                    headerName: "Rake Type",
                                    field: "type",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    editable:false,
                                },
                                {
                                    headerName: "DAY 1 "+`(${this.state.subheader1})`,
                                    headerClass: ["cellstylegridBNG", "cellTopHeader"],
                                    field: "",
                                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                    // chartDataType: 'series',
                                    width: 150,
                                    chartDataType: "excluded",
                                    rake_count:6,
                                    rake_type:"BCACBM_OLD",
                                    children : [
                                        {
                                            headerName: "Orders",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_ORDER_1",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Matching Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_DIFF_1",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Total Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_STOCK_1",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        
                                    ]
                                },
                                {
                                    headerName: "DAY 2 "+`(${this.state.subheader2})`,
                                    headerClass: ["cellstylegridBNG", "cellTopHeader"],
                                    field: "",
                                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                    // chartDataType: 'series',
                                    width: 150,
                                    chartDataType: "excluded",
                                    rake_count:6,
                                    rake_type:"BCACBM_OLD",
                                    children : [
                                        {
                                            headerName: "Orders",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_ORDER_2",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Matching Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_DIFF_2",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Total Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_STOCK_2",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        
                                    ]
                                },
                                {
                                    headerName: "DAY 3 "+`(${this.state.subheader3})`,
                                    headerClass: ["cellstylegridBNG", "cellTopHeader"],
                                    field: "",
                                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                    // chartDataType: 'series',
                                    width: 150,
                                    chartDataType: "excluded",
                                    rake_count:6,
                                    rake_type:"BCACBM_OLD",
                                    children : [
                                        {
                                            headerName: "Orders",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_ORDER_3",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Matching Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_DIFF_3",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        {
                                            headerName: "Total Stock",
                                            headerClass:"cellstylegridBNG",
                                            field: "vins_count_STOCK_3",
                                            width: 100,
                                            filter: true,
                                            resizable: true,
                                            editable:false,
                                            // pinned:"left",
                                            cellClass:"cellstylegridBNG",
                                        },
                                        
                                    ]
                                })
                                this.setState({
                                    columnDefs:columns,
                                    summaryData:sendData,   
                                    overly:"show-n",
                                    loadshow:"show-n"
                                })
                            }else{
                                this.setState({
                                    overly:"show-n",
                                    loadshow:"show-n",
                                })
                            }
                        }
                    })
                }else{
                    this.setState({
                        basicTitle:"Please Select Status",
                        basicType:"warning",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            }else{
                this.setState({
                    basicTitle:"Please Select Cluster",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }else{
            this.setState({
                basicTitle:"Please Select Plant",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
        
    }
    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.ordervsstockdata)} onClick={this.onClickShowTabView.bind(this,"ordervsstock")}>Order vs Stock</button>
                        <button type="button" className={"btn "+(this.state.ordervsstocksummarydata)} onClick={this.onClickShowTabView.bind(this,"ordervsstocksummary")}>Order vs Stock Summary</button>
                        
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.ordervsstocktabshow)}>
                        <div className="col-xl-12 col-lg-12">
                                <div className="card-arms">
                                    <div className="card-header-arms">
                                        <h5>
                                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                        </h5>
                                    </div>
                                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                    
                                    <div className="col-xl-2 col-lg-2 form-group">
                                            <label>Plant:</label>
                                            <Select
                                                placeholder={"Select Plant"}
                                                closeMenuOnSelect={true}
                                                value={this.state.plant}
                                                onChange={this.selectplant.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{"label":"Haryana","value":"F"},{"label":"Gujarat","value":"G"},{"label":"Bidadi","value":"B"}]} required
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>Destination:</label>
                                            <Select
                                                placeholder={"Select Destination"}
                                                closeMenuOnSelect={true}
                                                value={this.state.cluster}
                                                onChange={this.selectcluster.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.clusterOptions} required
                                            />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>Status :</label>
                                            <Select
                                                placeholder={"Select Status"}
                                                closeMenuOnSelect={true}
                                                isMulti={true}
                                                value={this.state.status}
                                                onChange={this.selectstatus.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{"value":"ALL","label":"ALL"},{"value":"Fund Needed","label":"Fund Needed"},{"value":"Fund Validated","label":"Fund Validated"},{"value":"Stopped by SND","label":"Stopped by SND"}]} required
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-2 form-group'>
                                            <div className='row' style={{marginTop:"39px"}}>
                                                <div className='col-xl-2 col-lg-2' style={{paddingLeft:"17px",paddingRight:"0px"}}>
                                                    <input type="checkbox" name = "lp_check" id="lp_check" value="1"/>
                                                </div>
                                                <div className='col-xl-10 col-lg-10' style={{paddingLeft:"0px"}}>
                                                    <label className="" style={{fontWeight:"Bold",fontSize:"14px"}}>Disbale LP Check</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="form-group col-xl-2 col-lg-2">
                                            <label>&nbsp;</label><br />
                                            <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData}>Submit</button>

                                        </div>
                                    </form> 
                                </div>
                                    <div className="grid-container">
                                    
                                    <div className='row'>
                                        <div className='col-xl-12 col-lg-12'>
                                        <div className='card-header-arms ml-5'>
                                            <h5>
                                                <i className=""></i> <span>NO OF ORDERS</span>
                                            </h5>
                                        </div>
                                    
                                        <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnwithDefs1}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.orderData}
                                                rowSelection={this.state.rowSelection}
                                                enableCharts={false}
                                                //  paginationAutoPageSize={true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                deleteRowHandler={this.deleteRowHandler}
                                                // onCellClicked={this.onRowClicked.bind(this)}
                                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                // floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                suppressCellFlash={true}
                                                gridOptions={{
                                                    context: { componentParent: this },
                                                    suppressRowTransform: true,
                                                }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                            />
                                        </div>
                                        </div>
                                        <div className='col-xl-12 col-lg-12'>
                                        <div className='card-header-arms ml-5'>
                                            <h5>
                                                <i className=""></i> <span>MATCHING STOCK</span>
                                            </h5>
                                        </div>
                                    
                                        <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnwithDefs3}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData3}
                                                rowSelection={this.state.rowSelection}
                                                enableCharts={false}
                                                //  paginationAutoPageSize={true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                deleteRowHandler={this.deleteRowHandler}
                                                sideBar={true}
                                                statusBar={true}
                                                // onCellClicked={this.onRowClicked.bind(this)}
                                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                // floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                suppressCellFlash={true}
                                                gridOptions={{
                                                    context: { componentParent: this },
                                                    suppressRowTransform: true,
                                                }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                            />
                                        </div>
                                        </div>
                                        <div className='col-xl-12 col-lg-12'>
                                        <div className='card-header-arms ml-5'>
                                    <h5>

                                            <i className=""></i> <span>STOCK/PRODUCTION</span>
                                        </h5>
                                        </div>
                                        <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnwithDefs2}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.stockData}
                                                rowSelection={this.state.rowSelection}
                                                enableCharts={false}
                                                //  paginationAutoPageSize={true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                deleteRowHandler={this.deleteRowHandler}
                                                // onCellClicked={this.onRowClicked.bind(this)}
                                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                // floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                suppressCellFlash={true}
                                                gridOptions={{
                                                    context: { componentParent: this }
                                                }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                    </div>
                                </div>
                            </div>
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.ordervsstocksummaryshow)}>
                        <div className="col-xl-12 col-lg-12">
                                <div className="card-arms">
                                    <div className="card-header-arms">
                                        <h5>
                                            <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                        </h5>
                                    </div>
                                    {/* <div>
                                    <span className="layoutbtns pull-right">
                                
                                                    <button className="float-right custom-btn btn-arms"
                                                    style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",borderRadius:"5px"}}
                                                    onClick={this.onclickopengeneratereportslider}>
                                                    Generate Report
                                                    </button>
                                                    
                                                </span>
                                </div> */}
                                
                                        <div>
                                            <span className="layoutbtns pull-right">
                                                <button className="float-right custom-btn btn-arms"
                                                style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",borderRadius:"5px"}}
                                                onClick={this.onclickopengeneratereportslider}>
                                                Generate Report
                                                </button>
                                                
                                            </span>
                                        </div>
                                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                                    <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                    
                                    <div className="col-xl-2 col-lg-2 form-group">
                                            <label>Plant:</label>
                                            <Select
                                                placeholder={"Select Plant"}
                                                closeMenuOnSelect={true}
                                                value={this.state.plant_second}
                                                onChange={this.selectplantsecond.bind(this)}
                                                isMulti={true}
                                                style={{ borderRadius: "0px" }}
                                                options={[{"value":"ALL","label":"ALL"},{"label":"Haryana","value":"F"},{"label":"Gujarat","value":"G"},{"label":"Bidadi","value":"B"}]} required
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <label>Destination:</label>
                                            <Select
                                                placeholder={"Select Destination"}
                                                closeMenuOnSelect={true}
                                                value={this.state.cluster_second}
                                                onChange={this.selectclustersecond.bind(this)}
                                                isMulti={true}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.clusterOptions1} required
                                            />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <label>Status :</label>
                                            <Select
                                                placeholder={"Select Status"}
                                                closeMenuOnSelect={true}
                                                isMulti={true}
                                                value={this.state.status_second}
                                                onChange={this.selectstatussecond.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{"value":"ALL","label":"ALL"},{"value":"Fund Needed","label":"Fund Needed"},{"value":"Fund Validated","label":"Fund Validated"},{"value":"Stopped by SND","label":"Stopped by SND"}]} required
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-2 form-group'>
                                            <div className='row' style={{marginTop:"39px"}}>
                                                <div className='col-xl-2 col-lg-2' style={{paddingLeft:"17px",paddingRight:"0px"}}>
                                                    <input type="checkbox" name = "lp_check_second" id="lp_check_second" value="1"/>
                                                </div>
                                                <div className='col-xl-10 col-lg-10' style={{paddingLeft:"0px"}}>
                                                    <label className="" style={{fontWeight:"Bold",fontSize:"14px"}}>Disbale LP Check</label>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="form-group col-xl-2 col-lg-2">
                                            <label>&nbsp;</label><br />
                                            <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData2}>Submit</button>

                                        </div>
                                    </form> 
                                    <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: Clusters with common for codes might have duplicate orders vs stock counts</h5>
                                </div>
                                    <div className="grid-container">
                                    
                                    <div className='row'>
                                        <div className='col-xl-12 col-lg-12'>
                                        <div className='card-header-arms ml-5'>
                                            <h5>
                                                <i className=""></i> <span>NO OF ORDERS</span>
                                            </h5>
                                        </div>
                                    
                                        <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                            <AgGridReact
                                                modules={this.state.modules}
                                                columnDefs={this.state.columnDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.summaryData}
                                                rowSelection={this.state.rowSelection}
                                                enableCharts={false}
                                                //  paginationAutoPageSize={true}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                deleteRowHandler={this.deleteRowHandler}
                                                // onCellClicked={this.onRowClicked.bind(this)}
                                                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                                frameworkComponents={this.state.frameworkComponents}
                                                //editType={this.state.editType}
                                                stopEditingWhenGridLosesFocus={true}
                                                // floatingFilter={true}
                                                enableCellChangeFlash={true}
                                                suppressCellFlash={true}
                                                gridOptions={{
                                                    context: { componentParent: this },
                                                    suppressRowTransform: true,
                                                }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                            />
                                        </div>
                                        </div>      
                                </div>
                                    </div>
                                </div>
                            </div>
                </div>
                </div>
                <div className={"slide-r " + (this.state.bulkuploadsliderforreport)} style={{ overflow: "auto", width: "30%" }}>
					<h3 className="subHarms">Generate Report
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.onclickgeneratereport}>
                            <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        value={this.state.plant_slide}
                                        isMulti={true}
                                        onChange={this.selectplantslide.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{"value":"ALL","label":"ALL"},{"label":"Haryana","value":"F"},{"label":"Gujarat","value":"G"},{"label":"Bidadi","value":"B"}]} required
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Destination:</label>
                                    <Select
                                        placeholder={"Select Destination"}
                                        closeMenuOnSelect={true}
                                        isMulti={true}
                                        value={this.state.cluster_slide}
                                        onChange={this.selectclusterslide.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.clusterOptions1} required
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Status :</label>
                                    <Select
                                        placeholder={"Select Status"}
                                        closeMenuOnSelect={true}
                                        isMulti={true}
                                        value={this.state.status_slide}
                                        onChange={this.selectstatusslide.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{"value":"ALL","label":"ALL"},{"value":"Fund Needed","label":"Fund Needed"},{"value":"Fund Validated","label":"Fund Validated"},{"value":"Stopped by SND","label":"Stopped by SND"}]} required
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
									<label > To Email Id's :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.to_email}
										name="to_email"
										autoComplete='off'
                                        required/>
								</div>
                                <div className="col-xl-12 col-lg-12 form-group">
									<label > CC Email Id's :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.cc_email}
										name="cc_email"
										autoComplete='off'
                                        required/>
								</div>
                                <div className='col-xl-12 col-lg-12 form-group'>
                                    <div className='row' style={{marginTop:"39px"}}>
                                        <div className='col-xl-1 col-lg-1' style={{paddingLeft:"17px",paddingRight:"0px"}}>
                                            <input type="checkbox" name = "lp_check_slide" id="lp_check_slide" value="1"/>
                                        </div>
                                        <div className='col-xl-11 col-lg-11' style={{paddingLeft:"0px"}}>
                                            <label className="" style={{fontWeight:"Bold",fontSize:"14px"}}>Disbale LP Check</label>
                                        </div>
                                    </div>
                                </div>
                                <h5 style={{fontWeight:"bold",color:"red",fontSize:"14px",marginTop:"2%"}}>Note: Emails should be provided in comma ' , ' delimited only</h5>
								<div className="form-group">
                                    <button type="submit" className="btn btn-arms">Submit</button>
                                </div>
							</form>
						</div>
					</div>
				</div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}