'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMMYYYY } from '../common/utils';
//import DeleteButton from "./deleteshipmentDSR";
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var moment = require('moment');
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class LspTentativeRakeAvailability extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
         // DeleteButton:DeleteButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulksliderforViewWeeklyRailTarget:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        cluster_yard  : [],
        rail_yard : [],
        state :[],
        loading_cluster : [],
        unloading_cluster : [],
        baseStations : [],
        service_provider:"",
        rake_no:"",
        origin:"",
        loadingyard:"",
        category:"",
        rakenodetails : [],
        serviceproviderdetails:[],
        origindetails:[],
        selectloadingcluster:"",
        selectunloadingcluster:"",
        selectbasestation:"",
        showloadinginputTab :"",
        showunloadinginputTab :"",
        showbasestationinputTab :"",
        loadingyarddetails :[],
        categorydetails:[],
        railyard_name:"",
        latitude:"",
        longitude:"",
        ViewWeeklyRailTargetRowData:[],
        ViewWeeklyRailTargetRowData_week:"",
        pinnedBottomRowData:[{}],
        showInputfield:"No"
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    this.onLoadPageData();
    var params ={}
    var tpt_code = localStorage.getItem("transportercode")
    if(tpt_code != undefined && tpt_code != null && tpt_code != ""){
        params.transporter_code = tpt_code
    }
    console.log(params,"200")
    redirectURL.post("/arms/getTentativeRakeavailabilitydetails",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
})    
    .then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
        var  rakenos = response.data.rakeNo;
        var  loading_cluster = response.data.loading_yards;
        // var categories = response.data.categories
        var rakenodetails = []
        // var origindetails = []
        var loadingyarddetails = []
        rakenos.map((e)=>{
            rakenodetails.push({
                value : e,
                label : e
            })
        })
        loading_cluster.map((c)=>{
            loadingyarddetails.push({
                value : c,
                label : c
            })
        })
        console.log(rakenodetails,"233")
        this.setState({
            rakenodetails : rakenodetails,
            loadingyarddetails : loadingyarddetails,
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
    
}

onLoadPageData(){
    var params ={}
    var tpt_code = localStorage.getItem("transportercode")
    if(tpt_code != undefined && tpt_code != null && tpt_code != ""){
        params.transporter_code = tpt_code
    }
    redirectURL.post("/arms/gettentativerakeavailabilityData",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data,
            overly:"show-n",
            loadshow:"show-n"
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            cluster_name : e.data.cluster_name,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deletetentativeRakeavailabilityData",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
}

onClickHideAll(){
    $("#upform").val();

    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulksliderforViewWeeklyRailTarget:"",
    rake_no : "",
    service_provider :"",
    loading_yard :"",
    origin :"",
    category:"",
    serviceproviderdetails:[],
    origindetails:[],
    bulkslide1:"",
    loadingyard:"",
    showInputfield:"No"
    });
    $("#eta_date").val("")
    $("#distance_covered").val("")
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{station_code:"",state:"",cluster_name:"",railyard_name:"",latitude:"",longitude:"",loading_cluster:"",unloading_cluster:"",base_station:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['station_code_1', 'state_1', 'cluster_name_1', 'railyard_name_1', 'latitude_1', 'longitude_1','loading_cluster_1','unloading_cluster_1','base_station_1', 'bcacbm_eligible_1']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'station_code',
                inputName: 'station_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'state',
                inputName: 'state',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'cluster_name',
                inputName: 'cluster_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'railyard_name',
                inputName: 'railyard_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'latitude',
                inputName: 'latitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'longitude',
                inputName: 'longitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'loading_cluster',
                inputName: 'loading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'unloading_cluster',
                inputName: 'unloading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'base_station',
                inputName: 'base_station',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'bcacbm_eligible',
                inputName: 'bcacbm_eligible',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     this.setState({
                    //         csvcontent: csvData.data
                    //     });
                    // }
                    const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#]*$/); // define allowed characters using regex
                    let keys = Object.keys(csvData.data[0])
                    let items  = ['station_code','state','cluster_name','railyard_name','latitude','longitude','loading_cluster','unloading_cluster','base_station','bcacbm_eligible']
                    let check = false
                     if (csvData.inValidMessages.length === 0) {       
                                for (let i in keys){
                                    if(items.includes(keys[i])){
                                        check = true                 
                                    }else{
                                        check = false
                                        break
                                    }
                            }
                        }     
                            else{
                                check = false
                            }  
                            
                            let csvalues = Object.keys(csvData.data[0])
                            if(csvalues.length > 0){
                                for(let c in csvalues){
                                    if(aplhanumeichypen.test(csvalues[c]) === false)
                            {
                                check = false;
                                break;
                            }
                                }
                            }
                     console.log("check", check)
                    if (check === false) {
                        $("#bulkUploadID").val("")
                        // document.getElementById("appednCSVData").innerHTML = ''
                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields',
                            basicType: 'danger'
                        });
                       
                    } else {
                        this.setState({
                            csvcontent: csvData.data
                        });
                    }
                  

                }).catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []



            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);



            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))



            }
            else {
                e.target.value = null;
                this.setState({
                    bulkUploadID:'',
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            bulkUploadID:'',
            file:""
        });
    }
}

onclickslider(e){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
    
}

onLoadViewWeeklyRailTargetData = () => {
    this.setState({
        loadshow:'show-m'
    })
    var tpt_code = localStorage.getItem("transportercode")
    var params = {}
    if(tpt_code != undefined && tpt_code != null && tpt_code !="" && tpt_code != "undefined"){
        params.tpt_code = tpt_code
    }
    redirectURL.post("/arms/getviewWeeklyRailTargetData" ,params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data , "resposne_data")
        var records = response.data
        if(records.length>0){
            var ViewWeeklyRailTargetRowData_week = records[0].week
        }else{
            ViewWeeklyRailTargetRowData_week = ""
        }
        var total = 0
        if(records.length > 0){
            records.map(e => {
                total = total + parseInt(e.cars)
            })
        }else{
            records = []
        }
        
        var pinnedBottomRowData = {}
        pinnedBottomRowData['loading_cluster'] = ''
        pinnedBottomRowData['unloading_cluster'] = 'Total'
        pinnedBottomRowData['cars'] = total
        this.setState({
            ViewWeeklyRailTargetRowData : records,
            ViewWeeklyRailTargetRowData_week:ViewWeeklyRailTargetRowData_week,
            loadshow:'show-n',
            pinnedBottomRowData : [pinnedBottomRowData]
        })
    }
    })
}


onClickSliderForViewWeeklyRailTarget(e){
    this.setState({
        overly :"show-m",
        bulksliderforViewWeeklyRailTarget : "slider-translate-50p"
    })
    this.onLoadViewWeeklyRailTargetData()
    
}

selectrakeno = (e) => {
    this.setState({
        rake_no: e,
    })
    var params ={
        rake_no : e.value
    } 
    redirectURL.post("/arms/getspfortentativerakeavailability",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data.service_provider,"779")
        var serviceProvider = response.data.service_provider
        var service_provider = [response.data.service_provider]
         var serviceproviderdetails = []
         service_provider.map((k)=>{
            serviceproviderdetails.push({
                label : k,
                value :k
            })
        })
        this.setState({
            service_provider :{value : serviceProvider , label : serviceProvider},
            serviceproviderdetails:serviceproviderdetails
        })
    }
    })
}
selectserviceprovider = (e) =>{
    this.setState({
        service_provider : e
    })
}
selectloadingyard = async(e) => {
    this.setState({
        loadingyard: e,
    })
    console.log(e.value,"780")
    var params ={
        loading_cluster : e.value
    } 
    await redirectURL.post("/arms/getplantfortentativerakeavailability",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        // console.log(response.data.plant_name,"779")
        var plantData  = response.data.plant_name
        var plant_data = [response.data.plant_name]
         var origindetails = []
         plant_data.map((k)=>{
            origindetails.push({
                label : k,
                value :k
            })
        })
        this.setState({
            origin :{value : plantData , label : plantData},
            origindetails:origindetails
        })
    }
    })
    var transporter_code = this.state.service_provider.value
    var loading_yard =  this.state.loadingyard.value
    var params ={
        transporter_code : transporter_code,
        loading_yard : e.value,
    }
    await redirectURL.post("/arms/getDistinctcategorybaseddata",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var categories = response.data.categorydetails
        if(categories.length>0){
            var categorydetails = []
            categories.map((e)=>{
                categorydetails.push({
                    value : e,
                    label : e
                })
            })
            this.setState({
                categorydetails : categorydetails
            })
        }else{
            this.setState({
                basicTitle :"Please Add Transporter Serving Details",
                basicType :"warning",
                show:true,
                origindetails:[],
                origin:{},
                loadingyard:""
            })
        }
    }
    })
}
selectorigin = (e) => {
    this.setState({
        origin: e,
    })
    
}
selectCategory = (e) =>{
    this.setState({
        category :e,
    })
if(e.value == "C"){
    this.setState({
        showInputfield : "Yes"
    })
}else{
    this.setState({
        showInputfield : "No"
    })
}
}
selectLoadingcluster= (e) => {
    this.setState({
        selectloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showloadinginputTab:"Yes"
        })
    }else{
        this.setState({
            showloadinginputTab:"No"
        })
    }
    
}
selectunLoadingcluster= (e) => {
    this.setState({
        selectunloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showunloadinginputTab:"Yes"
        })
    }else{
        this.setState({
            showunloadinginputTab:"No"
        })
    }
}
selectBasestationcluster = (e) => {
    this.setState({
        selectbasestation: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showbasestationinputTab:"Yes"
        })
    }
else{
    this.setState({
        showbasestationinputTab:"No"
    })
}
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
validateInput = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue, "inputValue")
    const characterRegex = /^[a-zA-Z\s]+$/;
    console.log(!characterRegex.test(inputValue),"910")
    if (!characterRegex.test(inputValue)) {
        if(inputValue == ""){
            this.setState({
                railyard_name: "",
              });
        }
     
    } else {
      this.setState({
        railyard_name: inputValue,
      });
    }
  };
  validatelatitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    console.log(inputValue,"inputValue")
    const floatRegex = /^\d{1,}(\.\d{0,4})?$/;
    console.log(!floatRegex.test(inputValue))
    if (!floatRegex.test(inputValue)) {
     
    } else {
      this.setState({
        latitude: inputValue,
      });
    }
  };
  validatelongitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,4})?$/;

    if (!floatRegex.test(inputValue)) {
     
    } else {
      this.setState({
        longitude: inputValue,
      });
    }
  };
  changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
submitRialyarddetails=(event)=>{
    event.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var rake_no = this.state.rake_no.value
    var service_provider = this.state.service_provider.value
    var loading_yard = this.state.loadingyard.value
    var origin = this.state.origin.value
    var eta = $("#eta_date").val()
    var category = this.state.category.value
    // var distance_covered = $("#distance_covered").val()
    var params ={
        rake_no : rake_no,
        service_provider : service_provider,
        loading_yard : loading_yard,
        origin : origin,
        eta : eta,
        category : category
        // distance_covered : distance_covered
     }
     console.log(params,"1064")

    if(rake_no != undefined && rake_no != null && rake_no != ""){
        if(loading_yard != undefined && loading_yard != null && loading_yard != ""){
            if(eta != undefined && eta != null && eta != ""){
                if(category != undefined && category != null && category != ""){
                    redirectURL.post("/arms/savetentativerakeavailabilityData",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        if(response.data.message == "success"){
                            this.setState({
                                loadshow : "show-n",           
                                overly :"show-n",                
                                rake_no : "",                    
                                service_provider :"",            
                                loading_yard :"",                
                                origin :"",                       
                                category:"",                     
                                serviceproviderdetails:[],        
                                origindetails:[],  
                                categorydetails:[],               
                                basicTitle :response.data.message, 
                                basicType :"success",              
                                bulkslide1:"",                     
                                show:true,                         
                                loadingyard:"",                    
                                showInputfield:"No"                
                            })
                            $("#eta_date").val("")
                            $("#distance_covered").val("")
                            this.onLoadPageData()
                        }else{
                            this.setState({
                                basicTitle :response.data.message,
                                basicType :"warning",
                                show:true,
                                bulkslide1:"",
                                loadshow : "show-n",
                                overly :"show-n",
                                rake_no : "",
                                service_provider :"",
                                loading_yard :"",
                                origin :"",
                                category:"",
                                categorydetails:[],
                                serviceproviderdetails:[],
                                origindetails:[],
                                loadingyard:"",
                                showInputfield:"No"
                            })
                            $("#eta_date").val("")
                            $("#distance_covered").val("")
                        }
                    }
                    })
                }
                 else{
                    this.setState({
                        basicTitle :"Please Select Category",
                        basicType :"warning",
                        show:true,
                        loadshow :"show-n",
                        overly:"show-n"
                    })
                 }  
    
               
            }else{
                this.setState({
                    basicTitle :"Please Provide ETA",
                    basicType :"warning",
                    show:true,
                    loadshow :"show-n",
                    overly:"show-n"
                })
            }
        }else{
            this.setState({
                basicTitle :"Please Select Loading Yard",
                basicType :"warning",
                show:true,
                loadshow :"show-n",
                overly:"show-n"
            })
        }

    }else{
        this.setState({
            basicTitle :"Please Select Rake No",
            basicType :"warning",
            show:true,
            loadshow :"show-n",
            overly:"show-n"
        })
    }
     

}
onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}

  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [  
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },
        { 
            headerName: "Arrival Input Screen by LSP",
            headerClass: ["cellstylegrid", "cellTopHeader"],
            field: "",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            // chartDataType: 'series',
            width: 100,
            chartDataType: "excluded",
            children:[ 
        {
            headerName: "Service Provider",
            field: "transporter_code",
            width:170,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Rake No",
            field: "rake_no",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.state,
        },
        },
        {
            headerName: "Origin",
            field: "plant",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: this.state.cluster_yard ,
            },
        },
        {
            headerName: "Loading Yard",
            field: "railyard_name",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.rail_yard ,
            },
        },
        {
            headerName: "ETA",
            field: "eta",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.eta != "" && params.data.eta != undefined && params.data.eta != null){
                    return getHyphenDDMMMYYYY(params.data.eta)
                }else{
                    return ""
                }
            }
        },
        {
            headerName: "Category",
            field: "category",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
        },
        // {
        //     headerName: "Distance Covered",
        //     field: "distance_covered_in_circuit",
        //     width:170,
        //     filter: true,
        //     resizable: true,
        //     editable:false,
        // },
               
    ]
}      
    ]

    var columnwithDefsForViewWeeklyrailTarget= [  
        { 
            headerName: 'WEEK '+this.state.ViewWeeklyRailTargetRowData_week,
            headerClass: ["cellstylegrid", "cellTopHeader"],
            field: "",
            cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
            // chartDataType: 'series',
            width: 100,
            chartDataType: "excluded",
            children:[ 
        {
            headerName: "Plant",
            field: "loading_cluster",
            width:170,
            filter: true,
            resizable: true,
            // editable:true,            
        },
        {
            headerName: "Destination",
            field: "unloading_cluster",
            width:170,
            filter: true,
            resizable: true,
            // editable:true,
            cellEditor: 'agSelectCellEditor',
        //     cellEditorParams: {
        //         values: this.state.state,
        // },
        },
        {
            headerName: "Overall Available Volumes (Tentative)",
            field: "cars",
            width:170,
            filter: true,
            resizable: true,
            // editable:true,
            // cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.cluster_yard ,
            // },
        },        
    ]
}      
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
        <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>LSP Tentative Rake Availability</span>
                    </h5>
                    </div>
                    <h5>
                </h5>
                <div>
                <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider.bind(this)}>Add Tentative Rake Availability</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    {/* <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                    <button className="btn btn-warning" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickSliderForViewWeeklyRailTarget.bind(this)}>View Weekly Rail Target</button>
                </div>
                <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
          
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    />
            </div>
            </div>
        </div>
        </div>

        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Availability
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitRialyarddetails}>
                    
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Rake No:</label>
                                    <Select
                                                    placeholder={"Select Rake No"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_no}
                                                    onChange={this.selectrakeno.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.rakenodetails} required
                                                />
                                </div>
                                {(this.state.serviceproviderdetails.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Service Provider:</label>
                                    <Select
                                                    placeholder={"Select Service Provider"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.service_provider}
                                                    onChange={this.selectserviceprovider.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.serviceproviderdetails} required
                                                />
                                </div>
                                :
                                ""
                                }
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Loading Yard:</label>
                                    <Select
                                                    placeholder={"Select Loading Yard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loadingyard}
                                                    onChange={this.selectloadingyard.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loadingyarddetails} required
                                                />
                                </div>
                                {(this.state.origindetails.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Origin:</label>
                                    <Select
                                                    placeholder={"Select Origin"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.origin}
                                                    onChange={this.selectorigin.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.origindetails} required
                                                />
                                </div>
                                :
                                ""
                                }
                                
                                    <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12"> ETA : </label>
                                            <input type="text" name="eta_date" id="eta_date" className="datetimepicker_date form-control" readOnly required/>
                                        </div>
                                {(this.state.categorydetails.length>0)?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Category:</label>
                                    <Select
                                                    placeholder={"Select Category"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.category}
                                                    onChange={this.selectCategory.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.categorydetails} required
                                                />
                                </div>:""
                                }
                                {/* {(this.state.showInputfield == "Yes")?
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label className="col-xl-12 col-lg-12">Distacne Covered In Circuit : </label>
                                               <input type="number" name="distance_covered" id="distance_covered" className="form-control armsinputbox" onChange={this.changeHandler}
                                      required  />
                                      </div>
                                :""} */}
                                
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                    </div>
                    </form>		
                </div>
            </div>
        </div>
            <div className={"slide-r " + (this.state.bulksliderforViewWeeklyRailTarget)} style={{ overflow: "auto" }}>
                <h3 className="subHarms">View Weekly Rail Target
                    <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                </h3>
                <div className="slide-r-body" style={{ position: "relative" }}>

                    <div className="container-fluid">
                        <div id="myGrid" style={{ height: "600px", width: "600px" }} className="ag-theme-balham grid-card">

                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefsForViewWeeklyrailTarget}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.ViewWeeklyRailTargetRowData}
                                enableCharts={false}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                // sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={true}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                        return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                    },
                                }}
                                pinnedBottomRowData={this.state.pinnedBottomRowData}
                                enableRangeSelection={true}
                                onCellClicked={this.onCellClicked}
                            />
                        </div>
                    </div>
                </div>
            </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        // minDate:true,
        timepicker: false,
        closeOnDateSelect: true,

        beforeShowDay: function(date) {
            var formattedDate = $.datepicker.formatDate("dd-mm-yy", date);            
            var current_date =new Date()
            var endOfMonth = new Date( current_date.getFullYear() , current_date.getMonth() +1 , 0)
            console.log(endOfMonth.getDate())
            if(current_date.getDate() >=1 && current_date.getDate() <= 5){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=7){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }
            else if(current_date.getDate() ===6 || current_date.getDate() === 7){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=14){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }else if(current_date.getDate() >=8 && current_date.getDate() <= 12){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=14){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }else if(current_date.getDate() ===13 || current_date.getDate() === 14){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=23){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }else if(current_date.getDate() >=15 && current_date.getDate() <= 20){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 8 ; i++){
                    if(current_date.getDate()+i <=22){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }
            else if(current_date.getDate() ===21 || current_date.getDate() === 22){
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=endOfMonth.getDate()){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }
            else if(current_date.getDate() >=23 && current_date.getDate() <= endOfMonth.getDate()-2){
                console.log(endOfMonth.getDate(), "endOfMonth.getDate()-1")
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <=endOfMonth.getDate()){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }
            else {
                var disabledDates = []; // Array of date strings to disable
                var current_month = current_date.getMonth()
                var current_year = current_date.getFullYear()
                for (let i = 0; i < 10 ; i++){
                    if(current_date.getDate()+i <= endOfMonth.getDate()+7){
                        disabledDates.push(moment.parseZone(new Date(current_year , current_month , current_date.getDate() +i)).format("DD-MM-YYYY"))
                    }
                }
                return [disabledDates.includes(formattedDate) === true, ''];
            }
            // return [disabledDates.includes(formattedDate) === false, ''];
          }
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};