import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { values } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class OrdervsStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            csvcontent: [],
            billing_month: "",
            columnwithDefs1: [],
            columnwithDefs2: [],
            orderData:[],
            stockData:[],
            plant:"",
            clusterOptions:[],
            cluster:"",
            rowData1:[],
            rowData2:[]
        };
    }

    componentDidMount() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n"
        })
        redirectURL.post("/arms/getDistinctdataforclusters").then((response)=>{
            var resp = response.data.cluster_data
            var clusterOptions =[]
            resp.map((e)=>{
                clusterOptions.push({
                    "value":e,
                    "label":e
                })
            })
            this.setState({
                clusterOptions : clusterOptions
            })
        })
        // this.onLoadPageData()
    };
    onLoadPageData = (event) => {
        event.preventDefault();
        
        var plant_code = this.state.plant.value
        var cluster = this.state.cluster.value
        if (![undefined , "" , null , "undefined"].includes(plant_code) && ![undefined , "" , null , "undefined"].includes(cluster) ) {
            var params = {
                plant_code: plant_code,
                unloading_point: cluster
            }
            console.log(params , "params")
            this.setState({
                loadshow: "show-m",
                overly: "show-m"
            })
            redirectURL.post("/arms/getordervsstockdata", params).then((response) => {
                console.log(response.data, "8975")
                var resp = JSON.parse(response.data.data)
                var rowData1 = eval(resp.order_df)
                var rowData2 = eval(resp.stock_df)


                var records = rowData1
                if (records.length > 0) {

                    var distinct_car_models = []
                    var distinct_days = []
                    var distinct_status = []
                    records.map(e => {
                        var keys = Object.keys(e)
                        var keys = keys.filter(e => !["status", "days", "plant_code", "api_type"].includes(e))
                        keys.map(key => {
                            if (!distinct_car_models.includes(key)) {
                                distinct_car_models.push(key)
                            }
                        })

                        if (!distinct_days.includes(e.days)) {
                            distinct_days.push(e.days)
                        }

                        if (!distinct_status.includes(e.status)) {
                            distinct_status.push(e.status)
                        }
                    })

                    var lastest_row_data = []
                    distinct_car_models.map(e => {
                        var data = {
                            car_model: e
                        }
                        var group_by_days = groupBy(records, data => data.days)
                        group_by_days.forEach((values_days, keys_days) => {
                            var group_by_status = groupBy(values_days, data => data.status)
                            group_by_status.forEach((values_status, keys_status) => {
                                var model_count = 0
                                values_status.map(status => {
                                    model_count += parseInt(status[e])
                                })
                                data[`day_${keys_days}_${keys_status}`] = model_count
                            })
                        })

                        lastest_row_data.push(data)
                    })
                    var columns = []
                    columns.push(
                        {
                            headerName: "",
                            headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                            field: "",
                            width: 150,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "Car Model",
                                    headerClass: "cellstylegridTPFM",
                                    field: "car_model",
                                    width: 100,
                                    filter: true,
                                    resizable: true,
                                    editable: false,
                                    pinned: "left",
                                    cellClass: "cellstylegridTPFM",
                                    cellStyle: function (params) {
                                        if (params.data.plant_name == "Total") {
                                            return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                        }
                                    },
                                },
                            ]
                        }
                    )
                    distinct_days.map((e, index) => {
                        columns.push({
                            headerName: `Day ${e}`,
                            headerClass: ["cellstylegridSMG", "cellTopHeader"],
                            field: "",
                            width: 250,
                            chartDataType: "excluded",

                            children: []
                        }
                        )
                        distinct_status.map((status) => {
                            columns[index + 1].children.push(
                                {
                                    headerName: status,
                                    headerClass: "cellstylegridSMG",
                                    field: `day_${e}_${status}`,
                                    width: 80,
                                    filter: true,
                                    resizable: true,
                                    editable: false,
                                    cellClass: "cellstylegridSMG",
                                    cellStyle: function (params) {
                                        if (params.data.plant_name == "Total") {
                                            return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                        }
                                    },
                                    valueGetter: function (params) {
                                        if (![undefined, null, ""].includes(params.data[`day_${e}_${status}`])) {
                                            return params.data[`day_${e}_${status}`]
                                        } else {
                                            return 0
                                        }
                                    }
                                },
                            )


                        })
                    })
                    this.setState({
                        columnwithDefs1: columns,
                        orderData: lastest_row_data,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                } else {
                    this.setState({
                        columnwithDefs1: [],
                        orderData: [],
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }


                /////////////////////////////////////////////////second Grid///////////////////////////////////////////////
                var records2 = rowData2
                if (records2.length > 0) {
                    var distinct_car_models = []
                    var distinct_days = []
                    var disticnt_api_type = []
                    var distinct_status = []
                    records2.map(e => {
                        var keys = Object.keys(e)
                        var keys = keys.filter(e => !["status", "days", "plant_code", "api_type"].includes(e))
                        keys.map(key => {
                            if (!distinct_car_models.includes(key)) {
                                distinct_car_models.push(key)
                            }
                        })

                        if (!distinct_days.includes(e.days)) {
                            distinct_days.push(e.days)
                        }

                        if (!disticnt_api_type.includes(e.api_type)) {
                            disticnt_api_type.push(e.api_type)
                        }

                        if (!distinct_status.includes(e.status)) {
                            distinct_status.push(e.status)
                        }
                    })

                    var lastest_row_data = []
                    distinct_car_models.map(e => {
                        var data = {
                            car_model: e
                        }
                        var group_by_days = groupBy(records2, data => data.days)
                        group_by_days.forEach((values_days, keys_days) => {
                            var group_by_api_type = groupBy(values_days, data => data.api_type)
                            group_by_api_type.forEach((values_apitype, keys_apitype) => {
                                var group_by_status = groupBy(values_apitype, data => data.status)
                                group_by_status.forEach((values_status, keys_status) => {
                                    var model_count = 0
                                    values_status.map(status => {
                                        model_count += parseInt(status[e])
                                    })
                                    data[`day_${keys_days}_${keys_apitype}_${keys_status}`] = model_count
                                })

                            })

                        })
                        // })
                        lastest_row_data.push(data)
                    })
                    console.log(lastest_row_data, "lastest_row_data_503")


                    var check_keys2 = Object.keys(records2[0])
                    var orginal_keys2 = check_keys2.filter(e => !["days",
                        "plant_code",
                        "api_type",
                        "status"].includes(e))
                    var rowData2 = []
                    var columns2 = []
                    var uniqueStatus2 = []
                    var uniqueapitype2 = []
                    var group_by_date2 = groupBy(records2, data => data.days)
                    group_by_date2.forEach((values3, keys3) => {
                        var data2 = {
                            days: keys3
                        }
                        orginal_keys2.map(f => {
                            // console.log( values[0],f, values[0][`${f}`] , "f_1729")
                            var group_by_model2 = groupBy(values3, data => data[`${f}`])
                            group_by_model2.forEach((values4, keys4) => {
                                var group_by_apitype2 = groupBy(values4, data => data.api_type)
                                group_by_apitype2.forEach((values6, keys6) => {
                                    var group_by_status2 = groupBy(values6, data => data.status)
                                    if (!uniqueapitype2.includes(keys6)) {
                                        uniqueapitype2.push(keys6)
                                    }
                                    var total_model_count2 = 0
                                    group_by_status2.forEach((values5, keys5) => {
                                        values5.map(z => {
                                            total_model_count2 = total_model_count2 + z[`${f}`]
                                        })
                                        if (!uniqueStatus2.includes(keys5)) {
                                            uniqueStatus2.push(keys5)
                                        }


                                        data2[`${f}_${keys6}_${keys5}`] = total_model_count2
                                    })
                                })

                            })
                        })
                        rowData2.push(data2)
                    })
                    columns2.push(
                        {
                            headerName: "",
                            headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                            field: "",
                            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                            // chartDataType: 'series',
                            width: 150,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "",
                                    headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                                    field: "",
                                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                                    // chartDataType: 'series',
                                    width: 100,
                                    chartDataType: "excluded",
                                    children: [{
                                        headerName: "Car Model",
                                        headerClass: "cellstylegridTPFM",
                                        field: "car_model",
                                        width: 100,
                                        filter: true,
                                        resizable: true,
                                        editable: false,
                                        pinned: "left",
                                        cellClass: "cellstylegridTPFM",
                                        cellStyle: function (params) {
                                            if (params.data.plant_name == "Total") {
                                                return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                            }
                                        },
                                    },]
                                }
                            ]
                        }
                    )
                    distinct_days.map((e, index) => {
                        columns2.push({
                            headerName: `Day ${e}`,
                            headerClass: ["cellstylegridSMG", "cellTopHeader"],
                            field: "",
                            // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                            // chartDataType: 'series',
                            width: 450,
                            chartDataType: "excluded",

                            children: []
                        }
                        )
                        disticnt_api_type.map((l) => {
                            var children = []
                            columns2[index + 1].children.push(
                                {
                                    headerName: l,
                                    headerClass: ["cellstylegridSMG", "cellTopHeader1"],
                                    field: "",
                                    width: 110,
                                    filter: true,
                                    resizable: true,
                                    editable: false,
                                    cellClass: "cellstylegridSMG",
                                    cellStyle: function (params) {
                                        if (params.data.plant_name == "Total") {
                                            return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                        }
                                    },
                                    children: children
                                },
                            )

                            distinct_status.map((m) => {
                                children.push(
                                    {
                                        headerName: m,
                                        headerClass: "cellstylegridSMG",
                                        field: `day_${e}_${l}_${m}`,
                                        width: 110,
                                        filter: true,
                                        resizable: true,
                                        editable: false,
                                        cellClass: "cellstylegridSMG",
                                        cellStyle: function (params) {
                                            if (params.data.plant_name == "Total") {
                                                return { background: "#f7f305", color: "#333", fontWeight: "bold" };
                                            }
                                        },
                                        valueGetter: function (params) {
                                            if (![undefined, null, ""].includes(params.data[`day_${e}_${l}_${m}`])) {
                                                return params.data[`day_${e}_${l}_${m}`]
                                            } else {
                                                return 0
                                            }
                                        }
                                    },
                                )
                            })

                        })

                    })
                    this.setState({
                        columnwithDefs2: columns2,
                        stockData: lastest_row_data,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                } else {
                    this.setState({
                        columnwithDefs2: [],
                        stockData: [],
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }
            })

        } else {
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: "Please Select Both Options"
            })
        }


    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: ""
        });

    }
    selectplant = (e) => {
        this.setState({
            plant: e,
        })
    }
    selectcluster = (e) => {
        this.setState({
            cluster: e,
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card-arms">
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Order vs Stock</span>
                                </h5>
                            </div>
                            <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                            
                            <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Plant:</label>
                                    <Select
                                        placeholder={"Select Plant"}
                                        closeMenuOnSelect={true}
                                        value={this.state.plant}
                                        onChange={this.selectplant.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{"label":"Haryana","value":"F"},{"label":"Gujarat","value":"G"},{"label":"Bidadi","value":"B"}]} required
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Destination:</label>
                                    <Select
                                        placeholder={"Select Destination"}
                                        closeMenuOnSelect={true}
                                        value={this.state.cluster}
                                        onChange={this.selectcluster.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.clusterOptions} required
                                    />
                                </div>
                                
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>

                                </div>
                            </form> 
                        </div>
                            <div className="grid-container">
                            
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6'>
                                <div className='card-header-arms ml-5'>
                                    <h5>
                                        <i className=""></i> <span>NO OF ORDERS</span>
                                    </h5>
                                </div>
                               
                                <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs1}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.orderData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                                </div>
                                <div className='col-xl-6 col-lg-6'>
                                <div className='card-header-arms ml-5'>
                            <h5>
                                    <i className=""></i> <span>STOCK/PRODUCTION</span>
                                </h5>
                                </div>
                                <div id="myGrid" className="col-xl-12 col-lg-12 ag-theme-balham grid-card" style={{height:"500px"}}>
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs2}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.stockData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}