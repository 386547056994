'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
//import DeleteButton from "./deleteshipmentDSR";
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');



export default class RailConfigScreen extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
         // DeleteButton:DeleteButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        cluster_yard  : [],
        rail_yard : [],
        state :[],
        loading_cluster : [],
        unloading_cluster : [],
        baseStations : [],
        rake_no:"",
        origin:"",
        loadingyard:"",
        category:"",
        rakenodetails : [],
        origindetails:[],
        selectloadingcluster:"",
        selectunloadingcluster:"",
        selectbasestation:"",
        showloadinginputTab :"",
        showunloadinginputTab :"",
        showbasestationinputTab :"",
        loadingyarddetails :[],
        categorydetails:[],
        railyard_name:"",
        latitude:"",
        longitude:"",
        config_month:"",
        plantList:[],
        plantValues : [],
        columnWithDefs : [],
        startDate :"",
        endDate:""
        //components : {datepicker:getDatePicker()}
    }
    this.onCellClicked = this.onCellClicked.bind(this);
    // this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  async componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var end_time = moment.parseZone(new Date()).format("YYYY-MM-01")
    var start_time = moment.parseZone(new Date(end_time)).subtract(1,"month").format("YYYY-MM-01")
    console.log(start_time,end_time,"106")
    this.setState({
        startDate : start_time,
        endDate : end_time
    })
    var params={
        sDate : start_time,
        eDate : end_time
    }
    await redirectURL.post("/arms/getRailConfigData",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(async(response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data);
            var rowData = response.data;
            var columnWithDefs = [];
            var columns = [
                {
                    pinned:"left",
                    headerName : "",
                    field:"edit",
                    width:150,
                    resizable : true,
                    editable : false,
                    cellRenderer:function(params){       
                        var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                        return rendComponent;
                    },
                    filter:false,
                },
            ]
            columns.push({
                headerName:"Plan Month",
                field:"plan_month",
                editable:false,
                width:150
            });
            var rdData = [];
            if(rowData.length > 0)
            {
                var plantNames = groupBy(rowData, rdata => rdata.plant_name);
                plantNames.forEach((pNames, pKey) => {
                    var defs = {
                        headerName:pKey,
                        field:pKey,
                        width:150,
                        editable:false
                        // cellEditor: NumericCellEditor,
                    }         
                    columns.push(defs)
                })
                var plantMonths = groupBy(rowData, rdata => rdata.plan_month);
                plantMonths.forEach((pMonths, mKey) => {
                    var rdChd = {
                        "plan_month" : mKey,
                    };
                    var count = 0
                    pMonths.map((pm)=>{
                        rdChd[pm.plant_name] = pm.target;
                        count = count + parseInt(pm.target)
                    });
                    rdChd['total'] = count
                    rdData.push(rdChd);
                })
            }
            columns.push({
                headerName:"Total",
                field:"total",
                width:150,
                editable:false,
            })
            console.log(rdData,"rdData")
            this.setState({
                rowData : rdData,
                columnWithDefs : columns,
                loadshow:"show-n",
            overly:"show-n"
            })
        }
    })
    // this.onLoadPageData();
   
    redirectURL.post("/arms/getplantslist",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var plants = response.data.plantcodes;
            var plantList = []
            plants.map((e)=>{
                if(["Gurgaon"].includes(e)){
                    e = "Haryana"
                }
                plantList.push(e)
            })
            this.setState({
                plantList : plantList
            })
        }
    })
}

onLoadPageData = async()=>{
    var sDate = this.state.startDate
    var eDate = this.state.endDate
    console.log(sDate,eDate,"214")
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var params ={
        "sDate" :sDate,
        "eDate" :eDate
    }
    console.log(params,"218")
    if(sDate != undefined && sDate != "" && sDate != null && sDate != "NaN-NaN-NaN"){
        if(eDate != undefined && eDate != "" && eDate != null && eDate != "NaN-NaN-NaN"){
            redirectURL.post("/arms/getRailConfigData",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then(async(response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response.data);
                    var rowData = response.data;
                    var columnWithDefs = [];
                    var columns = [
                        {
                            pinned:"left",
                            headerName : "",
                            field:"edit",
                            width:150,
                            resizable : true,
                            editable : false,
                            cellRenderer:function(params){       
                                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                                return rendComponent;
                            },
                            filter:false,
                        },
                    ]
                    columns.push({
                        headerName:"Plan Month",
                        field:"plan_month",
                        width:150,
                        editable:false
                    });
                    
                    var rdData = [];
                    if(rowData.length > 0)
                    {
                        var plantNames = groupBy(rowData, rdata => rdata.plant_name);
                        plantNames.forEach((pNames, pKey) => {
                            var defs = {
                                headerName:pKey,
                                field:pKey,
                                width:150,
                                editable:false
                                // cellEditor: NumericCellEditor,
                            }         
                            columns.push(defs)
                        })
                        var plantMonths = groupBy(rowData, rdata => rdata.plan_month);
                        plantMonths.forEach((pMonths, mKey) => {
                            var rdChd = {
                                "plan_month" : mKey,
                            };
                            var count = 0
                            pMonths.map((pm)=>{
                                rdChd[pm.plant_name] = pm.target;
                                count = count + parseInt(pm.target)
                            });
                            rdChd['total'] = count
                            rdData.push(rdChd);
                        })
                    }
                    columns.push({
                        headerName:"Total",
                        field:"total",
                        width:150,
                        editable:false
                    });
                    console.log(rdData,"rdData")
                    this.setState({
                        rowData : rdData,
                        columnWithDefs : columns,
                        loadshow:"show-n",
                    overly:"show-n"
                    })
                }
            })
        }else{
            this.setState({
                basicTitle:"Please select To Date",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n",
                rowData:[],
                columnWithDefs:[]
            })
        }
       
    }else{
        this.setState({
            basicTitle:"Please select From Date",
            basicType:"warning",
            show:true,
            loadshow:"show-n",
            overly:"show-n",
            rowData:[],
            columnWithDefs:[]
        })
    }
   
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}
closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
    
}

onCellClicked(e)
{
    if(e.colDef.field == "edit"){
       var data = e.data
       console.log(data)
       var plant_list = this.state.plantList
       if(plant_list.length> 0){
        plant_list.map(e => {
            $('#'+e).val(data[`${e}`])
        })
       }
       var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
       var dateString = data.plan_month

       var [month, year] = dateString.split('-');
       month = monthNames.indexOf(month)+1
       if(month<10){
        month= `0${month}`
       }else{
        month = month
       }
       var plan_month = `${year}-${month}`
       this.setState({
            config_month:plan_month,
            bulkslide1:"slider-translate",
            rowId :data._id,
            overly:"show-m",
        })
       
    }
}
onClickHideAll(){
    document.getElementById("upform").reset();
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    config_month:""
    });
    
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
onclickslider(e){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }
    let plantValues = this.state.plantValues;
    plantValues[name] = value;


    this.setState({
        plantValues: plantValues
    })
}
handlerStartDateTime1 = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
  
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    if(this.state.endDate !=undefined && this.state.endDate !="")
    {
        console.log(startdate,"startdate")
        console.log(this.state.endDate,"endDate")
        var startDate = new Date(startdate);
        var endDate = new Date(this.state.endDate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        console.log(startTime,endTime);
        if(startTime > endTime)
        {
          this.setState({
            startDate:""
          });
          alert("To Date should be greater than From Date");
        }
        else
        { 	
          this.setState({
            startDate:startdate
          });
        }
    }
    else
    {
      this.setState({
        startDate:startdate
      });
    }
      //let value = event.target.value;
    //this.setState({'startDate':value});
}
handlerEndDateTime1  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);    
    var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())

    if(this.state.endDate !=undefined && this.state.endDate !="")
    {
        console.log(this.state.startDate,"startdate")
        console.log(edate,"endDate")
        var startDate = new Date(this.state.startDate);
        var endDate = new Date(edate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        console.log(startTime,endTime);
        if(startTime > endTime)
        {
          this.setState({
            endDate:""
          });
          alert("To Date should be greater than From Date");
        }
        else
        { 
          this.setState({
            endDate:edate
          });
        }
    }
    else
    {
        this.setState({
          endDate:edate
        });
    }
}
submitRialyarddetails(event){
    event.preventDefault()
    console.log(this.state.config_month)
    this.setState({
        overly:"show-m",
        loadshow :"show-m"
    })
    var plan_month =  this.state.config_month
    var month = plan_month.split("-")[1]
    var year = plan_month.split("-")[0]
    var PlantConfigDetials = [];
    var plantsList = this.state.plantList;
    var uploaded_by = localStorage.getItem("email")
    if(plan_month != undefined && plan_month != null && plan_month != ""){
        plantsList.map((pl)=>{
            var plantTarget = $("#"+pl).val();
            PlantConfigDetials.push({
                plan_month : moment.parseZone(new Date(plan_month)).format("MMM-YYYY"),
                month : month,
                year : year,
                plant_name : pl,
                target : plantTarget,
                uploaded_by : uploaded_by,
                process : 0
            });
        });
        console.log(PlantConfigDetials,"514")
        var params = {
            PlantConfigDetials : PlantConfigDetials,
            username : localStorage.getItem('username')
        }
        redirectURL.post("/arms/saveRailConfigData",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                if(response.data.message == "success"){
                    this.setState({
                        bulkslide1 :"",
                        loadshow:"show-n",
                        overly:"show-n",
                        basicType:"success",
                        basicTitle :"Data Updated Successfully",
                        show:true,
                        config_month :""
                    })
                    plantsList.map((e)=>{
                        $("#"+e).val("")
                    })
                    this.onLoadPageData()
                }else{
                    this.setState({
                        loadshow:"show-n",
                        overly:"show-n",
                        basicType:"warning",
                        basicTitle :"Data not Updated",
                        show:true,
                    })
                }
            }
        })
    }else{
        this.setState({
            loadshow:"show-n",
            overly:"show-n",
            basicType:"warning",
            basicTitle :"Please Select Month",
            show:true
        })
    }
    
}
onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var selDay = (d.getMonth() + 1);
    if(parseInt(selDay) < 10)
    {
        selDay = "0"+selDay; 
    }
    var plan_month = d.getFullYear() + "-" + selDay;
    this.setState({
        config_month: plan_month
    });
}

  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [
        {
            headerName : "Month",
            field:"month",
            width:100,
            resizable : true,
            editable : false,
            filter:false,
        },
        {
            headerName : "Year",
            field:"year",
            width:100,
            resizable : true,
            editable : false,
            filter:false,
        },
        
    
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
        <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rail Target Configuration</span>
                    </h5>
                    </div>
                    <h5>
                   
                    {/*<button className="btn btn-danger" style={{float:"right",marginRight:"10px"}} onClick={this.onClickBulkDelete.bind(this)}>Delete</button> */}
                </h5>
                <div>
                <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider.bind(this)}>Add Rail Target</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    {/* <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                </div>
                <div className='col-xl-12 col-lg-12'>
                                    <div className="row card-body" style={{marginLeft:"0px",paddingBottom:"0px"}}>
                                         <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                             <label>From Date</label>
                                             <Datetime
                                             value={this.state.startDate} 
                                             disableCloseOnClickOutside={false} 
                                             closeOnSelect={true} 
                                             inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
                                             dateFormat="YYYY-MM-DD" 
                                             name="startDate" 
                                             onChange={this.handlerStartDateTime1.bind(this)} />
                                         </div>  
                                         <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                             <label>To Date</label>
                                             <Datetime 
                                             value={this.state.endDate} 
                                             disableCloseOnClickOutside={false}
                                             closeOnSelect={true} 
                                             inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} 
                                             dateFormat="YYYY-MM-DD" 
                                             onChange={this.handlerEndDateTime1.bind(this)} />
                                         </div>
                                         <div className="form-group col-xl-2 col-lg-2">
                                             <button type="button" style={{position:"absolute",right:"20px",borderRadius:"5px"}} className="mt-30p btn btn-arms" onClick={this.onLoadPageData.bind(this)}>Submit</button>
                                         </div>
                                                    
                                     </div>
                             
                                 </div>	
                <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
            {/* <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}> */}
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={this.state.columnWithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Rail Target
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitRialyarddetails.bind(this)}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plan Month:</label>
                                    <Datetime
                                        value={this.state.config_month}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        id = "plan_month"
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTime.bind(this)}
                                    />
                                </div>
                                {this.state.plantList.map((item,idx)=>(
                                      <div className="col-xl-12 col-lg-12 form-group">
                                      <label className="col-xl-12 col-lg-12">{item} : </label>
                                      <input type="number" name={item} id={item} className="form-control"
                                       onChange={this.changeHandler.bind(this)} required />
                                  </div>    
                                ))}
                                
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}
// $(document).on("input", ".mobnum-cls", function() {
//     this.value = this.value.replace(/^\d{20}$/g,'');
// })
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}