import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import {verifyext, verifycsvdata} from "../common/verifyext";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class Plantmaster extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents : {
            // UpdateButton:UpdateButton,
               // DeleteButton:DeleteButton,
              },

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"", 
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:'',
            bulkslide1:'',
            bulkslide2:'',
            plant_code:'',
            plant_short_name: "",
            plant_name:"",
            longitude:'',
            lattitude:'',
            lattitude2:'',
            longitude2:'',
            stateList :[],
            addstate:"",
            editstate:""
        };
    }
   
    componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       redirectURL.post("/arms/getstatesdataforplantmaster",{},{"headers":
       {											
           'Content-Type': 'application/json;charset=UTF-8', 
           'Authorization': `Bearer ${localStorage.getItem("token")}`
   }
   }).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
            var resp = response.data.data
            var stateList = []
            resp.map((e)=>{
                stateList.push({
                    value:e,
                    label:e
                })
            })
            this.setState({
                stateList : stateList
            })
        }
       })
      this.loadData();
    };
    loadData(){
        redirectURL.post('/arms/getplantmaster',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"108")
            var records = response.data;
            // for(let data of records){
            //     console.log("data.plant_coordinates ",data.plant_coordinates.lat)
            //     const plant_coord = JSON.parse(data.plant_coordinates);
            //     data.lattitude = plant_coord.lat;
            //     data.langitude = plant_coord.lng;
            //     // delete data.plant_coordinates;
                            
            // }
                this.setState({
                    rowData:records,

                    overly: "show-n",
                    loadshow: "show-n"
                });
        }
          })
          .catch(function (error) {
            console.log(error);
        });
       
    }
    onClickShowFilterData(event) {
        event.preventDefault();
        // var dept_code = $("#dept_code").val()
        var plant_code = $("#plant_code").val()
        var plant_name = $("#plant_name").val()
        var plant_short_name = $("#plant_short_name").val()
        var lattitude = $("#lattitude").val()
        var longitude = $("#longitude").val()
        var state = this.state.addstate.value
        
       if(plant_code != undefined && plant_code != null && plant_code != ""){
        if(plant_name != undefined && plant_name != null && plant_name != ""){
            if(plant_short_name != undefined && plant_short_name != null && plant_short_name != ""){
                if(lattitude != undefined && lattitude != null && lattitude != ""){
                    if(longitude != undefined && longitude != null && longitude != ""){
                        if(state != undefined && state != null && state != ""){
                            var params ={
                                // dept_code,
                                plant_code,
                                plant_name,
                                plant_short_name,
                                lattitude,
                                longitude,
                                state,
                                dept_code:"SNDG",
                                plant_coordinates:{"lat":lattitude,"lng":longitude}
                                
                            }
                            redirectURL.post("arms/getaddplantmaster", params,{"headers":
                            {											
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                        }).then(response=>{
                            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                this.setState({
                                    show:true,
                                    basicTitle:"Session Expired",
                                    basicType:"warning",
                                    overly: "show-n",
                                    loadshow: "show-n"
                                })
                            }else{
                                console.log(response)
                                if(response.data.message == "Success")
                                    {
                                        this.setState({
                                            basicTitle:"Add Successfully.",
                                            basicType : "success",
                                            show : true,
                                            rowIds : [],
                                            loadshow:"show-n",
                                            overly:"show-n",
                                            bulkslide:"",
                                            plant_code:"",
                                            plant_name:"",
                                            plant_short_name:"",
                                            longitude:"",
                                            lattitude:""
                                        })
                                        $("#plant_code").val("")
                                        $("#plant_name").val("")
                                        $("#plant_short_name").val("")
                                        $("#lattitude").val("")
                                        $("#longitude").val("")
                                        
                                       
                                        //window.location.reload();
                                        this.loadData();                    }
                                    else
                                    {
                                        this.setState({
                                            basicTitle:response.data.message,
                                            basicType : "warning",
                                            show : true,
                                            loadshow:"show-n",
                                            overly:"show-n"
                                        })
                                    }
                                }
                            })
                        }else{
                            this.setState({
                                basicTitle:"Please Select State",
                                basicType : "warning",
                                show : true,
                                loadshow:"show-n",
                                overly:"show-n"
                            })
                        }
                        
                    }else{
                        this.setState({
                            basicTitle:"Please Enter Longitude",
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                    }
                    
                }else{
                    this.setState({
                        basicTitle:"Please Enter Lattitude",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
                
            }else{
                this.setState({
                    basicTitle:"Please Enter Plant Short Name",
                    basicType : "warning",
                    show : true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
           
        }else{
            this.setState({
                basicTitle:"Please Enter Plant Name",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
        
       }else{
        this.setState({
            basicTitle:"Please Enter Plant Code",
            basicType : "warning",
            show : true,
            loadshow:"show-n",
            overly:"show-n"
        })
       }
            
        
    
    }
    onClickShowFilterData2(event) {
        event.preventDefault();
        // var dept_code2 = $("#dept_code2").val()
       
        var plant_code2 = $("#plant_code2").val()
        var plant_name2 = $("#plant_name2").val()
        var plant_short_name2 = $("#plant_short_name2").val()
        var lattitude2 = $("#lattitude2").val()
        var longitude2 = $("#longitude2").val()
        var state = this.state.editstate.value
        var rowId = this.state.rowId
        if(plant_code2 != undefined && plant_code2 != null && plant_code2 != ""){
            if(plant_name2 != undefined && plant_name2 != null && plant_name2 != ""){
                if(plant_short_name2 != undefined && plant_short_name2 != null && plant_short_name2 != ""){
                    if(lattitude2 != undefined && lattitude2 != null && lattitude2 != ""){
                        if(longitude2 != undefined && longitude2 != null && longitude2 != ""){
                            if(state != undefined && state != "" && state != null){
                                var params ={
                                    // dept_code2,
                                    plant_code:plant_code2,
                                    plant_name:plant_name2,
                                    plant_short_name:plant_short_name2,
                                    lattitude:lattitude2,
                                    longitude:longitude2,
                                    plant_coordinates:{"lat":lattitude2,"lng":longitude2},
                                    state:state
                                }
                                redirectURL.post("arms/geteditplantmaster" , { params ,rowId},{"headers":
                                {											
                                    'Content-Type': 'application/json;charset=UTF-8', 
                                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                            }).then(response=>{
                                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                    this.setState({
                                        show:true,
                                        basicTitle:"Session Expired",
                                        basicType:"warning",
                                        overly: "show-n",
                                        loadshow: "show-n"
                                    })
                                }else{
                                    console.log(response)
                                    if(response.data.message == "success")
                                        {
                                            this.setState({
                                                
                                                basicTitle:"Update Data Successfully.",
                                                basicType : "success",
                                                show : true,
                                                rowIds : [],
                                                loadshow:"show-n",
                                                overly:"show-n",
                                                bulkslide2:""
                                            })
                                            this.loadData();   
                                            
                                           
                                        }
                                        else
                                        {
                                            this.setState({
                                                basicTitle:response.data.message,
                                                basicType : "warning",
                                                show : true,
                                                loadshow:"show-n",
                                                overly:"show-n"
                                            })
                                        }
                                    }
                                })
                            }else{
                                this.setState({
                                    basicTitle:"Please Enter State",
                                    basicType : "warning",
                                    show : true,
                                    loadshow:"show-n",
                                    overly:"show-n"
                                })
                            }
           
        }else{
            this.setState({
                basicTitle:"Please Enter Longitude",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
        
    }else{
        this.setState({
            basicTitle:"Please Enter Lattitude",
            basicType : "warning",
            show : true,
            loadshow:"show-n",
            overly:"show-n"
        })
    }
    
}else{
    this.setState({
        basicTitle:"Please Enter Plant Short Name",
        basicType : "warning",
        show : true,
        loadshow:"show-n",
        overly:"show-n"
    })
}

}else{
this.setState({
    basicTitle:"Please Enter Plant Name",
    basicType : "warning",
    show : true,
    loadshow:"show-n",
    overly:"show-n"
})
}

}else{
this.setState({
basicTitle:"Please Enter Plant Code",
basicType : "warning",
show : true,
loadshow:"show-n",
overly:"show-n"
})
}
    
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide1:"",
            bulkslide2:"",
            plant_code:"",
            plant_name:"",
            plant_short_name:"",
            longitude:"",
            lattitude:"",
            addstate:""
        });
    }
   
    onCellClicked(e){
        if(e.colDef.field == "delete"){
            var qry = {
                rowId:e.data._id,
                email:localStorage.getItem("email"),
                                // designation: localStorage.getItem('designation'),
                // user_role: localStorage.getItem('roles'),
            }
            console.log(qry,"243")
            var confirm = window.confirm("Are You Sure! Do you really want to delete?")
            if (confirm == true)
            {
                redirectURL.post("/arms/getdeletebulkTransitMaster",qry,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response.data);
                    if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Deleted Successfully.",
                            basicType : "success",
                            show : true,
                            rowIds : []
                        })
                        //window.location.reload();
                        this.componentDidMount();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to Delete.",
                            basicType : "warning",
                            show : true
                        })
                    }
                }
                }).catch((e)=>{
                    console.log(e);
                })
            } 
        }
        if(e.colDef.field == "edit"){
            var data = e.data;
            var plant_check = data.plant_coordinates
            console.log(data,"414")
            // var dept_code2 = $("#dept_code2").val(data.dept_code)
            var plant_code2 = $("#plant_code2").val(data.plant_code)
            var plant_name2 = $("#plant_name2").val(data.plant_name)
            var plant_short_name2 = $("#plant_short_name2").val(data.plant_short_name)
            this.setState({
                lattitude2 : data.lattitude,
                longitude2 : data.longitude
            })
            var lattitude2 = $("#lattitude2").val(data.lattitude)
            var longitude2 = $("#longitude2").val(data.longitude)
            console.log(longitude2,"678")
           
            this.setState({
                // loadshow:'show-r',
                editstate :{value:data.state,label:data.state},
                overly:'show-r',
                bulkslide2:"slider-translate-30p",
                rowId :data._id
            })
           
        } 
        
        
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            bulkslide1:'',
            bulkslide2:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                // { 
                //     name: 'id',
                //     inputName: 'id',
                //     required: false,
                // },
                // { 
                //     name: 'Dept Code',
                //     inputName: 'dept_code',
                //     required: true,
                //     requiredError: function (headerName, rowNumber, columnNumber) {
                //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                //     }
                // },
                { 
                    name: ' Plant Code',
                    inputName: 'plant_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Plant Name',
                    inputName: 'plant_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Plant Short Name',
                    inputName: 'plant_short_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Lattitude',
                    inputName: 'lattitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'Longitude',
                    inputName: 'longitude',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log(csvData)
                    this.setState({
                        csvData:csvData.data
                    });
                    console.log(csvData.data);
                })
                .catch(err => {})
                // console.log("e.target.files[0]",e.target.result);
                
                var out = new Promise(function(reject, resolve){
                    var reader =  new FileReader();
                    reader.onload = async function(e) {
                        var contents = await e.target.result;
                        resolve(contents);
                    };
                    var tt =  reader.readAsText(e.target.files[0]);
                });
                this.setState({
                    file:e.target.files[0]
                });
                this.checkFileData();
                console.log("e.target.files[0].type ", e.target.files[0]);
                var check = verifyext(e.target.files[0].name);
                console.log("Check ", check)
                if(check == true)
                {
                    if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
                    {
                        // console.log(fileData);
                        // console.log(typeof(fileData))
                        
                    }
                    else{
                        e.target.value = null;
                        this.setState({
                            uploadFile:'',
                            show: true, 
                            basicType:'danger', 
                            basicTitle:'Please upload file having extensions .csv only.',
                        });
                    }
                }
                else{
                    e.target.value = null;
                    this.setState({
                        uploadFile:'',
                        show: true, 
                        basicType:'danger', 
                        basicTitle:'Please upload file having extensions .csv only.',
                    });
                }
        }
    }
    changeHandler = (event) => {
        console.log("Select", event.target.value);
    
        var name = event.target.name;
        var value = event.target.value;
        const re = /^[A-Za-z]+$/;
        // if (name == 'deptName') {
        //     if (value === "" || re.test(value)) {
        //         console.log("name succ")
        //         alert('hi')
        //         //   this.setState(value);
        //     }else{
        //         alert('dont enter the numerals')
        //     }
        // }
    
        console.log(name);
    }
    validateInput = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[a-zA-Z\s]+$/;
        console.log(!characterRegex.test(inputValue),"910")
        if (!characterRegex.test(inputValue)) {
            if(inputValue == ""){
                this.setState({
                    plant_code: "",
                    plant_code2: "",
                  });
            }
         
        } else {
          this.setState({
            plant_code: inputValue,
            plant_code2: inputValue,
           
          });
        }
      };
      validateInput1 = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[a-zA-Z\s]+$/;
        console.log(!characterRegex.test(inputValue),"910")
        if (!characterRegex.test(inputValue)) {
            if(inputValue == ""){
                this.setState({
                    plant_name: "",
                    plant_name2: "",
                   
                  
                  });
            }
         
        } else {
          this.setState({
           
            plant_name: inputValue, 
            plant_name2: inputValue, 
           
          });
        }
      };
      validateInput2 = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[a-zA-Z\s]+$/;
        console.log(!characterRegex.test(inputValue),"910")
        if (!characterRegex.test(inputValue)) {
            if(inputValue == ""){
                this.setState({
                
                    plant_short_name: "",
                    plant_short_name2: "",
                  
                  });
            }
         
        } else {
          this.setState({
          
            plant_short_name: inputValue, 
            plant_short_name2: inputValue, 
           
          });
        }
      };
      validatelattitudeInput = (event) => {
        const inputValue = event.target.value.trim();
        console.log(inputValue,"inputValue")
       
        const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            lattitude: inputValue,
            lattitude2: inputValue,
    
        });
        }
    }else{
        this.setState({
            lattitude: "",
            lattitude2: "",
        });
    }
      };
      validatelongitudeInput = (event) => {
        const inputValue = event.target.value.trim();
        const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
        if(inputValue !=undefined && inputValue !="")
        {
            if (!floatRegex.test(inputValue)) {
            
            } else {
            this.setState({
                longitude: inputValue,
                longitude2: inputValue,
        
            });
            }
        }else{
            this.setState({
                longitude: "",
                longitude2: "",
            });
        }
      };
    
    addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{plant_code:"",plant_map_cod:"",msil_code:"",}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        overly :"show-m",
        bulkslide:"slider-translate-30p",
        // rowId : rowId
    })
    
    // this.setState({
    //     bulkslide2:"slider-translate-30p",
    //     // rowId : rowId
    // })

}

onClickDataExport(){
    var params = {
        columnKeys: ['dept_code','plant_code','plant_name','plant_coordinates','plant_short_name','lattitude','longitude']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
        
      
    })
}
selectstate= (e) => {
    this.setState({
        addstate: e,
    })
}
selecteditstate =(e) =>{
    this.setState({
        editstate : e
    })
}
 
render(){
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    const columnwithDefs = [
        // {
        //     headerName: "Dept Code",
        //     field: "dept_code",
        //     width:250,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width:180,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Plant Short Name",
            field: "plant_short_name",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Plant Lattitude",
            field: "lattitude",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
        },
        
        {
            headerName: "Plant Longitude",
            field: "longitude",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "State",
            field: "state",
            width:160,
            filter: true,
            resizable: true,
            editable:false,
        },
    //    const plant_coordinates:{ Lattitude:'',
    //                         Longitude:''}
                
        
        // {
        //     headerName: "Msil_Code",
        //     field: "msil_code",
        //     width:200,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        // },
       
            {
                pinned:"left",
                headerName : "",
                field:"edit",
                width:150,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                    return rendComponent;
                },
                filter:false,
            },
            {
                pinned:"left",
                headerName : "",
                field:"delete",
                width:80,
                resizable : true,
                editable : true,
                cellRenderer:function(params){       
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter:false,
            },       
            // {
            //     headerName: "Dom Exp Flag",
            //     field: "dom_exp_flag",
            //     width: 170,
            //     editable:false,
            //     filter: "agSetColumnFilter",
            //     valueGetter:function(params){
            //         if(params.data.dom_exp_flag == "E"){
            //             return "Exports"
            //         }
            //         else if(params.data.dom_exp_flag == "D"){
            //             return "Domestic"
            //         }
            //         else{
            //             return ""
            //         }
            //     }
            // },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Plant Master</span>
                            </h5>
                        </div>
                        <h5>
                            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)} >Add Plant Details</button>
                            {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                            <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                        </h5>
                        
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"auto"}}>
                    <h3 className="subHarms">Add Plant Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="container-fluid">
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                   {/* <label className="col-xl-12 col-lg-12">Dept Code : </label>
                                   <input type="text" name="dept_code" id="dept_code" className="datetimepicker_datew form-control armsinputbox"  /> */}
                                   
                                    <label>Plant Code:</label>
                                    <input type="text" name="plant_code" id="plant_code"  className="form-control" value={this.state.plant_code} onChange={this.validateInput}
                                     required />
                                </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                       <label className="col-xl-12 col-lg-12">Plant Name: </label>
                                       <input type="text" name="plant_name" id="plant_name"  className="form-control armsinputbox" value={this.state.plant_name} onChange={this.validateInput1}
                                      required />
                                       </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                   <label className="col-xl-12 col-lg-12">Plant Short Name : </label>
                                               <input type="text" name="plant_short_name" id="plant_short_name" className="datetimepicker_datew form-control armsinputbox" value={this.state.plant_short_name} onChange={this.validateInput2}
                                     required  />
                                               </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Plant Lattitude : </label>
                                   <input type="text" name="lattitude" id="lattitude" className="form-control armsinputbox" value={this.state.lattitude} onChange={this.validatelattitudeInput}
                                     required  />
                                   </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                   <label className="col-xl-12 col-lg-12">Plant Longitude : </label>
                                   <input type="text" name="longitude" id="longitude" className="form-control armsinputbox" value={this.state.longitude} onChange={this.validatelongitudeInput}
                                     required   />           
                                   </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                        <label>State:</label>
                                        <Select
                                                        placeholder={"Select State"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.addstate}
                                                        onChange={this.selectstate.bind(this)}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.stateList} required
                                                    />
                                    </div>
                                   
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit Plant Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            
                        <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 row form-group">
                                   {/* <label className="col-xl-12 col-lg-12">Dept Code : </label>
                                   <input type="text" name="dept_code2" id="dept_code2" className="datetimepicker_datew form-control armsinputbox"  /> */}
                                   <label className="col-xl-12 col-lg-12">Plant Code : </label>
                                               <input type="text" name="plant_code2" id="plant_code2" className="datetimepicker_datew form-control armsinputbox" value={this.state.plant_code2} onChange={this.validateInput}
                                     required />
                                   
                                   </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                       <label className="col-xl-12 col-lg-12">Plant Name: </label>
                                       <input type="text" name="plant_name2" id="plant_name2"  className="form-control armsinputbox" value={this.state.plant_name2} onChange={this.validateInput1}
                                     required />
                                       </div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                   
                                   <label className="col-xl-12 col-lg-12">Plant Short Name : </label>
                                               <input type="text" name="plant_short_name2" id="plant_short_name2" className="datetimepicker_datew form-control armsinputbox" value={this.state.plant_short_name2} onChange={this.validateInput2}
                                     required /></div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                 <label className="col-xl-12 col-lg-12">Plant Lattitude : </label>
                                   <input type="text" name="lattitude2" id="lattitude2" className="form-control armsinputbox" value={this.state.lattitude2} onChange={this.validatelattitudeInput}
                                     required /></div>
                                   <div className="col-xl-12 col-lg-12 row form-group">
                                   <label className="col-xl-12 col-lg-12">Plant Longitude : </label>
                                   <input type="text" name="longitude2" id="longitude2" className="form-control armsinputbox" value={this.state.longitude2} onChange={this.validatelongitudeInput}
                                     required />   
                                   </div>
                                   
                                   <div className="col-xl-12 col-lg-12 form-group">
                                        <label>State:</label>
                                        <Select
                                                        placeholder={"Select State"}
                                                        closeMenuOnSelect={true}
                                                        value={this.state.editstate}
                                                        onChange={this.selecteditstate.bind(this)}
                                                        style={{ borderRadius: "0px" }}
                                                        options={this.state.stateList} required
                                                    />
                                    </div>
                                  
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                                    
            
                                    

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}