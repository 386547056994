'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class RpCarDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        transportersList : [],
        rakesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        plantList : [],
        sbvhList : [],
        sbvh_code :"",
        plant:"",
        load_factor:"",
        car_model:"",
        edit_car_model : "",
        edit_sbvh_code : "",
        edit_load_factor : "",
        edit_plant:"",
        rowId:"",
        rake_type:"",
        edit_rake_type:""

        //components : {dapicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFortemHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  
  
componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
    redirectURL.post("/arms/getdetailsforcardetails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var plantData = response.data.plantData
        var sbvhCodes = response.data.sbvhCodes
        var sbvhList = []
        var plantList = []
        sbvhCodes.map((e)=>{
            sbvhList.push({
                "value":e,
                "label":e
            })
        })
        plantData.map((e)=>{
            plantList.push({
                value : e.plant_code,
                label : e.plant_name
            })
        })
        this.setState({
            sbvhList : sbvhList,
            plantList : plantList
        })
    }
    })
}

onLoadPageData(){
    redirectURL.post("/arms/getCarDetails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            user_name: localStorage.getItem('username'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteCarDetails",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
    if(e.colDef.field == "update"){
        this.setState({
            bulkslide2:"slider-translate",
            overly:"show-m",
            edit_car_model : e.data.car_model,
            edit_sbvh_code :{"value":e.data.family_code,"label":e.data.family_code},
            edit_load_factor :e.data.load_factor,
            edit_plant : {"label":e.data.loading_plant,"value":e.data.plant_code},
            edit_rake_type:{"label":e.data.rake_type,"value":e.data.rake_type},
            rowId : e.data._id

        })
        $("#edit_length").val(e.data.length)
        $("#edit_width").val(e.data.width)
        $("#edit_height").val(e.data.height)
        $("#edit_car_model").val(e.data.car_model)
        $("#edit_load_factor").val(e.data.load_factor)
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    plant :"",
    sbvh_code :"",
    car_model :"",
    load_factor:""
    });
    $("#car_model").val("")
    $("#length").val("")
    $("#width").val("")
    $("#height").val("")
    $("#load_factor").val("")
}
onClickHideAll1(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    plant :"",
    sbvh_code :"",
    car_model :"",
    load_factor:""
    });
    $("#car_model").val("")
    $("#length").val("")
    $("#width").val("")
    $("#height").val("")
    $("#load_factor").val("")
}

addNewRow()
{
    // console.log(this.gridApi);
    this.setState({
        bulkslide1:"slider-translate",
        overly:"show-m"
    })
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['car_model', 'family_code', 'length', 'width', 'height', 'load_factor', 'loading_plant']
    };
    this.gridApi.exportDataAsCsv(params);
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'car_model',
                inputName: 'car_model',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'family_code',
                inputName: 'family_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'length',
                inputName: 'length',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'width',
                inputName: 'width',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'height',
                inputName: 'height',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'load_factor',
                inputName: 'load_factor',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'loading_plant',
                inputName: 'loading_plant',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        // console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        //this.checkFileData();
        //console.log("e.target.files[0].type ", e.target.files[0]);
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}

onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
selectSbvhCode = (e)=>{
    this.setState({
        sbvh_code :e
    })
}
editselectSbvhCode = (e)=>{
    this.setState({
        edit_sbvh_code :e
    })
}
selectplant = (e)=>{
    this.setState({
        plant :e
    })
}
editselectplant= (e)=>{
    this.setState({
        edit_plant :e
    })
}
selectraketype= (e)=>{
    this.setState({
        rake_type :e
    })
}
selecteditraketype= (e)=>{
    this.setState({
        edit_rake_type :e
    })
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
validateloadfactorInput = (event) => {
    const inputValue = event.target.value.trim();
    console.log(inputValue,"inputValue")
    const floatRegex = /^[-+]?\d*\.?\d*$/;
    console.log(!floatRegex.test(inputValue))
    if (!floatRegex.test(inputValue)) {
        // this.setState({
        //     load_factor: "",
        //   });
    } else {
      this.setState({
        load_factor: inputValue,
      });
    }
  };
  editvalidateloadfactorInput = (event) => {
    const inputValue = event.target.value.trim();
    console.log(inputValue,"inputValue")
    const floatRegex = /^[-+]?\d*\.?\d*$/;
    console.log(!floatRegex.test(inputValue))
    if (!floatRegex.test(inputValue)) {
        // this.setState({
        //     load_factor: "",
        //   });
    } else {
      this.setState({
        edit_load_factor: inputValue,
      });
    }
  };
  validatecarmodelInput  = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue,"inputValue")
    const floatRegex = /^[-a-zA-Z0-9\s]*$/;
    if (floatRegex.test(inputValue)) {
        this.setState({
            car_model: inputValue,
          });
    }
  };
  editvalidatecarmodelInput  = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue,"inputValue")
    const floatRegex = /^[-a-zA-Z0-9\s]*$/;
    if (floatRegex.test(inputValue)) {
        this.setState({
            edit_car_model: inputValue,
          });
    }
  };
    submitCardetails = (event)=>{
        event.preventDefault()
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var car_model = $("#car_model").val()
        var family_code = this.state.sbvh_code.value
        var length = $("#length").val()
        var width = $("#width").val()
        var height = $("#height").val()
        var load_factor = $("#load_factor").val()
        var loading_plant = this.state.plant.label
        var plant_code = this.state.plant.value
        var rake_type = this.state.rake_type.value
        var created_by = localStorage.getItem("username")
         var params = {
            car_model : car_model,
            family_code : family_code,
            length : length,
            width : width,
            height : height,
            load_factor : load_factor,
            loading_plant : loading_plant,
            plant_code : plant_code,
            rake_type : rake_type,
            created_by : created_by
         }
         console.log(params,"728")
         if(family_code != undefined && family_code != null && family_code != ""){
            if(loading_plant != undefined && loading_plant != null && loading_plant != "" && plant_code != undefined && plant_code != null && loading_plant != ""){
                if(rake_type != undefined && rake_type != null && rake_type != ""){
                    redirectURL.post("/arms/insertCardetailsData",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        if(response.data.message == "success"){
                            this.setState({
                                basicTitle:"Data Suceessfully Updated",
                                basicType:"success",
                                show:true,
                                loadshow:"show-n",
                                overly:"show-n",
                                plant :"",
                                sbvh_code :"",
                                car_model :"",
                                load_factor:"",
                                bulkslide1:"",
                                rake_type:""
                            })
                            $("#car_model").val("")
                            $("#length").val("")
                            $("#width").val("")
                            $("#height").val("")
                            $("#load_factor").val("")
                            this.onLoadPageData()
                        }else{
                            this.setState({
                                basicTitle:response.data.message,
                                basicType:"warning",
                                show:true,
                                loadshow:"show-n",
                                overly:"show-n",
                                plant :"",
                                sbvh_code :"",
                                car_model :"",
                                load_factor:"",
                                bulkslide1:"",
                                rake_type:""
                            })
                            $("#car_model").val("")
                            $("#length").val("")
                            $("#width").val("")
                            $("#height").val("")
                            $("#load_factor").val("")
                        }
                    }
                    })
                }else{
                    this.setState({
                        basicTitle:"Please Select Rake Type",
                        basicType:"warning",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
                
            }else{
                this.setState({
                    basicTitle:"Please Select Plant",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }

         }else{
            this.setState({
                basicTitle:"Please Select Family Code",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
         }
    }
    editCardetails = (event) =>{
        event.preventDefault()
        var car_model = $("#edit_car_model").val()
        var family_code = this.state.edit_sbvh_code.value
        var length = $("#edit_length").val()
        var width = $("#edit_width").val()
        var height = $("#edit_height").val()
        var load_factor = $("#edit_load_factor").val()
        var loading_plant = this.state.edit_plant.label
        var plant_code = this.state.edit_plant.value
        var rake_type = this.state.edit_rake_type.value
        var created_by = localStorage.getItem("username")
        var rowId = this.state.rowId
         var params = {
            car_model : car_model,
            family_code : family_code,
            length : length,
            width : width,
            height : height,
            load_factor : load_factor,
            loading_plant : loading_plant,
            plant_code : plant_code,
            rake_type : rake_type,
            created_by : created_by,
            rowId : rowId
         }
         if(family_code != undefined && family_code != null && family_code != ""){
            if(loading_plant != undefined && loading_plant != null && loading_plant != "" && plant_code != undefined && plant_code != null && loading_plant != ""){
                if(rake_type != undefined && rake_type != null && rake_type != ""){
                    redirectURL.post("/arms/updateCardetailsData",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        if(response.data.message == "success"){
                            this.setState({
                                basicTitle:"Data Suceessfully Updated",
                                basicType:"success",
                                show:true,
                                loadshow:"show-n",
                                overly:"show-n",
                                plant :"",
                                sbvh_code :"",
                                car_model :"",
                                load_factor:"",
                                bulkslide2:""
                            })
                            $("#car_model").val("")
                            $("#length").val("")
                            $("#width").val("")
                            $("#height").val("")
                            $("#load_factor").val("")
                            this.onLoadPageData()
                        }else{
                            this.setState({
                                basicTitle:response.data.message,
                                basicType:"warning",
                                show:true,
                                loadshow:"show-n",
                                overly:"show-n",
                                plant :"",
                                sbvh_code :"",
                                car_model :"",
                                load_factor:"",
                                bulkslide2:""
                            })
                            $("#car_model").val("")
                            $("#length").val("")
                            $("#width").val("")
                            $("#height").val("")
                            $("#load_factor").val("")
                        }
                    }
                    })
                }else{
                    this.setState({
                        basicTitle:"Please Select Rake Type",
                        basicType:"warning",
                        show:true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
               
            }else{
                this.setState({
                    basicTitle:"Please Select Plant",
                    basicType:"warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }

         }else{
            this.setState({
                basicTitle:"Please Select Family Code",
                basicType:"warning",
                show:true,
                loadshow:"show-n",
                overly:"show-n"
            })
         }
    }

  render() {
    var columnwithDefs = [   
        {
            headerName: "Car Model",
            field: "car_model",
            width:150,
            filter: true,
            resizable: true,
            editable:false,      
        },
        {
            headerName: "Family Code",
            field: "family_code",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Length (mm)",
            field: "length",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: NumericCellEditor,
        },
        {
            headerName: "Width (mm)",
            field: "width",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: NumericCellEditor,
        },
        {
            headerName: "Height (mm)",
            field: "height",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: NumericCellEditor,
        },
        {
            headerName: "Load factor",
            field: "load_factor",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: NumericCellEditor,
        },
        {
            headerName: "Loading Plant",
            field: "loading_plant",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Gujarat","Haryana"],
            // }, 
        },
        {
            headerName: "Rake Type",
            field: "rake_type",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Gujarat","Haryana"],
            // }, 
        },
        {
            pinned:"left",
            headerName : "Update",
            field:"update",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },       
        // {
        //     pinned:"left",
        //     headerName: "id",
        //     field: "id",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card-arms col-xl-12">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-car cus-i"></i> <span>Car Details</span>
                    
                    
                </h5>
            </div>
            <div>
            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Add Car Details</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
            </div>
            <div className="grid-container col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Car Details <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                <form method="POST" id="upform" className="theme-form" onSubmit={this.submitCardetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Car Model:</label>
                                            <input type="text" name="car_model" id="car_model" className="form-control" value={this.state.car_model} onChange={this.validatecarmodelInput} required />
                                        </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Family Code:</label>
                                    <Select
                                                    placeholder={"Select Family Code"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.sbvh_code}
                                                    onChange={this.selectSbvhCode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.sbvhList} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Length:</label>
                                            <input type="number" name="length" id="length" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Width:</label>
                                            <input type="number" name="width" id="width" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Height:</label>
                                            <input type="number" name="height" id="height" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Load Factor:</label>
                                    <input type="text" name="load_factor" id="load_factor"  className="form-control" value={this.state.load_factor} onChange={this.validateloadfactorInput}
                                     required />
                                </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.plant}
                                                    onChange={this.selectplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantList} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Rake Type:</label>
                                    <Select
                                                    placeholder={"Select Rake Type"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.rake_type}
                                                    onChange={this.selectraketype.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "BCACBM", value: "BCACBM" },
                                                    { label: "NMG", value: "NMG" }]} required
                                                />
                                </div>        

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>			
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Car Details <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                <form method="POST" id="upform" className="theme-form" onSubmit={this.editCardetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Car Model:</label>
                                            <input type="text" name="edit_car_model" id="edit_car_model" className="form-control" value={this.state.editcar_model} onChange={this.editvalidatecarmodelInput} required />
                                        </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Family Code:</label>
                                    <Select
                                                    placeholder={"Select Family Code"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.edit_sbvh_code}
                                                    onChange={this.editselectSbvhCode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.sbvhList} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Length:</label>
                                            <input type="number" name="edit_length" id="edit_length" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Width:</label>
                                            <input type="number" name="edit_width" id="edit_width" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label>Height:</label>
                                            <input type="number" name="edit_height" id="edit_height" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Load Factor:</label>
                                    <input type="text" name="edit_load_factor" id="edit_load_factor"  className="form-control" value={this.state.edit_load_factor} onChange={this.editvalidateloadfactorInput}
                                     required />
                                </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.edit_plant}
                                                    onChange={this.editselectplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantList} required
                                                />
                                </div>  
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Rake Type:</label>
                                    <Select
                                                    placeholder={"Select Rake Type"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.edit_rake_type}
                                                    onChange={this.selecteditraketype.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "BCACBM", value: "BCACBM" },
                                                    { label: "NMG", value: "NMG" }]} required
                                                />
                                </div>  

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>			
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}
$(document).on("input", ".num-cls", function() {
    this.value = this.value.replace(/\D/g,'');
})
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
  $(document).on("input", ".dept-cls", function () {
    this.value = this.value.replace(/[^a-z-\s]/gi, '');
  });
  $(document).on("input", ".ringi-cls", function () {
    this.value = this.value.toUpperCase();
    this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
  });
  $(document).on("input", ".alfa-num-cls", function () {
    this.value = this.value.replace(/^[a-zA-Z0-9\s-]*$/gi, '');
  });

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};