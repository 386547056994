import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { escapeRegExp } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY} from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class ExceptionandLpcheck extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            rowData2: [],
            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file1:"",            
            csvcontent1:[],
            billing_month:"",
            startDate:"",
            endDate:"",
            posdatatabactive:"btn-arms",
            possummarytabactive:"",
            posdatatabshow:"show-m",
            possummarytabshow:"show-n",
            pagetitle:"LP Check",
            bulkslide1:"",
            bulkslide2:"",
            file2:"",            
            csvcontent2:[],
        };
    }
   
    async componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       
		await redirectURL.post("/arms/getlpchecksdata",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
            })
        }else{
				// console.log(response.data,"205")
				let records = response.data;
			// console.log(records,"206")
			if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
				if(records.length >0){
					this.setState({
						rowData:records,
						overly:"show-n",
						loadshow:"show-n"
					});
				}else{
					this.setState({
						overly:"show-n",
						loadshow:"show-n",
						rowData:[]
					});
				}
			}else{
				this.setState({
					overly:"show-n",
					loadshow:"show-n",
					rowData:[]
				});
			}
        }	
			})
		
        // this.onloadpagedata2()
    };
    async onloadpagedata1(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
		await redirectURL.post("/arms/getlpchecksdata",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
            })
        }else{
            // console.log(response.data,"205")
            let records = response.data;
			// console.log(records,"206")
			if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
				if(records.length >0){
					this.setState({
						rowData:records,
						overly:"show-n",
						loadshow:"show-n"
					});
				}else{
					this.setState({
						overly:"show-n",
						loadshow:"show-n",
						rowData:[]
					});
				}
			}else{
				this.setState({
					overly:"show-n",
					loadshow:"show-n",
					rowData:[]
				});
			}
        }
    })
		
    }
    async onloadpagedata2(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        await redirectURL.post("/arms/getexceptionchecksdata",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
            })
        }else{
                // console.log(response.data.data,"214")
                var resp = response.data
                // console.log(respcheck,"225")
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    rowData2:resp
                })
            }
        })
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
	  
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(startdate,"startdate")
			console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				startDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 	
			  this.setState({
				startDate:startdate
			  });
			}
		}
		else
		{
		  this.setState({
			startDate:startdate
		  });
		}
		  //let value = event.target.value;
		//this.setState({'startDate':value});
	}
	handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);    
		var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
	
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(this.state.startDate,"startdate")
			console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				endDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 
			  this.setState({
				endDate:edate
			  });
			}
		}
		else
		{
			this.setState({
			  endDate:edate
			});
		}
	}

        onClickShowTabView(tab){
            if(tab == "lpchecks")
            {
                this.setState({
                    posdatatabactive:"btn-arms",
                    possummarytabactive:"",
                    posdatatabshow:"show-m",
                    possummarytabshow:"show-n",
                    pagetitle:"LP Check",
                })
                this.onloadpagedata1()
            }
            if(tab == "exceptionchecks")
            {
                this.setState({
                    posdatatabactive:"",
                    possummarytabactive:"btn-arms",
                    posdatatabshow:"show-n",
                    possummarytabshow:"show-m",
                    pagetitle:"Exception Check",
                })
              this.onloadpagedata2()
            }
           
        }
        addNewRow=()=>{
            this.setState({
                bulkslide1:"slider-translate-30p",
                // rowId : rowId
                overly:"show-m"
            })
        }
        addNewRow2 =()=>{
            this.setState({
                bulkslide2:"slider-translate-30p",
                // rowId : rowId
                overly:"show-m"
            })
        }
        onClickHideAll1=()=>{
            this.setState({
                bulkslide1:"",
                overly:"show-n",
                loadshow:"show-n"
            })
           $("#bulkUploadID").val("")
        }
        onClickHideAll2=()=>{
            this.setState({
                bulkslide2:"",
                overly:"show-n",
                loadshow:"show-n"
            })
           $("#bulkUploadID").val("")
        }
        changeFileHandler = async (e) => {
            var dt = '';
            const config = {
                headers: [
                    // { 
                    //     name: 'id',
                    //     inputName: 'id',
                    //     required: false,
                    // },
                    { 
                        name: 'check_no',
                        inputName: 'check_no',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'region',
                        inputName: 'region',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                   { 
                        name: 'cluster',
                        inputName: 'cluster',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'state',
                        inputName: 'state',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'dealer_code',
                        inputName: 'dealer_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                   { 
                        name: 'for_code',
                        inputName: 'for_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'stockyard',
                        inputName: 'stockyard',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'smodel',
                        inputName: 'smodel',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'bveh_code',
                        inputName: 'bveh_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'pmodel_code',
                        inputName: 'pmodel_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }, 
                    { 
                        name: 'spec_code',
                        inputName: 'spec_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'selection_code',
                        inputName: 'selection_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }, 
                    { 
                        name: 'color',
                        inputName: 'color',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                ]
            }
            if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     this.setState({
                    //         csvcontent: csvData.data
                    //     });
                    // }
                    const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#]*$/); // define allowed characters using regex
                    let keys = Object.keys(csvData.data[0])
                    let check = false
                     
                    // if (check === false) {
                    //     $("#bulkUploadID").val("")
                    //     // document.getElementById("appednCSVData").innerHTML = ''
                    //     this.setState({
                    //         show: true,
                    //         basicTitle: 'dont upload other fields',
                    //         basicType: 'danger'
                    //     });
                       
                    // } else {
                        this.setState({
                            csvcontent1: csvData.data
                        });
                    // }
                  
            
                }).catch(err => { })
            
            
        // console.log("e.target.files[0]",e.target.result);
        var contentList = []
        
        
        
        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                // document.getElementById("appednCSVData").innerHTML = contents
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        // console.log(contentList);
        this.setState({
            file1: e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
        
        
        
        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            // console.log(fileData);
            // console.log(typeof(fileData))
        
        
        
        }
        else {
            e.target.value = null;
            this.setState({
                bulkUploadID:'',
                uploadFile: '',
                file: "",
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }
            }
        }
        changeFileHandler2= async (e) => {
            var dt = '';
            const config = {
                headers: [
                    // { 
                    //     name: 'id',
                    //     inputName: 'id',
                    //     required: false,
                    // },
                    { 
                        name: 'check_no',
                        inputName: 'check_no',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'region',
                        inputName: 'region',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                   { 
                        name: 'cluster',
                        inputName: 'cluster',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'state',
                        inputName: 'state',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'dealer_code',
                        inputName: 'dealer_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                   { 
                        name: 'for_code',
                        inputName: 'for_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'stockyard',
                        inputName: 'stockyard',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'smodel',
                        inputName: 'smodel',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'bveh_code',
                        inputName: 'bveh_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'pmodel_code',
                        inputName: 'pmodel_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }, 
                    { 
                        name: 'spec_code',
                        inputName: 'spec_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                    { 
                        name: 'selection_code',
                        inputName: 'selection_code',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    }, 
                    { 
                        name: 'color',
                        inputName: 'color',
                        required: true,
                        requiredError: function (headerName, rowNumber, columnNumber) {
                            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                        }
                    },
                ]
            }
            if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     this.setState({
                    //         csvcontent: csvData.data
                    //     });
                    // }
                    const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#]*$/); // define allowed characters using regex
                    let keys = Object.keys(csvData.data[0])
                    let check = false
                     
                    // if (check === false) {
                    //     $("#bulkUploadID").val("")
                    //     // document.getElementById("appednCSVData").innerHTML = ''
                    //     this.setState({
                    //         show: true,
                    //         basicTitle: 'dont upload other fields',
                    //         basicType: 'danger'
                    //     });
                       
                    // } else {
                        this.setState({
                            csvcontent2: csvData.data
                        });
                    // }
                  
            
                }).catch(err => { })
            
            
        // console.log("e.target.files[0]",e.target.result);
        var contentList = []
        
        
        
        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                // document.getElementById("appednCSVData").innerHTML = contents
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        // console.log(contentList);
        this.setState({
            file2: e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
        
        
        
        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            // console.log(fileData);
            // console.log(typeof(fileData))
        
        
        
        }
        else {
            e.target.value = null;
            this.setState({
                bulkUploadID:'',
                uploadFile: '',
                file: "",
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }
            }
        }
        uploadBulkFormHandler=(e)=>{
            e.preventDefault()
            this.setState({
                loadshow:"show-m",
                overly:"show-m"
            })
            var csvcontent = this.state.csvcontent1
            var uploaded_by = localStorage.getItem("username")
            var params = {
                csvcontent : csvcontent,
                uploaded_by : uploaded_by
            }
            redirectURL.post("/arms/uploadlpchecksData",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                })
            }else{
                    if(response.data.message == "success"){
                            this.setState({
                                bulkslide1:"",
                                overly: "show-n",
                                loadshow: "show-n",
                                show:true,
                                basicTitle:"Data Updated Successfully",
                                basicType:"success",
                            })
                            $("#bulkUploadID").val("")
                            this.onloadpagedata1()
                    }else{
                        this.setState({
                            bulkslide1:"",
                            overly: "show-n",
                            loadshow: "show-n",
                            show:true,
                            basicTitle:"Data Not Updated",
                            basicType:"warning",
                        })
                    }
                }
            })
        }
        uploadBulkFormHandler2=(e)=>{
            e.preventDefault()
            this.setState({
                loadshow:"show-m",
                overly:"show-m"
            })
            var csvcontent = this.state.csvcontent2
            var uploaded_by = localStorage.getItem("username")
            var params = {
                csvcontent : csvcontent,
                uploaded_by : uploaded_by
            }
            redirectURL.post("/arms/uploadexceptionchecksData",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                })
            }else{
                    if(response.data.message == "success"){
                            this.setState({
                                bulkslide2:"",
                                overly: "show-n",
                                loadshow: "show-n",
                                show:true,
                                basicTitle:"Data Updated Successfully",
                                basicType:"success",
                            })
                            $("#bulkUploadID2").val("")
                            this.onloadpagedata2()
                    }else{
                        this.setState({
                            bulkslide2:"",
                            overly: "show-n",
                            loadshow: "show-n",
                            show:true,
                            basicTitle:"Data Not Updated",
                            basicType:"warning",
                        })
                    }
                }
            })
        }

render(){
    const columnwithDefs = [
            {
                headerName: "Check Number",
                field: "check_no",
                width: 100,
                filter: "agSetColumnFilter",
                editable:true,
            },
            {
                headerName: "Region",
                field: "region",
                width: 80,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 80,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "State",
                field: "state",
                width: 80,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Dealer Code",
                field: "dealer_code",
                width: 100,
                filter: "agSetColumnFilter",
                editable:true,
            },
            {
                headerName: "FOR Code",
                field: "for_code",
                width: 100,
                filter: "agSetColumnFilter",
                editable:true,
            },
            {
                headerName: "Stockyard",
                field: "stockyard",
                width: 100,
                filter: "agSetColumnFilter",
                editable:true,
            },
            {
                headerName: "Smodel",
                field: "smodel",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "BVEH Code",
                field: "bveh_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Pmodel Code",
                field: "pmodel_code",
                width: 110,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "SPEC Code",
                field: "spec_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Selection Code",
                field: "selection_code",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Color",
                field: "color",
                width: 80,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "MSIL Modified Date",
                field: "msil_modified_date",
                width: 160,
                editable:false,
                filter: "agSetColumnFilter",
                valueGetter:function(params){
                    return getHyphenDDMMMYYYYHHMM(params.data.msil_modified_date)
                }
            },
            
    ];
    const columnwithDefs2 =[
        {
            headerName: "Check Number",
            field: "check_no",
            width: 100,
            filter: "agSetColumnFilter",
            editable:true,
        },
        {
            headerName: "Region",
            field: "region",
            width: 80,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Cluster",
            field: "cluster",
            width: 80,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "State",
            field: "state",
            width: 80,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Dealer Code",
            field: "dealer_code",
            width: 100,
            filter: "agSetColumnFilter",
            editable:true,
        },
        {
            headerName: "FOR Code",
            field: "for_code",
            width: 100,
            filter: "agSetColumnFilter",
            editable:true,
        },
        {
            headerName: "Stockyard",
            field: "stockyard",
            width: 100,
            filter: "agSetColumnFilter",
            editable:true,
        },
        {
            headerName: "Smodel",
            field: "smodel",
            width: 100,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "BVEH Code",
            field: "bveh_code",
            width: 100,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Pmodel Code",
            field: "pmodel_code",
            width: 110,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "SPEC Code",
            field: "spec_code",
            width: 100,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Selection Code",
            field: "selection_code",
            width: 120,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "Color",
            field: "color",
            width: 80,
            editable:false,
            filter: "agSetColumnFilter",
        },
        {
            headerName: "MSIL Modified Date",
            field: "msil_modified_date",
            width: 160,
            editable:false,
            filter: "agSetColumnFilter",
            valueGetter:function(params){
                return getHyphenDDMMMYYYYHHMM(params.data.msil_modified_date)
            }
        },
        
];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
                            
							
                    
                    
            <div className="row">
            <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.posdatatabactive)} onClick={this.onClickShowTabView.bind(this,"lpchecks")}>LP Check</button>
                        <button type="button" className={"btn "+(this.state.possummarytabactive)} onClick={this.onClickShowTabView.bind(this,"exceptionchecks")}>Exception Check</button>
                        
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.posdatatabshow)}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card-arms">
                                <div className="card-header-arms">
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    </h5>
                                </div>
                                <div>
                {/* <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Bulk Upload</button> */}
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                </div>
                                <div className="grid-container">
                                    <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData}
                                            rowSelection={this.state.rowSelection}
                                            enableCharts={false}
                                            //  paginationAutoPageSize={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            deleteRowHandler={this.deleteRowHandler}
                                            // onCellClicked={this.onRowClicked.bind(this)}
                                            // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //editType={this.state.editType}
                                            stopEditingWhenGridLosesFocus={true}
                                            // floatingFilter={true}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.possummarytabshow)}>
                        <div className="col-xl-12 col-lg-12">
                            <div className="card-arms">
                                <div className="card-header-arms">
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle}</span>
                                    </h5>
                                </div>
                                <div>
                {/* <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow2.bind(this)}>Bulk Upload</button> */}
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button>
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                </div>
                                <div className="grid-container">
                                    <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={columnwithDefs2}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.rowData2}
                                            rowSelection={this.state.rowSelection}
                                            enableCharts={false}
                                            //  paginationAutoPageSize={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            deleteRowHandler={this.deleteRowHandler}
                                            // onCellClicked={this.onRowClicked.bind(this)}
                                            // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //editType={this.state.editType}
                                            stopEditingWhenGridLosesFocus={true}
                                            // floatingFilter={true}
                                            enableCellChangeFlash={true}
                                            suppressCellFlash={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                <h3 className="subHarms">LP Check Bulk Upload
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll1.bind(this)}>x</span>
                </h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="bulkUploadID" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms"style={{borderRadius:"5px"}}>Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/lp_check_bulk_upload.csv')} target="_blank">Sample Template</a>
                    </div>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                <h3 className="subHarms">Exception Check Bulk Upload
                <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll2.bind(this)}>x</span>
                </h3>
                    <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler2}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="bulkUploadID2" id="bulkUploadID2" onChange={this.changeFileHandler2}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms"style={{borderRadius:"5px"}}>Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/exception_check_bulk_upload.csv')} target="_blank">Sample Template</a>
                    </div>		
                </div>
            </div>
        </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}