import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS,getHyphenDDMMMYYYY} from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class Dealermaster extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            startDate:"",
            endDate:"",
            total_quantity : 0,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
   
    async componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       
        // redirectURL.post('/arms/getdealermaster').then((response) => {
        //     var records = response.data;
        //     if(records.length > 0){
        //         this.setState({
        //             rowData:records,
        //             overly: "show-n",
        //             loadshow: "show-n"
        //         });
        //     }
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        // });
        var current_date =new Date()
        var endOfMonth = new Date(current_date.getFullYear() ,current_date.getMonth() +1 , 0)
        console.log(endOfMonth,"120")
        var end_time = moment.parseZone(new Date(endOfMonth)).format("YYYY-MM-DD")
		var start_time = moment.parseZone(new Date()).format("YYYY-MM-01")
		console.log(start_time,end_time,"106")
		this.setState({
			startDate:start_time,
			endDate:end_time
		})
		var params ={
			start_date:start_time,
			end_date:end_time
		}
		await redirectURL.post("/arms/getdatafromdealermasterfordates",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
            })
        }else{
            // console.log(response.data,"205")
            let records = response.data;
			// console.log(records,"206")
			if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
				if(records.length >0){
                    var total_quantity = 0
                    records.map(e => {
                        if(![undefined , null , "" , "undefined"].includes(e.quantity)){
                            total_quantity = total_quantity + parseInt(e.quantity)
                        } 
                    })
					this.setState({
						rowData:records,
						overly:"show-n",
						loadshow:"show-n",
                        total_quantity: total_quantity
					});
                   
				}else{
					this.setState({
						overly:"show-n",
						loadshow:"show-n",
                        total_quantity: 0,
                        rowData:[]
					});
				}
			}else{
				this.setState({
					overly:"show-n",
					loadshow:"show-n",
                    total_quantity:0,
					rowData:[]
				});
			}
				
			}
        })
    };
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
	  
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(startdate,"startdate")
			console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				startDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 	
			  this.setState({
				startDate:startdate
			  });
			}
		}
		else
		{
		  this.setState({
			startDate:startdate
		  });
		}
		  //let value = event.target.value;
		//this.setState({'startDate':value});
	}
	handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);    
		var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
	
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(this.state.startDate,"startdate")
			console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				endDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 
			  this.setState({
				endDate:edate
			  });
			}
		}
		else
		{
			this.setState({
			  endDate:edate
			});
		}
	}
    onClickFilterData(){
        //    console.log(this.state.startDate,this.state.endDate,"197")
           this.setState({
            overly :"show-m",
            loadshow : "show-m",
            total_quantity: 0
           })
           let start_date=this.state.startDate
           let end_date=this.state.endDate
            let params={
                start_date:this.state.startDate,
                end_date:this.state.endDate
            }
            console.log(params,"229")
           redirectURL.post("/arms/getdatafromdealermasterfordates",params,{"headers":
           {											
               'Content-Type': 'application/json;charset=UTF-8', 
               'Authorization': `Bearer ${localStorage.getItem("token")}`
       }
       }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
            })
        }else{
                // console.log(response.data,"205")
                let records = response.data;
                // console.log(records,"206")
                if(records != undefined && records != "" && records != null && records != "undefined" && records != "null"){
                    if(records.length >0){
                        var total_quantity = 0
                        records.map(e => {
                            if(![undefined , null , "" , "undefined"].includes(e.quantity)){
                                total_quantity = total_quantity + parseInt(e.quantity)
                                console.log(total_quantity,"359")
                            } 
                        })
                        this.setState({
                            rowData:records,
                            overly:"show-n",
                            loadshow:"show-n",
                            total_quantity: total_quantity
                        });
                       
                    }else{
                        this.setState({
                            overly:"show-n",
                            loadshow:"show-n",
                            total_quantity:0,
                            rowData:[]
                        });
                    }
                }else{
                    this.setState({
                        overly:"show-n",
                        loadshow:"show-n",
                        total_quantity:0,
                        rowData:[]
                    });
                }
                    
                }
            })
            
        }


render(){
    const columnwithDefs = [
            {
                headerName: "Start Date",
                field: "start_date",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter",
                valueGetter:function(params){
                    if(params.data.start_date != "" && params.data.start_date != undefined && params.data.start_date != null){
                        return getHyphenDDMMMYYYY(params.data.start_date)
                    }else{
                        return ""
                    }
                }
            },
            {
                headerName: "End Date",
                field: "to_date",
                width: 150,
                editable:false,
                filter: "agSetColumnFilter",
                valueGetter:function(params){
                    if(params.data.to_date != "" && params.data.to_date != undefined && params.data.to_date != null){
                        return getHyphenDDMMMYYYY(params.data.to_date)
                    }else{
                        return ""
                    }
                }
            },
            {
                headerName: "Dealer Code",
                field: "dealer_code",
                width: 140,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "FOR Code",
                field: "for_code",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Product Model Code",
                field: "prod_model_code",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Sales Model",
                field: "sales_model",
                width: 180,
                filter: "agSetColumnFilter",
                editable:false
            },
            {
                headerName: "SBVH Code",
                field: "sbvh_code",
                width: 140,
                filter: "agSetColumnFilter",
                
                editable:true,
            },
            {
                headerName: "Quantity",
                field: "quantity",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Dealer Mont Data</span>
                            </h5>
                        </div>
                        <div className='col-xl-12 col-lg-12'>
                            <div className="row card-body" style={{ marginLeft: "0px", paddingBottom: "0px" }}>
                                <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>From Date</label>
                                    <Datetime
                                        value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>To Date</label>
                                    <Datetime
                                        value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ position: "absolute", right: "20px", borderRadius: "5px" }} className="mt-30p btn btn-arms" onClick={this.onClickFilterData.bind(this)}>Submit</button>
                                </div>
                                <div>
                                    <p className='mt-30p' style={{fontSize:"24px", marginLeft:"100px" , color:"#333" , fontWeight:'bold'}}>Total Quantity : <span style={{color: "#171c8f"}}>{this.state.total_quantity}</span></p>
                                </div>

                            </div>

                        </div>
                        
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    statusBar={this.state.statusBar}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}