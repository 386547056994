import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import $ from 'jquery';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import CreatableSelect from 'react-select/creatable';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class RailyardMapping extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "multiple" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				// updateItem:UpdateItem,
                // deleteItem:DeleteItem
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk1:"",
            sliderBulk2:"",
            sliderBulk3:"",
            sliderBulk4:"",
            file:"",            
			csvcontent:[],
            billing_month:"",
            remarks_history_cols:[
                {
	  	          headerName: "Billing Id",
	  	          field: "billing_id",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                
		        {
	  	          headerName: "Remark",
	  	          field: "complete_remarks",
	  	          width: 180,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Updated Time",
	  	          field: "remark_time",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        if(params.data.remark_time != '' && params.data.remark_time != undefined)
                        {
                            return getHyphenYYYYMMDDHHMMSS(params.data.remark_time)
                        }
                        else
                        {
                            return "";
                        }
                    }
                    catch(e){
                        return "";
                    }
                }
                },
                {
                    headerName: "Remarks By",
                    field: "admin_email",
                    width: 180,
                    editable:false,
                    filter: "agSetColumnFilter"
                }

            ],
            remarks_data:[],
            imm_for_code:"",
            destination:"",
            for_code_options :[],
            imm_for_code_options: [],
            transporters_options:[],
            consigneer_codes_options:[],
            rake_loading_point_options : [],
            rake_unloading_point_options : [],
            cluster_options: [],
            destination_options:[],
            for_code:"",
            imm_for_code:"",
            transporter_code:"",
            consigner_code:"",
            rake_loading_point:"",
            rake_unloading_point:"",
            rake_type_add:"",
            cluster:"",
            row_id : "",
            destination:"",
            for_edit :false,
            edit_imm_for_code:"",
            edit_transporter_code:"",
            edit_for_code:"",
            edit_consigner_code:"",
            edit_destination:"",
            edit_rake_loading_point:"",
            edit_rake_unloading_point:"",
            edit_cluster:"",
            rake_type_edit:"",
            file:"",
            csvcontent:[],
            selectedRows:[]
		};
        
        this.onLoadData = this.onLoadData.bind(this);
	}

	async componentDidMount(){
        this.setState({
            loadshow:"show-m",
            overly:"show-m",
        })

       await redirectURL.post("/arms/getInitialDataForManageRailForCodes",{},{"headers":
       {											
           'Content-Type': 'application/json;charset=UTF-8', 
           'Authorization': `Bearer ${localStorage.getItem("token")}`
   }
   }).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
            var for_codes = response.data.for_codes
            var imm_for_codes = response.data.imm_for_codes
            var transporters = response.data.transporters
            var consigneer_codes = response.data.consigneer_codes
            var rake_loading_point = response.data.rake_loading_point
            var rake_unloading_point = response.data.rake_unloading_point
            var cluster = response.data.cluster
            var destination = response.data.destination
            var for_code_options = []
            for_codes.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e)){
                    for_code_options.push({label : e , value : e})
                }
            })
            var imm_for_code_options = []
            imm_for_codes.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e)){
                    imm_for_code_options.push({label : e , value : e})
                }
            })
            var transporters_options =[]
            transporters.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e.transporter_code)&& ![undefined , null , "" , "undefined"].includes(e.transporter_name)){
                    transporters_options.push({label:e.transporter_name , value : e.transporter_code})
                }
            })
            var consigneer_codes_options = []
            consigneer_codes.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e.plant_code)&& ![undefined , null , "" , "undefined"].includes(e.plant_name)){
                    consigneer_codes_options.push({label:e.plant_name , value : e.plant_code})
                }
            })
            var rake_loading_point_options = []
            rake_loading_point.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e)){
                    rake_loading_point_options.push({label:e , value : e})
                }
            })
            var rake_unloading_point_options = []
            rake_unloading_point.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e)){
                    rake_unloading_point_options.push({label:e , value : e})
                }
            })
            var cluster_options = []
            cluster.map(e => {
                if(![undefined , null , "" , "undefined"].includes(e.cluster_code)&& ![undefined , null , "" , "undefined"].includes(e.cluster_name)){
                    cluster_options.push({label:e.cluster_name , value : e.cluster_code})
                }
            })
            var destination_options = []
            destination.map(e=>{
                if(![undefined , null , "" , "undefined","0",0].includes(e)){
                    destination_options.push({label:e , value : e})
                }
            })
            this.setState({
                for_code_options,
                imm_for_code_options,
                transporters_options,
                consigneer_codes_options,
                rake_loading_point_options,
                rake_unloading_point_options,
                cluster_options,
                destination_options
            })
        }
       })

       this.onLoadData()
    
	  
    };
    onLoadData = () =>{
        redirectURL.post('/arms/managerailforcodesData',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
          var records = response.data.manageforcodeData;
        //   records = records.sort(GetSortDescOrder("billing_id"));
        //   console.log("records ", records)
          this.setState({
              rowData:records,
              overly: "show-n",
            loadshow: "show-n"
          });
        }
        })
        .catch(function (error) {
          console.log(error);
      });
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    /*Alert Popups*/
	closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
        // window.location.reload()
    }


   
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk1:"",
            sliderBulk2:"",
            sliderBulk3:"",
            sliderBulk4:"",
            for_edit :false,
            imm_for_code: "",
            transporter_code: "",
            for_code: "",
            consigner_code: "",
            rake_loading_point: "",
            rake_unloading_point: "",
            cluster: "",
            rake_type_add:"",
		});
        $('#destination').val("")
		$("#bulkUploadID").val('')
    }


 
    onClickShowBulk1()
    {
        this.setState({
            overly:"show-m",
			sliderBulk1:'slider-translate',
        })
    }
    onClickShowBulk2()
    {
        this.setState({
            overly:"show-m",
			sliderBulk2:'slider-translate',
        })
    }
    

	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'imm_for_code',
					inputName: 'imm_for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'for_code',
					inputName: 'for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consigner_name',
					inputName: 'consigner_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_loading_point',
					inputName: 'rake_loading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_unloading_point',
					inputName: 'rake_unloading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'cluster',
					inputName: 'cluster',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}

    onClickDataExport(){
    var params = {
        columnKeys: ["imm_for_code","transporter_name","for_code", "consignee_city","rake_loading_point","rake_unloading_point","consigner_name","consigner_code","cluster"]
    };
    this.gridApi.exportDataAsCsv(params);
}

    onRowClicked = (e) => {
        if(e.colDef.field == 'edit'){
            console.log(e.data)
            var checkdata = e.data
            this.setState({
                edit_imm_for_code:{label:checkdata.imm_for_code , value : checkdata.imm_for_code},
                edit_transporter_code : {label:checkdata.transporter_name, value:checkdata.transporter_code },
                edit_for_code:{label:checkdata.for_code , value:checkdata.for_code},
                edit_consigner_code : {label:checkdata.consigner_name , value : checkdata.consigner_code},
                edit_rake_loading_point:{label:checkdata.rake_loading_point , value : checkdata.rake_loading_point},
                edit_rake_unloading_point : {label : checkdata.rake_unloading_point , value : checkdata.rake_unloading_point},
                edit_cluster : {label : checkdata.cluster , value : checkdata.cluster},
                edit_destination:{label:checkdata.consignee_city,value:checkdata.consignee_city},
                rake_type_edit:{label:checkdata.rake_type,value:checkdata.rake_type},
                overly:"show-m",
			    sliderBulk3:'slider-translate',
                row_id : checkdata._id,
            })
        }
        if(e.colDef.field == 'delete'){
            var qry = {
                row_id : e.data._id
            }
            if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/DeleteRailYardData",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    // window.location.reload();
                    this.componentDidMount()
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        } 

        }
    }

    onClickAddNewForCodes(event){
        event.preventDefault();
        console.log("1729")
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var imm_for_code = this.state.imm_for_code.value;
        var transporter_code = this.state.transporter_code.value;
        var transporter_name = this.state.transporter_code.label
        var for_code = this.state.for_code.value;
        var consigner_name = this.state.consigner_code.label;
        var consigner_code = this.state.consigner_code.value;
        var destination = this.state.destination.value
        var rake_loading_point = this.state.rake_loading_point.value;
        var rake_unloading_point = this.state.rake_unloading_point.value;
        var cluster = this.state.cluster.label;
        var rake_type = this.state.rake_type_add.value
        var user_email = localStorage.getItem('email')
        var reqparams ={
            imm_for_code:imm_for_code,
            transporter_code:transporter_code,
            transporter_name:transporter_name,
            for_code:for_code,
            consigner_name:consigner_name,
            consigner_code:consigner_code,
            destination:destination,
            rake_loading_point:rake_loading_point,
            rake_unloading_point:rake_unloading_point,
            cluster:cluster,
            rake_type:rake_type,
            created_by:user_email,
        }
        console.log(reqparams,"reqparams");
        if(consigner_code !="" && consigner_code !=undefined && consigner_code !=null && consigner_name !="" && consigner_name !=undefined && consigner_name !=null){
            if(transporter_code !="" && transporter_code !=undefined && transporter_code !=null && transporter_name !="" && transporter_name !=undefined && transporter_name !=null){
                if(imm_for_code !="" && imm_for_code !=undefined && imm_for_code !=null){
                    if(for_code != "" && for_code != undefined && for_code != null){
                        if(destination != undefined && destination != null && destination != ""){
                            if(rake_loading_point != undefined && rake_loading_point != null && rake_loading_point != ""){
                                if(rake_unloading_point != undefined && rake_unloading_point != null && rake_unloading_point != ""){
                                    if(cluster != undefined && cluster != null && cluster != ""){
                                        if(rake_type != undefined && rake_type != null && rake_type != ""){
                                            redirectURL.post("/arms/insertRailYardMappingData", reqparams,{"headers":
                                            {											
                                                'Content-Type': 'application/json;charset=UTF-8', 
                                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                                        }
                                        })
                                            .then((response) => {
                                                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                                    this.setState({
                                                        show:true,
                                                        basicTitle:"Session Expired",
                                                        basicType:"warning",
                                                        overly: "show-n",
                                                        loadshow: "show-n"
                                                    })
                                                }else{
                                            var resp = response.data
                                            if(resp.status == "success"){
                                                this.setState({
                                                    loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:'Successfully Updated',
                                                    basicType:"success", 
                                                    imm_for_code: "",
                                                    transporter_code: "",
                                                    for_code: "",
                                                    consigner_code: "",
                                                    rake_loading_point: "",
                                                    rake_unloading_point: "",
                                                    cluster: "",
                                                    rake_type_add:"",
                                                    destination :"",
                                                    sliderBulk2:""
                                                })
                                                this.componentDidMount()
                                                
                                            }
                                            else{
                                                this.setState({
                                                    loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:resp.message,
                                                    basicType:"warning", 
                                                    imm_for_code: "",
                                                    transporter_code: "",
                                                    for_code: "",
                                                    consigner_code: "",
                                                    rake_loading_point: "",
                                                    rake_unloading_point: "",
                                                    cluster: "",
                                                    rake_type_add:"",
                                                    destination:"",
                                                    sliderBulk2:""
                                                })
                                            }
                                        }
                                            })
                                        }else{
                                            this.setState({
                                                loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:"Please Select Rake Type",
                                                    basicType:"warning", 
                                            })
                                        }
                                            
                                                }else{
                                                    this.setState({
                                                        loadshow:"show-n",
                                                            overly:"show-n",
                                                            show: true,
                                                            basicTitle:"Please Select Cluster",
                                                            basicType:"warning", 
                                                    })
                                                }
                                                
                                    }else{
                                        this.setState({
                                            loadshow:"show-n",
                                                overly:"show-n",
                                                show: true,
                                                basicTitle:"Please Select For Unloading Yard",
                                                basicType:"warning", 
                                        })
                                    }
                                        
                                    }else{
                                        this.setState({
                                            loadshow:"show-n",
                                                overly:"show-n",
                                                show: true,
                                                basicTitle:"Please Select Loading Yard",
                                                basicType:"warning", 
                                        })
                                    }
                        }else{
                            this.setState({
                                loadshow:"show-n",
                                    overly:"show-n",
                                    show: true,
                                    basicTitle:"Please Select Destination",
                                    basicType:"warning", 
                            })
                        }
                        
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select For Code",
                        basicType:"warning", 
                })
            }
                
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select Imm For Code",
                        basicType:"warning", 
                })
            }
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select Transporter",
                        basicType:"warning", 
                })
            }
        }else{
            this.setState({
                loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:"Please Select Consigner",
                    basicType:"warning", 
            })
        }
            
        
       
    }
    onClickEditNewForCodes(event){
        event.preventDefault();
        console.log("1729")
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var imm_for_code = this.state.edit_imm_for_code.value;
        var transporter_code = this.state.edit_transporter_code.value;
        var transporter_name = this.state.edit_transporter_code.label
        var for_code = this.state.edit_for_code.value;
        var consigner_name = this.state.edit_consigner_code.label;
        var consigner_code = this.state.edit_consigner_code.value;
        var destination = this.state.edit_destination.value
        var rake_loading_point = this.state.edit_rake_loading_point.value;
        var rake_unloading_point = this.state.edit_rake_unloading_point.value;
        var cluster = this.state.edit_cluster.label;
        var rake_type = this.state.rake_type_edit.value;
        var user_email = localStorage.getItem('email')
        var rowId = this.state.row_id
        var reqparams ={
            imm_for_code:imm_for_code,
            transporter_code:transporter_code,
            transporter_name:transporter_name,
            for_code:for_code,
            consigner_name:consigner_name,
            consigner_code:consigner_code,
            destination:destination,
            rake_loading_point:rake_loading_point,
            rake_unloading_point:rake_unloading_point,
            cluster:cluster,
            rake_type:rake_type,
            created_by:user_email,
            rowId : rowId
        }
        console.log(reqparams,"reqparams");
        if(consigner_code !="" && consigner_code !=undefined && consigner_code !=null && consigner_name !="" && consigner_name !=undefined && consigner_name !=null){
            if(transporter_code !="" && transporter_code !=undefined && transporter_code !=null && transporter_name !="" && transporter_name !=undefined && transporter_name !=null){
                if(imm_for_code !="" && imm_for_code !=undefined && imm_for_code !=null){
                    if(for_code != "" && for_code != undefined && for_code != null){
                        if(destination != undefined && destination != null && destination != ""){
                            if(rake_loading_point != undefined && rake_loading_point != null && rake_loading_point != ""){
                                if(rake_unloading_point != undefined && rake_unloading_point != null && rake_unloading_point != ""){
                                    if(cluster != undefined && cluster != null && cluster != ""){
                                        if(rake_type != undefined && rake_type != null && rake_type != ""){
                                            redirectURL.post("/arms/updateRailYardMappingData", reqparams,{"headers":
                                            {											
                                                'Content-Type': 'application/json;charset=UTF-8', 
                                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                                        }
                                        })
                                            .then((response) => {
                                                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                                    this.setState({
                                                        show:true,
                                                        basicTitle:"Session Expired",
                                                        basicType:"warning",
                                                        overly: "show-n",
                                                        loadshow: "show-n"
                                                    })
                                                }else{
                                            var resp = response.data
                                            if(resp.status == "success"){
                                                this.setState({
                                                    loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:'Successfully Updated',
                                                    basicType:"success", 
                                                    imm_for_code: "",
                                                    transporter_code: "",
                                                    for_code: "",
                                                    consigner_code: "",
                                                    rake_loading_point: "",
                                                    rake_unloading_point: "",
                                                    cluster: "",
                                                    destination :"",
                                                    sliderBulk3:""
                                                })
                                                this.componentDidMount()
                                                
                                            }
                                            else{
                                                this.setState({
                                                    loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:resp.message,
                                                    basicType:"warning", 
                                                    imm_for_code: "",
                                                    transporter_code: "",
                                                    for_code: "",
                                                    consigner_code: "",
                                                    rake_loading_point: "",
                                                    rake_unloading_point: "",
                                                    cluster: "",
                                                    destination:"",
                                                    sliderBulk2:""
                                                })
                                            }
                                        }
                                            })
                                        }else{
                                            this.setState({
                                                loadshow:"show-n",
                                                    overly:"show-n",
                                                    show: true,
                                                    basicTitle:"Please Select Rake Type",
                                                    basicType:"warning", 
                                            })
                                        }
                                            
                                                }else{
                                                    this.setState({
                                                        loadshow:"show-n",
                                                            overly:"show-n",
                                                            show: true,
                                                            basicTitle:"Please Select Cluster",
                                                            basicType:"warning", 
                                                    })
                                                }
                                                
                                    }else{
                                        this.setState({
                                            loadshow:"show-n",
                                                overly:"show-n",
                                                show: true,
                                                basicTitle:"Please Select For Unloading Yard",
                                                basicType:"warning", 
                                        })
                                    }
                                        
                                    }else{
                                        this.setState({
                                            loadshow:"show-n",
                                                overly:"show-n",
                                                show: true,
                                                basicTitle:"Please Select Loading Yard",
                                                basicType:"warning", 
                                        })
                                    }
                        }else{
                            this.setState({
                                loadshow:"show-n",
                                    overly:"show-n",
                                    show: true,
                                    basicTitle:"Please Select Destination",
                                    basicType:"warning", 
                            })
                        }
                        
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select For Code",
                        basicType:"warning", 
                })
            }
                
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select Imm For Code",
                        basicType:"warning", 
                })
            }
            }else{
                this.setState({
                    loadshow:"show-n",
                        overly:"show-n",
                        show: true,
                        basicTitle:"Please Select Transporter",
                        basicType:"warning", 
                })
            }
        }else{
            this.setState({
                loadshow:"show-n",
                    overly:"show-n",
                    show: true,
                    basicTitle:"Please Select Consigner",
                    basicType:"warning", 
            })
        }
          
    }
    onClickBulkUpload(){
        this.setState({
            sliderBulk4:"slider-translate",
            overly:"show-m"
        })
    }
    changeFileHandlerBulkUpload = async (e) => {
		// console.log(e.target.files[0].name,"791")
		var dt = '';
        
            // const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'imm_for_code',
					inputName: 'imm_for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'for_code',
					inputName: 'for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consignee_city',
					inputName: 'consignee_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_loading_point',
					inputName: 'rake_loading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_unloading_point',
					inputName: 'rake_unloading_point',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'cluster',
					inputName: 'cluster',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'rake_type',
					inputName: 'rake_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
            if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
                var filename = e.target.files[0].name.split(".")
                var fileName_check = filename[filename.length-1]
                if(fileName_check == "csv" || fileName_check == "CSV"){
                    var data = CSVFileValidator(e.target.files[0], config)
                    .then((csvData) => {
                        this.setState({
                            csvcontent:csvData.data
                        });
                    })
                    .catch(err => {})
                        
                    var out = new Promise(function(reject, resolve){
                        var reader =  new FileReader();
                        reader.onload = async function(e) {
                            var contents = await e.target.result;
                            console.log("contents ", contents )
                            
                            resolve(contents);
                        };
                        var tt =  reader.readAsText(e.target.files[0]);
                        console.log("tt ",tt)
                    });
                    //console.log("Out ", out);
                    
                    this.setState({
                        file:e.target.files[0]
                    });
                }else{
                    this.setState({
                        file:"",
                        csvcontent:[],
                        basicTitle:"Please Upload Valid File",
                        basicType:"danger",
                        show:true
                    })
                    $("#bulkUploadID").val("")
                }
            }
       
	}
    onClickBulkUploadRailForCodes(event){
        event.preventDefault()
        var csvcontent =this.state.csvcontent
        // console.log(csvcontent.length,"1113")
        var flag = 0;
        if(csvcontent.length > 0)
        {
            csvcontent.map((itm) => {
                var aKeys = Object.keys(itm)
                // console.log("aKeys ", aKeys)
                if(aKeys.length > 0)
                {
                    for(var i =0;i<aKeys.length;i++)
                    {
                        // console.log("aKeys i ", itm[aKeys[i]])
                        if(itm[aKeys[i]] == "")
                        {
                            flag=1;
                        }
                        else{
                            if(aKeys[i] == "consigner_code"){
                                // console.log(itm[aKeys[i]],"1130")
                                if(itm[aKeys[i]] == "Gurgaon" || itm[aKeys[i]] == "M"){
                                    itm[aKeys[i]] = "F"
                                }
                                else if(itm[aKeys[i]] == "Manesar" || itm[aKeys[i]] == "M"){
                                    itm[aKeys[i]] = "M"
                                }
                                else if(itm[aKeys[i]] == "Gujarat" || itm[aKeys[i]] == "G"){
                                    itm[aKeys[i]] = "G"
                                }
                                else if(itm[aKeys[i]] == "Bidadi" || itm[aKeys[i]] == "B"){
                                    itm[aKeys[i]] = "B"
                                }else{
                                    flag = 1
                                }
                            }
                        }
                    }
                }
            })
        }else{
            flag = 1
        }
        // console.log("Flagging ", flag)
        var username = localStorage.getItem("username")
        if(flag == 0){
            var reqparams = {
                csvcontent : csvcontent,
                username : username
            }
            redirectURL.post("/arms/bulkUploadManagerailforcodesData", reqparams,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
                }).then((response) => {
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        if(response.data.message == "success"){
                            this.setState({
                                show:true,
                                basicTitle:`Insereted Count - ${response.data.inserted} Uninserted Count - ${response.data.uninsereted}`,
                                basicType:"success",
                                overly: "show-n",
                                loadshow: "show-n",
                                sliderBulk4:""
                            })
                            $("#bulkUploadID").val('')
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:response.data.message,
                                basicType:"warning",
                                overly: "show-n",
                                loadshow: "show-n",
                                sliderBulk4:""
                            })
                            $("#bulkUploadID").val('')
                        }
                    }
                })
            }else{
                this.setState({
                    basicTitle:"Please Upload Valid Data",
                    basicType:"warning",
                    show:true,
                    overly: "show-n",
                    loadshow: "show-n",
                    sliderBulk4:""
                })
                $("#bulkUploadID").val('')
            }
    }
    onClickBulkDelete=()=>{
            var selectedRows = this.state.selectedRows
            if(selectedRows.length > 0){
                var cnf = window.confirm("Are you Sure, Do you really want to Delete?")
                if(cnf == true){
                    this.setState({
                        loadshow:"show-m",
                        overly:"show-m"
                    })
                        // console.log(selectedRows,"1188")
                        ///////////Test/////////////
                        var deleteRowIds = []
                        selectedRows.map((e)=>{
                            deleteRowIds.push(e._id)
                    })
                    // console.log(deleteRowIds,"1193")
                    var params={
                        deleteRowIds : deleteRowIds,
                        deleted_by:localStorage.getItem("username")
                    }
                    redirectURL.post("/arms/bulkDeletemanagerailforcodes",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                    }).then((response)=>{
                        console.log(response,"1236")
                        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                            this.setState({
                                show:true,
                                basicTitle:"Session Expired",
                                basicType:"warning",
                                overly: "show-n",
                                loadshow: "show-n"
                            })
                        }else{
                            if(response.data.message == "success"){
                                this.onLoadData();
                                this.setState({
                                    basicTitle:"Bulk Data Deleted Successfully",
                                    basicType:"success",
                                    show:true,
                                    loadshow:"show-n",
                                    overly:"show-n"
                                })
                                
                            }else{
                                this.setState({
                                    basicTitle:"Data Not Deleted",
                                    basicType:"danger",
                                    show:true,
                                    loadshow:"show-n",
                                    overly:"show-n"
                                })
                            }
                        }
                    })
                }else{
                    this.setState({
                        loadshow:"show-n",
                    overly:"show-n"
                    })
                }
               
            }else{
                this.setState({
                    basicTitle:"Please Select Rows",
                    basicType:"warning",
                    show:true
                })
            }
    }
    onRowSelection(event){
        var selectedRows=[];
        var rwCount = event.api.getSelectedNodes();
        rwCount.map((item) => {
            selectedRows.push(item.data)
        });
        // console.log(selectedRows,"selectedRows")
        this.setState({
            selectedRows:selectedRows
        })
    }
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}

		const columnwithDefs = [
            {
                headerName: "",
                field: "",
                colId: "",
                width: 40,
                pinned: 'left',
                filter: false,
                resizable: true,
                headerCheckboxSelection: false,
                //headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true
            },
                {
                    pinned:"left",
                    headerName : "",
                    field:"edit",
                    width:100,
                    resizable : true,
                    editable : false,
                    cellRenderer:function(params){       
                        var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                        return rendComponent;
                    },
                    filter:false,
                },
                {
                    pinned:"left",
                    headerName : "",
                    field:"delete",
                    width:50,
                    resizable : true,
                    editable : false,
                    cellRenderer:function(params){       
                        return '<i class="icofont icofont-ui-delete"></i>';
                    },
                    filter:false,
                },
                {
	  	          headerName: "Imm For Code",
	  	          field: "imm_for_code",
	  	          width: 120,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "Transporter Name",
	  	          field: "transporter_name",
	  	          width: 140,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Transporter Code",
	  	          field: "transporter_code",
	  	          width: 140,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "For Code",
	  	          field: "for_code",
	  	          width: 100,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Destination",
	  	          field: "consignee_city",
	  	          width: 110,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Consigner Code",
	  	          field: "consigner_code",
	  	          width: 130,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  cellRenderer:function(params){
                            if(params.data.consigner_code == "F"){
                            return "Gurgaon"
                            }
                            else if(params.data.consigner_code == "M"){
                                return "Manesar"
                            }
                            else if(params.data.consigner_code == "G"){
                                return "Gujarat"
                            }
                            else if(params.data.consigner_code == "B"){
                                return "Bidadi"
                            }
                            else{
                                return " "
                            }
                          }
                          
                   
                },
                {
	  	          headerName: "Rake Loading Point",
	  	          field: "rake_loading_point",
	  	          width: 150,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Rake Unloading Point",
	  	          field: "rake_unloading_point",
	  	          width: 160,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Cluster",
	  	          field: "cluster",
	  	          width: 100,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                {
	  	          headerName: "Rake Type",
	  	          field: "rake_type",
	  	          width: 100,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
                
	    ];

		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card-arms">
                            <div className="card-header-arms">
				       			<h5>
				       				<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Rail For Codes</span>
                                </h5>
                            </div>
                            <div className='col-xl-12 col-lg-12'>
                                        <button type="button" style={{margin : "5px",borderRadius:"5px"}} onClick={() => this.onClickShowBulk2()} className="btn btn-arms float-right">Add New</button>
                                        <button type = "button" className="btn btn-primary" style={{float:"right",margin:"5px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                                        <button type = "button" className="btn btn-secondary" style={{float:"right",margin:"5px",borderRadius:"5px"}} onClick={this.onClickBulkUpload.bind(this)}>Bulk Upload</button>
                                        <button type = "button" className="btn btn-danger" style={{float:"right",margin:"5px",borderRadius:"5px"}} onClick={this.onClickBulkDelete.bind(this)}>Bulk Delete</button>
                                </div>
		            		<div className="grid-container">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        onRowSelected={this.onRowSelection.bind(this)}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
                
                <div className={"slide-r "+(this.state.sliderBulk2)} style={{overflow:"auto"}}>
                    <div className="subHarms">
                        <h4>
                          Add New
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" id="updateform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickAddNewForCodes.bind(this)}>
							
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Imm For Code *</label>

                                    <Select
                                        value={this.state.imm_for_code}
                                        placeholder={"Select Imm For Code"}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => this.setState({
                                            imm_for_code :e
                                        })}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.imm_for_code_options}
                                        required />

                                </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code *</label>
                                <Select 
                                             value={this.state.transporter_code}
                                            placeholder={"Select Transporter Code"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                transporter_code :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.transporters_options}
                                            id="transporter_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">For Code *</label>
								
                                <Select
                                        value={this.state.for_code}
                                        placeholder={"Select For Code"}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => this.setState({
                                            for_code :e
                                        })}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.for_code_options}
                                        required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consigner Code *</label>
                                <Select 
                                            value={this.state.consigner_code}
                                            placeholder={"Select Consigner Code"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                consigner_code :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.consigneer_codes_options}
                                            id="consigner_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination *</label>
                                <Select 
                                            value={this.state.destination}
                                            placeholder={"Select Destination"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                destination :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.destination_options}
                                            id="destination"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Loading Point *</label>
								
                                <Select 
                                             value={this.state.rake_loading_point}
                                            placeholder={"Select Loading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                rake_loading_point :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.rake_loading_point_options}
                                            id="rail_loading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Unloading Point *</label>
								
                                <CreatableSelect 
                                            value={this.state.rake_unloading_point}
                                            placeholder={"Select Unloading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                rake_unloading_point :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.rake_unloading_point_options}
                                            id="rail_unloading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cluster *</label>
                                <Select 
                                            value={this.state.cluster}
                                            placeholder={"Select Cluster"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                cluster :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.cluster_options}
                                            id="cluster"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Rake Type *</label>
                                    <Select
                                                    placeholder={"Select Rake Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_type_add}
                                                    onChange={(e) => this.setState({
                                                        rake_type_add :e
                                                    })}
                                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                                    // style={{ borderRadius: "0px" }}
                                                    options={[{ label: "BCACBM", value: "BCACBM" },
                                                    { label: "NMG", value: "NMG" }]}
                                                    id="rake_type"
                                                     required/>            
                                </div>
							<div className="col-xl-12 col-lg-12 c">
                            <button type="submit" className="btn btn-arms">Submit</button>
							</div>
						</form> 
                
						
                        </div>
					</div>
				</div>
                <div className={"slide-r "+(this.state.sliderBulk3)} style={{overflow:"auto"}}>
                    <div className="subHarms">
                        <h4>
                          Edit Data
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" id="updateform" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickEditNewForCodes.bind(this)}>
							
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Imm For Code *</label>

                                    <Select
                                        value={this.state.edit_imm_for_code}
                                        placeholder={"Select Imm For Code"}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => this.setState({
                                            edit_imm_for_code :e
                                        })}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.imm_for_code_options}
                                        required />

                                </div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Transporter Code *</label>
                                <Select 
                                             value={this.state.edit_transporter_code}
                                            placeholder={"Select Transporter Code"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_transporter_code :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.transporters_options}
                                            id="transporter_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">For Code *</label>
								
                                <Select
                                        value={this.state.edit_for_code}
                                        placeholder={"Select For Code"}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => this.setState({
                                            edit_for_code :e
                                        })}
                                        className={"col-xl-12 col-lg-12 border-radius-0"}
                                        // style={{borderRadius:"0px"}}
                                        options={this.state.for_code_options}
                                        required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Consigner Code *</label>
                                <Select 
                                            value={this.state.edit_consigner_code}
                                            placeholder={"Select Consigner Code"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_consigner_code :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.consigneer_codes_options}
                                            id="consigner_code"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Destination *</label>
                                <Select 
                                            value={this.state.edit_destination}
                                            placeholder={"Select Destination"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_destination :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.destination_options}
                                            id="destination"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Loading Point *</label>
								
                                <Select 
                                             value={this.state.edit_rake_loading_point}
                                            placeholder={"Select Loading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_rake_loading_point :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.rake_loading_point_options}
                                            id="rail_loading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Rake Unloading Point *</label>
								
                                <CreatableSelect 
                                            value={this.state.edit_rake_unloading_point}
                                            placeholder={"Select Unloading Point"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_rake_unloading_point :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.rake_unloading_point_options}
                                            id="rail_unloading_point"
                                            required />
							
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Cluster *</label>
                                <Select 
                                            value={this.state.edit_cluster}
                                            placeholder={"Select Cluster"}
                                            closeMenuOnSelect={true}
                                            onChange={(e) => this.setState({
                                                edit_cluster :e
                                            })}
                                            className={"col-xl-12 col-lg-12 border-radius-0"}
                                            // style={{borderRadius:"0px"}}
                                            options={this.state.cluster_options}
                                            id="cluster"
                                            required />
							</div>
                            <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Rake Type *</label>
                                    <Select
                                                    placeholder={"Select Rake Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_type_edit}
                                                    onChange={(e) => this.setState({
                                                        rake_type_edit :e
                                                    })}
                                                    className={"col-xl-12 col-lg-12 border-radius-0"}
                                                    // style={{ borderRadius: "0px" }}
                                                    options={[{ label: "BCACBM", value: "BCACBM" },
                                                    { label: "NMG", value: "NMG" }]}
                                                    id="rake_type"
                                                     required
                                                />            
                                </div>
							<div className="col-xl-12 col-lg-12 c">
                            <button type="submit" className="btn btn-arms">Submit</button>
							</div>
						</form> 
                
						
                        </div>
					</div>
				</div>
                <div className={"slide-r "+(this.state.sliderBulk4)} style={{overflow:"auto"}}>
                    <div className="subHarms">
                        <h4>
                          Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                     
                            
						<form method="POST" id="bulkUploadfrom" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onClickBulkUploadRailForCodes.bind(this)}>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <label className="col-xl-12 col-lg-12 row">Upload File *</label>
                                <input type="file" name="uploadFile" className={"col-xl-12 col-lg-12 border-radius-0 form-control"} id="bulkUploadID" onChange={this.changeFileHandlerBulkUpload} required  />
                            </div>
							<div className="col-xl-12 col-lg-12 form-group">
                            <button type="submit" className="btn btn-arms">Submit</button>
							</div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a className="btn btn-primary" href={require('../../assets/json/sample_template_manage_rail_forcodes.csv')} target="_blank">Sample Template</a>
                            </div>
						</form> 
                                                    
						
                        </div>
					</div>
				</div>

                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}