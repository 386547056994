import React, {Component} from 'react';
import axios from 'axios';
import {loginClient} from "../../../common-partner-login-sdk/lib/esm"
// import jwtDecode from 'jwt-decode';
// import { Redirect } from 'react-router';
var googleAnalytics = require("../googleanalytics");
var redirectURL = require('../../redirectURL');


// Import authService

class UserMenu extends Component {
    constructor(props) {
		super(props);
		this.state = {
            welcomeMessage: ""
		}
	}
	logOut = async(e) => {
        try{
            // loginClient.federatedLogout({client_id:"7btrataiqj1mef1sjhemks7h85",logout_uri:"https://msilvendortrucknav.com/logout"}).then((res) => {
            loginClient.federatedLogout("2f35qtedlb8tpeok86t3p6j0ch","https://msilrail.in/logout/").then((res) => {
                console.log(res,'1686-------------------');
               
              }).catch(e=> console.log("error in adfs"));
        }catch(e){
            console.log("error in adfs login")
        }
        var loginType = localStorage.getItem("is_enmovil_transporter");
                localStorage.removeItem('token');
                localStorage.removeItem('userid');
                localStorage.removeItem('dept_code');
                localStorage.removeItem('firstname');
                localStorage.removeItem('lastname');
                localStorage.removeItem('email');
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
            this.setState({
                redirectToReferrer:''
            });
                // this.setState({
                //     redirectToReferrer:''
                // });
                //console.log("logout successfully",localStorage.getItem('token'));
              
              
                 window.location.href="/";
               //  return res.status(200).send({ adfs_data: res.data });
    }
    setWelcomeMessage() {
        let firstName = localStorage.getItem("firstname");
        let lastName = localStorage.getItem("lastname");
        let fullName = localStorage.getItem("email");
        if (firstName !== "undefined" || lastName !== "undefined") {
            fullName = firstName + " " + lastName;
        }
        //this.setState({welcomeMessage: "Welcome " + fullName })
        this.setState({welcomeMessage: fullName })
    }
    componentDidMount() {
        this.setWelcomeMessage();
    }
    render() {
    	/*if(localStorage.getItem('token') == null || localStorage.getItem('token') == '')
		{
    		return <Redirect to="/" push={true} />
		}*/
        return (
            <li className="onhover-dropdown">
                <div className="media  align-items-center">
                    <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/user.png")} alt="header-user" />
                    <div className="media-body">
                        <h6 className="m-0 txt-dark f-16">
                            <i className="fa fa-angle-down pull-right ml-2"></i>
                        </h6>
                    </div>
                </div>
                <ul className="profile-dropdown onhover-show-div p-20" id="profileDropdown">
                    <li className="ellipsis">
                        <a href="#" title={this.state.welcomeMessage}>
                            <i className="icon-user"></i>
                            {this.state.welcomeMessage}
                        </a>
                    </li>
                    {/* {(localStorage.getItem("email") != "airimports@maruti.co.in" && localStorage.getItem("is_load_management") != 1)? */}
                    {(localStorage.getItem("email") != "airimports@maruti.co.in" && localStorage.getItem("email") != "seaimports@maruti.co.in")?
                     <li>
                        <a href="/profile/changepassword" title="Change Password">
                            <i className="icofont icofont-ui-password"></i>
                            Change Password
                        </a>
                    </li>
                    : ""}
                    <li>
                        <a href="#" onClick={this.logOut.bind(this)} title="Logout">
                            <i className="icon-power-off"></i>
                            Logout
                        </a>
                    </li>
                </ul>
            </li>
        );
    }
}

function relaodpage(loginType){
    if(loginType == 1)
    {
        window.location.href="/tptlogin";
    }
    else if(loginType == 2)
    {
        window.location.href="/optirun";
    }
    else
    {
        window.location.reload();
    }
}

export default UserMenu;




