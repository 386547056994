import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { values } from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class PlantWiseVDDStock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single",
            context: { componentParent: this },

            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },

            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize: 100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters: [],
            sliderRso: "",
            sliderUpdate: "",
            transporter: "",
            truck_no: "",
            trucktype: "",
            deptcode: "",
            sources: [],
            destinations: [],
            sourceopt: "",
            oem_code: "",
            oem_name: "",
            industry_type: "",
            officer_mobile: "",
            officer_alteratemobile: "",
            officer_locations: [],
            officertype: "",
            rowid: "",
            sliderBulk: "",
            file: "",
            csvcontent: [],
            billing_month: "",
            columnwithDefs: []

        };
    }

    componentDidMount() {
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })

        redirectURL.post('/arms/getPlantWiseVDDStock',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var records = JSON.parse(response.data.data)
            var records = eval(records.for_code_target_plan)
            console.log(records , "records_1729")

            
            var group_by_plant = groupBy(records , data => data.plant_name)
            var row_data = []
            var t_domestic = 0
            var t_export = 0
            var total_data_fields = {}
            var total_Array = []
            group_by_plant.forEach((values , keys) =>{
                var group_by_car_model = groupBy(values , data => data.car_model)
                var total_domestic_total = 0
                var total_export_total = 0
                var data_fields = {}
                var array = []
                group_by_car_model.forEach((values_2 , keys_2) => {
                    var data = {
                        'plant_name':keys,
                        'car_model':keys_2,
                    }
                    var domestic_total = 0
                    var export_total = 0
                    values_2.map(e => {
                        data[`${e.status}_${e.type}`] = e.total
                        if(e.type == 'Domestic'){
                            domestic_total = domestic_total + e.total
                            total_domestic_total = total_domestic_total + e.total
                            t_domestic = t_domestic + e.total
                        }
                        if(e.type == 'Export'){
                            export_total = export_total + e.total
                            total_export_total = total_export_total + e.total
                            t_export = t_export + e.total
                        }
                        if(!array.includes(`${e.status}_${e.type}`)){
                            // console.log("1729")
                            array.push(`${e.status}_${e.type}`)
                            data_fields[`${e.status}_${e.type}`] = e.total
                        }else{
                            data_fields[`${e.status}_${e.type}`] +=  e.total
                        }   
                        if(!total_Array.includes(`${e.status}_${e.type}`)){
                            // console.log("1729")
                            total_Array.push(`${e.status}_${e.type}`)
                            total_data_fields[`${e.status}_${e.type}`] = e.total
                        }else{
                            total_data_fields[`${e.status}_${e.type}`] +=  e.total
                        }                    
                    })
                    data['domestic_total'] = domestic_total
                    data['export_total'] = export_total
                    data['domestic_export_total'] = domestic_total + export_total
                    row_data.push(data)
                })
                var data = {
                    plant_name :'Total',
                    domestic_total : total_domestic_total,
                    export_total : total_export_total,
                    domestic_export_total:total_domestic_total + total_export_total
                }
                row_data.push({...data , ...data_fields})
            })
            var total_data = {
                plant_name :'Total',
                domestic_total : t_domestic,
                export_total : t_export,
                domestic_export_total:t_domestic + t_export
            }
            row_data.push({...total_data , ...total_data_fields})

            console.log(row_data , "row_data")
            var columns = [
                {
                    headerName: "",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children : [
                    {
                        headerName: "Parking Zone",
                        headerClass:"cellstylegridTPFM",
                        field: "plant_name",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.plant_name == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                    },
                    {
                        headerName: "Model Desc",
                        headerClass:"cellstylegridTPFM",
                        field: "car_model",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.plant_name == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                    },

                ]
                },
                {
                    headerName: "Domestic",
                    headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 250,
                    chartDataType: "excluded",
                    children:[]
                },
                {
                    headerName: "Export",
                    headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 250,
                    chartDataType: "excluded",
                    children:[]
                },
                {
                    headerName: "Total",
                    headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 250,
                    chartDataType: "excluded",
                    children:[
                        {
                            headerName: '',
                            headerClass: "cellstylegridTPFM",
                            field: 'domestic_export_total',
                            width: 100,
                            filter: true,
                            resizable: true,
                            editable: false,
                            cellClass:"cellstylegridTPFM",
                        }
                    ]
                },

            ]
            var status_list_domestic = []
            var status_list_export = []
            records.map(e => {
                if(e.type == "Domestic"){
                    if(!status_list_domestic.includes(e.status)){
                        columns[1].children.push({
                        
                            headerName: `${e.status}`,
                            headerClass:"cellstylegridTPSMG",
                            field: `${e.status}_${e.type}`,
                            width: 70,
                            filter: true,
                            resizable: true,
                            editable:false,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.plant_name == "Total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            },
                            valueGetter:function(params){
                                if(![undefined , 'undefined' , null , ""].includes(params.data[`${e.status}_${e.type}`])){
                                    return params.data[`${e.status}_${e.type}`]

                                }else{
                                    return 0 
                                }
                            } 
                        
                    })
                    status_list_domestic.push(e.status)
                    }
                    
                }
                if(e.type == "Export"){
                    if(!status_list_export.includes(e.status)){
                        columns[2].children.push({
                        
                            headerName: `${e.status}`,
                            headerClass:"cellstylegridTPBNG",
                            field: `${e.status}_${e.type}`,
                            width: 70,
                            filter: true,
                            resizable: true,
                            editable:false,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.plant_name == "Total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            },
                            valueGetter:function(params){
                                if(![undefined , 'undefined' , null , ""].includes(params.data[`${e.status}_${e.type}`])){
                                    return params.data[`${e.status}_${e.type}`]

                                }else{
                                    return 0 
                                }

                            } 
                        
                    })
                    status_list_export.push(e.status)
                    }
                   
                }
            })
            columns[1].children.push({
                headerName: 'Total',
                headerClass: "cellstylegridTPSMG",
                field: 'domestic_total',
                width: 70,
                filter: true,
                resizable: true,
                editable: false,
                cellClass:"cellstylegridTPSMG",
                cellStyle:function(params){
                    if(params.data.plant_name == "Total"){
                        return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                    }
                },
            })
            columns[2].children.push({
                headerName: 'Total',
                headerClass: "cellstylegridTPBNG",
                field: 'export_total',
                width: 70,
                filter: true,
                resizable: true,
                editable: false,
                cellClass:"cellstylegridTPBNG",
                cellStyle:function(params){
                    if(params.data.plant_name == "Total"){
                        return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                    }
                },
            })
            if (row_data.length > 0) {
                this.setState({
                    rowData:row_data,
                    overly: "show-n",
                    loadshow: "show-n",
                    columnwithDefs: columns
                });
            } 
            else {
                this.setState({
                    rowData: [],
                    overly: "show-n",
                    loadshow: "show-n",
                    columnwithDefs: columns
                });
            }
        }
        })
            .catch(function (error) {
                console.log(error);
            });

    };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;

    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            sliderRso: "",
            sliderUpdate: "",
            sliderBulk: ""
        });

    }

    render() {
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card-arms">
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Plantwise VDD Stock</span>
                                </h5>
                            </div>
                            <div className="grid-container">
                                <div id="myGrid" className="ag-theme-balham grid-card">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>


            </div>

        );
    }
}

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}