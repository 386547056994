/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { withRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { forEach, functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class RailLoadConfigurator extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            remarks_data:[],
            target_summary_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            option:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "option1" },
            targetoption:{label: "PPC + Stock", value: "3"},
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            pinnedRowData4: [],
            plan_month:"",
            plan_month_upload:"",
            inprocessTab:"btn-arms",
            closedTab:"",
            inprocessShow:"show-m",
            closedShow:"show-n",
            pagetitle :"Rail Load Configurator In process",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            plan_type:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
            location:{label: "Haryana", value: "H" },
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            mode:{label:"Overall",value:"overall"},
            mode_header:"",
            detailCellRendererParams:{},
            remarks_history_cols:[],
            sliderData:"",
            rakeHeaderName:"",
            train_details:[],
            subheader:"",
            bulkslide5:"",
            frequency:"",
            frequency_row_id:""
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        await loadDateTimeScript();
        redirectURL.post("/arms/getDataforrailloadconfigurator",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"263")
            this.setState({
                rowData : response.data
            })
        }
        })
    }
    onClickHideAll1() {
        this.setState({
            bulkslide5:"",
            overly:"show-n",
            loadshow:"show-n",
            sliderData:""
        });
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    

    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }
    
    onClickShowTabView(tab){
        if(tab == "inprocess")
        {
            this.setState({
               inprocessTab:"btn-arms",
                closedTab:"",
                inprocessShow:"show-m",
                closedShow:"show-n",
                pagetitle:"Rail Load Configurator In process",
            })
        }
        if(tab == "closed")
        {
            this.setState({
                inprocessTab:"",
                closedTab:"btn-arms",
                inprocessShow:"show-n",
                closedShow:"show-m",
                pagetitle:"Rail Load Configurator Completed",
            })
        }
        
    }
    onCellClickedGrid=(e)=>{
        if(e.colDef.field == "view_rake_details"){
            var RakeName = e.data.rake_name
            this.setState({
                sliderData : "slider-translate-50p",
                overly:"show-m",
                rakeHeaderName : RakeName 
            })
            var params ={
                "rake_no":e.data.rake_name
            }
            redirectURL.post("/arms/getrakedetailsdataforloadconfigurator",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log("response-341",response.data.data)
                var resp = response.data.data
                var subheader = response.data.getDistinctType
                this.setState({
                    train_details : resp,
                    subheader : subheader
                })
            }
        })

        }
        if(e.colDef.field == "set_frequency"){
            var rowId = e.data._id
            this.setState({
                bulkslide5:"slider-translate",
                overly:"show-m",
                frequency_row_id : rowId,
                frequency :{"label":e.data.frequency+" Minutes","value":e.data.frequency}
            })

        }
    }
    onClickHideAll =(e)=>{
        this.setState({
            sliderData:"",
            overly:"show-n",
            loadshow:"show-n"
        })
    }
    selectfrequency =(e)=>{
        this.setState({
            frequency:e
        })
    }
    updateFrequency =(event)=>{
        event.preventDefault()
        var frequency = this.state.frequency.value
        var rowId = this.state.frequency_row_id
        var params = {
            frequency : frequency,
            rowId : rowId
        }
        redirectURL.post("/arms/addFrequencytorailloadconfigurator",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            if(response.data.message == "success"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Frequency Added",
                    basicType:"success",
                    show:true,
                    frequency:"",
                    bulkslide5:""
                })
            }else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Failed To Add",
                    basicType:"warning",
                    show:true,
                    frequency:"",
                    bulkslide5:""
                })
            }
        }
        })
    }
    render() {
        const columnwithDefs = [
            {
                headerName: "View Details",
                field: "view_details",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    let basestring = params.data.storage_plan_id;
                    var encryptedstring = window.btoa(basestring)
                    var htmloption = '<a href="/railloadconfiguratorsummary/'+encryptedstring+'" class="btn btn-arms label label-success"><i class="icofont icofont-eye"></i>View</a>';
                    return htmloption

                } 
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.rakesList,
                // },           
            },
            {
                headerName: "View Rake Details",
                field: "view_rake_details",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-success label label-success"><i class="icofont icofont-eye"></i>View Rake Details</button>';
                    return htmloption
                }
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.transportersList,
                // },  
            },
            {
                headerName: "Set Frequency",
                field: "set_frequency",
                width:170,
                filter: true,
                resizable: true,
                editable:false,
                pinned:"left",
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-danger label label-danger">Set Frequency</button>';
                    return htmloption
                }
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.transportersList,
                // },  
            },
            {
                headerName: "Storage Plan Id",
                field: "storage_plan_id",
                width:130,
                filter: true,
                resizable: true,
                editable:false, 
                pinned:"left",
            },
            {
                headerName: "Rake No",
                field: "rake_name",
                width:90,
                filter: true,
                resizable: true,
                editable:false, 
                pinned:"left",
            },
            {
                headerName: "MSIL Reck No",
                field: "msil_reck_no",
                width:120,
                filter: true,
                resizable: true,
                editable:false, 
                pinned:"left",
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.baseStationList,
                // },  
            },
            // {
            //     headerName: "Transporter Name",
            //     field: "transporter_name",
            //     width:150,
            //     filter: true,
            //     resizable: true,
            //     editable:false, 
            // },
            {
                headerName: "Plant",
                field: "loading_plant",
                width:110,
                filter: true,
                resizable: true,
                editable:false, 
            }, 
            {
                headerName: "Loading Yard",
                field: "loading_yard",
                width:120,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Unloading Yard",
                field: "unloading_yard",
                width:130,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Planning Date",
                field: "planning_date",
                width:140,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "No of Cars",
                field: "no_of_cars",
                width:100,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Status",
                field: "status",
                width:90,
                filter: true,
                resizable: true,
                editable:false, 
            }, 
            {
                headerName: "Frequency",
                field: "frequency",
                width:110,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Generated On",
                field: "approved_by",
                width:120,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Generated By",
                field: "approved_on",
                width:120,
                filter: true,
                resizable: true,
                editable:false, 
                valueGetter:function(params){
                    return getHyphenDDMMMYYYY(params.data.approved_on)
                }
            }, 
            {
                headerName: "Month",
                field: "plan_month",
                width:100,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Stage",
                field: "stage",
                width:80,
                filter: true,
                resizable: true,
                editable:false, 
            },     
            // {
            //     pinned:"left",
            //     headerName: "id",
            //     field: "id",
            //     width:100,
            //     filter: true,
            //     resizable: true,
            //     editable:false,            
            // },     
        ]
        const columnwithDefs2 =[
            {
                headerName: "Storage Plan Id",
                field: "storage_plan_id",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Rake No",
                field: "rake_no",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Transporter Code",
                field: "transporter_code",
                width:200,
                filter: true,
                resizable: true,
                editable:false,
                // cellEditor: 'agSelectCellEditor',
                // cellEditorParams: {
                //     values: this.state.baseStationList,
                // },  
            },
            {
                headerName: "Transporter Name",
                field: "transporter_name",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Plant",
                field: "origin",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            }, 
            {
                headerName: "Loading Yard",
                field: "loading_yard",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Unloading Yard",
                field: "unloading_yard",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Planning Date",
                field: "planning_date",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "No of Cars",
                field: "no_of_cars",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Status",
                field: "status",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            }, 
            {
                headerName: "Frequency",
                field: "frequency",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Generated On",
                field: "generated_on",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },
            {
                headerName: "Generated By",
                field: "generated_by",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            }, 
            {
                headerName: "Month",
                field: "plan_month",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },  
            {
                headerName: "Stage",
                field: "stage",
                width:150,
                filter: true,
                resizable: true,
                editable:false, 
            },  
        ]
        const slidercolumnDefs=[
            {
                headerName: this.state.rakeHeaderName+" - "+this.state.subheader,
                headerClass: ["cellstylegridBNG", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 150,
                chartDataType: "excluded",
                rake_count:6,
                rake_type:"BCACBM_OLD",
                children : [
                    {
                        headerName: "Wagon Type",
                        headerClass:"cellstylegridBNG",
                        field: "wagon_type",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Deck Type",
                        headerClass:"cellstylegridBNG",
                        field: "deck_type",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Length (mm)",
                        headerClass:"cellstylegridBNG",
                        field: "length_mm",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Max Height (mm)",
                        headerClass:"cellstylegridBNG",
                        field: "max_height_mm",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Min Height (mm)",
                        headerClass:"cellstylegridBNG",
                        field: "min_height_mm",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Height Clearance",
                        headerClass:"cellstylegridBNG",
                        field: "height_clearance",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Height Adjust (mm)",
                        headerClass:"cellstylegridBNG",
                        field: "height_adjust_mm",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                    {
                        headerName: "Rake Count",
                        headerClass:"cellstylegridBNG",
                        field: "rake_count",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        pinned:"left",
                        cellClass:"cellstylegridBNG",
                    },
                ]
            }
        ]
        
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
                <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.inprocessTab)} onClick={this.onClickShowTabView.bind(this,"inprocess")}>In Process</button>
                        <button type="button" className={"btn "+(this.state.closedTab)} onClick={this.onClickShowTabView.bind(this,"closed")}>Completed</button>
                        
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.inprocessShow)}>
                    <div className="card-arms col-xl-12">
                    <div className="card-header-arms">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                        </h5>
                    </div>
                        <div className="grid-container col-xl-12 col-lg-12">   
                               
                        
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    onCellClicked={this.onCellClickedGrid.bind(this)}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                <div className={"col-xl-12 col-lg-12 "+(this.state.closedShow)}>
                    <div className="card-arms col-xl-12">
                    <div className="card-header-arms">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                        </h5>
                    </div>
                        <div className="grid-container col-xl-12 col-lg-12"> 
                           
                                
                            <div id="myGrid" style={{ width: "100%", height: "500px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData2}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                    <div className={"slide-r " + (this.state.sliderData)}>
                        <h3 className="subHarms">Rake Details 
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
                        </h3>					
                        <div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                        <AgGridReact
                                            modules={this.state.modules}
                                            columnDefs={slidercolumnDefs}
                                            defaultColDef={this.state.defaultColDef}
                                            rowData={this.state.train_details}
                                            rowSelection={this.state.rowSelection}
                                            enableCharts={false}
                                            //  paginationAutoPageSize={true}
                                            paginationPageSize={this.state.paginationPageSize}
                                            pagination={true}
                                            enableRangeSelection={true}
                                            onGridReady={this.onGridReady}
                                            onGridState={this.onGridState}
                                            deleteRowHandler={this.deleteRowHandler}
                                            // onCellClicked={this.onRowClicked.bind(this)}
                                            // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                            frameworkComponents={this.state.frameworkComponents}
                                            //editType={this.state.editType}
                                            // stopEditingWhenGridLosesFocus={true}
                                            // floatingFilter={true}
                                            // enableCellChangeFlash={true}
                                            // suppressCellFlash={true}
                                            gridOptions={{
                                                context: { componentParent: this }
                                            }}
                                            //updateRowHandler={this.updateRowHandler}
                                            //onCellEditingStopped={this.updateCellData.bind(this)}
                                        />
                                    </div>
                    </div>
                    <div className={"slide-r " + (this.state.bulkslide5)}>
                        <h3 className="subHarms">Add Frequency 
                        <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span>
                        </h3>					
                        <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.updateFrequency.bind(this)}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Set Frequency:</label>
                                    <Select
                                                    placeholder={"Select Frequency"}
                                                    closeMenuOnSelect={true}
                                                   
                                                    value={this.state.frequency}
                                                    onChange={this.selectfrequency.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "10 Minutes", value: "10" },
                                                    { label: "20 Minutes", value: "20" },{label:"30 Minutes",value:"30"}]} required
                                                />
                                </div>
                                
                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}

export default withRouter(RailLoadConfigurator)

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});