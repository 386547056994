import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');

 
const BatchDownload = (props) => {
    
    const handleRouteClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.downloadbatchfile(props.data);
    };
    var batchname = props.data.batch_name;
    var file = props.data.file_upload_path;
    if(file.includes("\\"))
    {
        var filename = file.split("\\");
        filename = filename[(filename.length-1)]
    }
    else
    {
        var filename = file.split("/");
        filename = filename[(filename.length-1)]
    }
    return (
        <div className="map-icon-component">
			{/* <a href={require('../../assets/uploadedclusters/'+filename)} target="_blank" ><i className="fa fa-download"></i></a> {props.data.batch_name}  */}
        </div>
    );
};

export default BatchDownload;