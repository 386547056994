import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class RakeInitiationData extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            startDate:"",
            endData : "",
            total_quantity : 0,
            reck_number:"",
            reckNumberoptions:[],
            loadshow:"show-n",
            overly:"show-n"
        };
    }
   
    componentDidMount(){
        redirectURL.post("/arms/getdistinctrecknumbers",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var resp = response.data.reckList
            var reckNumberoptions =[]
            resp.map((e)=>{
                reckNumberoptions.push({
                    value:e,
                    label:e
                })
            })
            this.setState({
                reckNumberoptions : reckNumberoptions
            })
        }
        })
    };

    onLoadData = () => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var params ={}
        var reck_number = this.state.reck_number.value
        if(reck_number != undefined && reck_number != null && reck_number != ""){
            params.reck_number = reck_number
            redirectURL.post("/arms/geterakeinitiationData",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                var records = response.data;
                console.log(records,"127")
                if(records.length > 0){
                  
                    this.setState({
                        rowData:records,
                        overly: "show-n",
                        loadshow: "show-n"
                    });
                }else{
                    this.setState({
                        rowData:[],
                        overly: "show-n",
                        loadshow: "show-n"
                    });
                }
            }
              })
              .catch(function (error) {
                console.log(error);
            });
        }else{
            this.setState({
                show:true,
                basicTitle:"Please Select Reck Number",
                basicType:"warning",
                overly:"show-m",
                loadshow:"show-m"
            })
        }
        
    }

    onClickFilterData = () => {
        var params = {
            from_date:this.state.startDate , 
            to_date : this.state.endDate
        }
        this.onLoadData(params)
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }

   
    selectrecknumber =(e)=>{
        this.setState({
           reck_number: e,
        })
    }
render(){
    const columnwithDefs = [
    {
        headerName: "",
        headerClass: ["cellstylegrid", "cellTopHeader1"],
        field: "",
        width: 110,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: "cellstylegrid",
        children: [
            {
                headerName: "Rake Number",
                field: "reck_number",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Rake Code",
                field: "reck_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Rake Category",
                field: "for_code_max_qty",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Stockyard",
                field: "stockyard",
                width: 100,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Flag",
                field: "reck_flag",
                width: 70,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "From Date",
                field: "from_date",
                width: 120,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false,
                valueGetter:function(params){
                    if(params.data.from_date != "" && params.data.from_date != undefined && params.data.from_date != null){
                        return getHyphenDDMMMYYYY(params.data.from_date)
                    }else{
                        return ""
                    }
                }
            },
            {
                headerName: "To Date",
                field: "to_date",
                width: 120,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false,
                valueGetter:function(params){
                    if(params.data.to_date != "" && params.data.to_date != undefined && params.data.to_date != null){
                        return getHyphenDDMMMYYYY(params.data.to_date)
                    }else{
                        return ""
                    }
                }
            },
            {
                headerName: "Rake Type",
                field: "reck_type",
                width: 100,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Rake Quantity",
                field: "reck_qty",
                width: 120,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Plan Quantity",
                field: "reck_plan_qty",
                width: 120,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Group",
                field: "group_code",
                width: 90,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
        ]   
        
    },
    {
        headerName: "Rake City",
        headerClass: ["cellstylegrid", "cellTopHeader"],
        field: "",
        width: 110,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: "cellstylegrid",
        children: [
            {
                headerName: "FOR Code",
                field: "for_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 90,
                editable:false,
                filter: "agSetColumnFilter"
            },
            
        ]   
        
    },
    {
        headerName: "Rake Model Quantity Limits",
        headerClass: ["cellstylegrid", "cellTopHeader"],
        field: "",
        width: 110,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: "cellstylegrid",
        children: [
            {
                headerName: "Model Code",
                field: "model_catg",
                width: 110,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Flag",
                field: "model_flag",
                width: 80,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Minimum Quantity",
                field: "for_code_min_qty",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Maximum Quantity",
                field: "for_code_max_qty",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Plan Quantity",
                field: "for_code_plan_qty",
                width: 110,
                editable:false,
                filter: "agSetColumnFilter"
            },
            
        ]   
        
    },
    {
        headerName: "",
        headerClass: ["cellstylegrid", "cellTopHeader"],
        field: "",
        width: 110,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: "cellstylegrid",
        children: [
            {
                headerName: "Loading Terminal",
                field: "",
                width: 140,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Destination",
                field: "",
                width: 120,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "LSP Code",
                field: "",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "LSP Name",
                field: "",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            
        ]   
        
    }  
        
];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rake Initiation Data</span>
                            </h5>
                        </div>

                        <div className='col-xl-12 col-lg-12'>
                            <div className="row card-body" style={{ marginLeft: "0px", paddingBottom: "0px" }}>
                            <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>Rake Number:</label>
                                        <Select
                                            placeholder={"Select Rake Number"}
                                            closeMenuOnSelect={true}
                                            value={this.state.reck_number}
                                            onChange={this.selectrecknumber.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.reckNumberoptions} required
                                        />
                                </div>
                                {/* <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>Rake Initiation Date:</label>
                                    <Datetime
                                        value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        name="startDate"
                                        onChange={this.handlerStartDateTime.bind(this)} />
                                </div> */}
                                {/* <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>To Date</label>
                                    <Datetime
                                        value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        onChange={this.handlerEndDateTime.bind(this)} />
                                </div> */}
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ position: "absolute", right: "20px", borderRadius: "5px" }} className="mt-30p btn btn-arms" onClick={this.onClickFilterData.bind(this)}>Submit</button>
                                </div>

                            </div>

                        </div>


                       
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}