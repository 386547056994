import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class TemporaryLoadDetails extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            reck_number:"",
            reckNumberoptions:[],
        };
    }
   
    componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
        redirectURL.post("/arms/getdistinctrecknumbers",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var resp = response.data.reckList1
            var reckNumberoptions =[]
            resp.map((e)=>{
                reckNumberoptions.push({
                    value:e,
                    label:e
                })
            })
            this.setState({
                reckNumberoptions : reckNumberoptions
            })
        }
        })
       this.onLoadpageData()
        
       
    };
    onLoadpageData = ()=>{
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var reck_number = this.state.reck_number.value
        var params={}
        if(reck_number != undefined && reck_number != null && reck_number != ""){
            params.reck_number = reck_number
        }
        redirectURL.post('/arms/gettemporaryloaddetails',params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var records = response.data;
            if(records.length > 0){
                this.setState({
                    rowData:records,
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }else{
                this.setState({
                    rowData:[],
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }
        }
          })
          .catch(function (error) {
            console.log(error);
        });
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }
    selectrecknumber =(e)=>{
        this.setState({
           reck_number: e,
        })
    }
    onClickFilterData = ()=>{
        this.onLoadpageData()
    }

render(){
    const columnwithDefs = [
        {
            headerName: "LDLN Load Number",
            field: "LDLN_LOAD_NUMBER",
            width: 100,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "LDLN Line Number",
            field: "LDLN_LINE_NUMBER",
            width: 130,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "LDLN Dealer Code",
            field: "LDLN_DELR_CODE",
            width: 120,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "LDLN Colo Code",
            field: "LDLN_COLO_CODE",
            width: 100,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "LDLN FOR Code",
            field: "LDLN_FOR_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN DLDS Outlet Code",
            field: "LDLN_DLDS_OUTLET_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Spec Code",
            field: "LDLN_SPEC_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN PMOD Code",
            field: "LDLN_PMOD_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Price Parameter",
            field: "LDLN_PRICE_PARAMETER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Planned AMT",
            field: "LDLN_PLANNED_AMT",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN FOR Code Immediate",
            field: "LDLN_FOR_CODE_IMMEDIATE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Colour Type",
            field: "LDLN_COLOUR_TYPE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN INV Type",
            field: "LDLN_INV_TYPE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN SModel Code",
            field: "LDLN_SMODEL_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Body Colo Code",
            field: "LDLN_BODY_COLO_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN BVEH Code",
            field: "LDLN_BVEH_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN ATYP Code",
            field: "LDLN_ATYP_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN PMTY Code",
            field: "LDLN_PMTY_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN LC Serial Number",
            field: "LDLN_LC_SRL_NUMBER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Plant Number",
            field: "LDLN_PLANT_NUMBER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Returned Indicator",
            field: "LDLN_RETURNED_INDICATOR",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },
        {
            headerName: "LDLN Engine Chasis Number",
            field: "LDLN_ENGI_CHASSIS_NUMBER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN DBOR Number",
            field: "LDLN_DBOR_NUMBER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN DBOL Allotment Type",
            field: "LDLN_DBOL_ALLOTMENT_TYPE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN DBOL Allotment City",
            field: "LDLN_DBOL_ALLOTMENT_CITY",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN DBOL Allotment Priority",
            field: "LDLN_DBOL_ALLOTMENT_PRIORITY",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN DBOL Allotment Duplicate",
            field: "LDLN_DBOL_ALLOTMENT_DUPLICATE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN Allocation Date",
            field: "LDLN_ALLOCATION_DATE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN Employee Indicator",
            field: "LDLN_EMPLOYEE_INDICATOR",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN Quote Override Indicator",
            field: "LDLN_QUOTA_OVERRIDE_INDICATOR",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN Inv Count",
            field: "LDLN_INV_COUNT",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN AR4A Number",
            field: "LDLN_AR4A_NUMBER",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN OCTG Code",
            field: "LDLN_OCTG_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "LDLN Selection Code",
            field: "LDLN_SELECTION_CODE",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        },{
            headerName: "Order Number",
            field: "ORDER_NO",
            width: 140,
            filter: "agSetColumnFilter",
            // cellRenderer:'updateItem',
            editable:false
        }
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Temporary Load Details</span>
                            </h5>
                        </div>
                        <div className='col-xl-12 col-lg-12'>
                            <div className="row card-body" style={{ marginLeft: "0px", paddingBottom: "0px" }}>
                            <div className="col-xl-3 col-lg-3 form-group armsinputbox">
                                    <label>Rake Number:</label>
                                        <Select
                                            placeholder={"Select Rake Number"}
                                            closeMenuOnSelect={true}
                                            value={this.state.reck_number}
                                            onChange={this.selectrecknumber.bind(this)}
                                            style={{ borderRadius: "0px" }}
                                            options={this.state.reckNumberoptions} required
                                        />
                                </div>
                                <div className="form-group col-xl-2 col-lg-2">
                                    <button type="button" style={{ position: "absolute", right: "20px", borderRadius: "5px" }} className="mt-30p btn btn-arms" onClick={this.onClickFilterData}>Submit</button>
                                </div>

                            </div>

                        </div>
                        <div className="grid-container">
                            <div id="myGrid" className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                        </div>
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}