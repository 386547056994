/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { withRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { forEach, functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class RailScheduling extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            remarks_data:[],
            target_summary_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            rowData7:[],
            option:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "option1" },
            targetoption:{label: "PPC + Stock", value: "3"},
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            pinnedRowData4: [],
            plan_month:"",
            plan_month_upload:"",
            targettabactive:"",
            railtargettabstatusactive:"",
            summarydashboardtabactive:"",
            railschedulingtabstatusactive:"",
            targetpreparationshow:"show-m",
            railtargetshow:"show-n",
            summarydashboardshow:"show-n",
            railschedulingshow:"show-n",
            pagetitle :"Stage 1 PPC + Stock Target Preparation Data",
            week:{ label: "All Month", value: "all" },
            weekheadername:"Month",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            clusterwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            clusterwiseshow:"show-n",
            plan_type:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
            location:{label: "Haryana", value: "H" },
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            mode:{label:"Overall",value:"overall"},
            mode_header:"",
            detailCellRendererParams:{},
            remarks_history_cols:[],
            rail_target_summary :[
                {
                    headerName: "Cluster",
                    field: "cluster",
                    width: 100,
                    editable:false,
                    filter: "agSetColumnFilter",  
                    cellStyle:function(params){
                        if(params.data.cluster == "Total" || params.data.cluster == "total"){
                            return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                        }
                    }
                },
                { 
                    headerName: "Total Plan",
                    headerClass: ["cellstylegrid", "cellTopHeader"],
                    field: "",
                    cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 100,
                    chartDataType: "excluded",
                    children:[
                        {
                            headerName: "MSIL",
                            field: "vins_countmsil",
                            width: 80,
                            filter: true,
                            resizable: true,
                            // pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            field: "vins_countgujarat",
                            width: 80,
                            filter: true,
                            resizable: true,
                            // pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            field: "vins_countbidadi",
                            width: 80,
                            filter: true,
                            resizable: true,
                            // pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            field: "total",
                            width: 80,
                            filter: true,
                            resizable: true,
                            // pinned:"left",
                            valueGetter:function(params){
                                
                                    return parseInt(params.data.vins_countmsil) + parseInt(params.data.vins_countgujarat) + parseInt(params.data.vins_countbidadi) 
                               
                            },
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }
                    ]
                },
                {
                    headerName: "WEEK 1",
                    headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countmsil_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countgujarat_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countbidadi_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countTotal_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }                                                
                    ]
                },
                {
                    headerName: "WEEK 2",
                    headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countmsil_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countgujarat_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countbidadi_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countTotal_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }    
                    ]
                },
                {
                    headerName: "WEEK 3",
                    headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countmsil_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countgujarat_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countbidadi_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countTotal_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }   
                    ]
                },
                {
                    headerName: "WEEK 4",
                    headerClass: ["cellstylegridTPOther", "cellTopHeader"],
                    field: "",
                    cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countmsil_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countgujarat_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countbidadi_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countTotal_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }  
                    ]
                },
            ]
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        await loadDateTimeScript();
        var month_year = moment.parseZone(new Date()).format("YYYY-MM")
        // console.log(month_year,"244")
        this.setState({
            plan_month_rail : month_year,
            plan_month_target : month_year,
            month_rakescheduling : month_year
        })
        var rake_scheduling_month = this.state.month_rakescheduling
       this.onClickGetRakeSchedulingData()   
    }
    onClickHideAll1() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    handlerStartDateTimeRS = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            month_rakescheduling: plan_month
        });
        // this.getRevisionDataforRakescheduling(plan_month)
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            bulkslide3:"",
            bulkslide4:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    onClickGetRakeSchedulingData = () => {
        this.setState({
            loadshow : "show-m",
            overly :"show-m"
        })
        var reqParams ={
            "plan_month":moment.parseZone(new Date(this.state.month_rakescheduling)).format("MMM-YYYY"),
        }
        // var revision_data = this.state.revisionforRS.value
        // if(revision_data != undefined && revision_data != null && revision_data != ""){
        //     reqParams.revision = revision_data
        // }
        console.log(reqParams,"1731")
        redirectURL.post("/arms/getRakeschedulingdata",reqParams,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(async(response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"loadprocess");
            this.setState({
                rowData6 : response.data,
                overly:"show-n",
                loadshow:"show-n",
            })
        }
        })  
        
            
    }
    
    onCellClickedGrid(e)
{
    if(e.colDef.field == "history_view_data"){
        this.setState({
            detailCellRendererParams:{
                detailGridOptions: {
                    columnDefs: [
                        {
                            headerName: "View",
                            field: "details",
                            width: 170,
                        editable:false,
                        filter: "agSetColumnFilter",
                        cellRenderer:function(params){
                            
                            let basestring = params.data.transaction_id;
                            var encryptedstring = window.btoa(basestring)
                            if(params.data.approved == 1 || params.data.processed == 1){
                            var htmloption = '<a href="/railschedulingsummary/'+encryptedstring+'" class="btn btn-arms label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</a>';
                            return htmloption
                            }else{
                                var htmloption = '<a href="/railschedulingsummary/'+encryptedstring+'" class="btn btn-arms label label-success disabled" disabled="true" id="buttonStyling"><i class="icofont icofont-eye"></i>View</a>';
                            return htmloption 
                            }
                        }
                        },
                        {
                            headerName: "View",
                            field: "rail_scheduling_approval",
                            width: 170,
                        editable:false,
                        filter: "agSetColumnFilter",
                        cellRenderer:function(params){
                            if(params.data.approved == 1){
                                var htmloption = '<button class="btn btn-success label label-success disabled" disabled="true" id="buttonStyling"><i class="icofont-play-circle"></i>Approved</button>'
                                return htmloption
                            }else if(params.data.processed == 0 || params.data.processed == 2){
                                var htmloption = '<button class="btn btn-success label label-success disabled" disabled="true" id="buttonStyling"><i class="icofont-play-circle"></i>Approve</button>'
                                return htmloption
                            }
                            else{
                                var htmloption = '<button class="btn btn-success label label-success" id="buttonStyling"><i class="icofont-play-circle"></i>Approve</button>'
                                return htmloption
                            }
                           
                        }
                        },
                        {
                            headerName: "Request Id",
                            field: "request_id",
                            width: 170,
                        editable:false,
                        filter: "agSetColumnFilter"
                        },
                        {
                            headerName: "Transaction Id",
                            field: "transaction_id",
                            width: 160,
                        editable:false,
                        filter: "agSetColumnFilter",
                        },
                        {
                            headerName: "Plan Month",
                            field: "plan_month",
                            width: 120,
                        editable:false,
                        filter: "agSetColumnFilter",
                        },
                        {
                            headerName: "Total Cars Count",
                            field: "total_cars_count",
                            width: 160,
                        editable:false,
                        filter: "agSetColumnFilter",
                        },
                        {
                            headerName: "Processed",
                            field: "processed",
                            width: 180,
                        editable:false,
                        filter: "agSetColumnFilter",
                        valueGetter:function(params){
                            if(params.data.processed == 0)
                            {
                                return "SCHEDULER IN PROGRESS";
                            }
                            else if(params.data.processed == 1)
                            {
                                return "SCHEDULING COMPLETE";
                            }
                            else if(params.data.processed == 2)
                            {
                                return "SCHEDULING FAILED";
                            }
                            else{
                                return "SCHEDULER FAILED"
                            }
                        }
                        },
                        {
                            headerName: "Approved By",
                            field: "approved_by",
                            width: 180,
                        editable:false,
                        filter: "agSetColumnFilter",
                        },
                        {
                            headerName: "Approved On",
                            field: "approved_on",
                            width: 180,
                        editable:false,
                        filter: "agSetColumnFilter",
                        valueGetter:function(params){
                            if(params.data.approved_on != undefined && params.data.approved_on != null && params.data.approved_on != ""){
                                return getHyphenDDMMMYYYY(params.data.approved_on)
                            }else{
                                return ""
                            }
                        }
                        },
                    ],
                    overlayNoRowsTemplate: 'No rows to show',
                    onCellClicked:(params) =>{
                        
                            if(params.colDef.field == "rail_scheduling_approval"){
                                if(params.data.processed == 1 || params.data.processed == "1"){
                                        this.setState({
                                            loadshow :"show-m",
                                            overly :"show-m"
                                        })
                                        var request_id = params.data.request_id
                                        var transaction_id = params.data.transaction_id
                                        var username = localStorage.getItem("username")
                                        var params ={
                                            request_id : request_id,
                                            username : username,
                                            transaction_id : transaction_id
                                        }
                                        redirectURL.post("/arms/sendRailschedulingApproval",params,{"headers":
                                        {											
                                            'Content-Type': 'application/json;charset=UTF-8', 
                                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                                    }
                                    }).then((response)=>{
                                        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                            this.setState({
                                                show:true,
                                                basicTitle:"Session Expired",
                                                basicType:"warning",
                                                overly: "show-n",
                                                loadshow: "show-n"
                                            })
                                        }else{
                                            if(response.data.message == "success"){
                                                this.setState({
                                                    loadshow : "show-n",
                                                    overly : "show-n",
                                                    show : "true",
                                                    basicTitle : "Successfully Approved",
                                                    basicType:"success"
                                                })
                                                this.onClickGetRakeSchedulingData()
                                            }else{
                                                this.setState({
                                                    loadshow : "show-n",
                                                    overly : "show-n",
                                                    show : "true",
                                                    basicTitle : "Not Approved",
                                                    basicType:"warning"
                                                })
                                            }
                                        }
                                        })
                                }else{
                                    this.setState({
                                        loadshow : "show-n",
                                        overly : "show-n",
                                        show : "true",
                                        basicTitle : "Can Be Approved Only when Scheduler is Complete",
                                        basicType:"warning"
                                    })
                                }
                        }
                        
                    }
                },
                getDetailRowData: (params)=>{
                    var formData ={
                        request_id :params.data.request_id,
                        plan_type : params.data.plan_type
                    }
                    redirectURL.post("/arms/getDetailedtransactionData",formData,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        var resp = response.data;
                        console.log(resp,"resp")
                        // this.setState({
                        //     viewData:resp.data,
                        // })
                        params.successCallback(resp)
                    }
                    })
                    
                },
                masterDetail: true,
            },
                
            })
            console.log(e.colDef.field );
            if(e.colDef.field == 'history_view_data')
            {
                e.node.setExpanded(!e.node.expanded);
            }
            else{
                e.node.setExpanded(false);
            }
    }
    if(e.colDef.field == "view_target_details"){
        this.setState({
            bulkslide3: 'slider-translate-60p',
			overly: "show-m"
        })
        var request_id = e.data.request_id
        var plan_type = e.data.plan_type
        console.log(request_id,"1983")
         var params = {
            request_id: request_id,
            plan_type : plan_type
         }
         redirectURL.post("/arms/gettargetdetailsforrailscheduling",params,{"headers":
         {											
             'Content-Type': 'application/json;charset=UTF-8', 
             'Authorization': `Bearer ${localStorage.getItem("token")}`
     }
     }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"1989")
            var resp = eval(response.data[0].clustershare)
            console.log(resp,"2448")
            var rowData = resp 
            var columns =[]
            // columns.push({
            //     headerName:"FOR Code",
            //     field:"city_code",
            //     editable:false,
            //     width:150
            // })
            columns.push({
                headerName:"Unloading Cluster",
                field:"unloading_cluster",
                editable:false,
                width:150
            })
            var rdData =[]
            if(rowData.length > 0){
                var plantNames  = groupBy(rowData,rData=>rData.loading_cluster)
                plantNames.forEach((pname,pkey)=>{
                        columns.push({
                            headerName:pkey,
                            field:pkey,
                            editable:false,
                            width:150,
                            valueGetter:function(params){
                                if(["Bidadi", "Haryana", "Gujarat"].includes(pkey)){
                                    if(params.data[pkey] == undefined){
                                        return 0
                                    }else{
                                        return params.data[pkey]
                                    }
                                }
                            }
                        })
                })
                var planData = groupBy(rowData,rdata=>rdata.city_code)
                planData.forEach((pMonths,mKey)=>{
                    let temp_row = {"city_code": mKey}
                    pMonths.map((pm)=>{
                        temp_row[pm.loading_cluster] = pm.cars
                        temp_row['unloading_cluster'] = pm.unloading_cluster
                    });
                    rdData.push(temp_row);
                })
            }
            console.log(columns,"2473")
            console.log(rdData,"2486")
            // console.log(resp,"1994")
            if(resp != undefined && resp != ""){
                this.setState({
                    remarks_data : rdData,
                    remarks_history_cols :columns
                })
            }else{
                this.setState({
                    remarks_data :[]
                })
            }
        }
         })
    }
    if(e.colDef.field == "run_plan"){
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var request_id = e.data.request_id
        var plan_month = e.data.plan_month
        var week = e.data.week
        var revision = e.data.revision
        var stage = e.data.stage
        var approval_by = localStorage.getItem("username")
        var reqparams = {
            request_id : request_id,
            plan_month : plan_month,
            week : week,
            revision : revision,
            stage : stage,
            approval_by : approval_by
        }
        redirectURL.post("/arms/sendrunplanstatus",reqparams,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data.data,"1980")
            var resp = JSON.parse(response.data.data)
            console.log(resp,"2456")
            var message = resp.message 
            if(message == "Success" || message == "success" || message == "Successfully Updated"){
                this.setState({
                    basicTitle : "Rake Scheduling In Progress..!!",
                    basicType:"success",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
                this.onClickGetRakeSchedulingData()
            }else{
                this.setState({
                    basicTitle : message,
                    basicType : "warning",
                    show:true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }
        })
    }
}
closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}
    render() {
        const columnwithDefs6=[
            {
                 pinned:"left",
                headerName: "View Details",
                field: "history_view_data",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-arms label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                    return htmloption
                } 
            },{
                pinned:"left",
                headerName: "View Target Details",
                field: "view_target_details",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-danger label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                    return htmloption
                } 
            },
            {
                pinned:"left",
                headerName: "Generate Plan",
                field: "run_plan",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    if(params.data.approved == 1){
                        var htmloption = '<button class="btn btn-success label label-success disabled" disabled="true" id="buttonStyling"><i class="icofont-play-circle"></i>Run New Plan</button>';
                    return htmloption
                    }else{
                        var htmloption = '<button class="btn btn-success label label-success" id="buttonStyling"><i class="icofont-play-circle"></i>Run New Plan</button>';
                    return htmloption
                    }
                    
                } 
            },
            {
                headerName: "Request Id",
                field: "request_id",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Month",
                field: "plan_month",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Stage",
                field: "stage",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Week",
                field: "week",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                
            },
            {
                headerName: "Revision",
                field: "revision",
                width:140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName : "Approved By",
                field:"approved_by",
                width:160,
                resizable : true,
                editable : false,
                
            },
            {
                headerName : "Approved On",
                field:"approved_on",
                width:160,
                resizable : true,
                editable : false,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYY(params.data.approved_on)
                }
            },
            // {
            //     headerName : "Plan Type",
            //     field:"plan_type",
            //     width:160,
            //     resizable : true,
            //     editable : false,
                
            // }
        ]
        
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
               
                <div className="card-arms col-xl-12">
            <div class="card-header-arms">
                <h5>
                    <i className="icofont icofont-train-line cus-i"></i> <span>Rail Scheduling</span>
                    
                </h5>
            </div>
            <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                
                            
                               
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.month_rakescheduling}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTimeRS.bind(this)}
                                    />
                                </div>
                                {/* {(this.state.revisionoptionsforRS.length>0)?
                                    <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Revision:</label>
                                    <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required />
                                    <Select
                                        placeholder={"Select Revision"}
                                        closeMenuOnSelect={true}
                                        value={this.state.revisionforRS}
                                        onChange={this.newFilter.bind(this,"revisionforRS")}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.revisionoptionsforRS} required
                                    />
                                </div>:""
                                } */}
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onClickGetRakeSchedulingData.bind(this)}>Submit</button>

                                </div>
                            </form>
                            <div className="grid-container col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs6}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData6}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    masterDetail={true}
                    onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
            </div>
            </div>
                        </div>
            
        
                </div> 
                
                <div className={"slide-r " + (this.state.bulkslide3)}>
					<h3 className="subHarms">View Target Details 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        // stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				</div>
                
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}

export default withRouter(RailScheduling)

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});