import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import EditmenuAction from './editmenuAction';

var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
 

export default class AdminMenus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Menus",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            parent_code:"",
            menu_code:"",
            menu_title:"",
            menu_description:"",
            groupheader:"show-n",
            menu_icon:"",
            group_heading:"",
            is_group_header:'',
            is_link:'',
            path:"",
            is_active:'',
            is_mobile:'',
            env_show:"prod",
            formaction:"new",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            updateData:"",
            sliderTranslate:'',
            readonly:false,
            allmenus:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                editmenuAction:EditmenuAction
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            pagelinkstatus:'new',
            linkcode:'',
            pagelinks:'',
            sublinks:''


        }
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    logEvent = (actionName) => {
        try {
            if (googleAnalytics.page.enableGA) {
                let label = googleAnalytics.page.action.formSubmittedUpdateMenu;
                if (actionName == "add") {
                    label = googleAnalytics.page.action.formSubmittedAddMenu;
                }
                let pageTitle = this.state.pageTitle;
                let eventOptions = {
                    "category": pageTitle,
                    "action": this.state.eventFormAction,
                    "label": label,
                }
                googleAnalytics.logEvent(eventOptions);

            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }

    componentDidMount(){
        this.logPageView(); // Log page view to GA
        redirectURL.post("/superadmin/menus")
        .then((response) => {
            //console.log("Menus ",response.data)
            this.setState({
                rowData:response.data
            });
        })
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
            sliderTranslate:'',
            sliderTranslatePageLink:'',
            readonly:false
            
		});
		
	}
    onShowMenuForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            readonly:false,
            parent_code:"",
            code:"",
            menu_title:"",
            menu_description:"",
            menu_icon:"",
            is_group_header:"",
            group_heading:"",
            is_link:"",
            path:"",
            is_active:"",
            is_mobile:"",
            env_show:"",
            formaction:"new"
        });
    }
    formHandler = (event) =>{
        event.preventDefault();
        //console.log("IN form handler, this.state.formaction = ", this.state.formaction, event);
        if(this.state.group_heading == '')
        {
            var group_heading= null;
        }
        else{
            var group_heading=this.state.group_heading;
        }
        if(this.state.is_link == '')
        {
            var is_link= false;
        }
        else{
            var is_link=this.state.is_link;
        }
        if(this.state.path == '')
        {
            var pathurl= false;
        }
        else{
            var pathurl=this.state.path;
        }

        var sublinksarr = [];
        if(this.state.sublinks != '')
        {
            if(this.state.sublinks.length > 0)
            {
                this.state.sublinks.map((item) => {
                    sublinksarr.push(item.value)
                })
                
            }
        }
        if(this.state.formaction == 'new')
        {
            this.logEvent("add"); // Log event to GA
            var formdata = {
                parent_code:this.state.parent_code.value,
                code:this.state.menu_code,
                name:this.state.menu_title,
                description:this.state.menu_description,
                icon:this.state.menu_icon,
                is_group_header:this.state.is_group_header,
                group_heading:group_heading,
                is_link:is_link,
                path:pathurl,
                is_active:this.state.is_active,
                is_mobile:this.state.is_mobile,
                env_show:this.state.env_show,
                //pagelinks:this.state.pagelinks,
                sublinks:this.state.sublinks
            }
            //console.log("IN NEW, formdata ", formdata);
            var formurl = "/menus/create";
            redirectURL.post(formurl, formdata)
            .then((response) => {            
                window.location.reload();
                //console.log("Updated Response = ", response);
                this.setState({
                    loadshow:'show-n',
                    sliderTranslate:'',
                    overly:'show-n',
                });
            })
        }
        if(this.state.formaction == 'update')
        {
            this.logEvent("update"); // Log event to GA
            var formdata = {
                parent_code:this.state.parent_code.value,
                code:this.state.menu_code,
                name:this.state.menu_title,
                description:this.state.menu_description,
                icon:this.state.menu_icon,
                is_group_header:this.state.is_group_header,
                group_heading:group_heading,
                is_link:is_link,
                path:pathurl,
                is_active:this.state.is_active,
                is_mobile:this.state.is_mobile,
                env_show:this.state.env_show,
                updateData:this.state.updateData,
                //pagelinks:this.state.pagelinks,
                sublinks:this.state.sublinks
            }
            //console.log("IN update, formdata ", formdata);
            var formurl = "/menus/updateMenu";
            redirectURL.post(formurl, formdata)
            .then((response) => {            
                window.location.reload();
                //console.log("Updated Response = ", response);
                this.setState({
                    loadshow:'show-n',
                    sliderTranslate:'',
                    overly:'show-n',
                });
            })
        }
		
    }

    onShowPageLinkForm(){
        this.setState({
            sliderTranslatePageLink:'slider-translate',
            overly:'show-m',
            readonly:false,
            parent_code:"",
            code:"",
            menu_title:"",
            menu_description:"",
            menu_icon:"",
            is_group_header:"",
            group_heading:"",
            is_link:"",
            path:"",
            is_active:"",
            is_mobile:"",
            env_show:"",
            pagelinkstatus:"new"
        });
    }

    formPageLinkHandler(event){
        event.preventDefault();
        console.log(this.state.pagelinkstatus)
        if(this.state.pagelinkstatus == 'new')
        {
            this.logEvent("add"); // Log event to GA
            var formdata = {
                code:this.state.page_menu_code,
                name:this.state.page_menu_title,
                description:this.state.page_menu_description,
                path:this.state.page_path,
                is_sublink:1
            }
            //console.log("IN NEW, formdata ", formdata);
            var formurl = "/menus/createsublink";
            redirectURL.post(formurl, formdata)
            .then((response) => {            
                window.location.reload();
                //console.log("Updated Response = ", response);
                this.setState({
                    loadshow:'show-n',
                    sliderTranslatePageLink:'',
                    overly:'show-n',
                });
            })
        }
        if(this.state.pagelinkstatus == 'update')
        {
            this.logEvent("update"); // Log event to GA
            var formdata = {
                code:this.state.page_menu_code,
                name:this.state.page_menu_title,
                description:this.state.page_menu_description,
                path:this.state.page_path,
                is_sublink:1
            }
            //console.log("IN update, formdata ", formdata);
            var formurl = "/menus/updateSublink";
            redirectURL.post(formurl, formdata)
            .then((response) => {            
                window.location.reload();
                //console.log("Updated Response = ", response);
                this.setState({
                    loadshow:'show-n',
                    sliderTranslate:'',
                    sliderTranslatePageLink:'',
                    overly:'show-n',
                });
            })
        }
    }

    onShowEditPageLink(params){
        console.log("Edit Params ", params)
        this.setState({
            linkcode:params.data,
            pagelinkstatus:'update'
        });
        this.handlerForm()
        
        this.setState({
            menu_code:params.data.code,
            menu_title:params.data.name,
            menu_description:params.data.description,
            path:params.data.path,
            sliderTranslatePageLink:'slider-translate',
			overly:'show-m',
            readonly:true
        });
        // this.setState(
		// 	{ parent_code },
		// 	() => console.log(`Option selected:`, this.state.parent_code)
		//   );

    }

    onShowEditMenu(params){
        console.log("Edit Params ", params)
        this.setState({
            updateData:params.data,
            formaction:'update'
        });
        this.handlerForm()
        if(params.data.parent_code == null)
        {
            var pcode = 'Main'
        }
        else
        {
            var pcode = params.data.parent_code.charAt(0).toUpperCase() + params.data.parent_code.slice(1);
        }
        var parentcode = {"value":params.data.parent_code,"label":pcode};	
        if(params.data.path == null || params.data.path == false)
        {
            var menupath = ""
        }
        else{
            var menupath = params.data.path;
        }
        // var sublinkarr = []
        // if(params.data.sublinks.length > 0 && params.data.sublinks != undefined)
        // {
        //     params.data.sublinks.map((item) => {
        //         sublinkarr.push({
        //             "value":item
        //         })
        //     })
        // }
        this.setState({
            parent_code:parentcode,
            menu_code:params.data.code,
            menu_title:params.data.name,
            menu_description:params.data.description,
            menu_icon:params.data.icon,
            is_group_header:params.data.is_group_header,
            group_heading:params.data.group_heading,
            is_link:params.data.is_link,
            path:menupath,
            is_active:params.data.is_active,
            is_mobile:params.data.is_mobile,
            env_show:params.data.env_show,
            pagelinks:params.data.pagelinks,
            sublinks:params.data.sublinks,
            sliderTranslate:'slider-translate',
			overly:'show-m',
            readonly:true
        });
        // this.setState(
		// 	{ parent_code },
		// 	() => console.log(`Option selected:`, this.state.parent_code)
		//   );

    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};
	

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
		   
    }
    
    onShowMenus = async (params) => {
        if(params != 0)
        {
            //console.log("Menus ", e)

            var menuparams = {
                parentmenu:params.data.menut_alias
            }
            await this.setState({
					
				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Actions",field: "is_children", 
							
                                cellRendererSelector:function(param){
                                    //console.log("is child ", param.data.is_children)
                                    if(param.data.is_children == 1)
                                    {
                                        var rendComponent = {
                                            component: 'submenus'
                                        };
                                        return rendComponent;
                                    }
                                    else{
                                        var rendComponent = {
                                            component: 'empty'
                                        };
                                        return rendComponent;
                                    }
                                },
								width:100,
								suppressSizeToFit: true
							},
							{ headerName: "Menu Title",field: "menut_title", width:150,resizable: true },
							{ headerName: "Department Code",field: "dept_code", width:180,resizable: true },
							{ headerName: "Path",field: "path",resizable: true },
                            { headerName: "Type",field: "type", width:180,resizable: true },	
                            { headerName: "Is Children",field: "is_children", width:180,resizable: true },		
                            					
						]							
					},
					getDetailRowData: function(param) {
                        var menuparam = {
                            parentmenu:param.data.menut_alias
                        }
                        redirectURL.post("/superadmin/submenus", menuparam)                        
                        .then(async (resp) =>{
							param.successCallback(resp.data);
						});
						
					}
				}
			});
			
			if(params.column.colId == 'is_children')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
            }
            
        }
        
    }

	handlerForm = parent_code => {
		console.log("Select", parent_code);
		this.setState(
			{ parent_code },
			() => console.log(`Option selected:`, this.state.parent_code)
		  );

    }
    
	handlerMulti = sublinks => {
		console.log("Select", sublinks);
		this.setState(
			{ sublinks },
			() => console.log(`Option selected:`, this.state.sublinks)
		  );

    }
	selectOptionsItems() {
        let items = [];     
        //console.log(this.state.trucks);
        items.push({"value":null,"label":"Header Menu"});	
        this.state.rowData.forEach(item => {
            if(item.parent_code == null)
            {
                var pcode = 'Main'
            }
            else
            {
                var pcode = item.parent_code.charAt(0).toUpperCase() + item.parent_code.slice(1);
            }
            items.push({"value":item.code,"label":item.name+" ( "+pcode+" )"});	
        });
        return items;
    }
    
    checkRadioEvent = (event) => {
        var boolValue = event.target.value;

        if (typeof event.target.value == "string" && event.target.name != "env_show") {
            boolValue = (event.target.value.toLowerCase() === 'true');
            
        }

        if ( event.target.name == "is_group_header") {
            let displayGroupHeader = (boolValue) ? "show-m" : "show-n";
            this.setState({groupheader:displayGroupHeader});
        }
        
    	let name = event.target.name;
		//let value = event.target.value;
        this.setState({[name]:boolValue});
        if (this.state.updateData) {
            let newUpdateData = this.state.updateData;
            newUpdateData[name] = boolValue;
            this.setState({updateData:newUpdateData});
        }
    }

    checkGroupRadioEvent = (event) => {
    	let name = event.target.name;
        let value = event.target.value;
        console.log("event.target.value ", event.target.value)
        if(event.target.value == true)
        {
            this.setState({[name]:value, groupheader:"show-m"});
        }
        else{
            this.setState({[name]:value, groupheader:"show-n"});
        }
    }

    OnClickReIndexMenu(){
        redirectURL.post("/superadmin/reindexmenus")
        .then((response) => {
            console.log("Response Indexing ", response.data)
        })
    }

	selectOptionsPagelinks() {
        let items = [];     
        //console.log(this.state.trucks);
        this.state.rowData.forEach(item => {
            if(item.is_sublink == 1)
            {
                items.push({"value":item.path,"label":item.name});	
            }
        });
        return items;
    }
    
    render(){
        const columnDefs = [
            {
                headerName: "Actions",
                field: "code",
                width: 90,
                editable:false,
                filter: "agSetColumnFilter",
                cellRendererSelector:function(params){
                    var rendComponent = {
                        component: 'editmenuAction'
                    };
                    return rendComponent;
                }
            },
            {
                headerName: "Parent Menu",
                field: "parent_code",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
              headerName: "Menu Title",
              field: "name",
              width: 150,
              editable:true,
              filter: "agSetColumnFilter",
            },
            {
                headerName: "Code",
                field: "code",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },

            {
                headerName: "Path URL",
                field: "path",
                width: 200,
                editable:true,
                filter: "agSetColumnFilter",
                valueGetter:function(params)
                {
                    if(params.data.path == null)
                    {
                        return "NA";
                    }
                    else{
                        return params.data.path;
                    }
                }
            },
            {
                headerName: "Description",
                field: "description",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Icon",
                field: "icon",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter"
            },

            {
                headerName: "Is Group Header",
                field: "is_group_header",
                width: 100,
                editable:true,
                filter: "agSetColumnFilter",
            },
        
            {
                headerName: "Group Header Title",
                field: "group_heading",
                width: 150,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Link",
                field: "is_link",
                width: 80,
                editable:true,
                filter: "agSetColumnFilter",
            },

        

            {
                headerName: "Active",
                field: "active",
                width: 80,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "For Mobile",
                field: "is_mobile",
                width: 110,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Environment",
                field: "env_show",
                width: 100,
                editable:true,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Menu Position",
                field: "display_order",
                width: 120,
                editable:true,
                filter: "agSetColumnFilter",
            },
        ]
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>{this.state.pageTitle} </span>
                                       <a href="/superadmin/menuposition" onClick={this.onShowMenuForm.bind(this)} className="btn btn-warning float-right ml-5p">Reorder Menu Position</a>
                                        <button type="button" onClick={this.onShowPageLinkForm.bind(this)} className="btn btn-danger float-right">Add Page Link</button>
                                        <button type="button" onClick={this.onShowMenuForm.bind(this)} className="btn btn-info float-right">Add Menu</button>
                                        <button type="button" onClick={this.OnClickReIndexMenu.bind(this)} className="btn btn-secondary float-right" style={{marginRight:"5px"}}>Reindex Menus</button>
                                        
                                </h5>
				   			</div>
				   			<div className="card-body row">
                               
                                <div className=" col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
									    detailCellRendererParams={this.state.detailCellRendererParams}
									    // enableRangeSelection={false}
                                        //floatingFilter={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressCellFlash={true}
									    masterDetail={true}
                                        
                                    />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
				 			Add/Update Menu
				 		</h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
					
                         <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formHandler}>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Parent Menu *:</label>
                                    <Select name="parent_code" placeholder="Select Parent Menu" className="rselect" value={this.state.parent_code} onChange={this.handlerForm.bind(this)} options={this.selectOptionsItems()} />
					            </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Menu Code *:</label>
                                    <input type="text" readonly={this.state.readonly} name="menu_code" id="menu_code" value={this.state.menu_code} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Code" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Menu Title *:</label>
                                    <input type="text" name="menu_title" id="menu_title" value={this.state.menu_title} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Title" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Description *:</label>
                                    <input type="text" name="menu_description" id="menu_description" value={this.state.menu_description} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Description" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Menu Icon *:</label>
                                    <input type="text" name="menu_icon" id="menu_icon" value={this.state.menu_icon} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Icon" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group row col-xl-12 col-lg-12">
                                    <label className="c-lbl col-xl-12 col-lg-12">Is Group Header *:</label>
                                    <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                        <input id="is_group_header1" onChange={this.checkRadioEvent} 
                                            checked= {(this.state.is_group_header)? true : false} 
                                            name="is_group_header" type="radio" className="form-check-input" value={true} />
                                        <label htmlFor="is_group_header1" className="">Yes</label>
                                    </div>

                                    <div className="radio radio-primary col col-xl-5 col-lg-5">
                                        <input id="is_group_header2" onChange={this.checkRadioEvent} 
                                            checked= {(!this.state.is_group_header)? true : false} name="is_group_header" 
                                            type="radio" className="form-check-input" value={false} />
                                        <label htmlFor="is_group_header2" className="">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className={"row "+(this.state.groupheader)}>
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Group Heading *:</label>
                                    <input type="text" name="group_heading" id="group_heading" value={this.state.group_heading} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Group Heading Title" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="form-group row col-xl-12 col-lg-12">
                                    <label className="c-lbl col-xl-12 col-lg-12">Is Link *:</label>
                                    <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                        <input id="is_link1" onChange={this.checkRadioEvent} name="is_link" 
                                            checked= {(this.state.is_link)? true : false} type="radio" className="form-check-input" value={true} />
                                        <label htmlFor="is_link1" className="">Yes</label>
                                    </div>

                                    <div className="radio radio-primary col col-xl-5 col-lg-5">
                                        <input id="is_link2" onChange={this.checkRadioEvent} name="is_link" 
                                            checked= {(!this.state.is_link)? true : false} type="radio" className="form-check-input" value={false} />
                                        <label htmlFor="is_link2" className="">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Path URL *:</label>
                                    <input type="text" name="path" id="path" value={this.state.path} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="URL" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group row col-xl-12 col-lg-12">
                                    <label className="c-lbl col-xl-12 col-lg-12">Is Active *:</label>
                                    <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                        <input id="is_active1" onChange={this.checkRadioEvent} name="is_active" 
                                            checked= {(this.state.is_active)? true : false} type="radio" className="form-check-input" value={true} />
                                        <label htmlFor="is_active1" className="">Yes</label>
                                    </div>

                                    <div className="radio radio-primary col col-xl-5 col-lg-5">
                                        <input id="is_active2" onChange={this.checkRadioEvent} name="is_active" 
                                            checked= {(!this.state.is_active)? true : false}  type="radio" className="form-check-input" value={false} />
                                        <label htmlFor="is_active2" className="">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="form-group row col-xl-12 col-lg-12">
                                <label className="c-lbl col-xl-12 col-lg-12">Is Available for Mobile *:</label>
                                <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                    <input id="is_mobile1" onChange={this.checkRadioEvent} name="is_mobile"  checked= {(this.state.is_mobile)? true : false} 
                                        type="radio" className="form-check-input" value={true} />

                                    <label htmlFor="is_mobile1" className="">Yes</label>
                                </div>

                                <div className="radio radio-primary col col-xl-5 col-lg-5">
                                    <input id="is_mobile2" onChange={this.checkRadioEvent} name="is_mobile" 
                                        checked = {(!this.state.is_mobile)? true: false} type="radio" className="form-check-input" value={false} />
                                    <label htmlFor="is_mobile2" className="">No</label>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                                <div className="form-group row col-xl-12 col-lg-12">
                                    <label className="c-lbl col-xl-12 col-lg-12">Environment *:</label>
                                    <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                        <input id="env_show1" onChange={this.checkRadioEvent} name="env_show" checked={(this.state.env_show == "prod")?true:""} type="radio" className="form-check-input" value="prod" />
                                        <label htmlFor="env_show1" className="">Production</label>
                                    </div>

                                    <div className="radio radio-primary col ml-10p col-xl-5 col-lg-5">
                                        <input id="env_show2" onChange={this.checkRadioEvent} name="env_show" checked={(this.state.env_show == "test")?true:""} type="radio" className="form-check-input" value="test" />
                                        <label htmlFor="env_show2" className="">Development</label>
                                    </div>
                                </div>
                            </div>
                            {/* Page Links  */}
                            {/* <div className="row">
                                <div className="form-group row col-xl-12 col-lg-12">
                                    <label className="c-lbl col-xl-12 col-lg-12">Is having Page Link *:</label>
                                    <div className="radio radio-primary ml-20p col col-xl-5 col-lg-5">
                                        <input id="pagelinks" onChange={this.checkRadioEvent} name="pagelinks" 
                                            checked= {(this.state.pagelinks)? true : false} type="radio" className="form-check-input" value={true} />
                                        <label htmlFor="pagelinks" className="">Yes</label>
                                    </div>

                                    <div className="radio radio-primary col col-xl-5 col-lg-5">
                                        <input id="pagelinks1" onChange={this.checkRadioEvent} name="pagelinks" 
                                            checked= {(!this.state.pagelinks)? true : false} type="radio" className="form-check-input" value={false} />
                                        <label htmlFor="pagelinks1" className="">No</label>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Page Links *:</label>
                                    <Select name="sublinks" placeholder="Select Page Menus" isMulti={true} className="rselect" value={this.state.sublinks} onChange={this.handlerMulti.bind(this)} options={this.selectOptionsPagelinks()} />
					            </div>
                            </div>

                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <button type="submit" className="btn btn-success" name="saveMenu" id="saveMenu" >Save Menu</button>
                                </div>
                            </div>
                            
                        </form>
				 	</div>
				 </div>


                 {/* Page Sublinks Start */}
                
				 <div className={"slide-r "+(this.state.sliderTranslatePageLink)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
				 			Add/Update Page Links
				 		</h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
                         <form className={" theme-form col-xl-12 col-lg-12 "} onSubmit={this.formPageLinkHandler.bind(this)}>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Menu Code *:</label>
                                    <input type="text" readonly={this.state.readonly} name="page_menu_code" id="page_menu_code" value={this.state.page_menu_code} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Code" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Menu Title *:</label>
                                    <input type="text" name="page_menu_title" id="page_menu_title" value={this.state.page_menu_title} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Menu Title" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Description *:</label>
                                    <input type="text" name="page_menu_description" id="page_menu_description" value={this.state.page_menu_description} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Description" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <label className="c-lbl">Path URL *:</label>
                                    <input type="text" name="page_path" id="path" value={this.state.page_path} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="URL" />
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className="form-group  col-xl-12 col-lg-12">
                                    <button type="submit" className="btn btn-success" name="saveMenu" id="saveMenu" >Save Menu</button>
                                </div>
                            </div>
                            
                        </form>
				 	</div>
				 </div>
                 {/* End */}
                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}