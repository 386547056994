'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from './cellGraphComponent';
import $ from 'jquery';
// import ColumnGroup from './columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";    
import Showlmvalue from "./showlmvalue";
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




export default class RpTransporterServing extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
        //   UpdateButton:UpdateButton,
          commentActions : CommentActions,
          Showlmvalue:Showlmvalue
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide1:"",
        bulkslide2:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
        //   {
        //   statusPanel: "agTotalAndFilteredRowCountComponent",
        //   align: "left"
        //   },
        //   {
        //   statusPanel: "agTotalRowCountComponent",
        //   align: "center"
        //   },
        //   { statusPanel: "agFilteredRowCountComponent" },
        //   { statusPanel: "agSelectedRowCountComponent" },
        //   { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            // {
            // id: "columns",
            // labelDefault: "Columns",
            // labelKey: "columns",
            // iconKey: "columns",
            // toolPanel: "agColumnsToolPanel"
            // },
            // {
            // id: "filters",
            // labelDefault: "Filters",
            // labelKey: "filters",
            // iconKey: "filter",
            // toolPanel: "agFiltersToolPanel"
            // }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-m",
        loadshow:"show-m",
        trnsportersList : [],
        statesList : [],
        vehicleList: [],
        showCalendar : "show-m",
        showGridData : "show-m",
        transdata:[],
        zonesList:[],
        zoneStates:[],
        sliderType:1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        filter_start_date : "",
        filter_end_date : "",
        valid_from:"",
        valid_to:"",
        data_valid_from:"",
        data_valid_to:"",
        data_type:1,
        regularActive : "activet",
        truckActive : "",
        courierActive : "",
        transporter_code : "",
        transporterServing : [],
        source : [],
        destination : [],
        transportersList : [],
        addloading_plant:"",
        loading_plantdetails:[],
        addtransporter_code:'',
        transporter_codedetails:[],
        addloading_station:'',
        loading_stationdetails:[],
        addunloading_station:'',
        unloading_stationdetails:[],
        transporter_code2:'',
        loading_station2:'',
        loading_plant2:'',
        unloading_station2:'',
        category_for_edit:"",
        category_options:[],
        type_of_route_for_edit:"",
        type_of_route_options : [],
        category_for_add : "",
        type_of_route_for_add:"",
        transit_type_for_add:"",
        transit_type_for_edit:""


        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this)
    this.onLoadShowData = this.onLoadShowData.bind(this);
    this.createColumns = this.createColumns.bind(this);
    this.createRows = this.createRows.bind(this);
    this.checkFileData = this.checkFileData.bind(this);

  }

async componentDidMount(){
    document.body.addEventListener("click", this.handleClickOutside);
    loadDateTimeScript();
    var currentDate = moment.parseZone().format("YYYY-MM-DD");
    
    //console.log(currentMonth,"currentMonth")
    await redirectURL.post("/arms/gettransporter",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(async(response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var resp = response.data;
        var loading_plant = resp.loading_clusters;
         var transporter_code = resp.transporter_codes;
         var unloading_station = resp.unloading_clusters;
         var category_list = resp.distinct_category
         console.log(unloading_station,"212")
         var transporter_codedetails = []
         var loading_stationdetails = []
         var unloading_stationdetails = []
        transporter_code.map((e)=>{
            transporter_codedetails.push({
                value : e,
                label : e
            })
        })
        loading_plant.map((e)=>{
            loading_stationdetails.push({
                value : e,
                label : e
            })
        })
        console.log(loading_stationdetails,"238")
        unloading_station.map((e)=>{
            unloading_stationdetails.push({
                value : e,
                label : e
            })
        })
        var category_options = []
        if(category_list.length >0 ){
            category_list.map(e => {
                category_options.push({label:e , value:e})
            })
        }   
            this.setState({
                transporter_codedetails : transporter_codedetails,
                loading_stationdetails : loading_stationdetails,
                unloading_stationdetails : unloading_stationdetails,
                category_options : category_options
            })            
        }
    })
    await this.onLoadShowData();
}
onLoadShowData = async() =>{
    this.setState({
        overly : "show-m",
        loadshow : "show-m",
    })
    var transporter_code = this.state.transporter_code;
    var params = {
        transporter_code : transporter_code
    }
    await redirectURL.post("/arms/gettransporterserving",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response,"response")      
        if(response.data.length > 0)
        {
            this.setState({
                rowData:response.data,
                overly : "show-n",
                loadshow : "show-n",
                // transportersList : response,
                // transporter_code : response[0].transporter_code
            }) 
            // $("#transporter_code").val(response[0].transporter_code);           
        }
    }
    }).catch((e)=>{
        console.log(e);
    })
    // await this.onLoadShowData();

    //     this.setState({
    //         rowData:response,
    //         // transporterServing : response.data.transporterServing,
    //         // source : response.data.source,
    //         // destination : response.data.destination,
    //         // overly : "show-n",
    //         // loadshow : "show-n",
    //     })
    // }).catch((e)=>{
    //     console.log(e);
    // })

    await this.createColumns();
    await this.createRows();
} 
createColumns(){   
    var transporterServing = this.state.transporterServing;
    var source = this.state.source;
    var destination = this.state.destination;
    // var groupsource = groupBy(source, rdata => rdata.state);
    //console.log(groupsource,"groupsource")
    var columns = []
    columns.push({
        headerName:"Destinations",
        field:"destination",
        width:180,
        editable:false
    })
    // groupsource.forEach((lc, lckey) =>{
    //     var defs = {}
    //     defs.headerName = lckey;
    //     defs.headerClass = ["cellstylegridB","cellTopHeader"];
    //     var cdefs = [];
    //     lc.map((ste) => {
    //     //  console.log("ste ", ste)
    //         var childdefs = {};
    //         var ss = ste.railyard_name
    //         var dd = ste.railyard_name
    //         childdefs.headerName = ss;
    //         childdefs.field = ss;
    //         childdefs.width = 140;
    //         childdefs.editable = true;                           
    //         childdefs.cellEditor = 'agSelectCellEditor';
    //         childdefs.cellEditorParams = {
    //             values: ["Yes","No"]
    //         };
        
    //         cdefs.push(childdefs)
    //     })
    //     defs.children = cdefs
    //     columns.push(defs)
    // })
    console.log("Columns ", columns)
    this.setState({
        columnwithDefs:columns,
        overly:"show-n",
        loadshow:"show-n",
    })
}
createRows(){   
    var transporterServing = this.state.transporterServing;
    var source = this.state.source;
    var destination = this.state.destination;
    var rowdefs = []
    // destination.map(function(ste){
    //     //console.log(ste,"ste")
    //     var rows = {}
    //     rows.destination=ste.railyard_name;
    //     if(transporterServing.length > 0)
    //     {
            // var transportername = groupBy(transporterServing, rdata => rdata.loading_station);
            // transportername.forEach((trans, transkey) =>{
            //     //console.log(trans,"trans")
            //     trans.map((t) => {  
            //         //console.log("Here t ", t)
            //         if(t.unloading_station == ste.railyard_name)
            //         {
            //             rows[t.loading_station] = t.serving_status
            //         }
            //     })
            // })
        }
       // console.log("rows ", rows)
    //     rowdefs.push(rows)
    // })
//     console.log("rowdefs ", rowdefs)
//     this.setState({
//         rowData:rowdefs,
//         overly:'show-n',
//         load:'show-n',
//     })
// }
onClickShowFilterData(event) {
    event.preventDefault();
    var loading_plant =this.state.addloading_plant.value
    var transporter_code = this.state.addtransporter_code.value
    var loading_station = this.state.addloading_station.value
    var unloading_station  = this.state.addunloading_station.value
    var category = this.state.category_for_add.value
    var type_of_route = this.state.type_of_route_for_add.value
    var transit_type = this.state.transit_type_for_add.value
    var created_by = localStorage.getItem("username")
        var params ={
            loading_plant,
            transporter_code, 
            loading_station, 
            unloading_station,
            category,
            type_of_route,
            transit_type,
            created_by
        }
        if(!["" , undefined , "undefined" , null].includes(loading_plant) && !["" , undefined , "undefined" , null].includes(transporter_code) && !["" , undefined , "undefined" , null].includes(loading_station) && !["" , undefined , "undefined" , null].includes(unloading_station) && !["" , undefined , "undefined" , null].includes(category)&& !["" , undefined , "undefined" , null].includes(type_of_route)&& !["" , undefined , "undefined" , null].includes(transit_type)){
            redirectURL.post("arms/getaddtransporterserving" , params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then(response=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data,'response---')
                if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Data Updated Successfully.",
                            basicType : "success",
                            show : true,
                            rowIds : [],
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide1:"",
                            addloading_plant:"",
                            addtransporter_code:"",
                            addloading_station:"",
                            addunloading_station:"",
                            category_for_add:"",
                            type_of_route_for_add:"",
                            transit_type_for_add:""
                        })
                        // $("#loading_plant").val("")
                        // $("#transporter_code").val("")
                        // $("#loading_station").val("")
                        //  $("#unloading_station").val("")
                        this.componentDidMount();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Data Already Exists",
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide1:""
                        })
                    }
                }
            })        }else{
            this.setState({
                show: true, 
                basicType:'danger', 
                basicTitle:'All fields are mandatory',
            })
        }
        
    
}
onClickShowFilterData2(event) {
    event.preventDefault();
    var loading_plant2 = this.state.loading_plant2.value
    var transporter_code2 =this.state.transporter_code2.value
    var loading_station2 = this.state.loading_station2.value
    var unloading_station2 = this.state.unloading_station2.value
    var transit_type = this.state.transit_type_for_edit.value
    console.log(this.state.category_for_edit , "category_for_edit")
    if(!["", undefined , "undefined" ,null ].includes(this.state.category_for_edit.value)){
        var category = this.state.category_for_edit.value
    }else{
        var category = ""

    }
    if(!["", undefined , "undefined" ,null ].includes(this.state.type_of_route_for_edit.value)){
        var type_of_route = this.state.type_of_route_for_edit.value
    }else{
        var type_of_route = ""

    }
    var rowId = this.state.rowId
        var params ={
            loading_plant:loading_plant2,
            transporter_code:transporter_code2,
            loading_station:loading_station2,
            unloading_station:unloading_station2,
            category : category,
            type_of_route : type_of_route,
            rowId:rowId,
            transit_type:transit_type,
            updated_by:localStorage.getItem("username")
        }
        console.log(params , "parms_1729")
        if(!["" , undefined , "undefined" , null].includes(loading_plant2) && !["" , undefined , "undefined" , null].includes(transporter_code2) && !["" , undefined , "undefined" , null].includes(loading_station2) && !["" , undefined , "undefined" , null].includes(unloading_station2) && !["" , undefined , "undefined" , null].includes(category)&& !["" , undefined , "undefined" , null].includes(type_of_route)&& !["" , undefined , "undefined" , null].includes(transit_type)){
        redirectURL.post("arms/editMasterDetailsfortransporterserving" ,params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(response=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response)
            if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Data update Successfully.",
                        basicType : "success",
                        show : true,
                        rowIds : [],
                        bulkslide2:'',
                        loading_plant2:"",
                        transporter_code2:"",
                        loading_station2:"",
                        unloading_station2:"",

                    })
                    
                    //window.location.reload();
                    this.componentDidMount();
                }
                else
                {
                    this.setState({
                        basicTitle:response.data.message,
                        basicType : "warning",
                        show : true
                    })
                }
            }
            })
        }
    else{
        this.setState({
            show: true, 
            basicType:'danger', 
            basicTitle:'All fields are mandatory',
        })
    }

}

onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
    var sort = [
        {
          colId: 'transporter_name',
          sort: 'asc',
        },
      ];
    this.gridApi.setSortModel(sort);
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}




closeAlert = async () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
    // await window.location.reload();
}

onCellClicked(e)
{
    if(e.colDef.field == "delete"){
        var qry = {
            rowId:e.data._id,
            email:localStorage.getItem("email"),
                            // designation: localStorage.getItem('designation'),
            // user_role: localStorage.getItem('roles'),
        }
        console.log(qry,"243")
        var confirm = window.confirm("Are You Sure! Do you really want to delete?")
        if (confirm == true)
        {
            redirectURL.post("/arms/deletebulkTransitTimesDetailsfortransporterserving",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true,
                        rowIds : [],

                    })
                    //window.location.reload();
                    this.componentDidMount();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true,
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        } 
    }
    if(e.colDef.field == "edit"){
        var data = e.data;
        console.log(data)
        this.setState({
            bulkslide2:"slider-translate-30p",
            overly:"show-m",
            rowId :data._id,
            loading_plant2:{label:data.loading_plant , value : data.loading_plant},
            transporter_code2:{label:data.transporter_code , value : data.transporter_code},
            loading_station2:{label:data.loading_station , value : data.loading_station},
            unloading_station2:{label:data.unloading_station , value : data.unloading_station},
            category_for_edit:{label:data.category , value:data.category},
            type_of_route_for_edit : {label:data.type_of_route , value : data.type_of_route},
            transit_type_for_edit:{label:data.transit_type , value : data.transit_type}
        })
       
    } 
}

onCellUpdateData = (params) => {
    //console.log("Params ", params)
    // var colData = params.data;
    // var objects = Object.keys(colData);
    // var rowTotal = 0;
    // objects.map(function(e){
    //     if(parseInt(params.data[e]).toString() != "NaN")
    //     {
    //         rowTotal = parseInt(rowTotal)+parseInt(params.data[e]); 
    //     }
    // })
    // if(parseInt(rowTotal) > parseInt(100))
    // {
    //     alert("Share Of Business Should not be greater than 100%");
    //     var currentField = params.colDef.field;
    //     params.data[currentField] = 0
    // }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    zoneStates:[],
    sliderType:1,
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    loading_plantdetails:[],
    addtransporter_code:"",
        addloading_station:"",
        addloading_plant:"",
        addunloading_station:"",
        category_for_add:"",
        type_of_route_for_add:"",
        transit_type_for_add:""
    });
    
}
onClickHideAll1(){
    this.setState({
        loadshow:'show-n',
        overly:'show-n',
        sliderRso:"",
        sliderUpdate:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide:"",
        loading_plantdetails:[],
        addtransporter_code:"",
        addloading_station:"",
        addloading_plant:"",
        addunloading_station:"",
        category_for_add:"",
        type_of_route_for_add:"",
        transit_type_for_add:""
    }); 
}
handleClickOutside = (event) => {
    if (!event.target.closest(".slide-r")) { // Close the slider when clicking outside of it 
       this.onClickHideAll(); 
       
   }
};

addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{transporter_name:"",state:"",sob:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        bulkslide1:"slider-translate-30p",
        // rowId : rowId,
        overly:"show-m"
    })

}
selectloading_plant = (e) => {
    console.log(e,"822")
    this.setState({
        addloading_plant: e,
    })
}
selecttransporter_code = (e) => {
    console.log(e,"822")
    this.setState({
        addtransporter_code: e,
    })
}
selectloading_station = (e) => {
    console.log(e,"822")
    this.setState({
        addloading_station: e,
    })
    var params = {
        station: e.value
    }
    redirectURL.post("arms/getdistinctplantsfortransporterservice" , params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var plant_data = response.data
        var loading_plantdetails = []
        if(plant_data.length > 0){
            plant_data.map(e =>{
                loading_plantdetails.push({label:e , value:e})
            })
            this.setState({
                loading_plantdetails:loading_plantdetails,
                addloading_plant:{value:plant_data[0],label:plant_data[0]}
            })
        } 
    }       
    })
}
selectunLoadingstation = (e) => {
    console.log(e,"822")
    this.setState({
        addunloading_station: e,
    })
}
selecttransporter_code2 = (e) => {
    console.log(e,"822")
    this.setState({
        transporter_code2: e,
    })
}
selectloading_station2 = (e) => {
    console.log(e,"822")
    this.setState({
        loading_station2: e,
    })
    var params = {
        station: e.value
    }
    redirectURL.post("arms/getdistinctplantsfortransporterservice" , params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var plant_data = response.data
        var loading_plantdetails = []
        if(plant_data.length > 0){
            plant_data.map(e =>{
                loading_plantdetails.push({label:e , value:e})
            })
            this.setState({
                loading_plantdetails:loading_plantdetails,
                loading_plant2:{value:plant_data[0],label:plant_data[0]}
            })
        }
    }        
    })
}
selectloading_plant2 = (e) => {
    console.log(e,"822")
    this.setState({
        loading_plant2: e,
    })
}
selectunLoadingstation2 = (e) => {
    console.log(e,"822")
    this.setState({
        unloading_station2: e,
    })
}
selecttypeofrouteforedit = (e) => {
    console.log(e,"822")
    this.setState({
        type_of_route_for_edit: e,
    })
}
selecttypeofrouteforadd = (e) => {
    console.log(e,"822")
    this.setState({
        type_of_route_for_add: e,
    })
}
selecttransittypeforadd = (e) => {
    console.log(e,"822")
    this.setState({
        transit_type_for_add: e,
    })
}
selecttransittypeforedit= (e) => {
    console.log(e,"822")
    this.setState({
        transit_type_for_edit: e,
    })
}


selectcategoryforadd = (e) => {
    console.log(e,"822")
    this.setState({
        category_for_add: e,
    })
    var params = {
        "abbrevation":e.value
    }
    redirectURL.post("/arms/gettypeofrouteforcategory",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var routedata = response.data.typeofroutedata
        console.log(routedata,"827")
        var type_of_route_options = []
        routedata.map((e)=>{
            type_of_route_options.push({
                "label":e,
                "value":e
            })
        })
        this.setState({
            type_of_route_options : type_of_route_options,
            type_of_route_for_add :{"value":routedata[0],"label":routedata[0]}
        })
    }
    })
}
selectcategoryforedit = (e) => {
    console.log(e,"822")
    this.setState({
        category_for_edit: e,
    })
    var params = {
        "abbrevation":e.value
    }
    redirectURL.post("/arms/gettypeofrouteforcategory",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var routedata = response.data.typeofroutedata
        console.log(routedata,"827")
        var type_of_route_options = []
        routedata.map((e)=>{
            type_of_route_options.push({
                "label":e,
                "value":e
            })
        })
        this.setState({
            type_of_route_options : type_of_route_options,
            type_of_route_for_edit :{"value":routedata[0],"label":routedata[0]}
        })
    }
    })
}


showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['loading_plant','transporter_code','loading_station','unloading_station','type_of_route','category']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    $(".subH").html("Bulk Upload");
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        zoneStates:[],
        sliderType:1,
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Transporter Name',
                inputName: 'transporter_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Transporter Code',
                inputName: 'transporter_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Zone',
                inputName: 'zone',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Approved SOB',
                inputName: 'sob',
                required: false,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },                   
        ]
    }
    if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        // console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' ||e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
    
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}

onSelectBulkPlants= (location) =>{
    var plants=[];
    try{
        location.map(function(e){
            plants.push(e.value);
        })
        console.log(plants);
        this.setState({
            plant_name : plants
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
} 

handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);

    var filter_start_date = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        filter_start_date:filter_start_date
    });
}

handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var filter_end_date = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        filter_end_date:filter_end_date
    });
}

handlerStartDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var valid_from = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        valid_from:valid_from
    });
}

handlerEndDateTimeForNewTransporter = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    
    var valid_to = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate());
    
    this.setState({
        valid_to:valid_to
    });
}

filterDataByTransporter = async() => {
    var transporter_code = $("#transporter_code").val();
    if(transporter_code !="")
    {
        await this.setState({
            transporter_code : transporter_code
        });
        await this.onLoadShowData();
    }
}
async onClickTab(data_type)
{
    var regularActive = "";
    var truckActive = "";
    var courierActive = "";
    if(data_type == 1)
    {
        regularActive = "activet";
    }
    else if(data_type == 2)
    {
        truckActive = "activet";
    }
    else
    {
        courierActive = "activet";
    }
    await this.setState({
        data_type : data_type,
        regularActive : regularActive,
        truckActive : truckActive,
        courierActive : courierActive,
    });
    await this.onLoadShowData();
}
  render() {
    
    var columnwithDefs = [      
        {
            headerName: "Plant",
            field: "loading_plant",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.trnsportersList,
            },            
        },

        {
            headerName: "Service Provider",
            field: "transporter_code",
            width:140,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: this.state.statesList,
            },
        },         
        {
            headerName: "Source Railyard",
            field: "loading_station",
            width:130,
            filter: true,
            resizable: true,
            editable:false,
        },    
        {
            headerName: "Destination Railyard",
            field: "unloading_station",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },    
        {
            headerName: "Type Of Route",
            field: "type_of_route",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },    
        {
            headerName: "Category",
            field: "category",
            width:90,
            filter: true,
            resizable: true,
            editable:false,
        }, 
        {
            headerName: "Transit Type",
            field: "transit_type",
            width:110,
            filter: true,
            resizable: true,
            editable:false,
        },   
        {
            pinned:"left",
            headerName : "",
            field:"edit",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },                          
        
    ]
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card-arms col-xl-12">
            <div className="card-header-arms">
                 <h5>                
                    <span>Transporter Serving</span> 
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                 </h5> 
                 <h5>
                 <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Add Transporter Serving Data</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                 </h5>
            </div>
            {/* <div className="row col-xl-12 col-lg-12" style={{marginTop:"2%"}}> */}
                {/* <form method="POST" className="form-theme col-xl-12 col-lg-12 row"> */}
                    {/* <div className="col-xl-3 col-lg-3 form-group">
                        <select className="form-control" id="transporter_code">
                            <option value="">Select Transporter</option>
                            {(this.state.transportersList.map(function(t){
                                return <option value={t.transporter_code}>{t.transporter_name}</option>
                            }))}
                        </select>
                    </div> */}
                    {/* <div className="form-group col-xl-2 col-lg-2">
                        <button type="button" className="btn btn-arms" style={{padding:"5px 10px",marginRight:"2%",borderRadius:"5px"}} onClick={this.filterDataByTransporter.bind(this)}>Submit</button>
                    
                    </div> */}
                {/* </form> */}
            {/* </div> */}
            <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Add Transporter Serving Data
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll1.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Service Provider:</label>
                                    <Select
                                                    placeholder={"Service Provider"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addtransporter_code}
                                                    onChange={this.selecttransporter_code.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transporter_codedetails} required
                                                />
                                </div>       
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Source Railyard:</label>
                                    <Select
                                                    placeholder={"Source Railyard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addloading_station}
                                                    onChange={this.selectloading_station.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loading_stationdetails} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                {this.state.loading_plantdetails.length>0 ? 
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addloading_plant}
                                                    onChange={this.selectloading_plant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loading_plantdetails} required
                                                />
                                </div> :""}
                                 
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Destination Railyard:</label>
                                    <Select
                                                    placeholder={"Select Destination Railyard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addunloading_station}
                                                    onChange={this.selectunLoadingstation.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.unloading_stationdetails} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Category:</label>
                                    <Select
                                                    placeholder={"Select Category"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.category_for_add}
                                                    onChange={this.selectcategoryforadd.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.category_options} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Type Of Route:</label>
                                    <Select
                                                    placeholder={"Select Type of Route"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.type_of_route_for_add}
                                                    onChange={this.selecttypeofrouteforadd.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.type_of_route_options} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />            
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transit Type:</label>
                                    <Select
                                                    placeholder={"Select Transit Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.transit_type_for_add}
                                                    onChange={this.selecttransittypeforadd.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "TVP", value: "TVP" },
                                                    { label: "Direct", value: "Direct" }]} required
                                                />            
                                </div>
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit Master Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Service Provider:</label>
                                    <Select
                                                    placeholder={"Service Provider"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.transporter_code2}
                                                    onChange={this.selecttransporter_code2.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.transporter_codedetails} required
                                                />
                                </div>       
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Source Railyard:</label>
                                    <Select
                                                    placeholder={"Source Railyard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loading_station2}
                                                    onChange={this.selectloading_station2.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loading_stationdetails} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loading_plant2}
                                                    onChange={this.selectloading_plant2.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loading_plantdetails} required
                                                />
                                </div> 
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Destination Railyard:</label>
                                    <Select
                                                    placeholder={"Select Destination Railyard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.unloading_station2}
                                                    onChange={this.selectunLoadingstation2.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.unloading_stationdetails} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Category:</label>
                                    <Select
                                                    placeholder={"Select Category"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.category_for_edit}
                                                    onChange={this.selectcategoryforedit.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.category_options} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Type Of Route:</label>
                                    <Select
                                                    placeholder={"Select Type of Route"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.type_of_route_for_edit}
                                                    onChange={this.selecttypeofrouteforedit.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.type_of_route_options} required
                                                    // options={[{ label: "Yes", value: "Yes" },
                                                    // { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transit Type:</label>
                                    <Select
                                                    placeholder={"Select Transit Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.transit_type_for_edit}
                                                    onChange={this.selecttransittypeforedit.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "TVP", value: "TVP" },
                                                    { label: "Direct", value: "Direct" }]} required
                                                />            
                                </div>
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>

        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}


function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
// function groupBy(list, keyGetter) {
//     const map = new Map();
//     list.forEach((item) => {
//          const key = keyGetter(item);
//          const collection = map.get(key);
//          if (!collection) {
//              map.set(key, [item]);
//          } else {
//              collection.push(item);
//          }
//     });
//     return map;
// }
