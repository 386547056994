/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { withRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { forEach, functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class Targetpreparation extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            remarks_data:[],
            target_summary_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            rowData7:[],
            option:{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "option3" },
            targetoption:{label: "PPC + Stock", value: "3"},
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            pinnedRowData4: [],
            plan_month:"",
            plan_month_upload:"",
            targettabactive:"",
            railtargettabstatusactive:"",
            summarydashboardtabactive:"",
            railschedulingtabstatusactive:"",
            targetpreparationshow:"show-m",
            railtargetshow:"show-n",
            summarydashboardshow:"show-n",
            railschedulingshow:"show-n",
            pagetitle :"Stage 1 PPC + Stock Target Preparation Data",
            week:{ label: "All Month", value: "all" },
            weekheadername:"Month",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            clusterwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            clusterwiseshow:"show-n",
            plan_type:{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "3" },
            location:{label: "Haryana", value: "H" },
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            mode:{label:"Overall",value:"overall"},
            mode_header:"",
            detailCellRendererParams:{},
            remarks_history_cols:[],
            bulkuploadsliderforData:"",
            plan_month_for_uplaod:"",
            csvcontentforExports:"",
            file2:"",
            rail_target_summary :[
                {
                    headerName: "Cluster",
                    field: "cluster",
                    width: 100,
                    editable:false,
                    pinned:"left",
                    filter: "agSetColumnFilter",  
                    cellStyle:function(params){
                        if(params.data.cluster == "Total" || params.data.cluster == "total"){
                            return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                        }
                    }
                },
                { 
                    headerName: "Total Plan",
                    headerClass: ["cellstylegrid", "cellTopHeader"],
                    field: "",
                    cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 100,
                    chartDataType: "excluded",
                    children:[
                        {
                            headerName: "MSIL",
                            field: "vins_countmsil",
                            width: 80,
                            filter: true,
                            resizable: true,
                            pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            field: "vins_countgujarat",
                            width: 80,
                            filter: true,
                            resizable: true,
                            pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            field: "vins_countbidadi",
                            width: 80,
                            filter: true,
                            resizable: true,
                            pinned:"left",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            field: "total",
                            width: 80,
                            filter: true,
                            resizable: true,
                            pinned:"left",
                            valueGetter:function(params){
                                
                                    return parseInt(params.data.vins_countmsil) + parseInt(params.data.vins_countgujarat) + parseInt(params.data.vins_countbidadi) 
                               
                            },
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }
                    ]
                },
                {
                    headerName: "WEEK 1",
                    headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countmsil_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countgujarat_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countbidadi_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPFM",
                            field: "vins_countTotal_w1",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPFM",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }                                                
                    ]
                },
                {
                    headerName: "WEEK 2",
                    headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countmsil_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countgujarat_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countbidadi_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPSMG",
                            field: "vins_countTotal_w2",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPSMG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }    
                    ]
                },
                {
                    headerName: "WEEK 3",
                    headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countmsil_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countgujarat_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countbidadi_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPBNG",
                            field: "vins_countTotal_w3",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }   
                    ]
                },
                {
                    headerName: "WEEK 4",
                    headerClass: ["cellstylegridTPOther", "cellTopHeader"],
                    field: "",
                    cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children: [
                        {
                            headerName: "MSIL",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countmsil_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "SMG",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countgujarat_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "BNG",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countbidadi_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        },
                        {
                            headerName: "Total",
                            headerClass:"cellstylegridTPOther",
                            field: "vins_countTotal_w4",
                            width: 80,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPOther",
                            cellStyle:function(params){
                                if(params.data.cluster == "Total" || params.data.cluster == "total"){
                                    return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                                }
                            }
                        }  
                    ]
                },
            ]
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        await loadDateTimeScript();
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var month_year = moment.parseZone(new Date()).format("YYYY-MM")
        // console.log(month_year,"244")
        this.setState({
            plan_month_rail : month_year,
            plan_month_target : month_year,
            month_rakescheduling : month_year
        })
        const { location } = this.props;
        if(!["" , undefined , 'undefined' , null].includes(location.state)){
            const { propName } = location.state;
            this.setState({
                targettabactive:"",
                railtargettabstatusactive:"",
                summarydashboardtabactive:"",
                railschedulingtabstatusactive:"btn-arms",
                targetpreparationshow:"show-n",
                railtargetshow:"show-n",
                summarydashboardshow:"show-n",
                railschedulingshow:"show-m",
                pagetitle:"Stage 1 Rake Schedule",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:"",
                loadshow: "show-m",
                overly: "show-m",
                bidabibulkCheck:0
            })
        }else{
            this.setState({
                targettabactive:"btn-arms",
            })
            this.LoadTargetPreparationData()
        }
        
        var selected_option = this.state.option.value
        // var source_option = this.state.targetoption.value
        // var source_label =  this.state.targetoption.label
        var plan_month = this.state.plan_month_target

        
        var rail_target_month = this.state.plan_month_rail
        var rail_check_month = moment.parseZone(new Date(rail_target_month)).format("MMM-YYYY")
        var week = this.state.week.value
        await this.getRevisionData(rail_target_month,week)
        var target_preparation_month = this.state.plan_month_target
        await this.getRevisionDatafortargetpreparation(target_preparation_month)
        var rake_scheduling_month = this.state.month_rakescheduling
        await this.getRevisionDataforRakescheduling(rake_scheduling_month)
        if(!["" , undefined , 'undefined' , null].includes(location.state)){
            await this.LoadRakeScheduleData()
        }
       
       
    }

    LoadTargetPreparationData = async () => {
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        })
        var selected_option = this.state.option.value
        // var source_option = this.state.targetoption.value
        // var source_label =  this.state.targetoption.label
        var plan_month = this.state.plan_month_target
        var params = {}
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            if(selected_option == "option1"){
                params.option = "1"
            }else if(selected_option == "option2"){
                params.option = "2"
            }else{
                params.option = "3"
            }
            
        }

        // if(source_option != undefined && source_option != null && source_option != "" && source_label != undefined && source_label != null && source_label != ""){
        //     params.source_label = source_label
        //     params.source = source_option
        // }
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = moment.parseZone(new Date(plan_month)).format("MMM-YYYY")
        }
        console.log(params,"240")
        // getRailTargetByMonth
        await redirectURL.post("/arms/gettargetpreparationdata", params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData:[]
                })
            }else{
                // console.log("response", JSON.parse(response.data.data));
                let respData = JSON.parse(response.data.data)
                // console.log("respData", respData);
                var bidabibulkCheck = response.data.bidabibulkCheck
                this.setState({
                    rowData : eval(respData.for_code_target_plan),
                    bidabibulkCheck : parseInt(bidabibulkCheck),
                    loadshow : "show-n",
                    overly :"show-n"
                })
            }
        })
    }

    LoadRakeScheduleData = async() => {
        this.setState({
            overly:"show-m",
            loadshow:"show-m",
        })
        var rake_scheduling_month = this.state.month_rakescheduling
        var reqParams ={
            "plan_month":moment.parseZone(new Date(rake_scheduling_month)).format("MMM-YYYY")
        }
        await redirectURL.post("/arms/getRakeschedulingdata",reqParams,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(async(response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData6:[]
                })
            }else{
                console.log(response.data,"loadprocess");
            
                this.setState({
                    rowData6 : response.data,
                    overly:"show-n",
                    loadshow:"show-n",
                })
            }
        }) 

    }

    LoadSummaryDashboardData = async () => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var plan_month = this.state.plan_month_target
        var selected_option = this.state.plan_type.value
        var source_option = this.state.location.value
        var source_option_label = this.state.location.label
        var mode = this.state.mode.value
        var mode_label = this.state.mode.label
        console.log(mode,mode_label,"990")
        var summary_month = this.state.plan_month_target
        console.log(summary_month,"311")
        var params = {}
    
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            params.option = selected_option
        }
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = moment.parseZone(new Date(plan_month)).format("MMM-YYYY")
        }
        console.log(params,"240")
        var target_carrierCnt = 0;
        var achieve_carrierCnt = 0;
        var balance_carrierCnt = 0;
        var target_railCnt = 0;
        var achieve_railCnt = 0;
        var balance_railCnt = 0;
        var target_roadCnt = 0;
        var achieve_roadCnt = 0;
        var balance_roadCnt = 0;
        var target_totalCnt = 0;
        var achieve_totalCnt = 0;
        var balance_totalCnt = 0;
        var stage3_dom_carrier = 0;
        var stage3_dom_rail = 0;
        var stage3_dom_road = 0;
        var stage3_dom_total = 0;
        var stage3_exp_carrier = 0;
        var stage3_exp_rail = 0;
        var stage3_exp_road = 0;
        var stage3_exp_total = 0;
        var stage3_dom_exp_carrier = 0;
        var stage3_dom_exp_rail = 0;
        var stage3_dom_exp_road = 0;
        var stage3_dom_exp_total = 0;
        var stage2_dom_carrier = 0;
        var stage2_dom_rail = 0;
        var stage2_dom_road = 0;
        var stage2_dom_total = 0;
        var stage2_exp_carrier = 0;
        var stage2_exp_rail = 0;
        var stage2_exp_road = 0;
        var stage2_exp_total = 0;
        var stage2_dom_exp_carrier = 0 ;
        var stage2_dom_exp_rail = 0;
        var stage2_dom_exp_road = 0;
        var stage2_dom_exp_total = 0;
           redirectURL.post("/arms/gettargetsummarydata", params,{"headers":
           {											
               'Content-Type': 'application/json;charset=UTF-8', 
               'Authorization': `Bearer ${localStorage.getItem("token")}`
       }
       }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData3:[],
                    rowData4:[],
                    rowData5:[],
                    rowData7:[],
                    pinnedRowData : [{}],
                    pinnedRowData2 : [{}],
                    pinnedRowData3 :[{}],
                    pinnedRowData4:[{}]
                })
            }else{
                console.log("response", response.data);
                let responseData = JSON.parse(response.data.data)
                console.log(responseData,"250")
                let directresponse = eval(responseData.summary)
                let subscreen2 = eval(responseData.screen_2)
                let subscreen4 = eval(responseData.total_overal)
                let subscreen5 = eval(responseData.other_df)
                let subscreen6 = eval(responseData.overal_df) 
                console.log(subscreen4,"1017")
                console.log(subscreen2,"1011")
                console.log(subscreen5,"810")
                console.log(subscreen6,"812")
                let subscreen3 = eval(responseData.screen_3)
                //console.log(subscreen3,"1013")
                console.log(directresponse,"1002")
                if(directresponse != undefined && directresponse != null && directresponse != "" && subscreen2 != undefined && subscreen2 != null && subscreen2 != "" && subscreen4 != undefined && subscreen4 != null && subscreen4 != "" && subscreen3 != undefined && subscreen4 != null && subscreen4 != "" && subscreen5 != undefined && subscreen5 != null && subscreen5 != ""){
                    if(directresponse.length >0 && subscreen2.length >0 && subscreen4.length >0 && subscreen3.length>0 && subscreen5.length>0){
                 var respData = []
                 var haryanaData = []
                 var gujaratData = []
                 var bidadiData = []
                 var overallData = []
                 var carrierData = []
                 var railData = []
                 var roadData = []
                 var respData2 =[]
                 if(source_option == "G"){
                    gujaratData = directresponse.filter((e)=>e.consigner_code == "gujarat")
                    respData = gujaratData
                 }
                 if(source_option == "H"){
                    haryanaData = directresponse.filter((e)=>e.consigner_code == "msil")
                    respData = haryanaData
                 }
                 if(source_option == "B"){
                    bidadiData = directresponse.filter((e)=>e.consigner_code == "bidadi")
                    respData = bidadiData
                 }
                 if(mode == "overall"){
                    overallData = subscreen4
                    respData2 = overallData
                    console.log(respData2,"1046")
                 }
                 if(mode == "carrier"){
                    carrierData = subscreen2.filter((e)=>e.mode__=="carrier")
                    respData2 = carrierData
                 }
                 if(mode == "road"){
                    roadData = subscreen2.filter((e)=>e.mode__=="road")
                    respData2 = roadData
                 }
                 if(mode == "rail"){
                    railData = subscreen2.filter((e)=>e.mode__=="rail")
                    respData2 = railData
                 }
                //  console.log(gujaratData,haryanaData,bidadiData,respData,"268")
                respData.map((e)=>{
                    target_carrierCnt = e.carrier_target + target_carrierCnt
                })
                respData.map((f)=>{
                    target_railCnt = f.rail_target + target_railCnt 
                })
                respData.map((g)=>{
                    target_roadCnt = g.road_target + target_roadCnt 
                })
                respData.map((h)=>{
                    target_totalCnt = h.total_target + target_totalCnt 
                })
                respData.map((h)=>{
                    achieve_carrierCnt = h.carrier_achieve + achieve_carrierCnt 
                })
                respData.map((h)=>{
                    achieve_railCnt = h.rail_achieve + achieve_railCnt 
                })
                respData.map((h)=>{
                    achieve_roadCnt = h.road_achieve + achieve_roadCnt 
                })
                respData.map((h)=>{
                    achieve_totalCnt = h.total_achieve + achieve_totalCnt 
                })
                respData.map((h)=>{
                    balance_carrierCnt = h.carrier_balance + balance_carrierCnt 
                })
                respData.map((h)=>{
                    balance_railCnt = h.rail_balance + balance_railCnt 
                })
                respData.map((h)=>{
                    balance_roadCnt = h.road_balance + balance_roadCnt 
                })
                respData.map((h)=>{
                    balance_totalCnt = h.total_balance + balance_totalCnt 
                })
                subscreen3.map((g)=>{
                    stage3_dom_carrier = g.carrier_Domestic + stage3_dom_carrier
                })
                subscreen3.map((g)=>{
                    stage3_dom_rail = g.rail_Domestic + stage3_dom_rail
                })
                subscreen3.map((g)=>{
                    stage3_dom_road = g.road_Domestic + stage3_dom_road
                })
                subscreen3.map((g)=>{
                    stage3_dom_total = g.carrier_Domestic + g.rail_Domestic +  g.road_Domestic + stage3_dom_total 
                })
                subscreen3.map((g)=>{
                    stage3_exp_carrier = g.carrier_Export + stage3_exp_carrier
                })
                subscreen3.map((g)=>{
                    stage3_exp_rail = g.rail_Export + stage3_exp_rail
                })
                subscreen3.map((g)=>{
                    stage3_exp_road = g.road_Export + stage3_exp_road
                })
                subscreen3.map((g)=>{
                    stage3_exp_total = g.carrier_Export + g.rail_Export + g.road_Export + stage3_exp_total
                })
                subscreen3.map((g)=>{
                    stage3_dom_exp_carrier = g.carrier_Export + g.carrier_Domestic + stage3_dom_exp_carrier
                })
                subscreen3.map((g)=>{
                    stage3_dom_exp_rail = g.rail_Export + g.rail_Domestic + stage3_dom_exp_rail
                })
                subscreen3.map((g)=>{
                    stage3_dom_exp_road = g.road_Export + g.road_Domestic + stage3_dom_exp_road
                })
                subscreen3.map((g)=>{
                    stage3_dom_exp_total =  g.road_Export + g.road_Domestic + g.rail_Export + g.rail_Domestic + g.carrier_Export + g.carrier_Domestic + stage3_dom_exp_total
                })
                subscreen2.map((j)=>{
                    stage2_dom_carrier = stage2_dom_carrier + j.count_target_carrier_msil + j.count_target_carrier_gujarat + j.count_target_carrier_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_dom_rail = stage2_dom_rail + j.count_target_rail_msil + j.count_target_rail_gujarat
                    + j.count_target_rail_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_dom_road = stage2_dom_road + j.count_target_road_msil + j.count_target_road_gujarat
                    + j.count_target_road_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_dom_total = stage2_dom_total + j.count_target_carrier_msil + j.count_target_carrier_gujarat
                    + j.count_target_carrier_bidadi + j.count_target_rail_msil + j.count_target_rail_gujarat
                    + j.count_target_rail_bidadi + j.count_target_road_msil + j.count_target_road_gujarat
                    + j.count_target_road_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_exp_carrier = stage2_exp_carrier + j.count_achieve_carrier_msil + j.count_achieve_carrier_gujarat + j.count_achieve_carrier_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_exp_rail = stage2_exp_rail + j.count_achieve_rail_msil + j.count_achieve_rail_gujarat
                    + j.count_achieve_rail_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_exp_road = stage2_exp_road + j.count_achieve_road_msil + j.count_achieve_road_gujarat
                    + j.count_achieve_road_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_exp_total = stage2_exp_total + j.count_achieve_carrier_msil + j.count_achieve_carrier_gujarat + j.count_achieve_carrier_bidadi + j.count_achieve_rail_msil + j.count_achieve_rail_gujarat + j.count_achieve_rail_bidadi + j.count_achieve_road_msil + j.count_achieve_road_gujarat + j.count_achieve_road_bidadi
                })
                subscreen2.map((j)=>{
                    stage2_dom_exp_carrier = stage2_dom_exp_carrier + j.balance_carrier_msil
                    + j.balance_carrier_bidadi + j.balance_carrier_gujarat
                })
                subscreen2.map((j)=>{
                    stage2_dom_exp_rail = stage2_dom_exp_rail + j.balance_rail_msil
                    + j.balance_rail_bidadi + j.balance_rail_gujarat
                })
                subscreen2.map((j)=>{
                    stage2_dom_exp_road = stage2_dom_exp_road + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat
                })
                subscreen2.map((j)=>{
                    stage2_dom_exp_total = stage2_dom_exp_total + j.balance_carrier_msil
                    + j.balance_carrier_bidadi + j.balance_carrier_gujarat + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat 
                })
                subscreen4.map((j)=>{
                    stage2_dom_carrier = stage2_dom_carrier + j.count_target_carrier_msil + j.count_target_carrier_gujarat + j.count_target_carrier_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_dom_rail = stage2_dom_rail + j.count_target_rail_msil + j.count_target_rail_gujarat
                    + j.count_target_rail_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_dom_road = stage2_dom_road + j.count_target_road_msil + j.count_target_road_gujarat
                    + j.count_target_road_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_dom_total = stage2_dom_total + j.count_target_carrier_msil + j.count_target_carrier_gujarat
                    + j.count_target_carrier_bidadi + j.count_target_rail_msil + j.count_target_rail_gujarat
                    + j.count_target_rail_bidadi + j.count_target_road_msil + j.count_target_road_gujarat
                    + j.count_target_road_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_exp_carrier = stage2_exp_carrier + j.count_achieve_carrier_msil + j.count_achieve_carrier_gujarat + j.count_achieve_carrier_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_exp_rail = stage2_exp_rail + j.count_achieve_rail_msil + j.count_achieve_rail_gujarat
                    + j.count_achieve_rail_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_exp_road = stage2_exp_road + j.count_achieve_road_msil + j.count_achieve_road_gujarat
                    + j.count_achieve_road_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_exp_total = stage2_exp_total + j.count_achieve_carrier_msil + j.count_achieve_carrier_gujarat + j.count_achieve_carrier_bidadi + j.count_achieve_rail_msil + j.count_achieve_rail_gujarat + j.count_achieve_rail_bidadi + j.count_achieve_road_msil + j.count_achieve_road_gujarat + j.count_achieve_road_bidadi
                })
                subscreen4.map((j)=>{
                    stage2_dom_exp_carrier = stage2_dom_exp_carrier + j.balance_carrier_msil
                    + j.balance_carrier_bidadi + j.balance_carrier_gujarat
                })
                subscreen4.map((j)=>{
                    stage2_dom_exp_rail = stage2_dom_exp_rail + j.balance_rail_msil
                    + j.balance_rail_bidadi + j.balance_rail_gujarat
                })
                subscreen4.map((j)=>{
                    stage2_dom_exp_road = stage2_dom_exp_road + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat
                })
                subscreen4.map((j)=>{
                    stage2_dom_exp_total = stage2_dom_exp_total + j.balance_carrier_msil
                    + j.balance_carrier_bidadi + j.balance_carrier_gujarat + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat + j.balance_road_msil
                    + j.balance_road_bidadi + j.balance_road_gujarat 
                })
                // console.log(respData,carrierCnt,railCnt,roadCnt,totalCnt,"281")
                var pinnedBottomRowData = {}
                pinnedBottomRowData["type"] = ""
                pinnedBottomRowData["route_id"] = ""
                pinnedBottomRowData["cluster"] = "Total"
                pinnedBottomRowData["carrier_target"] = target_carrierCnt
                pinnedBottomRowData["rail_target"] = target_railCnt
                pinnedBottomRowData["road_target"] = target_roadCnt
                pinnedBottomRowData["total_target"] = target_totalCnt
                pinnedBottomRowData["carrier_achieve"] = achieve_carrierCnt
                pinnedBottomRowData["rail_achieve"] = achieve_railCnt
                pinnedBottomRowData["road_achieve"] = achieve_roadCnt
                pinnedBottomRowData["total_achieve"] = achieve_totalCnt
                pinnedBottomRowData["carrier_balance"] = balance_carrierCnt
                pinnedBottomRowData["rail_balance"] = balance_railCnt
                pinnedBottomRowData["road_balance"] = balance_roadCnt
                pinnedBottomRowData["total_balance"] = balance_totalCnt
                var pinnedBottomRowData3 ={}
                pinnedBottomRowData3["consigner_code_"] ="Total"
                pinnedBottomRowData3["carrier_Domestic"] =stage3_dom_carrier
                pinnedBottomRowData3["rail_Domestic"] =stage3_dom_rail
                pinnedBottomRowData3["road_Domestic"] =stage3_dom_road
                pinnedBottomRowData3["total_domestic"] =stage3_dom_total
                pinnedBottomRowData3["carrier_Export"] =stage3_exp_carrier
                pinnedBottomRowData3["rail_Export"] =stage3_exp_rail
                pinnedBottomRowData3["road_Export"] =stage3_exp_road
                pinnedBottomRowData3["total_Export"] =stage3_exp_total
                pinnedBottomRowData3["total_carrier"] =stage3_dom_exp_carrier
                pinnedBottomRowData3["total_rail"] =stage3_dom_exp_rail
                pinnedBottomRowData3["total_road"] =stage3_dom_exp_road
                pinnedBottomRowData3["total_exp_dom"] =stage3_dom_exp_total
                var pinnedBottomRowData2= {}
                pinnedBottomRowData2["route_id__"] ="Total"
                pinnedBottomRowData2["target_msil"] =stage2_dom_carrier
                pinnedBottomRowData2["target_smg"] =stage2_dom_rail 
                pinnedBottomRowData2["target_bng"] =stage2_dom_road
                pinnedBottomRowData2["target_total"] =stage2_dom_total
                pinnedBottomRowData2["achievement_msil"] =stage2_exp_carrier
                pinnedBottomRowData2["achievement_smg"] =stage2_exp_rail
                pinnedBottomRowData2["achievement_bng"] =stage2_exp_road
                pinnedBottomRowData2["achievement_total"] =stage2_exp_total
                pinnedBottomRowData2["balance_msil"] =stage2_dom_exp_carrier
                pinnedBottomRowData2["balance_smg"] =stage2_dom_exp_rail
                pinnedBottomRowData2["balance_bng"] =stage2_dom_exp_road
                pinnedBottomRowData2["balance_total"] =stage2_dom_exp_total

                var mode_target_msil_count = 0 
                var mode_target_smg_count = 0
                var mode_target_bng_count = 0
                var mode_target_total_count = 0
                var mode_achievement_msil_count = 0
                var mode_achievement_smg_count = 0
                var mode_achievement_bng_count = 0
                var mode_achievement_total_count = 0
                var mode_balance_msil_count = 0
                var mode_balance_smg_count = 0
                var mode_balance_bng_count = 0
                var mode_balance_total_count = 0
                respData2.map( e => {
                        mode_target_msil_count = mode_target_msil_count + parseInt(e.count_target_carrier_msil+ e.count_target_rail_msil + e.count_target_road_msil)
                        mode_target_smg_count = mode_target_smg_count + parseInt(e.count_target_carrier_gujarat+e.count_target_rail_gujarat+e.count_target_road_gujarat)
                        mode_target_bng_count = mode_target_bng_count + parseInt(e.count_target_carrier_bidadi + e.count_target_rail_bidadi+e.count_target_road_bidadi)
                        mode_target_total_count = mode_target_total_count + parseInt(e.count_target_carrier_gujarat+e.count_target_rail_gujarat+e.count_target_road_gujarat+e.count_target_carrier_msil+e.count_target_rail_msil+e.count_target_road_msil+e.count_target_carrier_bidadi + e.count_target_rail_bidadi+e.count_target_road_bidadi)
                        mode_achievement_msil_count = mode_achievement_msil_count + parseInt(e.count_achieve_carrier_msil+e.count_achieve_rail_msil+e.count_achieve_road_msil)
                        mode_achievement_smg_count = mode_achievement_smg_count + parseInt(e.count_achieve_carrier_gujarat+e.count_achieve_rail_gujarat+e.count_achieve_road_gujarat)
                        mode_achievement_bng_count = mode_achievement_bng_count + parseInt(e.count_achieve_carrier_bidadi+e.count_achieve_rail_bidadi+e.count_achieve_road_bidadi)
                        mode_achievement_total_count = mode_achievement_total_count + parseInt(e.count_achieve_carrier_msil+e.count_achieve_rail_msil+e.count_achieve_road_msil+e.count_achieve_carrier_gujarat+e.count_achieve_rail_gujarat+e.count_achieve_road_gujarat+e.count_achieve_carrier_bidadi+e.count_achieve_rail_bidadi+e.count_achieve_road_bidadi)
                        mode_balance_msil_count = mode_balance_msil_count + parseInt(e.balance_carrier_msil+e.balance_rail_msil+e.balance_road_msil)
                        mode_balance_smg_count = mode_balance_smg_count + parseInt(e.balance_carrier_gujarat+e.balance_rail_gujarat+e.balance_road_gujarat)
                        mode_balance_bng_count = mode_balance_bng_count + parseInt(e.balance_carrier_bidadi+e.balance_rail_bidadi+e.balance_road_bidadi)
                        mode_balance_total_count = mode_balance_total_count + parseInt(e.balance_carrier_msil+e.balance_rail_msil+e.balance_road_msil+e.balance_carrier_gujarat+e.balance_rail_gujarat+e.balance_road_gujarat+e.balance_carrier_bidadi+e.balance_rail_bidadi+e.balance_road_bidadi)


                })
                var pinnedBottomRowData4 = {}
                pinnedBottomRowData4['route_id__'] = "Total"
                pinnedBottomRowData4['target_msil'] = mode_target_msil_count
                pinnedBottomRowData4['target_smg'] = mode_target_smg_count
                pinnedBottomRowData4['target_bng'] = mode_target_bng_count
                pinnedBottomRowData4['target_total'] = mode_target_total_count
                pinnedBottomRowData4['achievement_msil'] = mode_achievement_msil_count
                pinnedBottomRowData4['achievement_smg'] = mode_achievement_smg_count
                pinnedBottomRowData4['achievement_bng'] = mode_achievement_bng_count
                pinnedBottomRowData4['achievement_total'] = mode_achievement_total_count
                pinnedBottomRowData4['balance_msil'] = mode_balance_msil_count
                pinnedBottomRowData4['balance_smg'] = mode_balance_smg_count
                pinnedBottomRowData4['balance_bng'] = mode_balance_bng_count
                pinnedBottomRowData4['balance_total'] = mode_balance_total_count

                console.log(pinnedBottomRowData4 , "1729")
                this.setState({
                    rowData3 : respData,
                    rowData4 : respData2,
                    rowData5: subscreen3,
                    rowData7:subscreen5,
                    colHeader:source_option_label,
                    loadshow : "show-n",
                    overly :"show-n",
                    mode_header : mode,
                    pinnedRowData : [pinnedBottomRowData],
                    pinnedRowData2 : [pinnedBottomRowData2],
                    pinnedRowData3 :[pinnedBottomRowData3],
                    pinnedRowData4 : [pinnedBottomRowData4]
                })
            }
                else{
                    this.setState({
                        rowData3 : [],
                        rowData4 : [],
                        rowData5: [],
                        rowData7:[],
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        mode_header : mode,
                        pinnedRowData : [{}],
                        pinnedRowData2 : [{}],
                        pinnedRowData3 :[{}],
                        pinnedRowData4:[{}]
                    })
                }
            }   
                else{
                    this.setState({
                        rowData3 : [],
                        rowData4 : [],
                        rowData5: [],
                        rowData7:[],
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        mode_header : mode,
                        pinnedRowData : [{}],
                        pinnedRowData2 : [{}],
                        pinnedRowData3 :[{}],
                        pinnedRowData4:[{}]
                    })
                }
            }
                   
                }) 
    }
    onLoadPageData = () => {
        this.setState({
            loadshow : "show-m",
            overly :"show-m"
        })
        var selected_option = this.state.option.value
        // var source_option = this.state.targetoption.value
        // var source_label =  this.state.targetoption.label
        var plan_month = this.state.plan_month_target
        var revision =  this.state.revisionforTP.value
        var params = {}
        if(revision != undefined && revision != null && revision != ""){
            params.revision = revision
        }
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            if(selected_option == "option1"){
                params.option = "1"
            }else if(selected_option == "option2"){
                params.option = "2"
            }else{
                params.option = "3"
            }
            
        }
        // if(source_option != undefined && source_option != null && source_option != "" && source_label != undefined && source_label != null && source_label != ""){
        //     params.source_label = source_label
        //     params.source = source_option
        // }
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = plan_month
        }
        // if(source_option != undefined && source_option != null && source_option != ""){
            if(selected_option != undefined && selected_option != null && selected_option != ""){
                if(plan_month != undefined && plan_month != null && plan_month != "" && plan_month != "NaN-NaN"){
                    // console.log(params,"240")
                    // getRailTargetByMonth
                    redirectURL.post("/arms/gettargetpreparationdata", params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response) => {
                        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                            this.setState({
                                show:true,
                                basicTitle:"Session Expired",
                                basicType:"warning",
                                overly: "show-n",
                                loadshow: "show-n",
                                rowData:[]
                            })
                        }else{
                            // console.log("response", JSON.parse(response.data.data));
                            let respData = JSON.parse(response.data.data)
                            var bidabibulkCheck = response.data.bidabibulkCheck
                            // console.log("respData", respData);
                            this.setState({
                                rowData : eval(respData.for_code_target_plan),
                                bidabibulkCheck:parseInt(bidabibulkCheck),
                                loadshow : "show-n",
                                overly :"show-n"
                            })
                        }
                    })
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Month",
                        basicType:"warning",
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
                
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please Select Target",
                    basicType:"warning",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
             
        // }else{
        //     this.setState({
        //         show:true,
        //         basicTitle:"Please Select Source",
        //         basicType:"warning",
        //         loadshow:"show-n",
        //         overly:"show-n"
        //     })
        // }
        
            
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    getRevisionData = async(month,week) =>{
        var month = month
        var week = week
        var month_field = moment.parseZone(new Date(month)).format("MMM-YYYY")
        var params ={
            month : month_field,
            week : week
        }
        console.log(month,week,"420")
        await redirectURL.post("/arms/getRevisionData",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                })
            }else{
                var revisionOptionsData = response.data.revisionData;
                    var revisionoptions = [];
                    if (revisionOptionsData.length > 0) {
                        revisionOptionsData.map(function (cl) {
                            revisionoptions.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                    this.setState({
                        revisionoptions : revisionoptions
                    })
            }
        })
    }
    onFirstDataRendered = (event) => {
        event.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: 4,
          columns: ['trip_per_rake'],
        });
      };

    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }
    selectotheroption = (e) => {
        this.setState({
            targetoption: e,
        })
    }

    selectoption = (e) => {
        this.setState({
            option: e,
        })
    }
    selectlocation = (e) => {
        this.setState({
            location: e,
        })
    }
    
    selectmode= (e) => {
        this.setState({
            mode: e,
        })
    }
    selectplantype = (e) => {
        this.setState({
            plan_type: e,
        })
    }
    selectweek = (e) => {
        this.setState({
            revision :""
        })
        if(e.value == "1"){
            this.setState({
                weekheadername : "Week 1"
            })
        }
        if(e.value == "2"){
            this.setState({
                weekheadername : "Week 2"
            })
        }
        if(e.value == "3"){
            this.setState({
                weekheadername : "Week 3"
            })
        }
        if(e.value == "4"){
            this.setState({
                weekheadername : "Week 4"
            })
        }
        if(e.value == "all"){
            this.setState({
                weekheadername : "Month"
            })
        }
        var month = this.state.plan_month_rail
        console.log(month,"488")
         this.getRevisionData(month,e.value)
        this.setState({
            week: e,
        })
    }
    selectweek1 = (e) => {
        
        this.setState({
            week_upload: e,
        })
    }
    
    onClickHideAll1() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            bulkuploadsliderforData:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month_target: plan_month
        });
        this.getRevisionDatafortargetpreparation(plan_month)
    }
    handlerStartDateTimeRS = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            month_rakescheduling: plan_month
        });
        this.getRevisionDataforRakescheduling(plan_month)
    }
    handlerStartDateTime2 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month_rail: plan_month
        });
    }
    handlerStartDateTime1 = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var selDay = (d.getMonth() + 1);
        if(parseInt(selDay) < 10)
        {
            selDay = "0"+selDay; 
        }
        var plan_month = d.getFullYear() + "-" + selDay;
        this.setState({
            plan_month_upload: plan_month
        });
    }
    onClickShowTabView(tab){
        if(tab == "targetpreparation")
        {
            this.setState({
               targettabactive:"btn-arms",
                railtargettabstatusactive:"",
                summarydashboardtabactive:"",
                railschedulingtabstatusactive:"",
                targetpreparationshow:"show-m",
                railtargetshow:"show-n",
                summarydashboardshow:"show-n",
                railschedulingshow:"show-n",
                pagetitle:"Stage 1 PPC + Stock Target Preparation Data",
                bulkslide : "",
                bulkslide3:"",
                bulkuploadsliderforData:"",
                bulkslide4:""
            })
            this.LoadTargetPreparationData()
        }
        if(tab == "railtarget")
        {
            this.setState({
                targettabactive:"",
                railtargettabstatusactive:"btn-arms",
                summarydashboardtabactive:"",
                railschedulingtabstatusactive:"",
                targetpreparationshow:"show-n",
                railtargetshow:"show-m",
                summarydashboardshow:"show-n",
                railschedulingshow:"show-n",
                pagetitle:"Stage 1 Cluster Wise Rail Target",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
            var rail_target_month = this.state.plan_month_rail
            var rail_check_month = moment.parseZone(new Date(rail_target_month)).format("MMM-YYYY")
            var week = this.state.week.value
            this.onsubmitgetrailtargetData(rail_check_month,week)
        }
        if(tab == "summarydashboard")
        {
            this.setState({
                targettabactive:"",
                railtargettabstatusactive:"",
                summarydashboardtabactive:"btn-arms",
                railschedulingtabstatusactive:"",
                targetpreparationshow:"show-n",
                railtargetshow:"show-n",
                summarydashboardshow:"show-m",
                railschedulingshow:"show-n",
                pagetitle:"Stage 1 Overall Target Summary Dashboard",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
            this.LoadSummaryDashboardData()
        } 
        if(tab == "railscheduling")
        {
            this.setState({
                targettabactive:"",
                railtargettabstatusactive:"",
                summarydashboardtabactive:"",
                railschedulingtabstatusactive:"btn-arms",
                targetpreparationshow:"show-n",
                railtargetshow:"show-n",
                summarydashboardshow:"show-n",
                railschedulingshow:"show-m",
                pagetitle:"Stage 1 Rake Schedule",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
            this.LoadRakeScheduleData()
        } 
    }
    onClickShowSubTabView(subtab){
        if(subtab == "plantwise")
        {
            this.setState({
                plantwisetabactive:"btn-arms",
                modewisetabactive:"",
                locationwisetabactive:"",
                clusterwisetabactive:"",
                plantwiseshow:"show-m",
                modewiseshow:"show-n",
                locationwiseshow:"show-n",
                clusterwiseshow:"show-n",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
        }
        if(subtab == "modewise")
        {
            this.setState({
                plantwisetabactive:"",
                modewisetabactive:"btn-arms",
                locationwisetabactive:"",
                clusterwisetabactive:"",
                plantwiseshow:"show-n",
                modewiseshow:"show-m",
                locationwiseshow:"show-n",
                clusterwiseshow:"show-n",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
        }
        if(subtab == "locationwise")
        {
            this.setState({
                plantwisetabactive:"",
                modewisetabactive:"",
                locationwisetabactive:"btn-arms",
                clusterwisetabactive:"",
                plantwiseshow:"show-n",
                modewiseshow:"show-n",
                locationwiseshow:"show-m",
                clusterwiseshow:"show-n",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
        }
        if(subtab == "clusterwise")
        {
            this.setState({
                plantwisetabactive:"",
                modewisetabactive:"",
                locationwisetabactive:"",
                clusterwisetabactive:"btn-arms",
                plantwiseshow:"show-n",
                modewiseshow:"show-n",
                locationwiseshow:"show-n",
                clusterwiseshow:"show-m",
                bulkslide : "",
                bulkslide3:"",
                bulkslide4:"",
                bulkuploadsliderforData:""
            })
        }

    }
    onclickShowsliderforupload = () => {
		this.setState({
			bulkslide: 'slider-translate',
			overly: "show-m"
		})
	}
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:"",
            bulkslide3:"",
            bulkslide4:"",
            bulkuploadsliderforData:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}
    changeHandler = (event) => {
      
            // console.log("Select", event.target.value);
                var name = event.target.name;
                var value = event.target.value;
            this.setState({
                    targetvalue:event.target.value
            })
        
        
    }
    changeFileHandler1 = (e) => {
		const config = {
			headers: [				
				{
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'for_code',
					inputName: 'for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'cluster',
					inputName: 'cluster',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'imm_for_code',
					inputName: 'imm_for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'sbvh',
					inputName: 'sbvh',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'vins_count',
					inputName: 'vins_count',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                
			]
		}
        if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent1: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				// console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);
       
            this.setState({
                file1: e.target.files[0]
            });
        
		
		//console.log("e.target.files[0].type ", e.target.files[0].type);

            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            }
            else {
                e.target.value = null;
                this.setState({
                    uploadFile: '',
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }
        }
	}
    uploadBulkFormHandlerforManualUpload = (event) => {
		event.preventDefault();
        this.setState({
            loadshow :"show-m",
            overly : "show-m"
        })
        var filename = this.state.file1
        var name_file = filename.name
        var check_file = name_file.split(".")
        var week = this.state.week_upload.value
        var month = this.state.plan_month_upload
        var month_format = moment.parseZone(new Date(month)).format("MMM-YYYY")
        var week_railtarget = this.state.week.value
        var month_railtarget = this.state.plan_month_rail
        var month_var = moment.parseZone(new Date(month_railtarget)).format("MMM-YYYY")
        // console.log(name_file,"566")
        if(month != undefined && month != null && month != ""){
            if(week !=  undefined && week != null && week != ""){
                if(check_file[check_file.length-1] == "csv" || check_file[check_file.length-1] == "CSV"){
                    var reqparams = {
                        csvcontent: this.state.csvcontent1,
                        uploaded_by: localStorage.getItem('email'),
                        week : week,
                        month : month_format
                }
                // console.log(reqparams,"567")
                redirectURL.post("/arms/sendmanualtargetupload",reqparams,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n",
                        })
                    }else{
                        var resp = eval(response.data)
                        var messageData  = JSON.parse(resp.data)
                        if(messageData.message == "success" || messageData.message == "Success" || messageData.message == "Data update was successful."){
                            this.setState({
                                loadshow : "show-n",
                                overly :"show-n",
                                bulkslide :"",
                                file1:"",
                                week_upload:"",
                                plan_month_upload:"",
                                show:true,
                                basicTitle:messageData.message,
                                basicType:"success"
                            })
                            $("#bulkUploadID").val("")
                            this.onsubmitgetrailtargetData(month_var,week_railtarget)
                            this.getRevisionData(month_railtarget,week_railtarget)
                            this.getRevisionDatafortargetpreparation(month_railtarget)
                        }else{
                            this.setState({
                                loadshow : "show-n",
                                overly :"show-n",
                                bulkslide :"",
                                file1:"",
                                week_upload:"",
                                plan_month_upload:"",
                                show:true,
                                basicTitle:messageData.message,
                                basicType:"danger"
                            })
                            $("#bulkUploadID").val("")
                            // this.componentDidMount()
                        }
                    }
                })
                }else{
                    this.setState({
                        basicTitle : "Please Upload Valid File",
                        basicType : "warning",
                        show :true,
                        loadshow : "show-n",
                        overly :"show-n",
                    })
                }
            }else{
                this.setState({
                    basicTitle : "Please Select Week",
                    basicType : "warning",
                    show :true,
                    loadshow : "show-n",
                    overly :"show-n",
                })
            }
        }else{
            this.setState({
                basicTitle : "Please Select Month",
                basicType : "warning",
                show :true,
                loadshow : "show-n",
                overly :"show-n",
            })
        }
	}
    onSubmitgetRailTarget = (e)=>{
        e.preventDefault();
        this.setState({
            loadshow : "show-m",
            overly :"show-m",
        })
        var plan_month_rail =  this.state.plan_month_rail
        var plan_month = moment.parseZone(new Date(plan_month_rail)).format("MMM-YYYY")
        var revision = this.state.revision.value
        var week = this.state.week.value
        if(plan_month != undefined && plan_month != "" && plan_month != null){
            if(week != undefined && week != null && week != ""){
                   this. onsubmitgetrailtargetData(plan_month,week)

            }else{
                this.setState({
                    basicTitle : "Please Select Week",
                    basicType : "warning",
                    show :true,
                    loadshow : "show-n",
                    overly :"show-n",
                })
            }
        }else{
            this.setState({
                basicTitle : "Please Select Month",
                basicType : "warning",
                show :true,
                loadshow : "show-n",
                overly :"show-n",
            })
        }
    }
    onclicksendApproval = ()=>{
        this.setState({
            loadshow :"show-m",
            overly:"show-m"
        })
        var month =  this.state.plan_month_rail
        var revision = this.state.revision.value
        var week = this.state.week.value
        var updated_by =localStorage.getItem("username")
        
        var request_id = moment.parseZone(new Date()).format("YYYYMMDDHHmmss")
        var params ={
            month : moment.parseZone(new Date(month)).format("MMM-YYYY"),
            revision : revision,
            week : week,
            updated_by : updated_by,
            request_id : request_id,

        }
        console.log(params,"779")
        if(revision != undefined && revision != null && revision != ""){
            redirectURL.post("/arms/sendapprovalstatus",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n",
                    })
                }else{
                    if(response.data.message =="success" || response.data.message == "Data update was successful."){
                        this.setState({
                            basicTitle : "Successfully Approved",
                            basicType : "success",
                            show :true,
                        })
                        this.onsubmitgetrailtargetData(moment.parseZone(new Date(month)).format("MMM-YYYY"),week)
                    }else{
                        this.setState({
                            basicTitle : "Data Not Approved",
                            basicType : "warning",
                            show :true,
                        })
                    }
                }
            })
        }else{
            this.setState({
                basicTitle : "Please Select Revision to Approve",
                basicType : "warning",
                show :true,
                loadshow :"show-n",
                overly:"show-n"
            })
        }
     
    }
    onsubmitgetrailtargetData = async(month,week) =>{
        this.setState({
            loadshow :"show-m",
            overly:"show-m",
        })
        
        var reqparams ={
            plan_month : month,
            week : week
        }
        var selected_option = this.state.option.value
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            if(selected_option == "option1"){
                reqparams.option = "1"
            }else if(selected_option == "option2"){
                reqparams.option = "2"
            }else{
                reqparams.option = "3"
            }
            
        }
        if(this.state.revision.value != undefined && this.state.revision.value != "" && this.state.revision.value != null){
                reqparams.revision = this.state.revision.value
        }
        console.log(reqparams,"819")
        await redirectURL.post("/arms/fetchingRailTargetData",reqparams,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData2:[]
                })
            }else{
                console.log(response.data,"677")
                var resp = JSON.parse(response.data.data)
                var checkData = eval(resp.for_code_target_plan)
                var approval_status = response.data.is_approved
                var approved_by = response.data.last_updated
                var approval_on = response.data.last_updated_on
                var targetvalue = resp.total_count
                console.log(approval_status,approved_by,approval_on,"832")
                console.log(targetvalue,"691")
                console.log(checkData,"681")
                this.setState({
                    rowData2:checkData,
                    loadshow :"show-n",
                    overly:"show-n",
                    targetvalue : targetvalue,
                    approval_status : approval_status[0],
                    approved_by : approved_by[0],
                    approval_on : approval_on[0]
                })
            }
        })
    }   
    onSubmitupdateTarget = (e)=>{

        e.preventDefault();
        var cnf = window.confirm("Do you want to update the target value?")
        if(cnf === true){
            this.setState({
                loadshow : "show-m",
                overly :"show-m",
            })
            var plan_month_rail =  this.state.plan_month_rail
            var plan_month = moment.parseZone(new Date(plan_month_rail)).format("MMM-YYYY")
            // var revision = this.state.revision.value
            var week = this.state.week.value
            var target_value = this.state.targetvalue
            var revision = this.state.revision.value
            var params = {
                month : plan_month,
                week : week,
                total_count : target_value
            }
            // console.log(params,"756")
            redirectURL.post("/arms/updateTargetcountvalue",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n",
                        rowData2:[]
                    })
                }else{
                    console.log(response.data,"1875")
                    var resp = eval(response.data)
                    var messageData  = JSON.parse(resp.data)
                    console.log(messageData,"765")
                    if(messageData.message == "Success" || messageData.message == "success" || messageData.message == "Data update was successful."){
                        this.setState({
                            loadshow : "show-n",
                            overly:"show-n",
                            show:true,
                            basicTitle:messageData.message,
                            basicType:"success"
                        })
                        this.onsubmitgetrailtargetData(plan_month,week)
                        this.getRevisionData(plan_month_rail,week)
                        this.getRevisionDatafortargetpreparation(plan_month_rail)
                    }else{
                        this.setState({
                            loadshow : "show-n",
                            overly:"show-n",
                            show:true,
                            basicTitle:messageData.message,
                            basicType:"warning"
                        })
                    }
                }
            })
        }
        
    }
    getdashboardsummaryData = (e) =>{
        e.preventDefault()
        this.setState({
            loadshow : "show-m",
            overly :"show-m",
        })
        var selected_option = this.state.option.value
        
        var source_option = this.state.location.value
        var source_option_label = this.state.location.label
        var mode = this.state.mode.value
        var mode_label = this.state.mode.label
        var plan_month =  this.state.plan_month_target
        var revision =  this.state.revisionforTP.value
        console.log(mode,mode_label,"990")
        var params = {}
       
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            if(selected_option == "option1"){
                params.option = "1"
            }else if(selected_option == "option2"){
                params.option = "2"
            }else{
                params.option = "3"
            }
        }
        if(plan_month != undefined && plan_month != null && plan_month != ""){
            params.month = moment.parseZone(new Date(plan_month)).format("MMM-YYYY")
        }
        if(revision != undefined && revision != null &&  revision != ""){
            params.revision = revision
        }
        console.log(params,"240")
        var target_carrierCnt = 0;
        var achieve_carrierCnt = 0;
        var balance_carrierCnt = 0;
        var target_railCnt = 0;
        var achieve_railCnt = 0;
        var balance_railCnt = 0;
        var target_roadCnt = 0;
        var achieve_roadCnt = 0;
        var balance_roadCnt = 0;
        var target_totalCnt = 0;
        var achieve_totalCnt = 0;
        var balance_totalCnt = 0;
        var stage3_dom_carrier = 0;
        var stage3_dom_rail = 0;
        var stage3_dom_road = 0;
        var stage3_dom_total = 0;
        var stage3_exp_carrier = 0;
        var stage3_exp_rail = 0;
        var stage3_exp_road = 0;
        var stage3_exp_total = 0;
        var stage3_dom_exp_carrier = 0;
        var stage3_dom_exp_rail = 0;
        var stage3_dom_exp_road = 0;
        var stage3_dom_exp_total = 0;

        redirectURL.post("/arms/gettargetsummarydata", params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
       }
       }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    rowData3 : [],
                    rowData4 : [],
                    rowData5: [],
                    pinnedRowData : [{}],
                    pinnedRowData3 :[{}],
                    pinnedRowData4 : [{}]
                })
            }else{
                console.log("response", response.data);
                    let responseData = JSON.parse(response.data.data)
                    let directresponse = eval(responseData.summary)
                    let subscreen2 = eval(responseData.screen_2)
                    let subscreen4 = eval(responseData.total_overal)
                    console.log(subscreen4,"1017")
                    console.log(subscreen2,"1011")
                    let subscreen3 = eval(responseData.screen_3)
                    console.log(subscreen3,"1013")
                    if(directresponse != undefined && directresponse != null && directresponse != "" && subscreen2 != undefined && subscreen2 != null && subscreen2 != "" && subscreen4 != undefined && subscreen4 != null && subscreen4 != "" && subscreen3 != undefined && subscreen4 != null && subscreen4 != ""){
                        if(directresponse.length >0 && subscreen2.length >0 && subscreen4.length >0 && subscreen3.length>0){
                     var respData = []
                     var haryanaData = []
                     var gujaratData = []
                     var bidadiData = []
                     var overallData = []
                     var carrierData = []
                     var railData = []
                     var roadData = []
                     var respData2 =[]
                     if(source_option == "G"){
                        gujaratData = directresponse.filter((e)=>e.consigner_code == "gujarat")
                        respData = gujaratData
                     }
                     if(source_option == "H"){
                        haryanaData = directresponse.filter((e)=>e.consigner_code == "msil")
                        respData = haryanaData
                     }
                     if(source_option == "B"){
                        bidadiData = directresponse.filter((e)=>e.consigner_code == "bidadi")
                        respData = bidadiData
                     }
                     if(mode == "overall"){
                        overallData = subscreen4
                        respData2 = overallData
                        console.log(respData2,"1046")
                     }
                     if(mode == "carrier"){
                        carrierData = subscreen2.filter((e)=>e.mode__=="carrier")
                        respData2 = carrierData
                     }
                     if(mode == "road"){
                        roadData = subscreen2.filter((e)=>e.mode__=="road")
                        respData2 = roadData
                     }
                     if(mode == "rail"){
                        railData = subscreen2.filter((e)=>e.mode__=="rail")
                        respData2 = railData
                     }
                    //  console.log(gujaratData,haryanaData,bidadiData,respData,"268")
                    respData.map((e)=>{
                        target_carrierCnt = e.carrier_target + target_carrierCnt
                    })
                    respData.map((f)=>{
                        target_railCnt = f.rail_target + target_railCnt 
                    })
                    respData.map((g)=>{
                        target_roadCnt = g.road_target + target_roadCnt 
                    })
                    respData.map((h)=>{
                        target_totalCnt = h.total_target + target_totalCnt 
                    })
                    respData.map((h)=>{
                        achieve_carrierCnt = h.carrier_achieve + achieve_carrierCnt 
                    })
                    respData.map((h)=>{
                        achieve_railCnt = h.rail_achieve + achieve_railCnt 
                    })
                    respData.map((h)=>{
                        achieve_roadCnt = h.road_achieve + achieve_roadCnt 
                    })
                    respData.map((h)=>{
                        achieve_totalCnt = h.total_achieve + achieve_totalCnt 
                    })
                    respData.map((h)=>{
                        balance_carrierCnt = h.carrier_balance + balance_carrierCnt 
                    })
                    respData.map((h)=>{
                        balance_railCnt = h.rail_balance + balance_railCnt 
                    })
                    respData.map((h)=>{
                        balance_roadCnt = h.road_balance + balance_roadCnt 
                    })
                    respData.map((h)=>{
                        balance_totalCnt = h.total_balance + balance_totalCnt 
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_carrier = g.carrier_Domestic + stage3_dom_carrier
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_rail = g.rail_Domestic + stage3_dom_rail
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_road = g.road_Domestic + stage3_dom_road
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_total = g.carrier_Domestic + g.rail_Domestic +  g.road_Domestic + stage3_dom_total 
                    })
                    subscreen3.map((g)=>{
                        stage3_exp_carrier = g.carrier_Export + stage3_exp_carrier
                    })
                    subscreen3.map((g)=>{
                        stage3_exp_rail = g.rail_Export + stage3_exp_rail
                    })
                    subscreen3.map((g)=>{
                        stage3_exp_road = g.road_Export + stage3_exp_road
                    })
                    subscreen3.map((g)=>{
                        stage3_exp_total = g.carrier_Export + g.rail_Export + g.road_Export + stage3_exp_total
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_exp_carrier = g.carrier_Export + g.carrier_Domestic + stage3_dom_exp_carrier
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_exp_rail = g.rail_Export + g.rail_Domestic + stage3_dom_exp_rail
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_exp_road = g.road_Export + g.road_Domestic + stage3_dom_exp_road
                    })
                    subscreen3.map((g)=>{
                        stage3_dom_exp_total =  g.road_Export + g.road_Domestic + g.rail_Export + g.rail_Domestic + g.carrier_Export + g.carrier_Domestic + stage3_dom_exp_total
                    })
                    // console.log(respData,carrierCnt,railCnt,roadCnt,totalCnt,"281")
                    var pinnedBottomRowData = {}
                    pinnedBottomRowData["type"] = ""
                    pinnedBottomRowData["route_id"] = ""
                    pinnedBottomRowData["cluster"] = "Total"
                    pinnedBottomRowData["carrier_target"] = target_carrierCnt
                    pinnedBottomRowData["rail_target"] = target_railCnt
                    pinnedBottomRowData["road_target"] = target_roadCnt
                    pinnedBottomRowData["total_target"] = target_totalCnt
                    pinnedBottomRowData["carrier_achieve"] = achieve_carrierCnt
                    pinnedBottomRowData["rail_achieve"] = achieve_railCnt
                    pinnedBottomRowData["road_achieve"] = achieve_roadCnt
                    pinnedBottomRowData["total_achieve"] = achieve_totalCnt
                    pinnedBottomRowData["carrier_balance"] = balance_carrierCnt
                    pinnedBottomRowData["rail_balance"] = balance_railCnt
                    pinnedBottomRowData["road_balance"] = balance_roadCnt
                    pinnedBottomRowData["total_balance"] = balance_totalCnt
                    var pinnedBottomRowData3 ={}
                    pinnedBottomRowData3["consigner_code_"] ="Total"
                    pinnedBottomRowData3["carrier_Domestic"] =stage3_dom_carrier
                    pinnedBottomRowData3["rail_Domestic"] =stage3_dom_rail
                    pinnedBottomRowData3["road_Domestic"] =stage3_dom_road
                    pinnedBottomRowData3["total_domestic"] =stage3_dom_total
                    pinnedBottomRowData3["carrier_Export"] =stage3_exp_carrier
                    pinnedBottomRowData3["rail_Export"] =stage3_exp_rail
                    pinnedBottomRowData3["road_Export"] =stage3_exp_road
                    pinnedBottomRowData3["total_Export"] =stage3_exp_total
                    pinnedBottomRowData3["total_carrier"] =stage3_dom_exp_carrier
                    pinnedBottomRowData3["total_rail"] =stage3_dom_exp_rail
                    pinnedBottomRowData3["total_road"] =stage3_dom_exp_road
                    pinnedBottomRowData3["total_exp_dom"] =stage3_dom_exp_total

                    console.log(respData2 , "respData2_1729")

                    var mode_target_msil_count = 0 
                    var mode_target_smg_count = 0
                    var mode_target_bng_count = 0
                    var mode_target_total_count = 0
                    var mode_achievement_msil_count = 0
                    var mode_achievement_smg_count = 0
                    var mode_achievement_bng_count = 0
                    var mode_achievement_total_count = 0
                    var mode_balance_msil_count = 0
                    var mode_balance_smg_count = 0
                    var mode_balance_bng_count = 0
                    var mode_balance_total_count = 0
                    respData2.map( e => {
                            mode_target_msil_count = mode_target_msil_count + parseInt(e.count_target_carrier_msil+ e.count_target_rail_msil + e.count_target_road_msil)
                            mode_target_smg_count = mode_target_smg_count + parseInt(e.count_target_carrier_gujarat+e.count_target_rail_gujarat+e.count_target_road_gujarat)
                            mode_target_bng_count = mode_target_bng_count + parseInt(e.count_target_carrier_bidadi + e.count_target_rail_bidadi+e.count_target_road_bidadi)
                            mode_target_total_count = mode_target_total_count + parseInt(e.count_target_carrier_gujarat+e.count_target_rail_gujarat+e.count_target_road_gujarat+e.count_target_carrier_msil+e.count_target_rail_msil+e.count_target_road_msil+e.count_target_carrier_bidadi + e.count_target_rail_bidadi+e.count_target_road_bidadi)
                            mode_achievement_msil_count = mode_achievement_msil_count + parseInt(e.count_achieve_carrier_msil+e.count_achieve_rail_msil+e.count_achieve_road_msil)
                            mode_achievement_smg_count = mode_achievement_smg_count + parseInt(e.count_achieve_carrier_gujarat+e.count_achieve_rail_gujarat+e.count_achieve_road_gujarat)
                            mode_achievement_bng_count = mode_achievement_bng_count + parseInt(e.count_achieve_carrier_bidadi+e.count_achieve_rail_bidadi+e.count_achieve_road_bidadi)
                            mode_achievement_total_count = mode_achievement_total_count + parseInt(e.count_achieve_carrier_msil+e.count_achieve_rail_msil+e.count_achieve_road_msil+e.count_achieve_carrier_gujarat+e.count_achieve_rail_gujarat+e.count_achieve_road_gujarat+e.count_achieve_carrier_bidadi+e.count_achieve_rail_bidadi+e.count_achieve_road_bidadi)
                            mode_balance_msil_count = mode_balance_msil_count + parseInt(e.balance_carrier_msil+e.balance_rail_msil+e.balance_road_msil)
                            mode_balance_smg_count = mode_balance_smg_count + parseInt(e.balance_carrier_gujarat+e.balance_rail_gujarat+e.balance_road_gujarat)
                            mode_balance_bng_count = mode_balance_bng_count + parseInt(e.balance_carrier_bidadi+e.balance_rail_bidadi+e.balance_road_bidadi)
                            mode_balance_total_count = mode_balance_total_count + parseInt(e.balance_carrier_msil+e.balance_rail_msil+e.balance_road_msil+e.balance_carrier_gujarat+e.balance_rail_gujarat+e.balance_road_gujarat+e.balance_carrier_bidadi+e.balance_rail_bidadi+e.balance_road_bidadi)

                        console.log(mode_balance_total_count,"2229")
                    })
                    console.log(mode_achievement_bng_count,"2229")
                    var pinnedBottomRowData4 = {}
                    pinnedBottomRowData4['route_id__'] = "Total"
                    pinnedBottomRowData4['target_msil'] = mode_target_msil_count
                    pinnedBottomRowData4['target_smg'] = mode_target_smg_count
                    pinnedBottomRowData4['target_bng'] = mode_target_bng_count
                    pinnedBottomRowData4['target_total'] = mode_target_total_count
                    pinnedBottomRowData4['achievement_msil'] = mode_achievement_msil_count
                    pinnedBottomRowData4['achievement_smg'] = mode_achievement_smg_count
                    pinnedBottomRowData4['achievement_bng'] = mode_achievement_bng_count
                    pinnedBottomRowData4['achievement_total'] = mode_achievement_total_count
                    pinnedBottomRowData4['balance_msil'] = mode_balance_msil_count
                    pinnedBottomRowData4['balance_smg'] = mode_balance_smg_count
                    pinnedBottomRowData4['balance_bng'] = mode_balance_bng_count
                    pinnedBottomRowData4['balance_total'] = mode_balance_total_count
                    console.log(pinnedBottomRowData4 , "pinnedBottomRowData4_1729")
                    this.setState({
                        rowData3 : respData,
                        rowData4 : respData2,
                        rowData5: subscreen3,
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        mode_header : mode,
                        pinnedRowData : [pinnedBottomRowData],
                        pinnedRowData3 :[pinnedBottomRowData3],
                        pinnedRowData4 : [pinnedBottomRowData4]
                    })
                }
                else{
                    this.setState({
                        rowData3 : [],
                        rowData4 : [],
                        rowData5: [],
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        mode_header : mode,
                        pinnedRowData : [{}],
                        pinnedRowData3 :[{}],
                        pinnedRowData4 : [{}]
                    })
                }
            }   
                else{
                    this.setState({
                        rowData3 : [],
                        rowData4 : [],
                        rowData5: [],
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        mode_header : mode,
                        pinnedRowData : [{}],
                        pinnedRowData3 :[{}],
                        pinnedRowData4:[{}]
                    })
                }
            }
                    
        })
    }
    getRevisionDatafortargetpreparation = (month) =>{
        console.log(month,"1281")
        var month_field = moment.parseZone(new Date(month)).format("MMM-YYYY")
        var params = {
            month : month_field
        }
        redirectURL.post("/arms/getRevisionDataforTargetPreparation",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                var revisionOptionsDataforTP = response.data.revisionData;
                console.log(revisionOptionsDataforTP,"1283")
                    var revisionoptionsforTP = [];
                    if (revisionOptionsDataforTP.length > 0) {
                        revisionOptionsDataforTP.map(function (cl) {
                            revisionoptionsforTP.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                    this.setState({
                        revisionoptionsforTP : revisionoptionsforTP
                    })
            }
        })
    }
    getRevisionDataforRakescheduling = (month) =>{
        console.log(month,"1281")
        var month_field = moment.parseZone(new Date(month)).format("MMM-YYYY")
        var params = {
            month : month_field
        }
        
        redirectURL.post("/arms/getRevisionDataforRakescheduling",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                var revisionOptionsDataforTP = response.data.revisionData;
                console.log(revisionOptionsDataforTP,"1283")
                    var getRevisionDataforRakescheduling = [];
                    if (revisionOptionsDataforTP.length > 0) {
                        revisionOptionsDataforTP.map(function (cl) {
                            getRevisionDataforRakescheduling.push({
                                label: cl,
                                value: cl,
                            });
                        });
                    }
                    this.setState({
                        revisionoptionsforRS : getRevisionDataforRakescheduling
                    })
            }
        })
    }
    onClickGetRakeSchedulingData = () => {
        this.setState({
            loadshow : "show-m",
            overly :"show-m"
        })
        var reqParams ={
            "plan_month":moment.parseZone(new Date(this.state.month_rakescheduling)).format("MMM-YYYY"),
        }
        // var revision_data = this.state.revisionforRS.value
        // if(revision_data != undefined && revision_data != null && revision_data != ""){
        //     reqParams.revision = revision_data
        // }
        console.log(reqParams,"1731")
        redirectURL.post("/arms/getRakeschedulingdata",reqParams,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(async(response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n",
                rowData6:[]
            })
        }else{
            console.log(response.data,"loadprocess");
            this.setState({
                rowData6 : response.data,
                overly:"show-n",
                loadshow:"show-n",
            })
        }
            
        })  
        
            
    }
    onclickopenrailtargetsummaryTab=()=>{
        this.setState({
            bulkslide4: 'slider-translate-90p',
			overly: "show-m",
            loadshow:"show-m"
        })
        var params = {
            plan_month : this.state.plan_month_rail,
            revision : this.state.revision.value
        }
        var selected_option = this.state.option.value
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            if(selected_option == "option1"){
                params.option = "1"
            }else if(selected_option == "option2"){
                params.option = "2"
            }else{
                params.option = "3"
            }
            
        }
        redirectURL.post("/arms/railtargetsummaryData",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n",
                    target_summary_data:[]
                })
            }else{
                console.log(JSON.parse(response.data.data),"2230")
                var resp = JSON.parse(response.data.data)
                console.log(eval(resp.for_code_target_plan),"2232")
                var checkData = eval(resp.for_code_target_plan)
                var week1_data = eval(resp.week_1)
                var week2_data =eval(resp.week_2)
                var week3_data = eval(resp.week_3)
                var week4_data = eval(resp.week_4)
                var week1Data = []
                var week2Data = []
                var week3Data = []
                var week4Data = []
                let rowData = []
                let uniqueClusters = [... new Set(checkData.map(e => e.cluster))];
                console.log(uniqueClusters, "uniqueClusters")
                uniqueClusters.map((e) => {
                
                    let filteredData = week1_data.filter(k => k.cluster == e);
                    let filteredData2 = week2_data.filter(y =>y.cluster == e);
                    let filteredData3 = week3_data.filter(j => j.cluster == e);
                    let filteredData4 = week4_data.filter(s => s.cluster == e);
                    let filteredData5 = checkData.filter(l => l.cluster == e);
                    console.log(filteredData,"2249")
                    console.log(filteredData2,"2250")
                    console.log(filteredData3,"2251")
                    console.log(filteredData4,"2252")
                    console.log(filteredData5,"2253")
                    var temp = {}
                    temp[ "cluster"]=(e != undefined)?e:""
                    temp["vins_countgujarat_w1"] = (filteredData.length > 0)?filteredData[0].vins_countgujarat:0
                    temp["vins_countmsil_w1"]=(filteredData.length > 0)?filteredData[0].vins_countmsil:0
                    temp["vins_countbidadi_w1"]=(filteredData.length > 0)?filteredData[0].vins_countbidadi:0
                    temp["vins_countTotal_w1"]=(filteredData.length > 0)?filteredData[0].vins_countTotal:0
                    temp["vins_countgujarat_w2"] =(filteredData2.length > 0)?filteredData2[0].vins_countgujarat:0
                    temp["vins_countmsil_w2"]=(filteredData2.length > 0)?filteredData2[0].vins_countmsil:0
                    temp["vins_countbidadi_w2"]=(filteredData2.length > 0)?filteredData2[0].vins_countbidadi:0
                    temp["vins_countTotal_w2"]=(filteredData2.length > 0)?filteredData2[0].vins_countTotal:0
                    temp["vins_countgujarat_w3"] =(filteredData3.length > 0)?filteredData3[0].vins_countgujarat:0
                    temp["vins_countmsil_w3"]=(filteredData3.length > 0)?filteredData3[0].vins_countmsil:0
                    temp["vins_countbidadi_w3"]=(filteredData3.length > 0)?filteredData3[0].vins_countbidadi:0
                    temp["vins_countTotal_w3"]=(filteredData3.length > 0)?filteredData3[0].vins_countTotal:0
                    temp["vins_countgujarat_w4"] = (filteredData4.length > 0)?filteredData4[0].vins_countgujarat:0
                    temp["vins_countmsil_w4"]=(filteredData4.length > 0)?filteredData4[0].vins_countmsil:0
                    temp["vins_countbidadi_w4"]=(filteredData4.length > 0)?filteredData4[0].vins_countbidadi:0
                    temp["vins_countTotal_w4"]=(filteredData4.length > 0)?filteredData4[0].vins_countTotal:0
                    temp["vins_countgujarat"] = (filteredData5.length > 0)?filteredData5[0].vins_countgujarat:0
                    temp["vins_countmsil"]=(filteredData5.length > 0)?filteredData5[0].vins_countmsil:0
                    temp["vins_countbidadi"]=(filteredData5.length > 0)?filteredData5[0].vins_countbidadi:0
                    temp["vins_countTotal"]=(filteredData5.length > 0)?filteredData5[0].vins_countTotal:0
                    rowData.push(temp)
                })
                console.log(rowData,"2319")
                this.setState({
                    target_summary_data:rowData,
                    loadshow:"show-n"
                })
            }
        })
    }
   
onClickShowSliderForBulkUpload=()=>{
    this.setState({
        bulkuploadsliderforData :'slider-translate',
            overly:"show-m",
    })
}
handlerStartDateTimeforUpload = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    var selDay = (d.getMonth() + 1);
    if(parseInt(selDay) < 10)
    {
        selDay = "0"+selDay; 
    }
    var plan_month = d.getFullYear() + "-" + selDay;
    this.setState({
        plan_month_for_uplaod: plan_month
    });
}
changeFileHandlerForExports = (e) => {
    console.log(e, "change file handler exports")
    const config = {
        headers: [				
            {
                name: 'consigner_code',
                inputName: 'consigner_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            {
                name: 'production_model_code',
                inputName: 'production_model_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            {
                name: 'color_code',
                inputName: 'color_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            {
                name: 'day_1',
                inputName: 'day_1',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_2',
                inputName: 'day_2',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_3',
                inputName: 'day_3',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_4',
                inputName: 'day_4',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_5',
                inputName: 'day_5',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_6',
                inputName: 'day_6',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_7',
                inputName: 'day_7',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_8',
                inputName: 'day_8',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_9',
                inputName: 'day_9',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_10',
                inputName: 'day_10',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_11',
                inputName: 'day_11',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_12',
                inputName: 'day_12',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_13',
                inputName: 'day_13',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_14',
                inputName: 'day_14',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_15',
                inputName: 'day_15',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_16',
                inputName: 'day_16',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_17',
                inputName: 'day_17',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_18',
                inputName: 'day_18',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_19',
                inputName: 'day_19',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_20',
                inputName: 'day_20',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_21',
                inputName: 'day_21',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_22',
                inputName: 'day_22',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_23',
                inputName: 'day_23',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_24',
                inputName: 'day_24',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_25',
                inputName: 'day_25',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_26',
                inputName: 'day_26',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_27',
                inputName: 'day_27',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_28',
                inputName: 'day_28',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_29',
                inputName: 'day_29',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_30',
                inputName: 'day_30',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },{
                name: 'day_31',
                inputName: 'day_31',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            
        ]
    }
    if(e.target.files[0] != undefined && e.target.files[0] != null && e.target.files[0] != ""){
        console.log(config, "config", e.target.files[0])
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log('csvData', csvData)
            // var columnsList = ["consigner_code","production_model_code","color_code","day_1","day_2","day_3","day_4","day_5","day_6","day_7","day_8","day_9","day_10","day_11","day_12","day_13","day_14","day_15","day_16","day_17","day_18","day_19","day_20","day_21","day_22","day_23","day_24","day_25","day_26","day_27","day_28","day_29","day_30","day_31"]
            // var error_flag = false;
            // columnsList.map((header)=>{
            //     csvData.inValidMessages.map((j)=>{
            //         if(j.includes("Header name")){
            //             error_flag = true;
            //         }
            //     })
            // })
            // if(error_flag){
            //     $("#bulkUploadID2").val("")
            //     this.setState({
            //         show: true,
            //         basicType: 'danger',
            //         basicTitle: 'Invalid Headers',
            //     });
            // }else{
                this.setState({
                    csvcontentforExports: csvData.data,
                    // file2: e.target.files[0]
                });
            // }
        }).catch(err => { console.log(e)})
    if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
    }
    else {
        e.target.value = null;
        this.setState({
            bulkUploadID: '',
            uploadFile: '',
            file: "",
            show: true,
            basicType: 'danger',
            basicTitle: 'Please upload file having extensions .csv only.',
        });
    }

        this.setState({
            file2: e.target.files[0]
        });
    }
    
}
uploadBulkFormHandlerforManualUploadForExports=(e)=>{
    e.preventDefault()
    this.setState({
        overly:"show-m",
        loadshow:"show-m"
    })
    var filename = this.state.file2
    console.log(filename,"2618")
    var name_file = filename.name
    var check_file = name_file.split(".")
    var month  = this.state.plan_month_for_uplaod
    var month_format = moment.parseZone(new Date(month)).format("MMM-YYYY")
    var csvData =  this.state.csvcontentforExports
    if(month != undefined && month != null && month != ""){
        if(check_file[check_file.length-1] == "csv" || check_file[check_file.length-1] == "CSV"){
            var params ={
                csvcontent: csvData,
                uploaded_by: localStorage.getItem('email'),
                month : month_format
            }
            redirectURL.post("/arms/sendmanualtargetuploadDataforBidadiplant",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n",
                        target_summary_data:[]
                    })
                }else{
                    var resp = eval(response.data)
                    var messageData  = JSON.parse(resp.data)
                    if(messageData.message == "success" || messageData.message == "Success" || messageData.message == "Data update was successful."){
                        this.setState({
                            loadshow : "show-n",
                            overly :"show-n",
                            bulkuploadsliderforData :"",
                            file2:"",
                            bulk_revison_for_uploads:"",
                            plan_month_for_uplaod:"",
                            show:true,
                            basicTitle:messageData.message,
                            basicType:"success"
                        })
                        $("#bulkUploadID2").val("")
                        this.onLoadPageData()
                    }else{
                        this.setState({
                            loadshow : "show-n",
                            overly :"show-n",
                            bulkuploadsliderforData :"",
                            file2:"",
                            bulk_revison_for_uploads:"",
                            plan_month_for_uplaod:"",
                            show:true,
                            basicTitle:messageData.message,
                            basicType:"danger"
                        })
                        $("#bulkUploadID2").val("")
                        // this.componentDidMount()
                    }
                }
            })
        }
        else{
            this.setState({
                loadshow:"show-n",
                overly:"show-n",
                basicTitle:"Please Upload Valid File",
                basicType:"warning",
                show:true
            }) 
        }
    }
    else{
        this.setState({
            loadshow:"show-n",
            overly:"show-n",
            basicTitle:"Please Select Month",
            basicType:"warning",
            show:true
        })
    }
}
    render() {
        const columnwithDefs = [
            { 
                headerName: "",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Type",
                        field: "type",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold"};
                            }
                        }
                    },
                    {
                        headerName: "FOR",
                        field: "for_code",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "City",
                        field: "for_city",
                        width: 140,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        valueGetter:function(params){
                            if(params.data.for_city != undefined && params.data.for_city != null && params.data.for_city != "" && params.data.for_city != 0){
                                return params.data.for_city
                            }else {
                                return ""
                            }
                        },
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }
                ]
            },
            {
                headerName: "Haryana",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "MSIL",
                        headerClass:"cellstylegridTPFM",
                        field: "msil_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }                                                
                ]
            },
            {
                headerName: "Gujarat",
                headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "SMG",
                        headerClass:"cellstylegridTPSMG",
                        field: "gujarat_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPSMG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    }
                ]
            },
            {
                headerName: "Bidadi",
                headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_carrier",
                        width: 100,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_rail",
                        width: 80,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_road",
                        width: 80,
                        filter: true,
                        resizable: true,
                        editable:false,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        }
                    },
                    {
                        headerName: "BNG",
                        headerClass:"cellstylegridTPBNG",
                        field: "bidadi_total",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPBNG",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.bidadi_carrier+params.data.bidadi_rail+params.data.bidadi_road
                        }
                    }                             
                  
                ]
            },
            {
                headerName: "Total",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        field: "",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_carrier+params.data.gujarat_carrier+params.data.bidadi_carrier
                        }
                    },
                    {
                        headerName: "RAIL",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_rail+params.data.gujarat_rail+params.data.bidadi_rail
                        }
                    },
                    {
                        headerName: "ROAD",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_road+params.data.gujarat_road+params.data.bidadi_road
                        }
                    },
                    {
                        headerName: "Total",
                        field: "",
                        width: 80,
                        filter: true,
                        resizable: true,
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333", fontWeight :"bold" };
                            }
                        },
                        valueGetter:function(params){
                            return params.data.msil_total+params.data.gujarat_total+params.data.bidadi_carrier+params.data.bidadi_rail+params.data.bidadi_road
                        }
                    },
                ]
            },
        ]
        const columnwithDefs2 =[
            { 
                headerName:this.state.weekheadername,
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Cluster",
                        field: "cluster",
                        width: 150,
                        editable:false,
                        filter: "agSetColumnFilter",
                        cellStyle:function(params){
                            if(params.data.cluster == "Total"){
                                return { background: "#f7f305", color: "#333" , fontWeight:"bold" };
                            }
                        }
                    },
                ]
            },
            { 
                headerName:"Target",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                {
                    headerName: "Haryana",
                    field: "vins_countmsil",
                    width: 130,
                    editable:false,
                    headerClass:"cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                    filter: "agSetColumnFilter",
                    cellStyle:function(params){
                        if(params.data.cluster == "Total"){
                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                        }
                    }
                },
                {
                    headerName: "Gujarat",
                    field: "vins_countgujarat",
                    width: 130,
                    filter: "agSetColumnFilter",
                    headerClass:"cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                    editable:true,
                    cellStyle:function(params){
                        if(params.data.cluster == "Total"){
                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                        }
                    }
                },
                {
                    headerName: "Bidadi",
                    field: "vins_countbidadi",
                    headerClass:"cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                    width: 130,
                    editable:false,
                    filter: "agSetColumnFilter",
                    cellStyle:function(params){
                        if(params.data.cluster == "Total"){
                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                        }
                    }
                },
                {
                    headerName: "Total",
                    field: "total_target",
                    headerClass:"cellstylegridTPFM",
                        cellClass:"cellstylegridTPFM",
                    width: 130,
                    editable:false,
                    filter: "agSetColumnFilter",
                    valueGetter:function(params){
                        return params.data.vins_countmsil + params.data.vins_countgujarat + params.data.vins_countbidadi
                    },
                    cellStyle:function(params){
                        if(params.data.cluster == "Total"){
                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                        }
                    }
                },
            ]
        },
                { 
                    headerName:"Achievement",
                    headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                    field: "",
                    cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 100,
                    chartDataType: "excluded",
                    children:[
                                {
                                    headerName: "Haryana",
                                    field: "dispatch_countmsil",
                                    headerClass:"cellstylegridTPSMG",
                        cellClass:"cellstylegridTPSMG",
                                    width: 130,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    cellStyle:function(params){
                                        if(params.data.cluster == "Total"){
                                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                        }
                                    }
                                },
                                {
                                    headerName: "Gujarat",
                                    field: "dispatch_countgujarat",
                                    headerClass:"cellstylegridTPSMG",
                                    cellClass:"cellstylegridTPSMG",
                                    width: 130,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    cellStyle:function(params){
                                        if(params.data.cluster == "Total"){
                                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                        }
                                    }
                                },
                                {
                                    headerName: "Bidadi",
                                    field: "dispatch_countbidadi",
                                    headerClass:"cellstylegridTPSMG",
                                    cellClass:"cellstylegridTPSMG",
                                    width: 130,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    cellStyle:function(params){
                                        if(params.data.cluster == "Total"){
                                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                        }
                                    }
                                },
                                {
                                    headerName: "Total",
                                    field: "total_target",
                                    headerClass:"cellstylegridTPSMG",
                                        cellClass:"cellstylegridTPSMG",
                                    width: 130,
                                    editable:false,
                                    filter: "agSetColumnFilter",
                                    valueGetter:function(params){
                                        return params.data.dispatch_countmsil + params.data.dispatch_countgujarat + params.data.dispatch_countbidadi
                                    },
                                    cellStyle:function(params){
                                        if(params.data.cluster == "Total"){
                                            return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                        }
                                    }
                                },
                            ]
                        },
                        { 
                            headerName:"Balance",
                            headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                            field: "",
                            cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                            // chartDataType: 'series',
                            width: 100,
                            chartDataType: "excluded",
                            children:[
                                        {
                                            headerName: "Haryana",
                                            field: "dispatch_countmsil",
                                            headerClass:"cellstylegridTPBNG",
                                cellClass:"cellstylegridTPBNG",
                                            width: 130,
                                            editable:false,
                                            filter: "agSetColumnFilter",
                                            cellStyle:function(params){
                                                if(params.data.cluster == "Total"){
                                                    return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                                }
                                            },
                                            valueGetter:function(params){
                                                return params.data.vins_countmsil-params.data.dispatch_countmsil
                                            }
                                        },
                                        {
                                            headerName: "Gujarat",
                                            field: "dispatch_countgujarat",
                                            headerClass:"cellstylegridTPBNG",
                                            cellClass:"cellstylegridTPBNG",
                                            width: 130,
                                            editable:false,
                                            filter: "agSetColumnFilter",
                                            cellStyle:function(params){
                                                if(params.data.cluster == "Total"){
                                                    return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                                }
                                            },
                                            valueGetter:function(params){
                                                return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                            }
                                        },
                                        {
                                            headerName: "Bidadi",
                                            field: "dispatch_countbidadi",
                                            headerClass:"cellstylegridTPBNG",
                                            cellClass:"cellstylegridTPBNG",
                                            width: 130,
                                            editable:false,
                                            filter: "agSetColumnFilter",
                                            cellStyle:function(params){
                                                if(params.data.cluster == "Total"){
                                                    return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                                }
                                            },
                                            valueGetter:function(params){
                                                return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                            }
                                        },
                                        {
                                            headerName: "Total",
                                            field: "total_target",
                                            headerClass:"cellstylegridTPBNG",
                                                cellClass:"cellstylegridTPBNG",
                                            width: 130,
                                            editable:false,
                                            filter: "agSetColumnFilter",
                                            valueGetter:function(params){
                                                return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                            },
                                            cellStyle:function(params){
                                                if(params.data.cluster == "Total"){
                                                    return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                                }
                                            }
                                        },
                                    ]
                                },
        ]
        const columnwithDefs3 =[
            { 
                headerName:  this.state.colHeader,
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Type",
                        field: "type",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "Route",
                        field: "route_id",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "Cluster",
                        field: "cluster",
                        width: 100,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        valueGetter:function(params){
                            if(params.data.cluster != undefined && params.data.cluster != null && params.data.cluster != "" && params.data.cluster != 0){
                                return params.data.cluster
                            }else {
                                return ""
                            }
                        },
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    }
                ]
            },
            
                    {
                        headerName: "Target",
                        headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                        field: "",
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 200,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "CARRIER",
                                headerClass:"cellstylegridTPFM",
                                field: "carrier_target",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "RAIL",
                                headerClass:"cellstylegridTPFM",
                                field: "rail_target",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "ROAD",
                                headerClass:"cellstylegridTPFM",
                                field: "road_target",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "Total",
                                headerClass:"cellstylegridTPFM",
                                field: "total_target",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            }  
                        ]
                    },
                    {
                        headerName: "Achievement",
                        headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                        field: "",
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 200,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "CARRIER",
                                headerClass:"cellstylegridTPSMG",
                                field: "carrier_achieve",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "RAIL",
                                headerClass:"cellstylegridTPSMG",
                                field: "rail_achieve",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "ROAD",
                                headerClass:"cellstylegridTPSMG",
                                field: "road_achieve",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "Total",
                                headerClass:"cellstylegridTPSMG",
                                field: "total_achieve",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            }  
                        ]
                    },
                    {
                        headerName: "Balance",
                        headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                        field: "",
                        // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 200,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "CARRIER",
                                headerClass:"cellstylegridTPBNG",
                                field: "carrier_balance",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "RAIL",
                                headerClass:"cellstylegridTPBNG",
                                field: "rail_balance",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "ROAD",
                                headerClass:"cellstylegridTPBNG",
                                field: "road_balance",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            },
                            {
                                headerName: "Total",
                                headerClass:"cellstylegridTPBNG",
                                field: "total_balance",
                                width: 120,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                cellStyle:function(params){
                                    if(params.data.for_code == "Total"){
                                        return { background: "#f7f305", color: "#333" };
                                    }
                                }
                            }  
                        ]
            },
        ]
        const columnwithDefs4=[
            
            { 
                headerName: this.state.mode_header,
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 160,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Route",
                        field: "route_id__",
                        width: 160,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        valueGetter:function(params){
                            if(params.data.route_id__ == "E"){
                                return "E"
                            }
                            if(params.data.route_id__ == "G"){
                                return "G"
                            }
                            if(params.data.route_id__ == "L"){
                                return "L"
                            }
                            if(params.data.route_id__ == "M"){
                                return "M"
                            }
                            if(params.data.route_id__ == "N"){
                                return "N"
                            }
                            if(params.data.route_id__ == "P"){
                                return "P"
                            }
                            if(params.data.route_id__ == "S"){
                                return "S"
                            }
                            if(params.data.route_id__ == "W"){
                                return "W"
                            }
                            if(params.data.route_id__ == "Z"){
                                return "Z"
                            }
                            if(params.data.route_id__ == "Total"){
                                return "Total"
                            }
                            
                        }
                    },
                ]
            },
            {
                headerName: "Route Wise",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "Target",
                        headerClass:"cellstylegridTPFM",
                        field: "carrier",
                        width: 120,
                        filter: true,
                        resizable: true,
                        children:[
                            {
                                headerName: "MSIL",
                                field: "target_msil",
                                headerClass:"cellstylegridTPFM",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                        return params.data.count_target_carrier_msil+params.data.count_target_rail_msil+params.data.count_target_road_msil
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.target_msil
                                    }
                                },
                            },
                            {
                                headerName: "SMG",
                                field: "target_smg",
                                headerClass :"cellstylegridTPFM",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass :"cellstylegridTPFM",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.count_target_carrier_gujarat+params.data.count_target_rail_gujarat+params.data.count_target_road_gujarat
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.target_smg
                                    }
                                },
                            },
                            {
                                headerName: "BNG",
                                field: "target_bng",
                                headerClass:"cellstylegridTPFM",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                        return params.data.count_target_carrier_bidadi+params.data.count_target_rail_bidadi+params.data.count_target_road_bidadi
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.target_bng
                                    }     
                                },
                            },
                            {
                                headerName: "TOTAL",
                                field: "target_total",
                                headerClass:"cellstylegridTPFM",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPFM",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if( params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.count_target_carrier_gujarat+params.data.count_target_rail_gujarat+params.data.count_target_road_gujarat+params.data.count_target_carrier_msil+params.data.count_target_rail_msil+params.data.count_target_road_msil+params.data.count_target_carrier_bidadi+params.data.count_target_rail_bidadi+params.data.count_target_road_bidadi
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.target_total
                                    }
                                },
                            }
                        ]
                    },
                    {
                        headerName: "Achievement Till Date",
                        headerClass: "cellstylegridTPSMG",
                        field: "carrier",
                        width: 120,
                        filter: true,
                        resizable: true,
                        children:[
                            {
                                headerName: "MSIL",
                                field: "achievement_msil",
                                headerClass:"cellstylegridTPSMG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.count_achieve_carrier_msil+params.data.count_achieve_rail_msil+params.data.count_achieve_road_msil
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.achievement_msil
                                    }
                                },
                                
                            },
                            {
                                headerName: "SMG",
                                field: "achievement_smg",
                                headerClass:"cellstylegridTPSMG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.count_achieve_carrier_gujarat+params.data.count_achieve_rail_gujarat+params.data.count_achieve_road_gujarat
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.achievement_smg
                                    }
                                },
                            },
                            {
                                headerName: "BNG",
                                field: "achievement_bng",
                                headerClass:"cellstylegridTPSMG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.count_achieve_carrier_bidadi+params.data.count_achieve_rail_bidadi+params.data.count_achieve_road_bidadi                                    
                                    }
                                    else if (params.data.route_id__ === "Total"){
                                        return params.data.achievement_bng
                                    }
                                },
                            },
                            {
                                headerName: "TOTAL",
                                field: "achievement_total",
                                headerClass:"cellstylegridTPSMG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPSMG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return  params.data.count_achieve_carrier_msil+params.data.count_achieve_rail_msil+params.data.count_achieve_road_msil+params.data.count_achieve_carrier_gujarat+params.data.count_achieve_rail_gujarat+params.data.count_achieve_road_gujarat+params.data.count_achieve_carrier_bidadi+params.data.count_achieve_rail_bidadi+params.data.count_achieve_road_bidadi
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.achievement_total
                                    }
                                },
                            }
                        ]
                    },
                    {
                        headerName: "Balance Till Month End",
                        headerClass:"cellstylegridTPBNG",
                        field: "carrier",
                        width: 120,
                        filter: true,
                        resizable: true,
                        children:[
                            {
                                headerName: "MSIL",
                                field: "balance_msil",
                                headerClass:"cellstylegridTPBNG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.balance_carrier_msil+params.data.balance_rail_msil+params.data.balance_road_msil
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.balance_msil
                                    }
                                },
                            },
                            {
                                headerName: "SMG",
                                field: "balance_smg",
                                headerClass:"cellstylegridTPBNG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                    return params.data.balance_carrier_gujarat+params.data.balance_rail_gujarat+params.data.balance_road_gujarat
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.balance_smg
                                    }
                                },
                            },
                            {
                                headerName: "BNG",
                                field: "balance_bng",
                                headerClass:"cellstylegridTPBNG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if(params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z"){
                                        return params.data.balance_carrier_bidadi+params.data.balance_rail_bidadi+params.data.balance_road_bidadi 
                                       }else if (params.data.route_id__ === "Total"){
                                           return params.data.balance_bng
                                       } 
                                },
                            },
                            {
                                headerName: "TOTAL",
                                field: "balance_total",
                                headerClass:"cellstylegridTPBNG",
                                width: 100,
                                filter: true,
                                resizable: true,
                                cellClass:"cellstylegridTPBNG",
                                // pinned:"left",
                                valueGetter:function(params){
                                    if( params.data.route_id__ == "E" || params.data.route_id__ == "G" || params.data.route_id__ == "L" || params.data.route_id__ == "M" || params.data.route_id__ == "N" || params.data.route_id__ == "P" || params.data.route_id__ == "S" || params.data.route_id__ == "W" || params.data.route_id__ == "Z") {
                                    return  params.data.balance_carrier_msil+params.data.balance_rail_msil+params.data.balance_road_msil+params.data.balance_carrier_gujarat+params.data.balance_rail_gujarat+params.data.balance_road_gujarat+params.data.balance_carrier_bidadi+params.data.balance_rail_bidadi+params.data.balance_road_bidadi
                                    }else if (params.data.route_id__ === "Total"){
                                        return params.data.balance_total
                                    }
                                },
                            }
                        ]
                    },
                                                                 
                ]
            },
        ]
        const columnwithDefs5=[
            {
                headerName: "Location",
                field: "consigner_code_",
                width: 100,
                filter: true,
                resizable: true,
                valueGetter:function(params){
                    if(params.data.consigner_code_ != undefined && params.data.consigner_code_ != null && params.data.consigner_code_ != ""){
                        if(params.data.consigner_code_ == "gujarat"){
                            return "SMG"
                        }
                        if(params.data.consigner_code_ == "msil"){
                            return "MSIL"
                        }
                        if(params.data.consigner_code_ == "bidadi"){
                            return "BNG"
                        }
                        if(params.data.consigner_code_ == "Total"){
                            return "Total"
                        }
                    }
                }
                // pinned:"left",
                // cellStyle:function(params){
                //     if(params.data.for_code == "Total"){
                //         return { background: "#f7f305", color: "#333" };
                //     }
                // }
            },
            {
            headerName: "Domestic",
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children:[
                    
                    {
                        headerName: "Carrier",
                        field: "carrier_Domestic",
                        headerClass:"cellstylegridTPFM",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                    {
                        headerName: "Rail",
                        field: "rail_Domestic",
                        headerClass:"cellstylegridTPFM",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                    {
                        headerName: "Road",
                        field: "road_Domestic",
                        headerClass:"cellstylegridTPFM",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                    {
                        headerName: "Total",
                        field: "total_domestic",
                        headerClass:"cellstylegridTPFM",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        valueGetter:function(params){
                            if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                                return params.data.rail_Domestic+params.data.road_Domestic+params.data.carrier_Domestic
                            }
                           
                        }    
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                ],
            },
            {
                headerName: "Export",
                    headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children:[
                {
                    headerName: "Carrier",
                    field: "carrier_Export",
                    headerClass:"cellstylegridTPSMG",
                    width: 100,
                    filter: true,
                    resizable: true,
                    cellClass:"cellstylegridTPSMG",
                    // pinned:"left",
                    // cellStyle:function(params){
                    //     if(params.data.for_code == "Total"){
                    //         return { background: "#f7f305", color: "#333" };
                    //     }
                    // }
                },
                {
                    headerName: "Rail",
                    field: "rail_Export",
                    headerClass:"cellstylegridTPSMG",
                    width: 100,
                    filter: true,
                    resizable: true,
                    cellClass:"cellstylegridTPSMG",
                    // pinned:"left",
                    // cellStyle:function(params){
                    //     if(params.data.for_code == "Total"){
                    //         return { background: "#f7f305", color: "#333" };
                    //     }
                    // }
                },
                {
                    headerName: "Road",
                    field: "road_Export",
                    headerClass:"cellstylegridTPSMG",
                    width: 100,
                    filter: true,
                    resizable: true,
                    cellClass:"cellstylegridTPSMG",
                    // pinned:"left",
                    // cellStyle:function(params){
                    //     if(params.data.for_code == "Total"){
                    //         return { background: "#f7f305", color: "#333" };
                    //     }
                    // }
                },
                {
                    headerName: "Total",
                    field: "total_Export",
                    headerClass:"cellstylegridTPSMG",
                    width: 100,
                    filter: true,
                    resizable: true,
                    cellClass:"cellstylegridTPSMG",
                    valueGetter:function(params){
                        if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                        return params.data.carrier_Export+params.data.rail_Export+params.data.road_Export
                        }
                    }
                    // pinned:"left",
                    // cellStyle:function(params){
                    //     if(params.data.for_code == "Total"){
                    //         return { background: "#f7f305", color: "#333" };
                    //     }
                    // }
                },
            ]
            },
            {
                headerName: "Total",
                    headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                    field: "",
                    // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                    // chartDataType: 'series',
                    width: 200,
                    chartDataType: "excluded",
                    children:[
                        {
                            headerName: "Carrier",
                            field: "total_carrier",
                            headerClass:"cellstylegridTPBNG",
                            width: 100,
                            filter: true,
                            resizable: true,
                            cellClass:"cellstylegridTPBNG",
                            valueGetter:function(params){
                                if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                                return params.data.carrier_Domestic+params.data.carrier_Export
                                }
                            }
                            // pinned:"left",
                            // cellStyle:function(params){
                            //     if(params.data.for_code == "Total"){
                            //         return { background: "#f7f305", color: "#333" };
                            //     }
                            // }
                        },
                    {
                        headerName: "Rail",
                        field: "total_rail",
                        headerClass:"cellstylegridTPBNG",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPBNG",
                        valueGetter:function(params){
                            if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                            return params.data.rail_Domestic+params.data.rail_Export
                            }
                        }
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                    {
                        headerName: "Road",
                        field: "total_road",
                        headerClass:"cellstylegridTPBNG",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPBNG",
                        valueGetter:function(params){
                            if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                            return params.data.road_Domestic+params.data.road_Export
                            }
                        }
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                    {
                        headerName: "Total",
                        field: "total_exp_dom",
                        headerClass:"cellstylegridTPBNG",
                        width: 100,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPBNG",
                        valueGetter:function(params){
                            if(params.data.consigner_code_ == "gujarat" || params.data.consigner_code_ == "msil" || params.data.consigner_code_ == "bidadi" || params.data.consigner_code_ == "Total"){
                            return params.data.rail_Domestic + params.data.road_Domestic +params.data.carrier_Domestic+params.data.rail_Export+params.data.road_Export+params.data.carrier_Export
                            }
                        }
                        // pinned:"left",
                        // cellStyle:function(params){
                        //     if(params.data.for_code == "Total"){
                        //         return { background: "#f7f305", color: "#333" };
                        //     }
                        // }
                    },
                ]
            }
        ]
        const columnwithDefs6=[
            {
                 pinned:"left",
                headerName: "View Details",
                field: "history_view_data",
                width:140,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-arms label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                    return htmloption
                } 
            },{
                pinned:"left",
                headerName: "View Target Details",
                field: "view_target_details",
                width:150,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    var htmloption = '<button class="btn btn-danger label label-success" id="buttonStyling"><i class="icofont icofont-eye"></i>View</button>';
                    return htmloption
                } 
            },
            {
                pinned:"left",
                headerName: "Generate Plan",
                field: "run_plan",
                width:180,
                filter: true,
                resizable: true,
                editable:false,
                cellRenderer:function(params){
                    if(params.data.approved == 1){
                        var htmloption = '<button class="btn btn-success label label-success disabled" disabled="true" id="buttonStyling"><i class="icofont-play-circle"></i>Run New Plan</button>';
                    return htmloption
                    }else{
                        var htmloption = '<button class="btn btn-success label label-success" id="buttonStyling"><i class="icofont-play-circle"></i>Run New Plan</button>';
                    return htmloption
                    }
                    
                } 
            },
            {
                headerName: "Request Id",
                field: "request_id",
                width:160,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Month",
                field: "plan_month",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
            },
            {
                headerName: "Week",
                field: "week",
                width:120,
                filter: true,
                resizable: true,
                editable:false,
                
            },
            {
                headerName: "Revision",
                field: "revision",
                width:140,
                filter: true,
                resizable: true,
                editable:false
            },
            {
                headerName : "Approved By",
                field:"approved_by",
                width:160,
                resizable : true,
                editable : false,
                
            },
            {
                headerName : "Approved On",
                field:"approved_on",
                width:160,
                resizable : true,
                editable : false,
                valueGetter:function(params){
                    return getHyphenDDMMMYYYY(params.data.approved_on)
                }
            },
            // {
            //     headerName : "Plan Type",
            //     field:"plan_type",
            //     width:160,
            //     resizable : true,
            //     editable : false,
                
            // }
        ]
        const columnwithDefs7=[
                    { 
                        headerName:"Achievement Till Date",
                        headerClass: ["cellstylegridTop", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "Channel",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTop",
                                cellClass:"cellstylegridTop",
                                width: 100,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countmsil-params.data.dispatch_countmsil
                                // }
                            },
                            {
                                headerName: "Location",
                                field: "consigner_code",
                                headerClass:"cellstylegridTop",
                                cellClass:"cellstylegridTop",
                                width: 100,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "SBVH",
                                field: "sbvh",
                                headerClass:"cellstylegridTop",
                                cellClass:"cellstylegridTop",
                                width: 100,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            {
                                headerName: "Model",
                                field: "car_model",
                                headerClass:"cellstylegridTop",
                                    cellClass:"cellstylegridTop",
                                width: 100,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"CENTRAL",
                        headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "C1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPFM",
                                cellClass:"cellstylegridTPFM",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countmsil-params.data.dispatch_countmsil
                                // }
                            },
                            {
                                headerName: "C2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPFM",
                                cellClass:"cellstylegridTPFM",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "C3",
                                field: "dispatch_countbidadi",
                                headerClass:"cellstylegridTPFM",
                                cellClass:"cellstylegridTPFM",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            {
                                headerName: "C4",
                                field: "total_target",
                                headerClass:"cellstylegridTPFM",
                                    cellClass:"cellstylegridTPFM",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPFM",
                                    cellClass:"cellstylegridTPFM",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"EAST",
                        headerClass: ["cellstylegridTPSMG", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "E1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPSMG",
                                cellClass:"cellstylegridTPSMG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                
                            },
                            {
                                headerName: "E2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPSMG",
                                cellClass:"cellstylegridTPSMG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "E3",
                                field: "dispatch_countbidadi",
                                headerClass:"cellstylegridTPSMG",
                                cellClass:"cellstylegridTPSMG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPSMG",
                                    cellClass:"cellstylegridTPSMG",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"NORTH",
                        headerClass: ["cellstylegridTPBNG", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "N1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPBNG",
                                cellClass:"cellstylegridTPBNG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                
                            },
                            {
                                headerName: "N2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPBNG",
                                cellClass:"cellstylegridTPBNG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "N3",
                                field: "dispatch_countbidadi",
                                headerClass:"cellstylegridTPBNG",
                                cellClass:"cellstylegridTPBNG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            {
                                headerName: "N4",
                                field: "total_target",
                                headerClass:"cellstylegridTPBNG",
                                    cellClass:"cellstylegridTPBNG",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPBNG",
                                    cellClass:"cellstylegridTPBNG",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"SOUTH",
                        headerClass: ["cellstylegridTPOther", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "S1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPOther",
                                cellClass:"cellstylegridTPOther",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countmsil-params.data.dispatch_countmsil
                                // }
                            },
                            {
                                headerName: "S2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPOther",
                                cellClass:"cellstylegridTPOther",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "S3",
                                field: "dispatch_countbidadi",
                                headerClass:"cellstylegridTPOther",
                                cellClass:"cellstylegridTPOther",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPOther",
                                    cellClass:"cellstylegridTPOther",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"SOUTH EAST",
                        headerClass: ["cellstylegridTPOther1", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "T1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPOther1",
                                cellClass:"cellstylegridTPOther1",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countmsil-params.data.dispatch_countmsil
                                // }
                            },
                            {
                                headerName: "T2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPOther1",
                                cellClass:"cellstylegridTPOther1",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPOther1",
                                    cellClass:"cellstylegridTPOther1",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"WEST",
                        headerClass: ["cellstylegridTPOther2", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "W1",
                                field: "dispatch_countmsil",
                                headerClass:"cellstylegridTPOther2",
                                cellClass:"cellstylegridTPOther2",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countmsil-params.data.dispatch_countmsil
                                // }
                            },
                            {
                                headerName: "W2",
                                field: "dispatch_countgujarat",
                                headerClass:"cellstylegridTPOther2",
                                cellClass:"cellstylegridTPOther2",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countgujarat-params.data.dispatch_countgujarat
                                // }
                            },
                            {
                                headerName: "W3",
                                field: "dispatch_countbidadi",
                                headerClass:"cellstylegridTPOther2",
                                cellClass:"cellstylegridTPOther2",
                                width: 60,
                                editable:false,
                                filter: "agSetColumnFilter",
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                },
                                // valueGetter:function(params){
                                //     return params.data.vins_countbidadi-params.data.dispatch_countbidadi
                                // }
                            },
                            
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTPOther2",
                                    cellClass:"cellstylegridTPOther2",
                                width: 80,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                    { 
                        headerName:"ALL INDIA",
                        headerClass: ["cellstylegrid", "cellTopHeader"],
                        field: "",
                        cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                        // chartDataType: 'series',
                        width: 100,
                        chartDataType: "excluded",
                        children:[
                            {
                                headerName: "Total",
                                field: "total_target",
                                headerClass:"cellstylegridTP",
                                    cellClass:"cellstylegridTP",
                                width: 160,
                                editable:false,
                                filter: "agSetColumnFilter",
                                // valueGetter:function(params){
                                //     return (params.data.vins_countbidadi-params.data.dispatch_countbidadi) +(params.data.vins_countgujarat-params.data.dispatch_countgujarat) +(params.data.vins_countmsil-params.data.dispatch_countmsil)
                                // },
                                cellStyle:function(params){
                                    if(params.data.cluster == "Total"){
                                        return { background: "#f7f305", color: "#333", fontWeight:"bold" };
                                    }
                                }
                            },
                        ]
                    },
                            
        ]
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
                <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.targettabactive)} onClick={this.onClickShowTabView.bind(this,"targetpreparation")}>Target Preparation</button>
                        <button type="button" className={"btn "+(this.state.summarydashboardtabactive)} onClick={this.onClickShowTabView.bind(this,"summarydashboard")}>Summary Dashboard</button>
                        <button type="button" className={"btn "+(this.state.railtargettabstatusactive)} onClick={this.onClickShowTabView.bind(this,"railtarget")}>Cluster Wise Rail Target</button>
                        {/* <button type="button" className={"btn "+(this.state.railschedulingtabstatusactive)} onClick={this.onClickShowTabView.bind(this,"railscheduling")}>Rail Schedule</button> */}
                        
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.targetpreparationshow)}>
                    <div className="card-arms col-xl-12">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                            </h5>
                        </div>
                        <div>
                        <span className="layoutbtns pull-right">
                        {(this.state.bidabibulkCheck != 1 || this.state.bidabibulkCheck != "1")?
											<button className="float-right custom-btn btn-arms"
											style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",borderRadius:"5px"}}
                                            onClick={this.onClickShowSliderForBulkUpload}>
											Bidadi Bulk Upload
											</button>:
                                            <button className="float-right custom-btn btn-arms disabled" disabled={true}
											style={{marginRight: "10px",padding: "9px 4px",fontSize: "14px",borderRadius:"5px"}}
                                            onClick={this.onClickShowSliderForBulkUpload}>
											Bidadi Bulk Upload
											</button>}
											
                                    	</span>
                        </div>
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Source:</label>
                                    <Select
                                                    placeholder={"Select Source"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.targetoption}
                                                    onChange={this.selectotheroption.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[
                                                    { label: "PPC", value: "1" },{label: "Stock", value: "2"},{label: "PPC + Stock", value: "3"}]} required
                                                />
                                </div> */}
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Target:</label>
                                    <Select
                                                    placeholder={"Select option"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.option}
                                                    onChange={this.selectoption.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "option1" },
                                                    { label: "nth month of previous year", value: "option2" },{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "option3" }]} required
                                                />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.plan_month_target}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTime.bind(this)}
                                    />
                                </div>
                                {(this.state.revisionoptionsforTP.length>0)?
                                    <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Revision:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Select
                                        placeholder={"Select Revision"}
                                        closeMenuOnSelect={true}
                                        value={this.state.revisionforTP}
                                        onChange={this.newFilter.bind(this,"revisionforTP")}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.revisionoptionsforTP} required
                                    />
                                </div>:""
                                }
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>

                                </div>
                            </form>
                        </div>

                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "600px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                    masterDetail={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div> 
                <div className={"col-xl-12 col-lg-12 "+(this.state.railtargetshow)}>
                    <div className="card-arms col-xl-12">
                        <div className="card-header-arms">
                        <h5>
                                <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                            </h5>
                        </div>
                        <div>
                        <button className="float-right f15 custom-btn btn-secondary" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onclickopenrailtargetsummaryTab}>
                            Rail Target Summary
                            </button>
                        {(this.state.approval_status != 1 || this.state.approval_status != "1")?
                            <button className="float-right f15 custom-btn btn-primary" style={{ padding: "5px 10px", marginRight: "2%", borderRadius:"5px"}} onClick={this.onclickShowsliderforupload}>
											Bulk Upload
                            </button>:
                            <button className="float-right f15 custom-btn btn-primary disabled" disabled={true} style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onclickShowsliderforupload}>
                            Bulk Upload
                            </button>}
                            {(this.state.approval_status != 1 || this.state.approval_status != "1")?
                            <button className="float-right f15 custom-btn btn-success" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onclicksendApproval}>
                            Approve
                            </button>:
                            <button className="float-right f15 custom-btn btn-success disabled" disabled={true} style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onclicksendApproval}>
                            Approve
                            </button>}

                        </div>
                           
                        <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                            
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.plan_month_rail}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTime2.bind(this)}
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Week:</label>
                                    <Select
                                        placeholder={"Select Week"}
                                        closeMenuOnSelect={true}
                                        value={this.state.week}
                                        onChange={this.selectweek.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ label: "Week 1", value: "1" },
                                        { label: "Week 2", value: "2" },{ label: "Week 3", value: "3" },{ label: "Week 4", value: "4" },{label:"All Month",value:"all"}]} required
                                    />
                                </div>
                                {(this.state.revisionoptions.length>0)?
                                    <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Revision:</label>
                                    <Select
                                        placeholder={"Select Revision"}
                                        closeMenuOnSelect={true}
                                        value={this.state.revision}
                                        onChange={this.newFilter.bind(this,"revision")}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.revisionoptions} required
                                    />
                                </div>:""
                                }
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onSubmitgetRailTarget.bind(this)}>Submit</button>

                                </div>
                            </form>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                            
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Target Count:</label>
                                    <input type="number" value={this.state.targetvalue} id="target_value" autocomplete="off" className="form-control" onChange={this.changeHandler}  required />
                                    
                                </div>
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    {(this.state.approval_status != 1 || this.state.approval_status != "1")?

                                    <button type="button" className="btn btn-warning" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onSubmitupdateTarget.bind(this)}>Update Target</button>:
                                    <button type="button" className="btn btn-warning disabled" disabled={true} style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onSubmitupdateTarget.bind(this)}>Update Target</button>}
                                </div>
                               
                            </form>
                                
                            
                        </div>
                        {(this.state.approval_status == 1 || this.state.approval_status == "1")?
                                <div className='row'>
                                    <div className='col-xl-4 col-lg-4'></div>
                                    <div className='col-xl-4 col-lg-4'>
                                    <span style={{marginBottom:"2px",fontSize:"16px"}}>{"Approved By  : " + this.state.approved_by} </span>
                                    </div>
                                    <div className='col-xl-4 col-lg-4'>
                                    <span style={{marginBottom:"2px",fontSize:"16px"}}>{"Approved On  : " + getHyphenDDMMMYYYYHHMM(this.state.approval_on)} </span>
                                    </div> 
                                </div>:""}
                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>
                                                        */}
                                
                                
                            <div id="myGrid" style={{ width: "100%", height: "500px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData2}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.summarydashboardshow)}>
                <div className="card-arms col-xl-12">
                    <div className="card-header-arms">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle}</span>
                        </h5>
                    </div>
                    <div className="col-xl-12 col-lg-12">
                        <button type="button" className={"btn "+(this.state.plantwisetabactive)} onClick={this.onClickShowSubTabView.bind(this,"plantwise")}>Plant Wise Target vs Achievement</button>
                        <button type="button" className={"btn "+(this.state.modewisetabactive)} onClick={this.onClickShowSubTabView.bind(this,"modewise")}>Mode & Route Wise Target vs Achievement</button>
                        <button type="button" className={"btn "+(this.state.locationwisetabactive)} onClick={this.onClickShowSubTabView.bind(this,"locationwise")}>Location Type & Mode Wise Summary</button>
                        {/* <button type="button" className={"btn "+(this.state.clusterwisetabactive)} onClick={this.onClickShowSubTabView.bind(this,"clusterwise")}>Cluster & Location Wise Summary</button> */}
                    </div>
                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                      
                        <div className={"col-xl-3 col-lg-3 form-group "+(this.state.plantwiseshow)}>
                        <label>Location:</label>
                        <Select
                                        placeholder={"Select Location"}
                                        closeMenuOnSelect={true}
                                        value={this.state.location}
                                        onChange={this.selectlocation.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ label: "Haryana", value: "H" },
                                        { label: "Gujarat", value: "G" },
                                        { label: "Bidadi", value: "B" }]} required
                                    />
                        </div>
                        <div className={"col-xl-3 col-lg-3 form-group "+(this.state.modewiseshow)}>
                        <label>Mode:</label>
                        <Select
                                        placeholder={"Select Location"}
                                        closeMenuOnSelect={true}
                                        value={this.state.mode}
                                        onChange={this.selectmode.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ label: "Overall", value: "overall" },
                                        { label: "Carrier", value: "carrier" },
                                        { label: "Rail", value: "rail" },
                                        { label: "Road", value: "road" }]} required
                                    />
                        </div>
                            {/* <div className="col-xl-3 col-lg-3 form-group">
                                <label>Target:</label>
                                <Select
                                                placeholder={"Select option"}
                                                closeMenuOnSelect={true}
                                                value={this.state.plan_type}
                                                onChange={this.selectplantype.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
                                                { label: "nth month of previous year", value: "2" },{ label: "((n-1)+(n-2)+(n-3))/3 months of current year", value: "3"}]} required
                                            />
                            </div> */}
                            {(this.state.locationwiseshow == "show-m" || this.state.clusterwiseshow=="show-m")?
                            "":
                                 <div className="form-group col-xl-2 col-lg-2">
                                 <label>&nbsp;</label><br />
                                 <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.getdashboardsummaryData.bind(this)}>Submit</button>
 
                             </div>
                            }
                           
                        </form>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.plantwiseshow)}>
                    

                    <div className="grid-container col-xl-12 col-lg-12">   
                            {/* <div className="card-header" style={{ padding: '10px' }}>
                                <h5>{this.state.gridTitle}</h5>
                            </div>                        */}
                        <div id="myGrid" style={{ width: "100%", height: "930px"}} className={"ag-theme-balham grid-card"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs3}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData3}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                pinnedBottomRowData={this.state.pinnedRowData}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                          return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                      },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.modewiseshow)}>
                    <div className="grid-container col-xl-12 col-lg-12">   
                            {/* <div className="card-header" style={{ padding: '10px' }}>
                                <h5>{this.state.gridTitle}</h5>
                            </div>                        */}
                        <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs4}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData4}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                pinnedBottomRowData={this.state.pinnedRowData4}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                          return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                      },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.locationwiseshow)}>
                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs5}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData5}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    pinnedBottomRowData={this.state.pinnedRowData3}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-xl-12 col-lg-12 "+(this.state.clusterwiseshow)}>
                        <div className="grid-container col-xl-12 col-lg-12">   
                                {/* <div className="card-header" style={{ padding: '10px' }}>
                                    <h5>{this.state.gridTitle}</h5>
                                </div>                        */}
                            <div id="myGrid" style={{ width: "100%", height: "400px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs7}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData7}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    pinnedBottomRowData={this.state.pinnedRowData3}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div> 
                <div className={"col-xl-12 col-lg-12 "+(this.state.railschedulingshow)}>
                <div className="card-arms col-xl-12">
            <div class="card-header-arms">
                <h5>
                    <i className="icofont icofont-train-line cus-i"></i> <span>{this.state.pagetitle}</span>
                    
                </h5>
            </div>
            <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                            <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                                
                            
                               
                                <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.month_rakescheduling}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTimeRS.bind(this)}
                                    />
                                </div>
                                {/* {(this.state.revisionoptionsforRS.length>0)?
                                    <div className="col-xl-3 col-lg-3 form-group">
                                    <label>Revision:</label>
                                    <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required />
                                    <Select
                                        placeholder={"Select Revision"}
                                        closeMenuOnSelect={true}
                                        value={this.state.revisionforRS}
                                        onChange={this.newFilter.bind(this,"revisionforRS")}
                                        style={{ borderRadius: "0px" }}
                                        options={this.state.revisionoptionsforRS} required
                                    />
                                </div>:""
                                } */}
                                
                                <div className="form-group col-xl-2 col-lg-2">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-arms" style={{ padding: "5px 10px", marginRight: "2%",borderRadius:"5px" }} onClick={this.onClickGetRakeSchedulingData.bind(this)}>Submit</button>

                                </div>
                            </form>
                        </div>
            <div className="grid-container col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>    
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs6}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData6}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    detailCellRendererParams={this.state.detailCellRendererParams}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    masterDetail={true}
                    // onCellClicked={this.onCellClickedGrid.bind(this)}
                    />
            </div>
            </div>
        </div>
                </div> 
                <div className={"slide-r " +(this.state.bulkslide)} style={{ overflow: "auto", width: "30%" }}>
					<h3 className="subHarms">Bulk Update 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandlerforManualUpload}>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.plan_month_upload}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTime1.bind(this)}
                                    />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Week:</label>
                                    <Select
                                        placeholder={"Select Week"}
                                        closeMenuOnSelect={true}
                                        value={this.state.week_upload}
                                        onChange={this.selectweek1.bind(this)}
                                        style={{ borderRadius: "0px" }}
                                        options={[{ label: "Week 1", value: "1" },
                                        { label: "Week 2", value: "2" },{ label: "Week 3", value: "3" },{ label: "Week 4", value: "4" },{ label: "All Month", value: "all" }]} required
                                    />
                                </div>
								<div className={"form-group col-xl-12 col-lg-12"}>
									<label >Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler1} className="form-control col-xl-12 col-lg-12" required />
								</div>
                                <button type="submit" className="btn btn-arms">Submit</button>
							</form>
							<div className="form-group">
								<p style={{ color: "#ff0000",marginBottom: "0px"}}>*Note: All fields are mandatory and should not be empty.</p><br/>
                                <p style={{ color: "#ff0000",marginBottom: "0px" }}>1st - 8th (Week 1) </p><br/>
                                <p style={{ color: "#ff0000",marginBottom: "0px" }}>8th - 15th (Week 2) </p><br/>
                                <p style={{ color: "#ff0000",marginBottom: "0px" }}>15th - 23rd (Week 3) </p><br/>
                                <p style={{ color: "#ff0000",marginBottom: "0px" }}>&#62;23rd (Week 4) </p><br/>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/rail_target_bulk_upload.csv')} target="_blank">Sample Template</a>
							</div>
						</div>
					</div>
				</div>
                <div className={"slide-r " +(this.state.bulkslide3)} style={{ overflow: "auto", width: "30%" }}>
					<h3 className="subHarms">View Target Details 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.remarks_history_cols}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.remarks_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        // stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				</div>
                <div className={"slide-r " +(this.state.bulkslide4)} style={{ overflow: "auto"}}>
					<h3 className="subHarms">Target Summary Details 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div id="myGrid" style={{ height: "500px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={this.state.rail_target_summary}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.target_summary_data}
                                        rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        // onCellClicked={this.onRowClicked.bind(this)}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}
                                        //editType={this.state.editType}
                                        // stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        // enableCellChangeFlash={true}
                                        // suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        //updateRowHandler={this.updateRowHandler}
                                        //onCellEditingStopped={this.updateCellData.bind(this)}
                                    />
                                </div>
				</div>
                <div className={"slide-r " + (this.state.bulkuploadsliderforData)} style={{ overflow: "auto", width: "30%" }}>
					<h3 className="subHarms">Bidadi Bulk Upload 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandlerforManualUploadForExports}>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Month:</label>
                                    {/* <input type="text" placeholder="Select Month" id="plan_month" autocomplete="off" className="datetimepicker_date form-control"  required /> */}
                                    <Datetime
                                        value={this.state.plan_month_for_uplaod}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{
                                            placeholder: 'Select Month',
                                            name: 'plan_month',
                                            autoComplete: 'off'
                                        }}
                                        dateFormat="YYYY-DD"
                                        name="plan_month"
                                        onChange={this.handlerStartDateTimeforUpload.bind(this)}
                                    />
                                </div>
								<div className={"form-group col-xl-12 col-lg-12"}>
									<label >Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadID2" onChange={this.changeFileHandlerForExports} className="form-control col-xl-12 col-lg-12" required />
								</div>
                                <button type="submit" className="btn btn-arms">Submit</button>
							</form>
							
							<div className="form-group mt-3">
								<a className="btn btn-primary" href={require('../../assets/json/stage_1_bulk_upload.csv')} target="_blank">Sample Template</a>
							</div>
						</div>
					</div>
				</div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}

export default withRouter(Targetpreparation)

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});