import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class ManualTargetUpload extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:"",
            file1:"",
            csvcontent1:""
        };
    }
   
    componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       
        redirectURL.post('/arms/getmanualtargetuploaddata').then((response) => {
            var records = response.data;
            if(records.length > 0){
                this.setState({
                    rowData:records,
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }else{
                this.setState({
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }
          })
          .catch(function (error) {
            console.log(error);
        });
       
    };
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        this.setState({
           show: false
       });
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            bulkslide:""
        });
       
    }
    onclickShowsliderforupload = () => {
		this.setState({
			bulkslide: 'slider-translate',
			overly: "show-m"
		})
	}
    changeFileHandler1 = (e) => {
		const config = {
			headers: [				
				{
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},{
					name: 'transport_mode',
					inputName: 'transport_mode',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'type',
					inputName: 'type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'route_id',
					inputName: 'route_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'cluster',
					inputName: 'cluster',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'for_code',
					inputName: 'for_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'sbvh',
					inputName: 'sbvh',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'quantity',
					inputName: 'quantity',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent1: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			reader.onload = async function (e) {
				var contents = await e.target.result;
				console.log("contents ", contents)
				resolve(contents);
			};
			var tt = reader.readAsText(e.target.files[0]);
			console.log("tt ", tt)
		});
		//console.log("Out ", out);

		this.setState({
			file1: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
		}
		else {
			e.target.value = null;
			this.setState({
				uploadFile: '',
				show: true,
				basicType: 'danger',
				basicTitle: 'Please upload file having extensions .csv only.',
			});
		}
	}
    uploadBulkFormHandlerforManualUpload = (event) => {
		event.preventDefault();
        this.setState({
            loadshow :"show-m",
            overly : "show-m"
        })
		var reqparams = {
				csvcontent: this.state.csvcontent1,
				uploaded_by: localStorage.getItem('email'),
        }
        redirectURL.post("/arms/manualtargetupload",reqparams).then((response)=>{
            if(response.data.message == "success"){
                this.setState({
                    loadshow : "show-n",
                    overly :"show-n",
                    bulkslide :"",
                    file1:"",
                    show:true,
                    basicTitle:"Data Successfully Uploaded",
                    basicType:"success"
                })
                $("#bulkUploadID").val("")
                this.componentDidMount()
            }else{
                this.setState({
                    loadshow : "show-n",
                    overly :"show-n",
                    bulkslide :"",
                    file1:"",
                    show:true,
                    basicTitle:"Data not Updated",
                    basicType:"warning"
                })
                $("#bulkUploadID").val("")
                this.componentDidMount()
            }
        })
	}
 
render(){
    const columnwithDefs = [
        {
            headerName: "Consigner Code",
            field: "consigner_code",
            width: 150,
            editable:false,
            filter: "agSetColumnFilter"
        },
            {
                headerName: "Transport Mode",
                field: "transport_mode",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Type",
                field: "type",
                width: 180,
                filter: false,
                editable:true,
            },
            {
                headerName: "Route Id",
                field: "route_id",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "FOR Code",
                field: "for_code",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "SBVH",
                field: "sbvh",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Quantity",
                field: "quantity",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manual Target Upload</span>
                            </h5>
                            <button className="float-right f15 custom-btn btn-success" onClick={this.onclickShowsliderforupload}>
											Bulk Upload
											</button>
                        </div>

                        <div className="card-body">
                            <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
                <div className={"slide-r " + (this.state.bulkslide)} style={{ overflow: "hidden", width: "30%" }}>
					<h3 className="subH">Bulk Update 
					<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
					</h3>					
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandlerforManualUpload}>
								<div className={"form-group col-xl-12 col-lg-12"}>
									<label >Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler1} className="form-control col-xl-12 col-lg-12" required />
								</div>
                                <button type="submit" className="btn btn-success">Submit</button>
							</form>
							<div className="form-group">
								<p style={{ color: "#ff0000" }}>*Note: All fields are mandatory and should not be empty.</p>
							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_bulk_manual_target_upload.csv')} target="_blank">Sample Template</a>
							</div>
						</div>
					</div>
				</div>
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}