/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import CountUp from 'react-countup';
import 'react-datetime/css/react-datetime.css'
import $ from 'jquery';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import 'jquery-ui/ui/widgets/datepicker';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class Cronjobmonitor extends Component {

	constructor(props){
		super(props);
		this.state = {
			currentDepartmentTitle: null,
			modules: AllModules,
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
      	    defaultColDef: {
      	        sortable: true,
      	        filter: true,
				editable: true,
				resizable: true
      		},
			rowData: [],

			editType: "fullRow",
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
		 
			frameworkComponents: {
				// updateItem:UpdateItem,
                // deleteItem:DeleteItem,
				//statusChange:StatusChange
			},

			rowModelType: "serverSide",
			paginationPageSize:100,
			cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            map_invoice_date: {},
            map_invoice_count: {},
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
			csvcontent:[],
            viewData:[],
            billing_month:"",
            reck_info:[],
            allrowData:[],
            nottrigerred:[],
            trigerred:[],
            dispatchedrakes:[],
            detailCellRendererParams:{},
            remarks_data:[],
            sliderConfigData:"",
            config_region_data:[],
            components: { datePicker: getDatePicker() },
		};
        
        // this.updateRowHandler = this.updateRowHandler.bind(this);
        // this.deleteRowHandler = this.deleteRowHandler.bind(this);
        this.onLoadData = this.onLoadData.bind(this);
	}
	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch(pathToMatch) {
			case "/tptoemmaster":
				department='SNDG';
				departmentName = " ";
				break;
			
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle:departmentName
		});
		return department;
	}
	componentDidMount(){
        loadDateTimeScript()
		if (googleAnalytics.page.enableGA) {
			this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});

        }
        
        this.setState({
            loadshow:"show-n",
            overly:"show-n"
        })

       
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		this.setState({
			departmentCode:currentDepartmentCode
		});
		 /*Consignments List*/		
	   this.onLoadData();
	  
    };
    onLoadData(){
        this.setState({
            overly: "show-m",
            loadshow: "show-m"
        })
        var edate = moment.parseZone().format('YYYY-MM-DD HH:mm');
		var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD HH:mm')
		$("#invoiceStartDate").val(sdate);
		$("#invoiceEndDate").val(edate);
        var params = {
            fromDate:sdate,
            toDate:edate
        }
        redirectURL.post('/arms/getcronjobmonitor',params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
          var records = response.data;
        console.log(records,"line 248")
              this.setState({
                  rowData:records,
                 
                  overly: "show-n",
                  loadshow: "show-n"
              });
        })
     
    }
	logPageView = () => {
		try {
		    if (googleAnalytics.page.enableGA) {
		        googleAnalytics.logPageView();
		        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		        let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
		        let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
		        this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
		    } else {
		        console.log("GA disabled...");
		    }
		} catch(error) {
		    console.error("Error occured while logging to GA, error = ", error);
		}
    }

	onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);
           
         
          
    }

    
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        // console.log(event,"event");
        var d = new Date(event._d);
        // console.log('startDate',d, 'changed date', moment.parseZone(event._d).format('YYYY-MM'))
        var startdate =  moment.parseZone(event._d).format('YYYY-MM')

        this.setState({
            selection_month: startdate
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }



    
    /*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        window.location.reload()
    }

    
	 onAddRow() {
        // var newItem = 
        // {
        //     "truck_no":"",
        //     "truck_type":"",
        //     "transporter_code":"",
        //     "dept_code":""
        // };
        // console.log("newItem",newItem);
        // var res = this.gridApi.updateRowData({ add: [newItem] });
        this.setState({
			sliderRso:'slider-translate',
            overly:'show-m',
            oem_code:"",
            oem_name:"",
            industry_type:""
		})
        
    }
    onRowClicked(cell){
        if(cell.colDef.field == "history_data"){
             this.setState({
                detailCellRendererParams:{
                detailGridOptions: {
                columnDefs: [
                    {
                        headerName: "Process Name",
                        field: "process_name",
                        width: 170,
                      editable:false,
                      filter: "agSetColumnFilter"
                    },
                    {
                        headerName: "Start Time",
                        field: "start_time",
                        width: 120,
                      editable:false,
                      filter: "agSetColumnFilter",
                      valueGetter:function(params){
                        try{
                            return getHyphenYYYYMMDDHHMMSS(params.data.start_time);
                        }
                        catch(e){
                            return "";
                        }
                    },
                    },
                    {
                        headerName: "End Time",
                        field: "end_time",
                        width: 160,
                      editable:false,
                      filter: "agSetColumnFilter",
                      valueGetter:function(params){
                        try{
                            return getHyphenYYYYMMDDHHMMSS(params.data.end_time);
                        }
                        catch(e){
                            return "";
                        }
                    },
                    },
                    {
                        headerName: "Records Count",
                        field: "records_count",
                        width: 90,
                      editable:false,
                      filter: "agSetColumnFilter",
                    },
                    
                ],
                    overlayNoRowsTemplate: 'No rows to show',
                        },
                        getDetailRowData: (params)=>{
                            var formData ={
                                process_name :params.data.process_name
                            }
                            redirectURL.post("/arms/getcronjobmonitorlogs",formData,{"headers":
                            {											
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                        }).then((response)=>{
                                var resp = response.data;
                                console.log(resp,"resp")
                                // this.setState({
                                //     viewData:resp.data,
                                // })
                                params.successCallback(resp)
                            })
                           
                        },
                    masterDetail: true,
                    }
                })
                console.log(cell.colDef.field );
                if(cell.colDef.field == 'history_data')
                {
                    cell.node.setExpanded(!cell.node.expanded);
                }
                else{
                    cell.node.setExpanded(false);
                }
        }
    }
    
    
	 updateRowHandler = async(cell) => {
       
        var rowid = cell._id;
        var oem_code = cell.oem_code;
        var oem_name = cell.oem_name;
        var industry_type = cell.industry_type;
        await this.setState({
			sliderUpdate:'slider-translate',
            overly:'show-m',
            oem_code:oem_code,
            oem_name:oem_name,
            industry_type:industry_type,
            rowid:rowid
		})
        
    }


    
    onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:"",
            sliderConfigData:""
		});
		
    }

    onChangeTruckType(trucktype){
		this.setState(
			{ trucktype },
			() => console.log(`Option selected:`, this.state.trucktype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
	}
  



// onCLickShowRowDetails = async (rownode) => {
// console.log(rownode , "rownode")
//  await this.setState({
//     detailCellRendererParams:{
//     detailGridOptions: {
//     columnDefs: [
//         {
//             headerName: "Transporter",
//             field: "transporter_name",
//             width: 170,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Type of Rake",
//             field: "rake_no",
//             width: 120,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "To Mails",
//             field: "to_emails",
//             width: 160,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "CC Mails",
//             field: "cc_mails",
//             width: 90,
//           editable:false,
//           filter: "agSetColumnFilter",
//         },
//         {
//             headerName: "Reck Number",
//             field: "reck_number",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Invoice Time",
//             field: "invoice_time",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Mail Sent On",
//             field: "mail_sent_on",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//         {
//             headerName: "Mail Sent Type",
//             field: "mail_sent_type",
//             width: 150,
//           editable:false,
//           filter: "agSetColumnFilter"
//         },
//     ],
//         overlayNoRowsTemplate: 'No rows to show',
//             },
//             getDetailRowData: (params)=>{
//                 params.successCallback(this.state.viewData)
//             },
//         masterDetail: true,
//         }
//     })
//     console.log(rownode.colDef.field );
//     if(rownode.colDef.field == 'viewData')
//     {
//         rownode.node.setExpanded(!rownode.node.expanded);
//     }
//     else{
//         rownode.node.setExpanded(false);
//     }
//  }
    
    onChangeTransporter(transporter){
		this.setState(
			{ transporter },
			() => console.log(`Option selected:`, this.state.transporter)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    onChangeDepartment(deptcode){
		this.setState(
			{ deptcode },
			() => console.log(`Option selected:`, this.state.deptcode)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
    }
    
    
     
    onChangeSourceItem(sourceopt){
		this.setState(
			{ sourceopt },
			() => console.log(`Option selected:`, this.state.sourceopt)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    onChangeOfficerType(officertype){
		this.setState(
			{ officertype },
			() => console.log(`Option selected:`, this.state.officertype)
          );
         
		  
		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}
    selectsources(){
		let items = [];    
		try{
			if(this.state.sources.length > 0)
			{
				this.state.sources.map((item) =>{
					items.push({"value":item.geofence_name,"label":item.geofence_name})
				})
			}
		}
		catch(e){

		}		
		return items;
    }
    
    onClickShowBulk()
    {
        this.setState({
            overly:"show-m",
			sliderBulk:'slider-translate',
        })
    }
    
    
	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'oem_name',
					inputName: 'oem_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'oem_code',
					inputName: 'oem_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
                },
                { 
					name: 'industry_type',
					inputName: 'industry_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
				
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				// console.log("contents ", contents )
				
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			// console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		
	}
   
    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }
    applyDatePicker = (e) => {
		console.log("Datepicker ", e);
	}
    render(){
		const modalStyles  = {
			width:'1300px !important',
		}
        var transporters = []
        var trans = this.state.transporters;
        if(trans.length > 0)
        {
            trans.map((item) => {
                transporters.push(item.value)
            })
        }
		const {usermanualmodal} = this.state;
		var hideChilds = true;
		if(this.state.departmentCode == "LOG-PRT")
		{
			hideChilds = false
		}
		const columnwithDefs = [
                {
                    // View Remarks
                    headerName: "History",
                    field: "history_data",
                    width: 170,
                    filter: false,
                    // cellRenderer:'updateItem',
                    editable:false,
                    cellRenderer:function(params){
                        let basestring = params.data.billingId;
                        // let encryptedstring = window.btoa(basestring);
                        var htmloption = '<i class="icofont icofont-site-map f25" aria-hidden="true"></i>';
                        // var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                        return htmloption
                    }
                },
                // {
                //     // View Remarks
                //     headerName: "view ",
                //     // field: "env",
                //     width: 170,
                //     filter: false,
                //     // cellRenderer:'updateItem',
                //     editable:false,
                //     cellRenderer:function(params){
                //         let basestring = params.data.billingId;
                //         let encryptedstring = window.btoa(basestring);
                //         var htmloption = '<i class="icofont icofont-eye">View</i>';
                //         //var htmloption = '<a href="/tptlogin/billingdownload/'+encryptedstring+'" class="custom-btn label label-success"><i class="fa fa-comment f15"></i>View</a>';
                //         return htmloption
                //     }
                // },
                {
	  	          headerName: "process_name",
	  	          field: "process_name",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "env",
	  	          field: "env",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter"
                },
                {
	  	          headerName: "start_time",
	  	          field: "start_time",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                  valueGetter:function(params){
                    try{
                        return getHyphenYYYYMMDDHHMMSS(params.data.start_time);
                    }
                    catch(e){
                        return "";
                    }
                },
                },
                {
	  	          headerName: "end_time",
	  	          field: "end_time",
	  	          width: 170,
	    	      filter: "agSetColumnFilter",
                  editable: (param)=>{
                    return (param.data.rake_no == "")?true:false
                  },
                //   cellEditor: "agSelectCellEditor",
                  cellEditor: 'agRichSelectCellEditor',
                  cellEditorParams: param =>{
                    console.log("param", param)
                    if(param.data.rake_no === ""){
                        return {
                            values: ['BCACBM','NMG']
                          }
                    }
                  } ,
                  valueGetter:function(params){
                    try{
                        return getHyphenYYYYMMDDHHMMSS(params.data.end_time);
                    }
                    catch(e){
                        return "";
                    }
                },
                },
		        {
	  	          headerName: "records_count",
	  	          field: "records_count",
	  	          width: 170,
                  editable:false,
	    	      filter: "agSetColumnFilter",
                },
            ]
        
		return(
			<div className="container-fluid">
			<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
                    <div className="card">
                            
                            <div className="card-header">
				       			<h5>
				       				 <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Cronjobmonitor </span>
                                     
                                </h5>
                            </div>
                           
                            
		            		<div className="card-body">
                                <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        // rowSelection={this.state.rowSelection}
                                        enableCharts={false}
                                        //  paginationAutoPageSize={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        enableRangeSelection={true}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        deleteRowHandler={this.deleteRowHandler}
                                        onCellClicked={this.onRowClicked.bind(this)}
                                        detailCellRendererParams={this.state.detailCellRendererParams}
                                        masterDetail={true}
                                        // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                        frameworkComponents={this.state.frameworkComponents}

                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        // floatingFilter={true}
                                        enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                    />
                                </div>
				   			</div>
				   		</div>	
				   	</div>
				 </div>
				 <div className={"slide-r "+(this.state.sliderTranslate)} >
				 	
					 <div className="slide-r-title">
				 		<h4>
                            Sidebars
                        </h4>
				 	</div>
				 	<div className="slide-r-body" style={{position:"relative"}}>
						
				 		<div className="col-xl-12 col-lg-12">
				 		<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg "+(this.state.alerterrshow)+" "+(this.state.alerterrmg)}>{this.state.csverrmessage}</div>
	        			
				 		</div>
				 	</div>
				 </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            

            </div>
              	
		);
	}
}
function getDatePicker() {
	function Datepicker() {}
	Datepicker.prototype.init = function(params) {
	  this.eInput = document.createElement("input");
	  //this.eInput = this.eInput.setAttribute("class","datepicker");
	  this.eInput.value = params.value;
	  console.log(this.eInput)
	  window.$(".datepicker").datepicker({ dateFormat: "dd/mm/yy" });
	};
	Datepicker.prototype.getGui = function() {
	  return this.eInput;
	};
	Datepicker.prototype.afterGuiAttached = function() {
	  this.eInput.focus();
	  this.eInput.select();
	};
	Datepicker.prototype.getValue = function() {
	  return this.eInput.value;
	};
	Datepicker.prototype.destroy = function() {};
	Datepicker.prototype.isPopup = function() {
	  return false;
	};
	return Datepicker;
  }

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    console.log(params, "params")
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}
function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}
function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	//console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
    console.log(date1Number, date2Number, "comparator")
	return date1Number - date2Number;
	}	
}
function monthToComparableNumber1(date) {
	//console.log(date.length);
	//console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.device_filter_data').datetimepicker({
		format:'Y-m-d H:i',
		timepicker:true
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}


