import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class Routeclustermapping extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents : {
            // UpdateButton:UpdateButton,
               // DeleteButton:DeleteButton,
              },

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:'',
            bulkslide2:'',
            for_code:'',
            cluster:'',
            for_code:'',
            clusterList:[],
            forCodeList:[],
            plantList:[],
            cluster:"",
            for_code:"",
            plant:"",
            edit_cluster:"",
            edit_for_code:"",
            edit_plant:""
        };
    }
   
    componentDidMount() {
        document.body.addEventListener("click", this.handleClickOutside);
        this.setState({
          loadshow: "show-m",
          overly: "show-m"
        });
      this.onLoadpageData()
        redirectURL.post("/arms/getDataforrouteclustermapping",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }else{
                var clusterData = response.data.clusterList
                var forCodeData = response.data.forCodeList
                var plantData = response.data.plantList
                var clusterList = []
                var forCodeList = []
                var plantList = []
                clusterData.map((e)=>{
                    clusterList.push({
                        value :e,
                        label :e
                    })
                })
                forCodeData.map((e)=>{
                    forCodeList.push({
                        value :e,
                        label:e
                    })
                })
                plantData.map((e)=>{
                    plantList.push({
                        value : e.plant_code,
                        label : e.plant_name
                    })
                })
                this.setState({
                    forCodeList : forCodeList,
                    clusterList : clusterList,
                    plantList : plantList
                })
            }
        })
      };
    onLoadpageData = ()=>{
        redirectURL.post('/arms/getrouteclustermapping',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }else{
                console.log(response.data, "108");
                var records = response.data;
                if (records.length > 0) {
                // Reverse the order of the records
                records.reverse();
        
                this.setState({
                    rowData: records,
                    overly: "show-n",
                    loadshow: "show-n"
                });
                }
            }
        })
    }

    onClickShowFilterData(event) {
        event.preventDefault();
        this.setState({
            loadshow :"show-m",
            overly:"show-m"
        })
        var route_id = $("#route_id").val()
        var cluster = this.state.cluster.value
        var for_code = this.state.for_code.value
        var plant = this.state.plant.value
        // var msil_code  =  $("#msil_code").val()
        var params ={
            route_id:route_id.toUpperCase(),
            cluster:cluster,
            for_code:for_code,
            plant:plant,
            username : localStorage.getItem("username")
        }
        if(cluster != undefined && cluster != null && cluster != ""){
           if(for_code != undefined && for_code != null && for_code != ""){
                if(plant != undefined && plant != null && plant != ""){
                    redirectURL.post("arms/addrouteclustermappingdetails",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then(response=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }else{
                        console.log(response)
                        if(response.data.message == "Success")
                            {
                                this.setState({
                                    basicTitle:"Data Add Successfully.",
                                    basicType : "success",
                                    show : true,
                                    bulkslide:'',
                                    cluster :"",
                                    for_code:"",
                                    plant:"",
                                    loadshow :"show-n",
                                    overly:"show-n"
                                })
                                $("#route_id").val("")
                                this.onLoadpageData();
                            }
                            else
                            {
                                this.setState({
                                    basicTitle:response.data.message,
                                    basicType : "warning",
                                    show : true,
                                    bulkslide:'',
                                    loadshow :"show-n",
                                    overly:"show-n"
                                })
                            }
                        }
                    })
                }else{
                    this.setState({
                        basicTitle:"Please Select Plant",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            
           }else{
            this.setState({
                basicTitle:"Please Select For Code",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
           }
            
        }else{
            this.setState({
                basicTitle:"Please Select Cluster",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }

    }
    onClickShowFilterData2(event) {
        event.preventDefault();
        this.setState({
            loadshow :"show-m",
            overly:"show-m"
        })
        var route_id = $("#edit_route_id").val()
        var cluster = this.state.edit_cluster.value
        var for_code = this.state.edit_for_code.value
        var plant = this.state.edit_plant.value
        // var msil_code2  =  $("#msil_code2").val()
        var rowId = this.state.rowId
       
            var params ={
                route_id:route_id.toUpperCase(),
                cluster:cluster,
                for_code:for_code,
                plant:plant,
                rowId : rowId,
                edited_by : localStorage.getItem("username")
                // msil_code:msil_code2,
            }
            if(cluster != undefined && cluster != null && cluster != ""){
                if(for_code != undefined && for_code != null && for_code != ""){
                     if(plant != undefined && plant != null && plant != ""){
                        redirectURL.post("arms/editrouteclustermappingdetails",params,{"headers":
                        {											
                            'Content-Type': 'application/json;charset=UTF-8', 
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                    }).then(response=>{
                            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                this.setState({
                                    show:true,
                                    basicTitle:"Session Expired",
                                    basicType:"warning",
                                    overly:"show-n",
                                    loadshow:"show-n"
                                })
                            }else{
                                console.log(response)
                                if(response.data.message == "Success")
                                    {
                                        this.setState({
                                            basicTitle:"Data update Successfully.",
                                            basicType : "success",
                                            show : true,
                                            loadshow :"show-n",
                                            overly:"show-n",
                                            bulkslide2:""
                                        })
                                        this.onLoadpageData();
                                    }
                                    else
                                    {
                                        this.setState({
                                            basicTitle:response.data.message,
                                            basicType : "warning",
                                            show : true,
                                            loadshow :"show-n",
                                            overly:"show-n",
                                            bulkslide2:""
                                        })
                                    }
                            }
                        })
                    }else{
                        this.setState({
                            basicTitle:"Please Select Plant",
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                    }
                
               }else{
                this.setState({
                    basicTitle:"Please Select For Code",
                    basicType : "warning",
                    show : true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
               }
                
            }else{
                this.setState({
                    basicTitle:"Please Select Cluster",
                    basicType : "warning",
                    show : true,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }

    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
        
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide2:"",
            cluster :"",
            for_code:"",
            plant:"",
        });
        $("#route_id").val("")
    }
    handleClickOutside = (event) => {
        if (!event.target.closest(".slide-r")) { // Close the slider when clicking outside of it 
           this.onClickHideAll(); 
       }
    };
  
   
    onCellClicked(e){
        if(e.colDef.field == "delete"){
            this.setState({
                overly:"show-m",
                loadshow:"show-m"
            })
            var qry = {
                rowId:e.data._id,
                deleted_by:localStorage.getItem("email"),
                username:localStorage.getItem("username"),
                                // designation: localStorage.getItem('designation'),
                // user_role: localStorage.getItem('roles'),
            }
            console.log(qry,"243")
            var confirm = window.confirm("Are You Sure! Do you really want to delete?")
            if (confirm == true)
            {
                redirectURL.post("/arms/deleterouteclustermappingdata",qry,{"headers":{											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }}).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }else{
                        console.log(response.data);
                        if(response.data.message == "success")
                        {
                            this.setState({
                                basicTitle:"Deleted Successfully.",
                                basicType : "success",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                            //window.location.reload();
                            this.onLoadpageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:"Failed to Delete.",
                                basicType : "warning",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                        }
                    }
                })
            }else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n"
                })
            } 
        }
        if(e.colDef.field == "edit"){
            var checkdata = e.data;
            var route_id = $("#edit_route_id").val(checkdata.route_id)
            this.setState({
                bulkslide2:"slider-translate-30p",
                rowId :checkdata._id,
                overly:"show-m",
                edit_plant : {"value":checkdata.plant,"label":checkdata.plant},
                edit_for_code :{"value":checkdata.for_code,"label":checkdata.for_code},
                edit_cluster :{"value":checkdata.cluster,"label":checkdata.cluster},
            })
        } 
    }
    addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{plant_code:"",plant_map_cod:"",msil_code:"",}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        bulkslide:"slider-translate-30p",
        // rowId : rowId
        overly:"show-m"
    })
    
    // this.setState({
    //     bulkslide2:"slider-translate-30p",
    //     // rowId : rowId
    // })

}
  changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
selectcluster =(e)=>{
    this.setState({
        cluster :e
    })
}
selectforcode =(e)=>{
    this.setState({
        for_code :e
    })
}
selectplant = (e)=>{
    this.setState({
        plant:e
    })
}
selecteditcluster = (e) =>{
    this.setState({
        edit_cluster :e
    })
}
selecteditforcode = (e) =>{
    this.setState({
        edit_for_code :e
    })
}
selecteditplant = (e)=>{
    this.setState({
        edit_plant :e
    })
}
render(){
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    const columnwithDefs = [
       
            {
                headerName: "Route Id",
                field: "route_id",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Cluster",
                field: "cluster",
                width: 100,
                filter: "agSetColumnFilter",
                editable:false,
            },
            {
                headerName: "For Code",
                field: "for_code",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Plant",
                field: "plant",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                pinned:"left",
                headerName : "",
                field:"edit",
                width:150,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    var rendComponent = '<button class="btn btn-arms"  id="buttonStyling">Edit</button>'
                    return rendComponent;
                },
                filter:false,
            },
            {
                pinned:"left",
                headerName : "",
                field:"delete",
                width:80,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter:false,
            },       
            // {
            //     headerName: "Dom Exp Flag",
            //     field: "dom_exp_flag",
            //     width: 170,
            //     editable:false,
            //     filter: "agSetColumnFilter",
            //     valueGetter:function(params){
            //         if(params.data.dom_exp_flag == "E"){
            //             return "Exports"
            //         }
            //         else if(params.data.dom_exp_flag == "D"){
            //             return "Domestic"
            //         }
            //         else{
            //             return ""
            //         }
            //     }
            // },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Route Cluster Mapping</span>
                            </h5>
                        </div>
                <h5>
                    <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)} >Add master Details</button>
                </h5>
                    

                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Add Cluster Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Route Id* : </label>
                                            <input type="text" name="route_id" id="route_id" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Cluster:</label>
                                    <Select
                                                    placeholder={"Select Cluster"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.cluster}
                                                    onChange={this.selectcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.clusterList} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>FOR Code:</label>
                                    <Select
                                                    placeholder={"Select For Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.for_code}
                                                    onChange={this.selectforcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.forCodeList} required
                                                />
                                </div> 
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.plant}
                                                    onChange={this.selectplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantList} required
                                                />
                                </div>   
                                   
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>

                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit master details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">Route Id* : </label>
                                            <input type="text" name="edit_route_id" id="edit_route_id" className="form-control alfa-cls" onChange={this.changeHandler} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Cluster:</label>
                                    <Select
                                                    placeholder={"Select Cluster"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_cluster}
                                                    onChange={this.selecteditcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.clusterList} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>FOR Code:</label>
                                    <Select
                                                    placeholder={"Select For Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_for_code}
                                                    onChange={this.selecteditforcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.forCodeList} required
                                                />
                                </div> 
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_plant}
                                                    onChange={this.selecteditplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantList} required
                                                />
                                </div>  
                                  
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>

        </div>
           
    );
}
}
$(document).on("input", ".alfa-cls", function () {
    this.value = this.value.replace(/[^a-z\s]+$/gi, '');
  });
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}