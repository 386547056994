'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
import Modal from 'react-responsive-modal';
import Select from 'react-select';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class RpTrainDimensions extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        transportersList : [],
        rakesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        //components : {datepicker:getDatePicker()}
        open:false,
        wagon_type:"",
        deck_type:"",
        type_of_submit : ""
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

logPageView = () => {
    try {
        if (googleAnalytics.page.enableGA) {
            googleAnalytics.logPageView();
            let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
            let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
            let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
            this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
        } else {
            console.log("GA disabled...");
        }
    } catch(error) {
        console.error("Error occured while logging to GA, error = ", error);
    }
}  
    
componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
}

onLoadPageData(){
    this.setState({
        loadshow:'show-m',
        overly:"show-m"
    })
    redirectURL.post("/arms/getTrainDimensions",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data,
            loadshow:'show-n',
            overly:'show-n'
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rake_type : e.data.rake_type,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_role: localStorage.getItem('roles'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteTrainDimensions",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    this.componentDidMount()
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
}

AddTrainDimesionsData = (event) => {
    this.setState({
        loadshow:'show-m',
        overly:'show-m'
    })
    event.preventDefault()

    var rake_type = $("#rake_type").val().trim()
    var rake_type = rake_type.toUpperCase()
    var formdata = [
        {
            rake_type :rake_type,
            wagon_type: "TYPE_A",
            deck_type:"UPPER",
            length_mm:parseInt($("#lenght_a_upper").val()),
            max_height_mm:parseInt($("#max_height_a_upper").val()),
            min_height_mm:parseInt($("#min_height_a_upper").val()),
            width_mm:parseInt($("#width_a_upper").val()),
            height_adjust_mm:parseInt($("#height_adjust_a_upper").val()),
            bumper_distance_mm:parseInt($("#car_to_car_space_a_upper").val()),
            rake_count:parseInt($("#type_a_wagon_count").val()),
            height_clearance:parseInt($("#height_clearance_a_upper").val())
        },
        {
            rake_type :rake_type,
            wagon_type: "TYPE_A",
            deck_type:"LOWER",
            length_mm:parseInt($("#lenght_a_lower").val()),
            max_height_mm:parseInt($("#max_height_a_lower").val()),
            min_height_mm:parseInt($("#min_height_a_lower").val()),
            width_mm:parseInt($("#width_a_lower").val()),
            height_adjust_mm:parseInt($("#height_adjust_a_lower").val()),
            bumper_distance_mm:parseInt($("#car_to_car_space_a_lower").val()),
            rake_count:parseInt($("#type_a_wagon_count").val()),
            height_clearance:parseInt($("#height_clearance_a_lower").val())
        },
        {
            rake_type : rake_type,
            wagon_type: "TYPE_B",
            deck_type:"UPPER",
            length_mm:parseInt($("#lenght_b_upper").val()),
            max_height_mm:parseInt($("#max_height_b_upper").val()),
            min_height_mm:parseInt($("#min_height_b_upper").val()),
            width_mm:parseInt($("#width_b_upper").val()),
            height_adjust_mm:parseInt($("#height_adjust_b_upper").val()),
            bumper_distance_mm:parseInt($("#car_to_car_space_b_upper").val()),
            rake_count:parseInt($("#type_b_wagon_count").val()),
            height_clearance:parseInt($("#height_clearance_b_upper").val())
        },
        {
            rake_type : rake_type,
            wagon_type: "TYPE_B",
            deck_type:"LOWER",
            length_mm:parseInt($("#lenght_b_lower").val()),
            max_height_mm:parseInt($("#max_height_b_lower").val()),
            min_height_mm:parseInt($("#min_height_b_lower").val()),
            width_mm:parseInt($("#width_b_lower").val()),
            height_adjust_mm:parseInt($("#height_adjust_b_lower").val()),
            bumper_distance_mm:parseInt($("#car_to_car_space_b_lower").val()),
            rake_count:parseInt($("#type_b_wagon_count").val()),
            height_clearance:parseInt($("#height_clearance_b_lower").val())
        }
    ]

    var rake_count = {
        type_a_wagon_count : parseInt($("#type_a_wagon_count").val()),
        type_b_wagon_count : parseInt($("#type_b_wagon_count").val())
    }

    

    redirectURL.post("/arms/addingTrainDimensionData" , {formdata , rake_count, user_name:localStorage.getItem("username")},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data , "resposne_data_1729")
        var resp = response.data
        if(resp.message =="success"){
            this.setState({
                loadshow: 'show-n',
                overly:'show-n',
                open:false,
                show:true,
                basicType:'success',
                basicTitle:'Train Dimensions Data Updated Successfully'
            })
            this.componentDidMount()
        }
        else if (resp.message == "Rake Type Already Exists"){
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                open:false,
                show:true,
                basicType:'warning',
                basicTitle:"Rake Type Already Exists"
            })
        }else{
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                open:false,
                show:true,
                basicType:'danger',
                basicTitle:"Data not Updated"
            })
        }
    }
    })
}

updateTrainDimesionsData = (event) => {
    this.setState({
        loadshow:'show-m',
        overly:'show-m'
    })
    event.preventDefault()
    var data = this.state.data_for_update
    var rake_type = $("#rake_type").val().trim()
    var rake_type = rake_type.toUpperCase()
    var formdata = {
        rake_type: rake_type,
        wagon_type: data.wagon_type,
        deck_type: data.deck_type,
        length_mm: parseInt($("#lenght").val()),
        max_height_mm: parseInt($("#max_height").val()),
        min_height_mm: parseInt($("#min_height").val()),
        width_mm: parseInt($("#width").val()),
        height_adjust_mm: parseInt($("#height_adjust").val()),
        bumper_distance_mm: parseInt($("#car_to_car_space").val()),
        rake_count:data.wagon_type == "TYPE_A" ?parseInt($("#type_a_wagon_count").val()) : parseInt($("#type_b_wagon_count").val()),
        height_clearance: parseInt($("#height_clearance").val()),
        user_name : localStorage.getItem("username")
    }

    if(rake_type !=data.rake_type){
        formdata.old_rake_type = data.rake_type
    }
    
    redirectURL.post("/arms/updateTrainDimensionData" , formdata,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(response => {
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        var resp = response.data
        if(resp.message =="success"){
            this.setState({
                loadshow: 'show-n',
                overly:'show-n',
                open:false,
                show:true,
                basicType:'success',
                basicTitle:'Train Dimensions Data Updated Successfully'
            })
            this.componentDidMount()
        }else{
            this.setState({
                loadshow:'show-n',
                overly:'show-n',
                open:false,
                show:true,
                basicType:'danger',
                basicTitle:"Data not Updated"
            })
        }
    }
    })
}

updateLmStates = async (data) => {
    console.log(data.rake_type)
   await this.setState({
        open:true,
        wagon_type:data.wagon_type,
        deck_type:data.deck_type,
        type_of_submit : "update",
        data_for_update : data
    })
    $("#rake_type").val(data.rake_type)
    $("#lenght").val(data.length_mm)
    $("#max_height").val(data.max_height_mm)
    $("#min_height").val(data.min_height_mm)
    $("#width").val(data.width_mm)
    $("#height_adjust").val(data.height_adjust_mm)
    $("#car_to_car_space").val(data.bumper_distance_mm)
    data.wagon_type == "TYPE_A" ?$("#type_a_wagon_count").val(data.rake_count) :$("#type_b_wagon_count").val(data.rake_count)
    $("#height_clearance").val(data.height_clearance)
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    
    });
    
}

addNewRow()
{
    this.setState({
        open:true
    })
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['rake_type', 'wagon_type', 'deck_type', 'length_mm', 'max_height_mm', 'min_height_mm', 'width_mm', 'height_adjust_mm', 'bumper_distance_mm', 'rake_count', 'height_clearance']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate"
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'rake_type',
                inputName: 'rake_type',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'wagon_type',
                inputName: 'wagon_type',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'deck_type',
                inputName: 'deck_type',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'length_mm',
                inputName: 'length_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'max_height_mm',
                inputName: 'max_height_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'min_height_mm',
                inputName: 'min_height_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'width_mm',
                inputName: 'width_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'height_adjust_mm',
                inputName: 'height_adjust_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'bumper_distance_mm',
                inputName: 'bumper_distance_mm',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'rake_count',
                inputName: 'rake_count',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'height_clearance',
                inputName: 'height_clearance',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
    if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
        var data = CSVFileValidator(e.target.files[0], config)
        .then((csvData) => {
            console.log(csvData)
            this.setState({
                csvData:csvData.data
            });
            console.log(csvData.data);
        })
        .catch(err => {})
        // console.log("e.target.files[0]",e.target.result);
        
        var out = new Promise(function(reject, resolve){
            var reader =  new FileReader();
            reader.onload = async function(e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt =  reader.readAsText(e.target.files[0]);
        });
        this.setState({
            file:e.target.files[0]
        });
        //this.checkFileData();
        //console.log("e.target.files[0].type ", e.target.files[0]);
        var check = verifyext(e.target.files[0].name);
        //console.log("Check ", check)
        if(check == true)
        {
            if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
            {
                // console.log(fileData);
                // console.log(typeof(fileData))
                
            }
            else{
                e.target.value = null;
                this.setState({
                    uploadFile:'',
                    show: true, 
                    basicType:'danger', 
                    basicTitle:'Please upload file having extensions .csv only.',
                });
            }
        }
        else{
            e.target.value = null;
            this.setState({
                uploadFile:'',
                show: true, 
                basicType:'danger', 
                basicTitle:'Please upload file having extensions .csv only.',
            });
        }
    }
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            file:""
        });
    }
}


onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}

onCloseModal = () => {
    this.setState({ open: false , type_of_submit : "" });
  };

  render() {
    var columnwithDefs = [
        {
            headerName: "Length (mm)",
            field: "length_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Max Height (mm)",
            field: "max_height_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Min Height (mm)",
            field: "min_height_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Width (mm)",
            field: "width_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Height Adjust (mm)",
            field: "height_adjust_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Car to Car Space (mm)",
            field: "bumper_distance_mm",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Rake Count",
            field: "rake_count",
            width:120,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            headerName: "Height Clearance",
            field: "height_clearance",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: NumericCellEditor,
        },
        {
            pinned:"left",
            headerName : "Update",
            field:"_id",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },   
        {
            pinned:"left",
            headerName: "Rake Type",
            field: "rake_type",
            width:120,
            filter: true,
            resizable: true,
            editable:false,      
        },
        {
            pinned:"left",
            headerName: "Wagon Type",
            field: "wagon_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            pinned:"left",
            headerName: "Deck Type",
            field: "deck_type",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
        },
        // {
        //     pinned:"left",
        //     headerName: "id",
        //     field: "id",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="card-arms col-xl-12">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-train cus-i"></i> <span>Train Dimensions</span>
                    
                </h5>
            </div>
            <div>
            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)}>Add Train Dimensions</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
            </div>
            <div className="grid-container col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham grid-card "+this.state.showGridData}>
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
            <Modal open={this.state.open} onClose={this.onCloseModal} styles={{ width: '1300px !important', }} >
                <div className={"row"} style={{ height: '100%', padding: '5px 0px', width: '1185px', overflow: 'hidden', zIndex: '9999', }} >
                    <div className="card-body pt-15px">
                        {this.state.type_of_submit == "update"?
                        <form class="update-form" onSubmit={this.updateTrainDimesionsData}>
                            <div className="row col-xl-12">
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Rake Type : </label>
                                    <input type="text" name="rake_type" id="rake_type" className=" form-control" required/>
                                </div>
                                {this.state.wagon_type == "TYPE_A"?<div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Type A Wagon Count : </label>
                                    <input type="number" name="type_a_wagon_count" id="type_a_wagon_count" className="form-control"
                                         required />
                                </div>:""}
                                {this.state.wagon_type == "TYPE_B"?<div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Type B Wagon Count : </label>
                                    <input type="number" name="type_b_wagon_count" id="type_b_wagon_count" className="form-control"
                                         required />
                                </div>:""}
                            </div>                            
                            <div class="hr-dashed"></div>       
                            <div className="row col-xl-12">
                                <div className="col-xl-12 mb-3" >
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Wagon Type : {this.state.wagon_type.substring(this.state.wagon_type.length - 1 , this.state.wagon_type.length)} </label>
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Deck Type : {this.state.deck_type} </label>
                                </div>
                                <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Length (mm) : </label>
                                    <input type="number" name="lenght" id="lenght" className="form-control" required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Max Height (mm) : </label>
                                    <input type="number" name="max_height" id="max_height" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Min Height (mm) : </label>
                                    <input type="number" name="min_height" id="min_height" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Width (mm) : </label>
                                    <input type="number" name="width" id="width" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Adjust (mm) : </label>
                                    <input type="number" name="height_adjust" id="height_adjust" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Car to Car Space (cm) : </label>
                                    <input type="number" name="car_to_car_space" id="car_to_car_space" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Clearance : </label>
                                    <input type="number" name="height_clearance" id="height_clearance" className="form-control"
                                         required />
                                </div>
                            </div>
                            </div>
                            <div class="hr-dashed"></div>       
                            <div className="row col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                            </div>
                        </form>:<form class="add-form" onSubmit={this.AddTrainDimesionsData}>
                            <div className="row col-xl-12">
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Rake Type : </label>
                                    <input type="text" name="rake_type" id="rake_type" className=" form-control" required/>
                                </div>
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Type A Wagon Count : </label>
                                    <input type="number" name="type_a_wagon_count" id="type_a_wagon_count" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-4">
                                    <label className="col-xl-12 col-lg-12">Type B Wagon Count : </label>
                                    <input type="number" name="type_b_wagon_count" id="type_b_wagon_count" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div class="hr-dashed"></div>
                            <div className="row col-xl-12">
                                <div className="col-xl-12 mb-3" >
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Wagon Type : A </label>
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Deck Type : UPPER </label>
                                </div>
                                <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Length (mm) : </label>
                                    <input type="number" name="lenght_a_upper" id="lenght_a_upper" className="form-control" required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Max Height (mm) : </label>
                                    <input type="number" name="max_height_a_upper" id="max_height_a_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Min Height (mm) : </label>
                                    <input type="number" name="min_height_a_upper" id="min_height_a_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Width (mm) : </label>
                                    <input type="number" name="width_a_upper" id="width_a_upper" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Adjust (mm) : </label>
                                    <input type="number" name="height_adjust_a_upper" id="height_adjust_a_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Car to Car Space (cm) : </label>
                                    <input type="number" name="car_to_car_space_a_upper" id="car_to_car_space_a_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Clearance : </label>
                                    <input type="number" name="height_clearance_a_upper" id="height_clearance_a_upper" className="form-control"
                                         required />
                                </div>
                            </div>
                            </div>
                            <div class="hr-dashed"></div>
                            <div className="row col-xl-12">
                                <div className="col-xl-12 mb-3" >
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Wagon Type : A </label>
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Deck Type : LOWER </label>
                                </div>
                                <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Length (mm) : </label>
                                    <input type="number" name="lenght_a_lower" id="lenght_a_lower" className="form-control" required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Max Height (mm) : </label>
                                    <input type="number" name="max_height_a_lower" id="max_height_a_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Min Height (mm) : </label>
                                    <input type="number" name="min_height_a_lower" id="min_height_a_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Width (mm) : </label>
                                    <input type="number" name="width_a_lower" id="width_a_lower" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Adjust (mm) : </label>
                                    <input type="number" name="height_adjust_a_lower" id="height_adjust_a_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Car to Car Space (cm) : </label>
                                    <input type="number" name="car_to_car_space_a_lower" id="car_to_car_space_a_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Clearance : </label>
                                    <input type="number" name="height_clearance_a_lower" id="height_clearance_a_lower" className="form-control"
                                         required />
                                </div>
                            </div>
                            </div>
                            <div class="hr-dashed"></div>
                            <div className="row col-xl-12">
                                <div className="col-xl-12 mb-3" >
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Wagon Type : B </label>
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Deck Type : UPPER </label>
                                </div>
                                <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Length (mm) : </label>
                                    <input type="number" name="lenght_b_upper" id="lenght_b_upper" className="form-control" required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Max Height (mm) : </label>
                                    <input type="number" name="max_height_b_upper" id="max_height_b_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Min Height (mm) : </label>
                                    <input type="number" name="min_height_b_upper" id="min_height_b_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Width (mm) : </label>
                                    <input type="number" name="width_b_upper" id="width_b_upper" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Adjust (mm) : </label>
                                    <input type="number" name="height_adjust_b_upper" id="height_adjust_b_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Car to Car Space (cm) : </label>
                                    <input type="number" name="car_to_car_space_b_upper" id="car_to_car_space_b_upper" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Clearance : </label>
                                    <input type="number" name="height_clearance_b_upper" id="height_clearance_b_upper" className="form-control"
                                         required />
                                </div>
                            </div>
                            </div>
                            <div class="hr-dashed"></div>
                            <div className="row col-xl-12">
                                <div className="col-xl-12 mb-3" >
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Wagon Type : B </label>
                                    <label className="col-xl-4 col-lg-4" style={{ color: "#333", fontWeight: 'bold' }}>Deck Type : LOWER </label>
                                </div>
                                <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Length (mm) : </label>
                                    <input type="number" name="lenght_b_lower" id="lenght_b_lower" className="form-control" required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Max Height (mm) : </label>
                                    <input type="number" name="max_height_b_lower" id="max_height_b_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Min Height (mm) : </label>
                                    <input type="number" name="min_height_b_lower" id="min_height_b_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Width (mm) : </label>
                                    <input type="number" name="width_b_lower" id="width_b_lower" className="form-control"
                                         required />
                                </div>
                            </div>
                            <div className="row col-xl-12">
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Adjust (mm) : </label>
                                    <input type="number" name="height_adjust_b_lower" id="height_adjust_b_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Car to Car Space (cm) : </label>
                                    <input type="number" name="car_to_car_space_b_lower" id="car_to_car_space_b_lower" className="form-control"
                                         required />
                                </div>
                                <div className="col-xl-3">
                                    <label className="col-xl-12 col-lg-12">Height Clearance : </label>
                                    <input type="number" name="height_clearance_b_lower" id="height_clearance_b_lower" className="form-control"
                                         required />
                                </div>
                            </div>
                            </div>
                            <div class="hr-dashed"></div>       
                            <div className="row col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success" style={{ marginLeft: "45%", width: "192px", marginTop: "20px" }}>Submit</button>
                            </div>
                        </form>}
                    </div>
                </div>
            </Modal>	
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};