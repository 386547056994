import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import CountUp from 'react-countup';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class VVDPlantData extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            endDate:"",
            startDate:"",
            dispatchableData:[],
            allrowData:[]
        };
    }
   
    componentDidMount(){
        if (googleAnalytics.page.enableGA) {
         this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});}
       
        this.onLoadData()
    };

    onLoadData = () => {
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        redirectURL.post("/arms/getDataforVDD",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var records = response.data;
            console.log(records.length,"125")
            var statusrecords = records.filter((e)=>e.status == "N")
            console.log(statusrecords.length,"127")
            console.log(records)
            if(records.length > 0){
                this.setState({
                    rowData:records,
                    allrowData:records,
                    dispatchableData:statusrecords,
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }else{
                this.setState({
                    rowData:[],
                    allrowData:[],
                    dispatchableData:[],
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }
        }
          })
    }

    // onClickFilterData = () => {
    //     var params = {
    //         from_date:this.state.startDate , 
    //         to_date : this.state.endDate
    //     }
    //     this.onLoadData(params)
    // }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
	    console.log(d.getMonth() , "month")
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1 <10?"0"+(d.getMonth() + 1):d.getMonth() + 1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(startdate,"startdate")
			console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				startDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 	
			  this.setState({
				startDate:startdate
			  });
			}
		}
		else
		{
		  this.setState({
			startDate:startdate
		  });
		}
		  //let value = event.target.value;
		//this.setState({'startDate':value});
	}

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);    
		var edate = d.getFullYear()+"-"+(d.getMonth() +1 <10?"0"+(d.getMonth() + 1):d.getMonth() + 1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
		if(this.state.endDate !=undefined && this.state.endDate !="")
		{
			console.log(this.state.startDate,"startdate")
			console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			console.log(startTime,endTime);
			if(startTime > endTime)
			{
			  this.setState({
				endDate:""
			  });
			  alert("To Date should be greater than From Date");
			}
			else
			{ 
			  this.setState({
				endDate:edate
			  });
			}
		}
		else
		{
			this.setState({
			  endDate:edate
			});
		}
	}
    onClickTab = (counterKey) => {
        // allcords = []
        console.log(counterKey,"1011")
        this.setState({
            rowData : this.state[counterKey]
        });
    }

render(){
    const columnwithDefs = [
            {
                headerName: "Vin No",
                field: "vin_no",
                width: 160,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Body Colo",
                field: "body_colo",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Cell No",
                field: "cell_no",
                width: 100,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Chas Prefix",
                field: "chas_prefix",
                width: 140,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Chassis",
                field: "chassis",
                width: 100,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Colour",
                field: "colour",
                width: 80,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Country",
                field: "country",
                width: 90,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Days",
                field: "days",
                width: 70,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Engine Model",
                field: "eng_model",
                width: 130,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Engine",
                field: "engine",
                width: 120,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Family Code",
                field: "family_code",
                width: 110,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Order No",
                field: "ord_no",
                width: 100,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Mod Desc",
                field: "mod_desc",
                width: 180,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Parking Zone",
                field: "parking_zone",
                width: 110,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Plant",
                field: "plant",
                width: 80,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "PPNT",
                field: "ppnt",
                width: 80,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Shift",
                field: "shift",
                width: 70,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Status",
                field: "status",
                width: 80,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Supp Colo",
                field: "supp_colo",
                width: 180,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },
            {
                headerName: "Vehicle Model",
                field: "veh_model",
                width: 180,
                filter: "agSetColumnFilter",
                // cellRenderer:'updateItem',
                editable:false
            },


    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>VDD Plan Data </span>
                            </h5>
                        </div>
                        <div className="crm-numbers pb-0">
                                    <div className="row">
                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"allrowData")}>
                                            <span className="f13" style={{color:"#4099FF"}}>Total Stock</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.allrowData.length)?this.state.allrowData.length:0}/>
                                                </span>
                                            </h4>
                                        </div> 
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"dispatchableData")}>
                                            <span className="f13" style={{color:"#FF5370"}}>Dispatchable Stock</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.dispatchableData.length)?this.state.dispatchableData.length:0}/>
                                                </span>
                                            </h4>
                                        </div>                         
                                    </div>                               
                                </div>
                       
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "450px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}