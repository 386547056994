'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
//import DeleteButton from "./deleteshipmentDSR";
import $ from 'jquery';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");




export default class RakeYardDetails extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
         // DeleteButton:DeleteButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:'',
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        rowId : "",
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        cluster_yard  : [],
        rail_yard : [],
        state :[],
        loading_cluster : [],
        unloading_cluster : [],
        baseStations : [],
        addState:"",
        edit_addState:"",
        addCluster:"",
        edit_addCluster:"",
        addPlant:"",
        edit_addPlant:"",
        statesdetails : [],
        clusterdetails:[],
        plantsdetails:[],
        selectloadingcluster:"",
        selectunloadingcluster:"",
        selectbasestation:"",
        edit_selectloadingcluster:"",
        edit_selectunloadingcluster:"",
        edit_selectbasestation:"",
        showloadinginputTab :"",
        showunloadinginputTab :"",
        showbasestationinputTab :"",
        plantdetails :[],
        railyard_name:"",
        latitude:"",
        longitude:"",
        showloadingeditinputTab:"",
        showunloadingeditinputTab:"",
        showbasestationeditinputTab:""
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    this.onLoadPageData();
    redirectURL.post("/arms/getTransportercodesforrakeyarddetails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
})    
    .then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
        var states = response.data.states;
        var  clusters  = response.data.cluster;
        var plants = response.data.plants
        var statesdetails = []
        var clusterdetails = []
        var plantsdetails = []
        states.map((e)=>{
            statesdetails.push({
                value : e,
                label : e
            })
        })
        clusters.map((j)=>{
            clusterdetails.push({
                value : j,
                label : j
            })
        })
        plants.map((b)=>{
            plantsdetails.push({
                value:b,
                label:b
            })
        })
        //console.log("records ", records)
        this.setState({
            statesdetails : statesdetails,
            clusterdetails : clusterdetails,
            plantsdetails : plantsdetails
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}

onLoadPageData(){
    redirectURL.post("/arms/getRailYardDetails",{},{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        this.setState({
            rowData : response.data
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
       
       
        var rowId = e.data._id;
        console.log(e.data._id);
        var qry = {
            rowId:rowId,
            cluster_name : e.data.cluster_name,
            user_name: localStorage.getItem('username'),
            email: localStorage.getItem('email'),
            user_role: localStorage.getItem('roles'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
        if (window.confirm("Are You Sure! Do you really want to delete?"))
        {
            redirectURL.post("/arms/deleteRailYardDetails",qry,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response.data);
                if(response.data.message == "Success")
                {
                    this.setState({
                        basicTitle:"Deleted Successfully.",
                        basicType : "success",
                        show : true
                    })
                    window.location.reload();
                    this.onGridState();
                    this.onLoadPageData();
                }
                else
                {
                    this.setState({
                        basicTitle:"Failed to Delete.",
                        basicType : "warning",
                        show : true
                    })
                }
            }
            }).catch((e)=>{
                console.log(e);
            })
        }        
    }
    if(e.colDef.field == "edit"){
        console.log("Hit--2")
        var railyard_name  = e.data.railyard_name
        console.log(railyard_name,"334")
       var laititude = e.data.latitude
       var longitude = e.data.longitude
       var state = e.data.state
       var cluster_name = e.data.cluster_name
        var loading_cluster = e.data.loading_cluster
        var unloading_cluster = e.data.unloading_cluster
        var base_station = e.data.base_station
        var max_loadings = e.data.max_loadings
        var max_unloadings = e.data.max_unloadings
        var max_loadings_unloadings = e.data.max_loadings_unloadings
        var max_sevice_days = e.data.max_sevice_days
        var rowId = e.data._id
        var plant = e.data.plant
        this.setState({
            bulkslide2:"slider-translate",
            overly:"shom-m",
            edit_addState:{value:state,label:state},
            edit_addCluster:{value:cluster_name,label:cluster_name},
            edit_addPlant:{value:plant,label:plant},
            edit_selectloadingcluster : {value:loading_cluster,label:loading_cluster},
            edit_selectunloadingcluster : {value : unloading_cluster,label:unloading_cluster},
            edit_selectbasestation : {value :base_station,label:base_station},
            showloadingeditinputTab:loading_cluster,
            showunloadingeditinputTab:unloading_cluster,
            showbasestationeditinputTab:base_station,
            rowId : rowId
        })
        $("#edit_railyard_name").val(railyard_name)
        $("#edit_longitude").val(longitude)
        $("#edit_latitude").val(laititude)
        $("#edit_max_loadings").val(max_loadings)
        $("#edit_max_unloadings").val(max_unloadings)
        $("#edit_max_loadings_unloadings").val(max_loadings_unloadings)
        $("#edit_max_sevice_days").val(max_sevice_days)
    }
}

onClickHideAll(){
    $("#upform").val();

    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    addState:"",
    addCluster:"",
    selectloadingcluster:"",
    selectbasestation:"",
    selectunloadingcluster:"",
    addPlant:"",
    showloadinginputTab:"",
    showunloadinginputTab:"",
    showbasestationinputTab:"",
    railyard_name:"",
    latitude:"",
    longitude:""
    });
    $("#max_loadings").val("")
   $("#max_unloadings").val("")
   $("#max_loadings_unloadings").val("")
   $("#max_sevice_days").val("")
  $("#railyard_name").val("")
    $("#latitude").val("")
   $("#longitude").val("")
}
async onClickHideAll1(){
    await this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide1:"",
    addState:"",
    addCluster:"",
    selectloadingcluster:"",
    selectbasestation:"",
    selectunloadingcluster:"",
    addPlant:"",
    showloadinginputTab:"",
    showunloadinginputTab:"",
    showbasestationinputTab:"",
    railyard_name:"",
    latitude:"",
    longitude:""
    }); 
  $("#max_loadings").val("")
   $("#max_unloadings").val("")
   $("#max_loadings_unloadings").val("")
   $("#max_sevice_days").val("")
  $("#railyard_name").val("")
    $("#latitude").val("")
   $("#longitude").val("")
}

addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{station_code:"",state:"",cluster_name:"",railyard_name:"",latitude:"",longitude:"",loading_cluster:"",unloading_cluster:"",base_station:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['station_code', 'railyard_name', 'latitude', 'longitude', 'state', 'cluster_name','loading_cluster','unloading_cluster','base_station', 'bcacbm_eligible','max_loadings','max_unloadings','max_loadings_unloadings','max_sevice_days','plant']
    };
    this.gridApi.exportDataAsCsv(params);
}

showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'station_code',
                inputName: 'station_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'state',
                inputName: 'state',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'cluster_name',
                inputName: 'cluster_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'railyard_name',
                inputName: 'railyard_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'latitude',
                inputName: 'latitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'longitude',
                inputName: 'longitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'loading_cluster',
                inputName: 'loading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'unloading_cluster',
                inputName: 'unloading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'base_station',
                inputName: 'base_station',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'bcacbm_eligible',
                inputName: 'bcacbm_eligible',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     this.setState({
                    //         csvcontent: csvData.data
                    //     });
                    // }
                    const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#]*$/); // define allowed characters using regex
                    let keys = Object.keys(csvData.data[0])
                    let items  = ['station_code','state','cluster_name','railyard_name','latitude','longitude','loading_cluster','unloading_cluster','base_station','bcacbm_eligible']
                    let check = false
                     if (csvData.inValidMessages.length === 0) {       
                                for (let i in keys){
                                    if(items.includes(keys[i])){
                                        check = true                 
                                    }else{
                                        check = false
                                        break
                                    }
                            }
                        }     
                            else{
                                check = false
                            }  
                            
                            let csvalues = Object.keys(csvData.data[0])
                            if(csvalues.length > 0){
                                for(let c in csvalues){
                                    if(aplhanumeichypen.test(csvalues[c]) === false)
                            {
                                check = false;
                                break;
                            }
                                }
                            }
                     console.log("check", check)
                    if (check === false) {
                        $("#bulkUploadID").val("")
                        // document.getElementById("appednCSVData").innerHTML = ''
                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields',
                            basicType: 'danger'
                        });
                       
                    } else {
                        this.setState({
                            csvcontent: csvData.data
                        });
                    }
                  

                }).catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []



            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);



            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))



            }
            else {
                e.target.value = null;
                this.setState({
                    bulkUploadID:'',
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }






    // if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
    //     var data = CSVFileValidator(e.target.files[0], config)
    //     .then((csvData) => {
    //         console.log(csvData)
    //         this.setState({
    //             csvData:csvData.data
    //         });
    //         console.log(csvData.data);
    //     })
    //     .catch(err => {})
    //     // console.log("e.target.files[0]",e.target.result);
        
    //     var out = new Promise(function(reject, resolve){
    //         var reader =  new FileReader();
    //         reader.onload = async function(e) {
    //             var contents = await e.target.result;
    //             resolve(contents);
    //         };
    //         var tt =  reader.readAsText(e.target.files[0]);
    //     });
    //     this.setState({
    //         file:e.target.files[0]
    //     });
    //     //this.checkFileData();
    //     //console.log("e.target.files[0].type ", e.target.files[0]);
    //     var check = verifyext(e.target.files[0].name);
    //     //console.log("Check ", check)
    //     if(check == true)
    //     {
    //         if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    //         {
    //             // console.log(fileData);
    //             // console.log(typeof(fileData))
                
    //         }
    //         else{
    //             e.target.value = null;
    //             this.setState({
    //                 uploadFile:'',
    //                 show: true, 
    //                 basicType:'danger', 
    //                 basicTitle:'Please upload file having extensions .csv only.',
    //             });
    //         }
    //     }
    //     else{
    //         e.target.value = null;
    //         this.setState({
    //             uploadFile:'',
    //             show: true, 
    //             basicType:'danger', 
    //             basicTitle:'Please upload file having extensions .csv only.',
    //         });
    //     }
    // }    
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            bulkUploadID:'',
            file:""
        });
    }
}

onclickslider(e){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
    
}
selectstate = (e) => {
    console.log(e,"822")
    this.setState({
        addState: e,
    })
}
selectEditstate = (e)=>{
    this.setState({
        edit_addState:e
    })
}
selectcluster = (e) => {
    this.setState({
        addCluster: e,
    })
}
selecteditcluster= (e) => {
    this.setState({
        edit_addCluster: e,
    })
}
selectplant =(e)=>{
    this.setState({
       addPlant: e,
    })
}
selecteditplant = (e) => {
    this.setState({
        edit_addPlant: e,
    })
}
selectLoadingcluster= (e) => {
    this.setState({
        selectloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showloadinginputTab:"Yes"
        })
    }else{
        this.setState({
            showloadinginputTab:"No"
        })
    }
    
}
selectunLoadingcluster= (e) => {
    this.setState({
        selectunloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showunloadinginputTab:"Yes"
        })
    }else{
        this.setState({
            showunloadinginputTab:"No"
        })
    }
}
selectBasestationcluster = (e) => {
    this.setState({
       selectbasestation: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showbasestationinputTab:"Yes"
        })
    }
else{
    this.setState({
        showbasestationinputTab:"No"
    })
}

}
selecteditLoadingcluster= (e) => {
    this.setState({
        edit_selectloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showloadingeditinputTab:"Yes"
        })
    }else{
        this.setState({
            showloadingeditinputTab:"No"
        })
    }
    
}
selecteditunLoadingcluster= (e) => {
    this.setState({
       edit_selectunloadingcluster: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showunloadingeditinputTab:"Yes"
        })
    }else{
        this.setState({
            showunloadingeditinputTab:"No"
        })
    }
}
selecteditBasestationcluster = (e) => {
    this.setState({
        edit_selectbasestation: e,
    })
    if(e.value == "Yes"){
        this.setState({
            showbasestationeditinputTab:"Yes"
        })
    }
else{
    this.setState({
        showbasestationeditinputTab:"No"
    })
}
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
validateInput = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue, "inputValue")
    const characterRegex = /^[a-zA-Z\s]+$/;
    console.log(!characterRegex.test(inputValue),"910")
    if (!characterRegex.test(inputValue)) {
        if(inputValue == ""){
            this.setState({
                railyard_name: "",
              });
        }
     
    } else {
      this.setState({
        railyard_name: inputValue,
      });
    }
  };
  validatelatitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            latitude: inputValue,
        });
        }
    }else{
        this.setState({
            latitude: "",
        });
    }
  };
  validateEditlatitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
        this.setState({
            edit_latitude : inputValue
        });
        }
    }else{
        this.setState({
            edit_latitude:""
        });
    }
  };
  validatelongitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                longitude: inputValue,
            });
        }
    }
    else
    {
        this.setState({
            longitude: "",
        });
    }
  };
  validateEditlongitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,20})?$/;
    if(inputValue !=undefined && inputValue !="")
    {
        if (!floatRegex.test(inputValue)) {
        
        } else {
            this.setState({
                edit_longitude : inputValue
            });
        }
    }
    else
    {
        this.setState({
            edit_longitude : ""
        });
    }
  };
submitRialyarddetails=(event)=>{
    event.preventDefault()
        var state  =  this.state.addState.value
        var cluster = this.state.addCluster.value
        var loading_cluster = this.state.selectloadingcluster.value
        var unloading_cluster = this.state.selectunloadingcluster.value
        var baseStation_cluster = this.state.selectbasestation.value
        var max_loadings = $("#max_loadings").val()
        var max_unloadings = $("#max_unloadings").val()
        var max_loadings_unloadings = $("#max_loadings_unloadings").val()
        var max_sevice_days = $("#max_sevice_days").val()
        var railyard_name = $("#railyard_name").val()
        var latitude = $("#latitude").val()
        var longitude = $("#longitude").val()
        var plant = this.state.addPlant.value
        var params ={
            state : state,
            cluster : cluster,
            loading_cluster : loading_cluster,
            unloading_cluster : unloading_cluster,
            baseStation_cluster : baseStation_cluster,
            max_loadings : max_loadings,
            max_unloadings : max_unloadings,
            max_loadings_unloadings : max_loadings_unloadings,
            max_sevice_days : max_sevice_days,
            railyard_name : railyard_name,
            latitude : latitude,
            longitude : longitude,
            plant : plant
        }
        console.log(params,"929")
        if(state != undefined && state != null && state != ""){
            if(cluster != undefined && cluster != null && cluster != ""){
                if(loading_cluster != undefined && loading_cluster != null && loading_cluster != ""){
                    if(unloading_cluster != undefined && unloading_cluster != null && unloading_cluster !=""){
                        if(baseStation_cluster != undefined && baseStation_cluster != null && baseStation_cluster != ""){
                            redirectURL.post("/arms/addRailyardDetails",params,{"headers":
                            {											
                                'Content-Type': 'application/json;charset=UTF-8', 
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                        }
                        }).then((response)=>{
                            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                this.setState({
                                    show:true,
                                    basicTitle:"Session Expired",
                                    basicType:"warning",
                                    overly: "show-n",
                                    loadshow: "show-n"
                                })
                            }else{
                                if(response.data.message == "Success"){
                                    this.setState({
                                        basicTitle:response.data.message,
                                        basicType :"success",
                                        show:true,
                                        overly:"show-n",
                                        loadshow:"show-n",
                                        bulkslide1:"",
                                        addState:"",
                                        addCluster:"",
                                        selectloadingcluster:"",
                                        selectbasestation:"",
                                        selectunloadingcluster:"",
                                        addPlant:"",
                                        showloadinginputTab:"",
                                        showunloadinginputTab:"",
                                        showbasestationinputTab:"",
                                        railyard_name:"",
                                        latitude:"",
                                        longitude:""
                                    })
                                    $("#max_loadings").val("")
                                    $("#max_unloadings").val("")
                                    $("#max_loadings_unloadings").val("")
                                    $("#max_sevice_days").val("")
                                    $("#railyard_name").val("")
                                    $("#latitude").val("")
                                    $("#longitude").val("")
                                    this.onLoadPageData()
                                }else{
                                    this.setState({
                                        basicTitle:response.data.message,
                                        basicType :"warning",
                                        show:true,
                                        overly:"show-n",
                                        loadshow:"show-n"
                                    })
                                }
                            }
                            })
                        }else{
                            this.setState({
                                basicTitle:"Please Select Base Station",
                                basicType :"warning",
                                show:true,
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                        }
                        
                    }else{
                        this.setState({
                            basicTitle:"Please Select Unloading Cluster",
                            basicType :"warning",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }
                   
                }else{
                    this.setState({
                        basicTitle:"Please Select Loading Cluster",
                        basicType :"warning",
                        show:true,
                        overly:"show-n",
                        loadshow:"show-n"
                    })
                }
            }else{
                 this.setState({
                basicTitle:"Please Select Cluster",
                basicType :"warning",
                show:true,
                overly:"show-n",
                loadshow:"show-n"
            })
            }
        }   
        else{
            this.setState({
                basicTitle:"Please Select State",
                basicType :"warning",
                show:true,
                overly:"show-n",
                loadshow:"show-n"
            })
        }
        
   
}
editRialyarddetails=(event)=>{
    event.preventDefault()
        var state  =  this.state.edit_addState.value
        var cluster = this.state.edit_addCluster.value
        var loading_cluster = this.state.edit_selectloadingcluster.value
        var unloading_cluster = this.state.edit_selectunloadingcluster.value
        var baseStation_cluster = this.state.edit_selectbasestation.value
        var max_loadings = $("#edit_max_loadings").val()
        var max_unloadings = $("#edit_max_unloadings").val()
        var max_loadings_unloadings = $("#edit_max_loadings_unloadings").val()
        var max_sevice_days = $("#edit_max_sevice_days").val()
        var railyard_name = $("#edit_railyard_name").val()
        var latitude = $("#edit_latitude").val()
        var longitude = $("#edit_longitude").val()
        var plant = this.state.edit_addPlant.value
        var rowId = this.state.rowId
        var params ={
            state : state,
            cluster : cluster,
            loading_cluster : loading_cluster,
            unloading_cluster : unloading_cluster,
            baseStation_cluster : baseStation_cluster,
            max_loadings : max_loadings,
            max_unloadings : max_unloadings,
            max_loadings_unloadings : max_loadings_unloadings,
            max_sevice_days : max_sevice_days,
            railyard_name : railyard_name,
            latitude : latitude,
            longitude : longitude,
            plant : plant,
            rowId : rowId
        }
        console.log(params,"929")
        if(state != undefined && state != null && state != ""){
            if(cluster != undefined && cluster != null && cluster != ""){
                if(loading_cluster != undefined && loading_cluster != null && loading_cluster != ""){
                    if(unloading_cluster != undefined && unloading_cluster != null && unloading_cluster !=""){
                        if(baseStation_cluster != undefined && baseStation_cluster != null && baseStation_cluster != ""){
                                redirectURL.post("/arms/editRailyardDetails",params,{"headers":
                                {											
                                    'Content-Type': 'application/json;charset=UTF-8', 
                                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                            }).then((response)=>{
                                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                    this.setState({
                                        show:true,
                                        basicTitle:"Session Expired",
                                        basicType:"warning",
                                        overly: "show-n",
                                        loadshow: "show-n"
                                    })
                                }else{
                                    if(response.data.message == "Success"){
                                        this.setState({
                                            basicTitle:response.data.message,
                                            basicType :"success",
                                            show:true,
                                            overly:"show-n",
                                            loadshow:"show-n",
                                            bulkslide2:"",
                                        })
                                        this.onLoadPageData()
                                    }else{
                                        this.setState({
                                            basicTitle:response.data.message,
                                            basicType :"warning",
                                            show:true,
                                            overly:"show-n",
                                            loadshow:"show-n"
                                        })
                                    }
                                }
                                })
                            }else{
                                this.setState({
                                    basicTitle:"Please Select Base Station",
                                    basicType :"warning",
                                    show:true,
                                    overly:"show-n",
                                    loadshow:"show-n"
                                })
                            }
                            
                        }else{
                            this.setState({
                                basicTitle:"Please Select Unloading Cluster",
                                basicType :"warning",
                                show:true,
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                        }
                       
                    }else{
                        this.setState({
                            basicTitle:"Please Select Loading Cluster",
                            basicType :"warning",
                            show:true,
                            overly:"show-n",
                            loadshow:"show-n"
                        })
                    }
                }else{
                     this.setState({
                    basicTitle:"Please Select Cluster",
                    basicType :"warning",
                    show:true,
                    overly:"show-n",
                    loadshow:"show-n"
                })
                }
            }   
            else{
                this.setState({
                    basicTitle:"Please Select State",
                    basicType :"warning",
                    show:true,
                    overly:"show-n",
                    loadshow:"show-n"
                })
            }
   
}
onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}

  render() {
    var showActions = false;
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    var columnwithDefs = [   
        {
            headerName: "Station Code",
            field: "station_code",
            width:130,
            filter: true,
            resizable: true,
            editable:false,            
        },
        {
            headerName: "Railyard Name",
            field: "railyard_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: this.state.rail_yard ,
            // },
        },
        {
            headerName: "Latitude",
            field: "latitude",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Longitude",
            field: "longitude",
            width:150,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "State",
            field: "state",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
        //     cellEditorParams: {
        //         values: this.state.state,
        // },
        },
        {
            headerName: "Cluster Name",
            field: "cluster_name",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            //     cellEditorParams: {
            //         values: this.state.cluster_yard ,
            // },
        },
        
        {
            headerName: "Loading Cluster",
            field: "loading_cluster",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Un Loading Cluster",
            field: "unloading_cluster",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Base Station",
            field: "base_station",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Max Loading per Day",
            field: "max_loadings",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Max Unloading per Day",
            field: "max_unloadings",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Max Loading/Unloading per Day",
            field: "max_loadings_unloadings",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Base Station Service Days",
            field: "max_sevice_days",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        {
            headerName: "Plant",
            field: "plant",
            width:150,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: ["Yes","No"] ,
            // },
        },
        // {
        //     headerName: "Is BCACBM Eligible",
        //     field: "bcacbm_eligible",
        //     width:200,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        //     cellEditor: 'agSelectCellEditor',
        //     cellEditorParams: {
        //         values: ["Yes","No"] ,
        //     },
        // },
        {
            pinned:"left",
            headerName : "Edit",
            field:"edit",
            width:110,
            resizable : true,
            editable : false,
           
            cellRenderer:function(params){       
                var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                return rendComponent;
            },
            filter:false,
        },
        {
            pinned:"left",
            headerName : "Delete",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:false,
        },       
              
        // {
        //     pinned:"left",
        //     headerName: "id",
        //     field: "id",
        //     width:100,
        //     filter: true,
        //     resizable: true,
        //     editable:false,            
        // },
    ]
    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
        <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Railyard Details</span>
                    </h5>
                    </div>
                    <h5>
                   
                </h5>
                <div>
                <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider.bind(this)}>Add Railyard Details</button>
                    {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                    <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                </div>
                <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
            {/* <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}> */}
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Railyard Details<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll1.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitRialyarddetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Railyard Name:</label>
                                    <input type="text" name="railyard_name" id="railyard_name"  className="form-control" value={this.state.railyard_name} onChange={this.validateInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Latitude:</label>
                                    <input type="text" name="latitude" id="latitude"  className="form-control" value={this.state.latitude} onChange={this.validatelatitudeInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Longitude:</label>
                                    <input type="text" name="longitude" id="longitude"  className="form-control" value={this.state.longitude} onChange={this.validatelongitudeInput}
                                     required />
                                </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>State:</label>
                                    <Select
                                                    placeholder={"Select State"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addState}
                                                    onChange={this.selectstate.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.statesdetails} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Cluster:</label>
                                    <Select
                                                    placeholder={"Select Cluster"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addCluster}
                                                    onChange={this.selectcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.clusterdetails} required
                                                />
                                </div>
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Loading Cluster:</label>
                                    <Select
                                                    placeholder={"Select Loading Area"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.selectloadingcluster}
                                                    onChange={this.selectLoadingcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Unloading Cluster:</label>
                                    <Select
                                                    placeholder={"Select Unloading Area"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.selectunloadingcluster}
                                                    onChange={this.selectunLoadingcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station Cluster:</label>
                                    <Select
                                                    placeholder={"Select Base Station"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.selectbasestation}
                                                    onChange={this.selectBasestationcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                {(this.state.showloadinginputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Max Loadings Per Day:</label>
                                       <input type="number" name="max_loadings" id="max_loadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showunloadinginputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Max Unloadings Per Day:</label>
                                    <input type="number" name="max_unloadings" id="max_unloadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showloadinginputTab == "Yes" && this.state.showunloadinginputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label> Max loadings/Unloadings Per Day:</label>
                                    <input type="number" name="max_loadings_unloadings" id="max_loadings_unloadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showbasestationinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station Service Days:</label>
                                    <input type="number" name="max_sevice_days" id="max_sevice_days"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showloadinginputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.addPlant}
                                                    onChange={this.selectplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantsdetails} required
                                                />
                                </div>:""
                                }
                                

                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Edit Railyard Details<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span></h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.editRialyarddetails}>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Railyard Name:</label>
                                    <input type="text" name="edit_railyard_name" id="edit_railyard_name"  className="form-control" value={this.state.edit_railyard_name} onChange={this.validateInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Latitude:</label>
                                    <input type="text" name="edit_latitude" id="edit_latitude"  className="form-control" value={this.state.edit_latitude} onChange={this.validateEditlatitudeInput}
                                     required />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Longitude:</label>
                                    <input type="text" name="edit_longitude" id="edit_longitude"  className="form-control" value={this.state.edit_longitude} onChange={this.validateEditlongitudeInput}
                                     required />
                                </div>
                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>State:</label>
                                    <Select
                                                    placeholder={"Select State"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_addState}
                                                    onChange={this.selectEditstate.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.statesdetails} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Cluster:</label>
                                    <Select
                                                    placeholder={"Select Cluster"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_addCluster}
                                                    onChange={this.selecteditcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.clusterdetails} required
                                                />
                                </div>
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Loading Cluster:</label>
                                    <Select
                                                    placeholder={"Select Loading Area"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_selectloadingcluster}
                                                    onChange={this.selecteditLoadingcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Unloading Cluster:</label>
                                    <Select
                                                    placeholder={"Select Unloading Area"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_selectunloadingcluster}
                                                    onChange={this.selecteditunLoadingcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station Cluster:</label>
                                    <Select
                                                    placeholder={"Select Base Station"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_selectbasestation}
                                                    onChange={this.selecteditBasestationcluster.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{ label: "Yes", value: "Yes" },
                                                    { label: "No", value: "No" }]} required
                                                />
                                </div>
                                {(this.state.showloadingeditinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Max Loadings Per Day:</label>
                                       <input type="number" name="edit_max_loadings" id="edit_max_loadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showunloadingeditinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Max Unloadings Per Day:</label>
                                    <input type="number" name="edit_max_unloadings" id="edit_max_unloadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showloadingeditinputTab == "Yes" && this.state.showunloadingeditinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label> Max loadings/Unloadings Per Day:</label>
                                    <input type="number" name="edit_max_loadings_unloadings" id="edit_max_loadings_unloadings"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showbasestationeditinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Base Station Service Days:</label>
                                    <input type="number" name="edit_max_sevice_days" id="edit_max_sevice_days"  className="form-control" placeholder="" onChange={this.changeHandler} required />
                                </div>:""
                                }
                                {(this.state.showloadingeditinputTab == "Yes")?
                                    <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant:</label>
                                    <Select
                                                    placeholder={"Select Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_addPlant}
                                                    onChange={this.selecteditplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantsdetails} required
                                                />
                                </div>:""
                                }
                                

                    {/* <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div> */}

                    <div className="form-group">
                        <button type="submit" className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};