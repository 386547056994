var axios = require('axios');
let NODE_PROD_URL = " https://msilrail.in/foxtrot"
var axiosInstance = axios.create({

  // baseURL: 'http://localhost:5000/foxtrot',
  baseURL: NODE_PROD_URL,
  /* other custom settings */
});

module.exports = axiosInstance;
