import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import {verifyext, verifycsvdata} from "../common/verifyext";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class RailClusterMaster extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents : {
            // UpdateButton:UpdateButton,
               // DeleteButton:DeleteButton,
              },

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:'',
            bulkslide1:'',
            bulkslide2:'',
            cluster_name:'',

        };
    }
   
    componentDidMount(){
        document.body.addEventListener("click", this.handleClickOutside);
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        this.loadData();
    };
    loadData(){
        redirectURL.post('/arms/getclustermaster',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"108")
            var records = response.data;
            // for(let data of records){
            //     console.log("data.plant_coordinates ",data.plant_coordinates.lat)
            //     const plant_coord = JSON.parse(data.plant_coordinates);
            //     data.lattitude = plant_coord.lat;
            //     data.langitude = plant_coord.lng;
            //     // delete data.plant_coordinates;
                            
            // }
                this.setState({
                    rowData:records,

                    overly: "show-n",
                    loadshow: "show-n"
                });
        }
          })
          .catch(function (error) {
            console.log(error);
        });
       
    }
   

    onClickShowFilterData(event) {
        event.preventDefault();
        var cluster_name = $("#cluster_name").val()
        cluster_name = cluster_name.charAt(0).toUpperCase() + cluster_name.slice(1);
        // var cluster_code = $("#cluster_code").val()
        // var created_date = $("#created_date").val()
        // var station_name = $("#station_name").val()
        // var msil_code  =  $("#msil_code").val()
       
    if(cluster_name != undefined && cluster_name != null && cluster_name != "")
        {
            var params ={
                cluster_name,
                created_by : localStorage.getItem("username")
                // cluster_code,
                // created_date,
                // station_name,
                
            }
            redirectURL.post("arms/getaddclustermaster" , params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then(response=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response)
                if(response.data.message == "Success")
                    {
                        this.setState({
                            basicTitle:"Add Successfully.",
                            basicType : "success",
                            show : true,
                            rowIds : [],
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide:"",
                            cluster_name:""
                        })
                        
                        $("#cluster_name").val("")
                        this.loadData(); 
                    }
                    else
                    {
                        this.setState({
                            basicTitle:response.data.message,
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide:""
                        })
                    }
                }
            })
        }else{
            this.setState({
                basicTitle:"Please Enter Cluster Name",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
    
    }
    onClickShowFilterData2(event) {
        event.preventDefault();
        var cluster_name2 = $("#cluster_name2").val()
        cluster_name2 = cluster_name2.charAt(0).toUpperCase() + cluster_name2.slice(1);
        // var cluster_code2 = $("#cluster_code2").val()
        // var created_date2 = $("#created_date2").val()
        // var station_name2 = $("#station_name2").val()
        var rowId = this.state.rowId
       
        if(cluster_name2 != undefined && cluster_name2 != null && cluster_name2 != ""){
            var params ={
                cluster_name:cluster_name2,
                edited_by : localStorage.getItem("username")
                // cluster_code2,
                // created_date2,
                // station_name2,
               
            }
            redirectURL.post("arms/geteditclustermaster" , { params ,rowId},{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                console.log(response)
                if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Data update Successfully.",
                            basicType : "success",
                            show : true,
                            rowIds : [],
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide2:""
                        })
                        $("#cluster_name2").val("")
                        // $("#cluster_code2").val("")
                        // $("#created_date2").val("")
                        // $("#station_name2").val("")
                         
                         this.loadData(); 
                        //window.location.reload();
                        // this.componentDidMount();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:response.data.message,
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n",
                            bulkslide2:""
                        })
                    }
                }
            })
        }else{
            this.setState({
                basicTitle:"Please Enter Cluster Name",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n"
            })
        }
    
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide1:"",
            bulkslide2:"",
            cluster_name:""
        });
    }
    onClickHideAll1(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide1:"",
            bulkslide2:"",
            cluster_name:""
        }); 
    }
    handleClickOutside = (event) => {
         if (!event.target.closest(".slide-r")) { // Close the slider when clicking outside of it 
            this.onClickHideAll(); 
        }
     };
   
    onCellClicked(e){
        if(e.colDef.field == "delete"){
            var qry = {
                rowId:e.data._id,
                email:localStorage.getItem("email"),
                                // designation: localStorage.getItem('designation'),
                // user_role: localStorage.getItem('roles'),
            }
            console.log(qry,"243")
            var confirm = window.confirm("Are You Sure! Do you really want to delete?")
            if (confirm == true)
            {
                redirectURL.post("/arms/deletebulkTransitTimesDetails3",qry,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response.data);
                    if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Deleted Successfully.",
                            basicType : "success",
                            show : true,
                            rowIds : []
                        })
                        //window.location.reload();
                        this.componentDidMount();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to Delete.",
                            basicType : "warning",
                            show : true
                        })
                    }
                }
                }).catch((e)=>{
                    console.log(e);
                })
            } 
        }
        if(e.colDef.field == "edit"){
            var data = e.data;
            var cluster_name2 = $("#cluster_name2").val(data.cluster_name)
            // var cluster_code2 = $("#cluster_code2").val(data.cluster_code)
            // var created_date2 = $("#created_date2").val(data.created_date)
            // var station_name2  =  $("#station_name2").val(data.station_name)
            this.setState({
                 overly:'show-m',
                bulkslide2:"slider-translate-30p",
                rowId :data._id,
                cluster_name2:data.cluster_name
              
            })
           
        } 
        
        
    }
    resetUpload = () => {
        this.setState({
            bulkslide:'',
            bulkslide1:'',
            bulkslide2:'',
            overly:'show-n',
            file:''
        });
        document.getElementById("upform").reset();
    }
    changeFileHandler = async (e) => {
        var dt = '';
        const config = {
            headers: [
                // { 
                //     name: 'id',
                //     inputName: 'id',
                //     required: false,
                // },
                { 
                    name: 'cluster_name',
                    inputName: 'cluster_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: ' cluster_code',
                    inputName: 'cluster_code',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'created_date',
                    inputName: 'created_date',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                { 
                    name: 'station_name',
                    inputName: 'station_name',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
            ]
        }
        if(e.target.files[0] != undefined && e.target.files[0] != "" && e.target.files[0] != null){
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log(csvData)
                    this.setState({
                        csvData:csvData.data
                    });
                    console.log(csvData.data);
                })
                .catch(err => {})
                // console.log("e.target.files[0]",e.target.result);
                
                var out = new Promise(function(reject, resolve){
                    var reader =  new FileReader();
                    reader.onload = async function(e) {
                        var contents = await e.target.result;
                        resolve(contents);
                    };
                    var tt =  reader.readAsText(e.target.files[0]);
                });
                this.setState({
                    file:e.target.files[0]
                });
                this.checkFileData();
                console.log("e.target.files[0].type ", e.target.files[0]);
                var check = verifyext(e.target.files[0].name);
                console.log("Check ", check)
                if(check == true)
                {
                    if(e.target.files[0].type == '' || e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
                    {
                        // console.log(fileData);
                        // console.log(typeof(fileData))
                        
                    }
                    else{
                        e.target.value = null;
                        this.setState({
                            uploadFile:'',
                            show: true, 
                            basicType:'danger', 
                            basicTitle:'Please upload file having extensions .csv only.',
                        });
                    }
                }
                else{
                    e.target.value = null;
                    this.setState({
                        uploadFile:'',
                        show: true, 
                        basicType:'danger', 
                        basicTitle:'Please upload file having extensions .csv only.',
                    });
                }
        }
    }
    validateInput = (event) => {
        const inputValue = event.target.value;
        console.log(inputValue, "inputValue")
        const characterRegex = /^[a-zA-Z\s]+$/;
        console.log(!characterRegex.test(inputValue),"910")
        if (!characterRegex.test(inputValue)) {
            if(inputValue == ""){
                this.setState({
                    cluster_name: "",
                    cluster_name2:'',
                  });
            }
         
        } else {
          this.setState({
            cluster_name: inputValue,
            cluster_name2: inputValue,
          });
        }
      };
    
    addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{plant_code:"",plant_map_cod:"",msil_code:"",}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        bulkslide:"slider-translate-30p",
        // rowId : rowId,
        overly:"show-m"
    })
    
    // this.setState({
    //     bulkslide2:"slider-translate-30p",
    //     // rowId : rowId
    // })

}

onClickDataExport(){
    var params = {
        columnKeys: ['cluster_name','cluster_code','created_date','station_name']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
      
    })
}
 
render(){
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    const columnwithDefs = [
        {
            headerName: "Cluster Name",
            field: "cluster_name",
            width:250,
            filter: true,
            resizable: true,
            editable:false,            
        },
            {
                pinned:"left",
                headerName : "",
                field:"edit",
                width:150,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                    return rendComponent;
                },
                filter:false,
            },
            {
                pinned:"left",
                headerName : "",
                field:"delete",
                width:80,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter:false,
            },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Rail Cluster Master</span>
                            </h5>
                        </div>
                        <h5>
                            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)} >Add Cluster Details</button>
                            {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.showBulkUpload.bind(this)}>Import Data</button> */}
                            <button className="btn btn-primary" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button>
                        </h5>
                        
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
           
           

            <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Add Cluster details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll1.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                                 
                            <div className="col-xl-12 col-lg-12 row form-group">
                                   <label className="col-xl-12 col-lg-12">Cluster Name : </label>
                                   <input type="text" name="cluster_name" id="cluster_name" className="datetimepicker_datew form-control armsinputbox"value={this.state.cluster_name} onChange={this.validateInput}
                                     required  />
                            </div>
                            <div className="form-group col-xl-3 col-lg-3">
                                    <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit master details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                            
                        <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 row form-group">
                                   <label className="col-xl-12 col-lg-12">Cluster Name : </label>
                                   <input type="text" name="cluster_name2" id="cluster_name2" className="datetimepicker_datew form-control armsinputbox" value={this.state.cluster_name2} onChange={this.validateInput}
                                     required   />
                                   {/* <label className="col-xl-12 col-lg-12">Cluster Code : </label>
                                               <input type="text" name="cluster_code2" id="cluster_code2" className="datetimepicker_datew form-control armsinputbox"  />
                                 
                                       <label className="col-xl-12 col-lg-12">Created Date: </label>
                                       <input type="text" name="created_date2" id="created_date2"  className="form-control armsinputbox" placeholder="" onChange={this.dispatchportchangeHandler} required />
                                  
                                   <label className="col-xl-12 col-lg-12">Station Name : </label>
                                               <input type="text" name="station_name2" id="station_name2" className="datetimepicker_datew form-control armsinputbox"  /> */}
                                   </div>
                                   <div className="col-xl-12 col-lg-12 row form-group mt-20p">
                                   
                                               {/* <Select 
                                               placeholder={"Transporter Name"} 
                                               closeMenuOnSelect={true} 
                                               // id={"transporterName"}
                                               // name={"transporterName"}
                                               value={this.state.container_name} 
                                               className={"col-xl-12 col-lg-12 border-radius-0"}
                                            //    onChange={this.containernamechangeHandler.bind(this)} 
                                               style={{borderRadius:"0px"}} 
                                               options={[{ label: "Add master details", value: "Add master details" },{ label: "Plant code", value: "plant code" },
                                               { label: "Plant map code", value: "plant map code" },{ label: "plant name", value: "plant name"},
                                               { label: "Msil code", value: "Msil code" }]} required
                                           />
    */}
                                   </div>
                                  
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       
        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}