import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';

// Super Admin Screens
import AdminMenus from './components/superadmin/menus'
import AdminRoles from './components/superadmin/roles'
import AdminUserRoles from './components/superadmin/userroles'
//import AdminRoleMenus from './components/superadmin/assignrolemenus'

// import AdminRoleMenus from './components/superadmin/assignmenustoroles'
import AdminViewSubMenus from './components/superadmin/view-submenus'
import AdminMenuReOrder from  './components/superadmin/menu-reorder'

// Dashboards

import Login from './components/login/login'
import EnmUserLogin from './components/login/enmuserlogin'
import Logout from './components/login/logout'
import Register  from './components/login/register'
// import LmRegister  from './components/login/lmregister'
// import LmLogin  from './components/login/lmlogin'
import AuthCallBack  from './components/login/authcallback'
import ForgetPassword  from './components/pages/forget-password'
import Comingsoon  from './components/pages/comingsoon'
import Token from './components/zoho/token'
import Accounts from './components/zoho/accounts'
import Folders from './components/zoho/folders'
import ZohoMessages from './components/zoho/messages'
import AttachmentInfo from './components/zoho/attachmentinfo'
import Attachment from './components/zoho/attachment'
import RefreshToken from './components/zoho/refreshToken'
// import Assets from './components/assets/assets'
import Maintenance  from './components/pages/maintenance';
import ChangePasswordComponent from './components/manage/changepassword.component';
import ResetPassword from './components/login/reset-password';
import Error404 from './components/pages/error-404';
// import WeatherMap from "./components/layouts/weathermap";
import Error405 from './components/pages/error-405';
import RakePlanningTransporters from "./components/layouts/rakeplanningtransporters";
import RpRakeDetails from "./components/layouts/rprakedetails";
import RpRailYardDetails from './components/layouts/rprailyarddetails';
// import RpBaseStationDetails from './components/layouts/rpbasestationdetails';
// import RpBaseStationCityDistance from './components/layouts/rpbasestationcitydistance';
import RpTransitTimes from './components/layouts/rptransittimes';
// import CarsPlanningSummary from './components/layouts/carsplanning';
// import RpAutoRakePlanningHistoricalData from './components/layouts/rpautorakeplanninghistoricaldata';
// import RpRakePlanSummary from './components/layouts/rprakeplansummary';
// import viewRakeSummaryDetails from "./components/layouts/viewRakeSummaryDetails";
// import viewCarSummaryDetails from "./components/layouts/viewCarSummaryDetails";
// import RakeAvailability from "./components/layouts/rakeavailability";
import CarsPlanning from "./components/layouts/createcarsplanning";
import RpCarDetails from "./components/layouts/rpcardetails";
// import RpTrainDetails from "./components/layouts/rptraindetails";
import RpTrainDimensions from "./components/layouts/rptraindimensions";
import RpTransporterServing from "./components/layouts/rptransporterserving";

//ARMS
import Targetpreparation from './components/arms/targetpreparation';
import DealerMaster from './components/arms/dealermaster';
import ForcodeMaster from './components/arms/forcodemaster';
import SalesMaster from './components/arms/salesmaster';
import PosDataMaster from './components/arms/posdatamaster';
import InvoiceOrderMaster from './components/arms/invoiceordermaster';
import TargetSummary from './components/arms/targetsummary';
import ManualTargetUpload from './components/arms/manualtargetuploads';
import DealerMonthlyData from './components/arms/dealermonthlydata';
import SBVHtargetpreparation from './components/arms/sbvhtargetpreparation';
import Additionaldispatches from './components/arms/additionaldispatches'
// import LastYearDispatchData from './components/arms/lastyeardispatchdata';
import PpntMaster from './components/arms/ppntmaster';
import Routeclustermapping from './components/arms/routeclustermapping';
import Apifcokplandata from './components/arms/apifcokplandata';
import Apiabokplandata from './components/arms/apiabokplandata';
import Cronjobmonitor from './components/arms/cronjobmonitor';
import VVDPlantData from './components/arms/vdd_data';
import VBSFirmData from './components/arms/vbs_firm_data';
import VBSTentativeData from './components/arms/vbs_tentative_data';
import Lsprakeusagepriority from './components/layouts/lsprakeusagepriority';
import Plantmaster from './components/layouts/plantmaster';
import RailClusterMaster from './components/layouts/railclustermaster';
import StatesMaster from './components/layouts/statesmaster';
import RailSchedulingSummary from './components/arms/rakeschedulingsummary';
import LspTentativeRakeAvailability from './components/arms/tentativelsprakeavailability';
import ActualRakeAvailabilty from './components/arms/actualrakeavailability';
import RailConfigScreen from './components/arms/railconfigscreen';
import SBVHcodemapping from './components/arms/sbvhcodemapping';
import RailTargetSummary from './components/arms/railtargetsummary';
import MonthShareTarget from './components/arms/monthlytaargetshare';
import RailyardMapping from './components/arms/railyardmapping';
import RakePositions from './components/arms/rakepositions';
import PlantWiseVDDStock from './components/arms/plantwisevddstock';
import RailLoadConfigurator from './components/arms/railloadconfigurator';
import RailLoadConfiguratorSummary from './components/arms/railloadconfiguratorsummary';
import OrdervsStock from './components/arms/ordervsstock';
import TempLoadvehicleloadfactors from './components/arms/temploadvehicleloadfactors';
import PlantwiseLoadTruckType from './components/arms/plantlspwiseloadtrucktype';
import ApiRunLogs from './components/arms/apirunlogs';
import ColorTypeMaster from './components/arms/colortypemaster';
import RakeInitiationData from './components/arms/rakeintiationdata';
import PlantwiseModelCategory from './components/arms/plantwisemodelcategory';
import NewOrdervsStock from './components/arms/newordervsstock';
import RailScheduling from './components/arms/railscheduling';
import TemporaryLoadDetails from './components/arms/temporaryloaddetails';
import TemporaryHistoryDetails from './components/arms/temporaryhistorydetails';
import ExceptionandLpcheck from './components/arms/exceptioncheckandlpcheck';
import Bvehmodelmaster from './components/arms/bvehmodelmaster';
import AutoMailScreen from './components/arms/automailscreen';
import ModelCategoryDetails from './components/arms/modelcategorydetails';
import ApiStocksCount from './components/arms/apistockscount';
class Root extends React.Component {

    render() {
        return (
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                        <Route path={`${process.env.PUBLIC_URL}/login/`} component={AuthCallBack} />
                        <Route exact path={`${process.env.PUBLIC_URL}/tptlogin`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/loadmanagement/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/loadmanagement/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/lmregister`} component={LmRegister} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/lmlogin`} component={LmLogin} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/token`} component={Token} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/accounts`} component={Accounts} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/folders/:accountId`} component={Folders} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/messages/:accountId/:folderId/:folderName`} component={ZohoMessages} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachmentinfo/:accountId/:folderId/:messageId/:subject/:folderName`} component={AttachmentInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachment/:accountId/:folderId/:messageId/:attachmentId/:attachmentName/:subject`} component={Attachment} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/refreshToken`} component={RefreshToken} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />                       
                        {/* <Route path={`${process.env.PUBLIC_URL}/mgmtweathermap`} component={WeatherMap} /> */}
                       <App>
                       {/* <Route exact path={`${process.env.PUBLIC_URL}/login/`} component={ForcodeMaster} /> */}
                      
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menus`} component={AdminMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/roles`} component={AdminRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/usermenus`} component={AdminUserRoles} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/assignmenus`} component={AdminRoleMenus} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/viewSubmenus/:parentmenu`} component={AdminViewSubMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menuposition`} component={AdminMenuReOrder} />
                        <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/error-405`} component={Error405} />
                        <Route path={`${process.env.PUBLIC_URL}/rakeplanningtransporters`} component={RakePlanningTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/rprakedetails`} component={RpRakeDetails} />
                        <Route path={`${process.env.PUBLIC_URL}/rprailyarddetails`} component={RpRailYardDetails} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/rpbasestationdetails`} component={RpBaseStationDetails} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/rpbasestationcitydistance`} component={RpBaseStationCityDistance} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/rptransittimes`} component={RpTransitTimes} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/rpautorakeplanning1`} component={RpAutoRakePlanningHistoricalData} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/rprakeplansummary`} component={RpRakePlanSummary} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/viewRakeSummaryDetails/:eventname`} component={viewRakeSummaryDetails} />                         */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/rakeavailability`} component={RakeAvailability} />                         */}
                        <Route path={`${process.env.PUBLIC_URL}/carsplanning`} component={CarsPlanning} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/viewCarSummaryDetails/:eventname`} component={viewCarSummaryDetails} /> */}
                        {/* <Route path={`${process.env.PUBLIC_URL}/carsplansummary`} component={CarsPlanningSummary} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/rpcardetails`} component={RpCarDetails} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/rptraindetails`} component={RpTrainDetails} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/rptraindimensions`} component={RpTrainDimensions} />
                        <Route path={`${process.env.PUBLIC_URL}/rptransporterserving`} component={RpTransporterServing} />
                        {/* ARMS */}
                        <Route path={`${process.env.PUBLIC_URL}/targetpreparation`} component={Targetpreparation} />
                        <Route path={`${process.env.PUBLIC_URL}/dealermaster`} component={DealerMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/forcodemaster`} component={ForcodeMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/salesmaster`} component={SalesMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/posdatamaster`} component={PosDataMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/invoiceordermaster`} component={InvoiceOrderMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/targetsummary`} component={TargetSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/manualtargetupload`} component={ManualTargetUpload} />
                        <Route path={`${process.env.PUBLIC_URL}/dealermonthlydata`} component={DealerMonthlyData} />
                        <Route path={`${process.env.PUBLIC_URL}/sbvhtargetpreparationdata`} component={SBVHtargetpreparation} />
                        <Route path={`${process.env.PUBLIC_URL}/additionaldispatches`} component={Additionaldispatches} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/lastyeardispatch`} component={LastYearDispatchData} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/ppntmaster`} component={PpntMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/routeclustermapping`} component={Routeclustermapping} />
                        <Route path={`${process.env.PUBLIC_URL}/apifcokplandata`} component={Apifcokplandata} />
                        <Route path={`${process.env.PUBLIC_URL}/apiabokplandata`} component={Apiabokplandata} />
                        <Route path={`${process.env.PUBLIC_URL}/cronjobmonitor`} component={Cronjobmonitor} />
                        <Route path={`${process.env.PUBLIC_URL}/vvdplantdata`} component={VVDPlantData} />
                        <Route path={`${process.env.PUBLIC_URL}/vbsfirmdata`} component={VBSFirmData} />
                        <Route path={`${process.env.PUBLIC_URL}/vbstentativedata`} component={VBSTentativeData} />
                        <Route path={`${process.env.PUBLIC_URL}/lsprakeusagepriority`} component={Lsprakeusagepriority} />
                        <Route path={`${process.env.PUBLIC_URL}/plantmaster`} component={Plantmaster} />
                        <Route path={`${process.env.PUBLIC_URL}/railclustermaster`} component={RailClusterMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/statesmaster`} component={StatesMaster} />
                        <Route path={`${process.env.PUBLIC_URL}/railschedulingsummary/:transaction_id`} component={RailSchedulingSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/tentativerakeavailability`} component={LspTentativeRakeAvailability} />
                        <Route path={`${process.env.PUBLIC_URL}/actualrakeavailability`} component={ActualRakeAvailabilty} />
                        <Route path={`${process.env.PUBLIC_URL}/railconfig`} component={RailConfigScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/sbvhcodemapping`} component={SBVHcodemapping} />
                        <Route path={`${process.env.PUBLIC_URL}/railtargetsummary`} component={RailTargetSummary}/>
                        <Route path={`${process.env.PUBLIC_URL}/monthlytargetshare`} component={MonthShareTarget}/>
                        <Route path={`${process.env.PUBLIC_URL}/managerailforcodes`} component={RailyardMapping}/>
                        <Route path={`${process.env.PUBLIC_URL}/rakepositions`} component={RakePositions}/>
                        <Route path={`${process.env.PUBLIC_URL}/plantwisevddstock`} component={PlantWiseVDDStock}/>
                        <Route path={`${process.env.PUBLIC_URL}/railloadconfigurator`} component={RailLoadConfigurator}/>
                        <Route path={`${process.env.PUBLIC_URL}/railloadconfiguratorsummary/:storage_plan_id`} component={RailLoadConfiguratorSummary}/>
                        <Route path={`${process.env.PUBLIC_URL}/ordervsstock`} component={OrdervsStock}/>
                        <Route path={`${process.env.PUBLIC_URL}/temploadvehicleloadfactors`} component={TempLoadvehicleloadfactors}/>
                        <Route path={`${process.env.PUBLIC_URL}/plantlspwiseloadtrucktypes`} component={PlantwiseLoadTruckType}/>
                        <Route path={`${process.env.PUBLIC_URL}/apirunlogs`} component={ApiRunLogs}/>
                        <Route path={`${process.env.PUBLIC_URL}/colortypemaster`} component={ColorTypeMaster}/>
                        <Route path={`${process.env.PUBLIC_URL}/plantwisemodelcategory`} component={PlantwiseModelCategory}/>
                        <Route path={`${process.env.PUBLIC_URL}/rakeinitiationdata`} component={RakeInitiationData}/>
                        <Route path={`${process.env.PUBLIC_URL}/newordervsstock`} component={NewOrdervsStock}/>
                        <Route path={`${process.env.PUBLIC_URL}/railscheduling`} component={RailScheduling}/>
                        <Route path={`${process.env.PUBLIC_URL}/temporaryloaddetails`} component={TemporaryLoadDetails}/>
                        <Route path={`${process.env.PUBLIC_URL}/temporaryhistorydetails`} component={TemporaryHistoryDetails}/>
                        <Route path={`${process.env.PUBLIC_URL}/exceptionandlpchecks`} component={ExceptionandLpcheck}/>
                        <Route path={`${process.env.PUBLIC_URL}/bvehmodelmaster`} component={Bvehmodelmaster}/>
                        <Route path={`${process.env.PUBLIC_URL}/automailscreen`} component={AutoMailScreen}/>
                        <Route path={`${process.env.PUBLIC_URL}/modelcategorydetails`} component={ModelCategoryDetails}/>
                        <Route path={`${process.env.PUBLIC_URL}/apistockscount`} component={ApiStocksCount}/>
                    </App>

                        
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </Provider>
        )
    }
}



ReactDOM.render(<Root/>, document.getElementById('root'));


