import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class PpntMaster extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents : {
            // UpdateButton:UpdateButton,
               // DeleteButton:DeleteButton,
              },

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:'',
            bulkslide2:'',
            plant_code:'',
            map_code:'',
            plant_name:'',
            msil_code:'',
            plantcodeList:[],
            plantShortNameList :[],
            plantNameList : [],
            plant_short_name:"",
            plant_name:"",
            edit_plant_name:"",
            edit_plant_short_name:"",
            edit_plant_code:""
        };
    }
    handleClickOutside = (event) => {
        if (!event.target.closest(".slide-r")) { // Close the slider when clicking outside of it 
           this.onClickHideAll(); 
       }
    };
  
   
    componentDidMount(){
        document.body.addEventListener("click", this.handleClickOutside);
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        redirectURL.post("/arms/getplantsdataforppntmaster",{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                })
            }else{
                var resp = response.data
                var plantcodeList = []
                resp.map((e)=>{
                    plantcodeList.push({
                        label:e.plant_code,
                        value:e.plant_code
                    })
                })
                this.setState({
                    plantcodeList : plantcodeList,
                })
            }
        })
        this.onloadPageData()
    };
    onloadPageData = ()=>{
        redirectURL.post('/arms/getppntmaster',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"108")
            var records = response.data;
            if(records.length > 0){
                this.setState({
                    rowData:records,
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }
         
        }
    })   
    }
    

    onClickShowFilterData(event) {
        event.preventDefault();
        this.setState({
            loadshow :"show-m",
            overly:"show-m"
        })
        var plant_code = this.state.plant_code.value
        var map_code = this.state.plant_short_name.value
        var plant_name = this.state.plant_name.value
        var msil_code  =  $("#msil_code").val()
        var params ={
            plant_code : plant_code,
            plant_map_code:map_code,
            plant_name: plant_name,
            msil_code : msil_code,
            created_by : localStorage.getItem("username")
        }
        if(plant_code != undefined && plant_code != null && plant_code !=""){
            if(map_code != undefined && map_code != null && map_code != ""){
                if(plant_name != undefined && plant_name != null && plant_name != ""){
                    redirectURL.post("/arms/addppntmasterdetails" , params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then(response=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })     
                    }else{
                        console.log(response)
                        if(response.data.message == "Success")
                            {
                                this.setState({
                                    basicTitle:"Add Successfully.",
                                    basicType : "success",
                                    show : true,
                                    loadshow:"show-n",
                                    overly:"show-n",
                                    rowIds : [],
                                    plant_code:"",
                                    plant_short_name:"",
                                    plant_name:"",
                                    bulkslide:"",
                                })
                                $("#msil_code").val("")
                                //window.location.reload();
                                this.onloadPageData();
                            }
                            else
                            {
                                this.setState({
                                    basicTitle:response.data.message,
                                    basicType : "warning",
                                    show : true,
                                    loadshow:"show-n",
                                    overly:"show-n",
                                    bulkslide:""
                                })
                            }
                    }
                       
                    })
                }else{
                    this.setState({
                        basicTitle:"Please Select Plant Name",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n",
                    })
                }
                
            }else{
                this.setState({
                    basicTitle:"Please Select Plant Short Name",
                    basicType : "warning",
                    show : true,
                    loadshow:"show-n",
                    overly:"show-n",
                })
            }
                
        }else{
            this.setState({
                basicTitle:"Please Select Plant Code",
                basicType : "warning",
                show : true,
                loadshow:"show-n",
                overly:"show-n",
            })
        }

    }
    onClickShowFilterData2(event) {
        event.preventDefault();
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var plant_code = this.state.edit_plant_code.value
        var map_code = this.state.edit_plant_short_name.value
        var plant_name = this.state.edit_plant_name.value
        var msil_code =  $("#edit_msil_code").val()
        var rowId = this.state.rowId
        
            var params ={
                plant_code:plant_code,
                plant_map_code:map_code,
                plant_name:plant_name,
                msil_code:msil_code,
                rowId : rowId,
                updated_by:localStorage.getItem("username")
            }
            if(plant_code != undefined && plant_code != null && plant_code !=""){
                if(map_code != undefined && map_code != null && map_code != ""){
                    if(plant_name != undefined && plant_name != null && plant_name != ""){
                        redirectURL.post("/arms/editppntmasterdata",params,{"headers":
                        {											
                            'Content-Type': 'application/json;charset=UTF-8', 
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                    }).then(response=>{
                            console.log(response)
                            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                this.setState({
                                    show:true,
                                    basicTitle:"Session Expired",
                                    basicType:"warning",
                                    overly: "show-n",
                                    loadshow: "show-n"
                                })     
                            }else{
                                if(response.data.message == "Success")
                                {
                                    this.setState({
                                        basicTitle:"Data update Successfully.",
                                        basicType : "success",
                                        show : true,
                                        rowIds : [],
                                        loadshow:"show-n",
                                        overly:"show-n",
                                        bulkslide2:""
                                    })
                                    this.onloadPageData();
                                }
                                else
                                {
                                    this.setState({
                                        basicTitle:response.data.message,
                                        basicType : "warning",
                                        show : true,
                                        loadshow:"show-n",
                                        overly:"show-n",
                                        bulkslide2:""
                                    })
                                }
                            }
                        })
                    }else{
                        this.setState({
                            basicTitle:"Please Select Plant Name",
                            basicType : "warning",
                            show : true,
                            loadshow:"show-n",
                            overly:"show-n",
                        })
                    }
                    
                }else{
                    this.setState({
                        basicTitle:"Please Select Plant Short Name",
                        basicType : "warning",
                        show : true,
                        loadshow:"show-n",
                        overly:"show-n",
                    })
                }
                    
            }else{
                this.setState({
                    basicTitle:"Please Select Plant Code",
                    basicType : "warning",
                    show : true,
                    loadshow:"show-n",
                    overly:"show-n",
                })
            }

    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide2:"",
            plant_name : "",
            plant_short_name:"",
            plant_code:"",
            plantShortNameList :[],
            plantNameList : [],
        });
        $("#msil_code").val("")
    }
   
    onCellClicked(e){
        if(e.colDef.field == "delete"){
            this.setState({
                loadshow:"show-m",
                overly:"show-m"
            })
            var qry = {
                rowId:e.data._id,
                email:localStorage.getItem("email"),
                                // designation: localStorage.getItem('designation'),
                // user_role: localStorage.getItem('roles'),
                username :localStorage.getItem("username")
            }
            console.log(qry,"243")
            var confirm = window.confirm("Are You Sure! Do you really want to delete?")
            if (confirm == true)
            {
                redirectURL.post("/arms/deleteppntmasterData",qry,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                    console.log(response.data);
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })     
                    }
                    else{
                        if(response.data.message == "success")
                        {
                            this.setState({
                                basicTitle:"Deleted Successfully.",
                                basicType : "success",
                                show : true,
                                rowIds : [],
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                            //window.location.reload();
                            this.onloadPageData();
                        }
                        else
                        {
                            this.setState({
                                basicTitle:"Failed to Delete.",
                                basicType : "warning",
                                show : true,
                                overly:"show-n",
                                loadshow:"show-n"
                            })
                        }
                    }
                })
            } 
        }
        if(e.colDef.field == "edit"){
            var checkdata = e.data;
            var msil_code2  =  $("#edit_msil_code").val(checkdata.msil_code)
            this.setState({
                bulkslide2:"slider-translate-30p",
                edit_plant_name:{"value":checkdata.plant_name,"label":checkdata.plant_name},
                edit_plant_short_name:{"value":checkdata.plant_map_code,"label":checkdata.plant_map_code},
                edit_plant_code:{"value":checkdata.plant_code,"label":checkdata.plant_code},
                rowId :checkdata._id,
                overly:"show-m"
            })
           
        } 
        
        
    }
    addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{plant_code:"",plant_map_cod:"",msil_code:"",}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        bulkslide:"slider-translate-30p",
        // rowId : rowId
        overly:"show-m"
    })
    
    // this.setState({
    //     bulkslide2:"slider-translate-30p",
    //     // rowId : rowId
    // })

}
changeHandler1 = (event) => {
    console.log("Select", event.target.value);

    var {value , name} = event.target

    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (alphanumericRegex.test(value) || value === '') {
      this.setState({
        [name] :value
      })
    }
}
  selectplantcode=(e)=>{
    this.setState({
        plant_code:e
    })
    console.log(e,"657")
    var params ={
        plant_code : e.value
    }
    redirectURL.post("/arms/getdistinctbasedonplantcodes",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })     
    }else{
        var resp = response.data
        var plantShortNameList = []
        var plantNameList = []
        resp.map((e)=>{
            plantShortNameList.push({
                label:e.plant_short_name,
                value:e.plant_short_name
            })
            plantNameList.push({
                value : e.plant_name,
                label : e.plant_name
            })
        })
        this.setState({
            plantShortNameList : plantShortNameList,
            plantNameList : plantNameList,
            plant_short_name:{value:resp[0].plant_short_name,label:resp[0].plant_short_name},
            plant_name : {value:resp[0].plant_name,label:resp[0].plant_name}
        })
    }
        
    })
}
selectplantname = (e)=>{
    this.setState({
        plant_name : e
    })
}
selectplantshortname = (e) =>{
    this.setState({
        plant_short_name : e
    })
}
selecteditplantcode =(e)=>{
    this.setState({
        edit_plant_code :e
    })
    var params ={
        plant_code : e.value
    }
    redirectURL.post("/arms/getdistinctbasedonplantcodes",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then((response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })     
    }else{
        var resp = response.data
        var plantShortNameList = []
        var plantNameList = []
        resp.map((e)=>{
            plantShortNameList.push({
                label:e.plant_short_name,
                value:e.plant_short_name
            })
            plantNameList.push({
                value : e.plant_name,
                label : e.plant_name
            })
        })
        this.setState({
            plantShortNameList : plantShortNameList,
            plantNameList : plantNameList,
            edit_plant_short_name:{value:resp[0].plant_short_name,label:resp[0].plant_short_name},
            edit_plant_name : {value:resp[0].plant_name,label:resp[0].plant_name}
        })
    }
    })
}
selecteditplantshortname = (e)=>{
    this.setState({
        edit_plant_short_name : e
    })
}
selecteditplantname = (e) =>{
    this.setState({
        edit_plant_name :e
    })
}
render(){
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    const columnwithDefs = [
        // {
        //     headerName: "Dept Code",
        //     field: "Dept_code",
        //     width: 170,
        //     editable:false,
        //     filter: "agSetColumnFilter"
        // },
        {
            headerName: "PPNT Suffix Code",
            field: "msil_code",
            width: 140,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Plant Code",
            field: "plant_code",
            width: 120,
            editable:false,
            filter: "agSetColumnFilter",
            // valueGetter:function(params){
            //     if(params.data.plant_code == "F"){
            //         return "Gurgoan"
            //     }
            //     else if(params.data.plant_code == "M"){
            //         return "Manesar"
            //     }
            //     else if(params.data.plant_code == "G"){
            //         return "Gujarat"
            //     }
            //     else if(params.data.plant_code == "B"){
            //         return "Banglore"
            //     }
            //     else{
            //         return ""
            //     }
            // }
        },
            {
                headerName: "Plant Short Name",
                field: "plant_map_code",
                width: 160,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Plant Name",
                field: "plant_name",
                width: 120,
                filter: "agSetColumnFilter",
                editable:true,
            },
           
            {
                pinned:"left",
                headerName : "",
                field:"edit",
                width:140,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Edit</button>'
                    return rendComponent;
                },
                filter:false,
            },
            {
                pinned:"left",
                headerName : "",
                field:"delete",
                width:80,
                resizable : true,
                editable : false,
                cellRenderer:function(params){       
                    return '<i class="icofont icofont-ui-delete"></i>';
                },
                filter:false,
            },       
            // {
            //     headerName: "Dom Exp Flag",
            //     field: "dom_exp_flag",
            //     width: 170,
            //     editable:false,
            //     filter: "agSetColumnFilter",
            //     valueGetter:function(params){
            //         if(params.data.dom_exp_flag == "E"){
            //             return "Exports"
            //         }
            //         else if(params.data.dom_exp_flag == "D"){
            //             return "Domestic"
            //         }
            //         else{
            //             return ""
            //         }
            //     }
            // },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>PPNT Master</span>
                            </h5>
                        </div>
                        <h5>
                            <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.addNewRow.bind(this)} >Add master Details</button>
                        </h5>
                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Add PPNT details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">PPNT Suffix Code :</label>
                                            <input type="text" name="msil_code" id="msil_code" value ={this.state.msil_code} className="form-control" onChange={this.changeHandler1} required />
                                        </div>
                                   <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant Code:</label>
                                    <Select
                                                    placeholder={"Select Plant Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.plant_code}
                                                    onChange={this.selectplantcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantcodeList} required
                                                />
                                </div>
                                {(this.state.plantShortNameList.length >0)?
                                     <div className="col-xl-12 col-lg-12 form-group">
                                     <label>Plant short Code:</label>
                                     <Select
                                                     placeholder={"Select Plant Short Name"}
                                                     closeMenuOnSelect={true}
                                                     value={this.state.plant_short_name}
                                                     onChange={this.selectplantshortname.bind(this)}
                                                     style={{ borderRadius: "0px" }}
                                                     options={this.state.plantShortNameList} required
                                                 />
                                 </div>:""
                                }
                               {(this.state.plantNameList.length > 0)?
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Plant Name:</label>
                                <Select
                                                placeholder={"Select Plant Name"}
                                                closeMenuOnSelect={true}
                                                value={this.state.plant_name}
                                                onChange={this.selectplantname.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.plantNameList} required
                                            />
                                </div>:""
                               }
                               
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"hidden"}}>
                    <h3 className="subHarms">Edit Master Details
                    <span className="float-right fclose" style={{marginRight:"12px",padding:"1px 8px",marginTop:"-2px"}} onClick={this.onClickHideAll.bind(this)}>x</span>
                    </h3>
                            
                    <div className="slide-r-body" style={{position:"relative"}}>
                        
                        <div className="container-fluid">
                            
                        <form method="post" onSubmit={this.onClickShowFilterData2.bind(this)} autocomplete="off" className="row col-xl-12 col-lg-12">
                        <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12">PPNT Suffix Code :</label>
                                            <input type="text" name="edit_msil_code" id="edit_msil_code" className="form-control" value = {this.state.edit_msil_code} onChange={this.changeHandler1} required />
                                        </div>
                                        <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Plant Code:</label>
                                    <Select
                                                    placeholder={"Select Plant Code"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.edit_plant_code}
                                                    onChange={this.selecteditplantcode.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.plantcodeList} required
                                                />
                                </div>
                                     <div className="col-xl-12 col-lg-12 form-group">
                                     <label>Plant short Code:</label>
                                     <Select
                                                     placeholder={"Select Plant Short Name"}
                                                     closeMenuOnSelect={true}
                                                     value={this.state.edit_plant_short_name}
                                                     onChange={this.selecteditplantshortname.bind(this)}
                                                     style={{ borderRadius: "0px" }}
                                                     options={this.state.plantShortNameList} required
                                                 />
                                 </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Plant Name:</label>
                                <Select
                                                placeholder={"Select Plant Name"}
                                                closeMenuOnSelect={true}
                                                value={this.state.edit_plant_name}
                                                onChange={this.selecteditplantname.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.plantNameList} required
                                            />
                                </div>
                                   <div className="form-group col-xl-3 col-lg-3">
                                           <button  type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                                       </div>
                               
                           </form>
                        </div>
                    </div>
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        </div>
           
    );
}
}
$(document).on("input", ".alfa-num-cls", function () {
    this.value = this.value.replace(/[^A-Z0-9]*$/gi, '');
  });
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}