import React, { Component } from "react";
import axios from 'axios';
import {Redirect} from 'react-router-dom';
import redirectURL from '../redirectURL'; // Assuming redirectURL is an axios instance

class EnmUserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {successLogin:0};
    }

    async componentDidMount() {
        const token = this.props.match.params.token;
        var ppwwdd = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var usrtoken = ppwwdd(token)
        const query = {
            token: token
        };
        try {
            const response = await redirectURL.post("/authenticateUserToken", query);
            console.log("After login ", response);

            if (response.status === 200) {
                if (response.data.auth) {
                    if (response.data.otpAuthentication === 1) {
                        this.setState({
                            otpAuthentication: response.data.otpAuthentication,
                            otpMessage: "OTP has been sent to your Email",
                            userData: response.data,
                            successLogin: 0,
                        });
                    } else {
                        this.setState({
                            "successLogin" : 1,
                        })
                        await redirectURL.post("/manage/saveuserlogged", {
                            token: response.data.token,
                            userId: response.data.userid
                        });
                        
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userid', response.data.userid);
                        localStorage.setItem('dept_code', JSON.stringify(response.data.user.dept_code));
                        localStorage.setItem('is_admin', response.data.user.is_admin);
                        localStorage.setItem('is_dept_admin', response.data.user.is_dept_admin);
                        localStorage.setItem('is_global', response.data.user.is_global);
                        localStorage.setItem('firstname', response.data.user.firstname);
                        localStorage.setItem('username', response.data.user.username);
                        localStorage.setItem('lastname', response.data.user.lastname);
                        // localStorage.setItem('pc', response.data.user.plant_code || "HZ");
                        localStorage.setItem('email', response.data.user.email.replace("[at]", "@").replace("[dot]", "."));
                        localStorage.setItem('user_type', response.data.user.user_type);
                        // localStorage.setItem('plant_code', response.data.user.plant_code || "");
                        localStorage.setItem('roles', response.data.user.roles);
                        localStorage.setItem("successLogin",this.state.successLogin)
						localStorage.setItem('usermenus', JSON.stringify(response.data.menus));
						//console.log("response.data.usermenucodes ", response.data.usermenucodes);
						localStorage.setItem('usermenucodes', JSON.stringify(response.data.usermenucodes));
                        // localStorage.setItem('actual_roles', response.data.user.roles);
                        sessionStorage.setItem('ref', 1);

                        this.setState({
                            username: '',
                            email: '',
                            password: '',
                            loginMessage: response.data.msg
                        });
                    }
                } else {
                    this.setState({
                        username: '',
                        email: '',
                        password: '',
                        loginMessage: response.data.msg
                    });
                }
            } else {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    loginMessage: response.data.msg
                });
            }
        } catch (error) {
            console.error("Error authenticating user:", error);
        }
    }

    getUserDashboardUrl(pathToMatch = null) {
 
		var uroles = localStorage.getItem("roles");
		var arr = []
		
		let dashboardUrl = "/targetpreparation";
		let currentDepartmentCode = null;
		let departmentCode = JSON.parse(localStorage.getItem("dept_code"));

		//console.log("IN render, departmentCode ", departmentCode);
		if (Array.isArray(departmentCode)) {
			currentDepartmentCode = departmentCode[0]; 
		} else {
			currentDepartmentCode = departmentCode; 
		}
		var userroles = localStorage.getItem("roles");
		
					
			console.log(currentDepartmentCode,"currentDepartmentCode")
			switch(currentDepartmentCode) {
				case "AD":
					//dashboardUrl="/dashboard/scdefault";
					var userroles = localStorage.getItem("roles");
					
						dashboardUrl="/targetpreparation";
					
					break;


				case "RAIL":
					var userroles = localStorage.getItem("roles");
					if(localStorage.getItem("user_type") == "TRANSPORTER"){
						if(userroles.indexOf("arms_transporter") >=0){
							console.log(userroles,"userroles")
							dashboardUrl = "/rprakedetails"
						}else{
							dashboardUrl = "/rprakedetails"
						}
					}
					else if(userroles.indexOf("rail") >= 0)
					{
						dashboardUrl = "/targetpreparation"
					}else{
						dashboardUrl = "/targetpreparation"
					}
					break;
				default:
					let isTmsUser = false;
					if(currentDepartmentCode != null && currentDepartmentCode != undefined && currentDepartmentCode != ""){
						currentDepartmentCode = currentDepartmentCode.trim();

						if (currentDepartmentCode !== "SA") {
							// TMS users don't belong to any department
							let userMenuCodes = JSON.parse(localStorage.getItem("usermenucodes"));
							if (Array.isArray(userMenuCodes)) {
								isTmsUser = userMenuCodes.includes("tms");
							} else if (userMenuCodes == "tms") {
								isTmsUser = true; 
							}
						} else {
							console.log("IN default, ELSE, currentDepartmentCode = ", currentDepartmentCode);
						}
						console.log("isTmsUser ",isTmsUser)
						
						
						console.log("IN getUserDashboardUrl, default, params = ", departmentCode);
					}
					
			}
		
		
		console.log("dashboardUrl Login", dashboardUrl)
		return dashboardUrl;
	}


	cipher = salt => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	
		return text => text.split('')
			.map(textToChars)
			.map(applySaltToChar)
			.map(byteHex)
			.join('');
	}
    render() {
		if(localStorage.getItem('token') != '' && localStorage.getItem('token') != null)
		{
			var successLogin = this.state.successLogin;
            console.log(successLogin,"successLogin")
			if(successLogin == 1)
			{
				let dashboardUrl = this.getUserDashboardUrl();
				console.log(dashboardUrl,"dashboardUrl1143");
				//let dashboardUrl = this.state.landingpage;
				// return <Redirect to="dashboard/snddefault" push={true} />

				return <Redirect to={dashboardUrl} push={true} />
			}
		}
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper">
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Authenticating... <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}
            </div>
        );
    }
}

export default EnmUserLogin;