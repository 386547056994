import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import MapComponent from './mapLocationComponent';
import { getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenYYYYMMDD,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY,
    getDDMMMYYYYHHMMDefault } from '../common/utils';
var redirectURL = require('../redirectURL');
var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`)
var moment = require('moment');

var googleAnalytics = require("../common/googleanalytics");
var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var doOnce = true;
var rad = function(x) {
    return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;

export default class RakePositions extends Component {

    constructor(props) {
        super(props);
        this.displayData = [];
        this.state = {
            showform:'show-m',
            loadshow:'show-n',
            showrefresh:'show-n',
            sliderTranslate:'',
            showdata:this.displayData,
            modules: AllModules,
            radius:'15',
            rlatitude:'',
            rlongitude:'',
            latitude:'',
            longitude:'',
            defaultradius:0,
            coordinates:[],
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            contentString:"testing",
            mwidth:"col-xl-12 col-lg-12",
            dept_code:'',
            defaultColDef: {
                sortable: true,
                filter: true,
                //editable: true,
                resizable: true
            },
            rowData: [],

            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
            rowModelType: "serverSide",
            paginationPageSize:50,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            pagetitle:"Rake Positions",
            allrowData:[],
            totalRakes:[],
            columnDefs: [
                {
                  headerName: "Rake No",
                  field: "truck_no",
                  width: 200,
                 
                 
                },          
              {
                headerName: "Transporter Name",
                field: "transporter_name",
                width: 200,
                   
              },
                {
                headerName: "Actual GPS Provider",
                field: "actual_lspuser",
                width: 200,
                   
              },
              {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 200,
                valueGetter:function(params){
                    return moment.parseZone(params.data.timestamp).format("DD-MMM-YYYY HH:mm")
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
                cellClass:function(params){
                    let hourDifference = 0;
                    var dateFormat = moment.parseZone(new Date()).format("YYYY-MM-DD")
                    if(new Date(params.data.timestamp)< moment.parseZone(new Date(dateFormat+" 00:00:00")).utcOffset("+05:30")._d)
                    {
                        return 'bgColorDangerMedium'
                    }
                }
            },
                {
                headerName: "Last city / Town ",
                field: "last_known_address",
                width: 280,
                   
              },
            //   {
            //     headerName: "Last State",
            //     field: "state",
            //     width: 200
            //   }
                 
                 
              ],
        };
        this.popmarker=this.popmarker.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    /*Alert Popups*/
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
   
    componentDidMount(){
        // console.log("path ", this.props.match.path)
        this.logPageView(); // Log page view to GA
        let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        this.setState({eventAction: eventAction});

        
        this.renderMapDefault();
        // var urlpath;
        // var dpt;
        // if(this.props.match.path == "/sndrakes")
        // {
        //     urlpath = '/consignments/sndrakes';
        //     dpt='SNDG';
        // }
        // if(this.props.match.path == "/tnprakes")
        // {
        //     urlpath = '/consignments/sndrakes';
        //     dpt='LOG-TNP';
        // }
        // this.setState({
        //     dept_code:dpt,
        // });
        this.onLoadPage()
       
    }
    onLoadPage =()=>{
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var transporter_code = "";
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="undefined" && localStorage.getItem("transportercode") !="")
        {
            transporter_code =localStorage.getItem("transportercode");
        }
        redirectURL.post("/arms/getDataforRakepositions",{transporter_code:transporter_code},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    })
        .then((response) => {
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
            var rakesData = response.data.data;
            var currentDay = moment.parseZone(new Date()).format("YYYY-MM-DD")
            var inactiveRakes = rakesData.filter((e)=>new Date(e.timestamp) < moment.parseZone(new Date(currentDay+" 00:00:00")).utcOffset("+05:30")._d)
            console.log(inactiveRakes,'243')
            // console.log(rakesData);
            // rakesData = JSON.parse(rakesData);
             console.log("truckinfo,",rakesData);
             this.setState({
                rowData:rakesData,
                allrowData : inactiveRakes,
                totalRakes : rakesData,
                loadshow:"show-n",
                overly:"show-n"
            });
            // console.log(this.state.rowData);
            var dCode = this.state.dept_code;
            if(rakesData.length > 0)
            {
                //console.log("UPpcase Dept ", dCode);
                var markers = [];
                rakesData.map((item) => {
                        markers.push(item);
                });
                console.log("Markers ", markers);
                this.setState({
                    // defaultradius:formdata.radius,
                    coordinates:markers,
                    mwidth:"col-xl-7 col-lg-7",
                    rowData:markers,
                    // radius:formdata.radius,
                    loadshow:'show-n',
                    showform:'show-n',
                    showrefresh:'show-m',
                    rlatitude:rakesData[0].latitude,
                    rlongitude:rakesData[0].longitude,
                });
               
               
                var coords = markers;
                /*oms = new oms.OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,
                    markersWontHide: true,
                    keepSpiderfied: true,
                    basicFormatEvents: true
                });*/
                var p1 = {lat:parseFloat(rakesData[0].latitude), lng:parseFloat(rakesData[0].longitude)};
                coords.map((marker) => {
                    //console.log("marker", marker);
                    var p2 = {lat:marker.latitude, lng:marker.longitude};
                    //console.log("Distace is ", this.getDistance(p1,p2));
                    //console.log(marker.distance +"<= "+ response.data.radius)
                    var distance =  this.getDistance(p1,p2);
                    //console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
                    // if (distance <= formdata.radius)
                    // {
                        allcords.push(marker);
                    // }
                   
                });
                window.initMap = this.initMap
                this.renderMap();
            }
            else{
                this.setState({
                    show: true, basicType:'warning', basicTitle:"No Data found.",
                    loadshow:'show-n',
                    showform:'show-m',
                });
            }
            }
        });
    }
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
   
    renderMapDefault = () => {
       
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapDefault");
        window.initMapDefault = this.initMapDefault
    }
    renderMap = () => {    
        loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMap");
        window.initMap = this.initMap
    }
    initMapDefault = () => {
        if(this.state.rowData.length <= 0 ){
            var lt=28.4519751;
            var ln=77.0310713;
        }
        else{
            var lt=this.state.rowData[0].latitude;
            var ln=this.state.rowData[0].longitude;
        }
   
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 13,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            /*mapTypeControl: true,
            gestureHandling: 'greedy',
            mapTypeControlOptions: {
                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: window.google.maps.ControlPosition.TOP_RIGHT
              },            
            disableDefaultUI: true,
            zoomControl: true*/
        });
        var markers = [];
   

        circle = new window.google.maps.Circle({
            map: map
        });
       
        marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });
       
        marker.addListener('click', function(){
            marker.setAnimation(window.google.maps.Animation.BOUNCE)            
        });
       
        window.google.maps.event.addListener(map, 'click', function(event) {
           
            document.getElementById("latitude").value = event.latLng.lat();
            document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);
           
        });
        circle.bindTo('center', marker, 'position');
       
       
       
        var eventtrigger = document.getElementsByClassName('truck');

          // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
          //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
         
    }
     initMap = () => {
        var contentdata = this.state.contentString;
        console.log("initmap funciton");
        console.log(this.state.rowData,"408");
        if(this.state.rowData.length == 0){
            var lt=28.4519751;
            var ln=77.0310713;
        }
        else{
            var lt=this.state.rowData[0].latitude;
            var ln=this.state.rowData[0].longitude;
        }
        map = new window.google.maps.Map(document.getElementById('map_canvas'), {
            zoom: 11,
            center: new window.google.maps.LatLng(lt,ln),
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
       
        });

       
        var markers = [];
       
        var bounds = new window.google.maps.LatLngBounds();

        circle = new window.google.maps.Circle({
            map: map
        });
       
        circle.bindTo('center', marker, 'position');
        var markerLatlng1;
        //console.log("Radius ", this.state.radius)
        //console.log("state Lat ",this.state.rlatitude)
        if(this.state.rlatitude != '' && this.state.rlongitude != '')
        {
            //var geoFencingObj = allcords;
            //console.log(parseFloat(this.state.rlatitude),parseFloat(this.state.rlongitude));
            markerLatlng1 = new window.google.maps.LatLng(this.state.rlatitude,this.state.rlongitude);
            //console.log("markerLatlng", markerLatlng1)
            var circle = new window.google.maps.Circle({
                strokeColor: '#71caab',
                strokeOpacity: 0.7,
                strokeWeight: 1,
                fillColor: '#71caab',
                fillOpacity: 0.25,
                map: map,
                center: markerLatlng1,
                //radius: Math.sqrt(this.state.radius) * 10000
            });

        }
   
        //console.log("allcords initmap ", allcords)
        if(doOnce){
            // initOMS();
            doOnce = false;
        }
        allcords.map((marker) => {
        // console.log("marker ", marker)
            var image =  require('../../assets/icons/traindeliver.png');
            var contentarr = []
            var headerTitle = marker.truck_no
            contentarr.push({"key":"Transporter Name", "value":marker.transporter_name});
            contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
            contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(marker.timestamp)});
            contentarr.push({"key":"Speed (km/h)", "value":marker.speed});
            contentarr.push({"key":"Address", "value":marker.last_known_address});
           
            var contentString = infoBox(image, headerTitle, contentarr, '')


            //var contentString = "Here "+marker.truck_no;
            var infowindow = new window.google.maps.InfoWindow({
                content: contentString
            });
           console.log(marker.latitude,marker.longitude,"479")
            var markerLatlng = new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude));
       

            var image =  require('../../assets/icons/traindeliver.png');
            var mark = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title:marker.truck_no,
                icon: image
            });
            mark.addListener('click', function() {
                infowindow.open(map, mark);
              });
            mark.setMap(map);
            bounds.extend(new window.google.maps.LatLng(parseFloat(marker.latitude), parseFloat(marker.longitude)));
           
            map.fitBounds(bounds);
        });
        var eventtrigger = document.getElementsByClassName('truck');

          // var searchBox = new window.google.maps.places.SearchBox(eventtrigger);
          //map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(eventtrigger);
         
    }
    toggleBounce() {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }
       
      }
   
    changeInputHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
   
    changeRadiusHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
        circle.setRadius(value);
    }
   
    
    goBack(){
        window.location.reload();
    }
    getDistance = (p1, p2) => {
        //console.log("p1",p1);
        //console.log("p2",p2);
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d/1000; // returns the distance in meter
      };
    onShowInfo = (e) =>{
        console.log(JSON.parse(e.target.id));
        this.setState({
            contentString:"Here Success"
        });
        var ourMarker = e.target.id;
            window.google.maps.event.trigger(ourMarker, 'click');
        var infowindow = new window.google.maps.InfoWindow({
            content: "Here Success"
          });
        infowindow.open(map, marker);

    }
    popmarker(e)
    {
        console.log("OnClick Table ",e.data)
        var lat = parseFloat(e.data.latitude);
        var lng =parseFloat(e.data.longitude);
        var data =e.data;
        map.setCenter(new window.google.maps.LatLng(lat,lng));
        map.setZoom(17);
        var markerLatlng = new window.google.maps.LatLng(lat, lng);
        //var image =  require('../../assets/icons/truckblue.png');
        var image =  require('../../assets/icons/traindeliver.png');
        /*if(data.overspeeding_exception == 1)
        {
            var image =  require('../../assets/icons/truck_overspeed.png');
        }
       
        if(data.nightdriving_exception == 1)
        {
            var image =  require('../../assets/icons/truck-end.png');
        }*/

       
        var image =  require('../../assets/icons/traindeliver.png');
        var marker = new window.google.maps.Marker({
            position: markerLatlng,
            map: map,
            title:data.truck_no,
            icon:image
        });


        var contentarr = []
        var headerTitle = e.data.truck_no
        contentarr.push({"key":"Transporter Name", "value":e.data.transporter_name});
        contentarr.push({"key":"GPS Provider", "value":e.data.actual_lspuser});
        contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(e.data.timestamp)});
        contentarr.push({"key":"Speed (km/h)", "value":e.data.speed});
        contentarr.push({"key":"Address", "value":e.data.last_known_address});
       
        var contentString = infoBox(image, headerTitle, contentarr, '')
        /*
        var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Department:" + data.dept_code+"<br />Address:"+data.address;
        var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+truckText+"</td></tr><tr><th>Distance from Geofence: </th><td style='text-align: right'>"+data.distancel
        contentString = contentString + " (KM)</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
        contentString = contentString + data.transporter_name + "</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name+"</td></tr><tr><th>Department:</th>";
        contentString = contentString + "<td style='text-align: right'>"+data.dept_code+"</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
            */  
        var infowindow = new window.google.maps.InfoWindow({
            content: contentString
        });
       
        marker.setAnimation(window.google.maps.Animation.DROP)
        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
       
        marker.setMap(map);
       
       
    }
    onShowUploadDiv = () => {
        //console.log("Click Upload Button")
        this.setState({
            uploadDivWidth:'30%',
            sliderTranslate:"slider-translate",
            showDiv:'show-m',
            csvmessage:'',
            csverrmessage:'',
        });
    }
    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth:'0%',
            sliderTranslate:"",
            showDiv:'show-n',
            uploadFile:'',
            file:'',
            csvmessage:'',
            csverrmessage:'',
        });
    }
   onClickTab = (counterKey) => {
    allcords = []
    console.log(counterKey,"1011")
    this.setState({
        rowData : this.state[counterKey]
    });
    var rakesData = this.state[counterKey];
            if(rakesData.length > 0)
            {
                //console.log("UPpcase Dept ", dCode);
                var markers = [];
                rakesData.map((item, index) => {
                        console.log(index+1)
                        markers.push(item);
                });
                console.log("Markers ", markers);
                this.setState({
                    // defaultradius:formdata.radius,
                    coordinates:markers,
                    mwidth:"col-xl-7 col-lg-7",
                    rowData:markers,
                    // radius:formdata.radius,
                    loadshow:'show-n',
                    showform:'show-n',
                    showrefresh:'show-m',
                    rlatitude:rakesData[0].latitude,
                    rlongitude:rakesData[0].longitude,
                });
               
               
                var coords = markers;
                /*oms = new oms.OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,
                    markersWontHide: true,
                    keepSpiderfied: true,
                    basicFormatEvents: true
                });*/
                var p1 = {lat:parseFloat(rakesData[0].latitude), lng:parseFloat(rakesData[0].longitude)};
                coords.map((marker) => {
                    //console.log("marker", marker);
                    var p2 = {lat:marker.latitude, lng:marker.longitude};
                    //console.log("Distace is ", this.getDistance(p1,p2));
                    //console.log(marker.distance +"<= "+ response.data.radius)
                    var distance =  this.getDistance(p1,p2);
                    //console.log(window.google.maps.geometry.spherical.computeDistanceBetween (p1, p2))
                    // if (distance <= formdata.radius)
                    // {
                        allcords.push(marker);
                    // }
                   
                });
                window.initMap = this.initMap
                this.renderMap();
            }
            else{
                this.setState({
                    show: true, basicType:'warning', basicTitle:"No Data found.",
                    loadshow:'show-n',
                    showform:'show-m',
                });
               
            }
        
}

    render(){
        var hText = 'All';
        if(this.state.dept_code == 'sndg')
        {
            hText = 'Sales and Dispatch'
        }
        if(this.state.dept_code == 'log-tnp')
        {
            hText = 'Train and Production'
        }
   
        if(this.state.dept_code == 'log-prt')
        {
            hText = 'Spare Parts'
        }
        const dStyles={
            width:'90%',
            marginLeft:'0px',
            paddingLeft:'0px',
            height:'500px'
        }
        const btstye={
            marginBottom:"10px"
        }
        return (
           
            <div className="container-fluid">
                <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
                {this.state.alert}
                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card-arms">
                       
                            <div className="card-header-arms">
                                <h5>
                                    <i className="icon-map cus-i"></i> <span>Rake Positions  </span>
                                    {/*<span className="float-right">
                                        <img className="mapico2" src={require('../../assets/icons/truck_overspeed.png')} /> - Overspeed
                                        &nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck-end.png')} /> - Night Driving
                                   
                                    </span> */}
                                    <span className="col-xl-12 col-lg-12 pull-right" style={{fontSize:"12px",marginBottom:"20px"}}>
                                        {/*<img className="mapico1" src={require('../../assets/icons/truck_na_32.png')} /> - N/A
                                        &nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_inside_32.png')} /> - Inside Plant
                                        &nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_going_32.png')} /> - Going to Dealer
                                        &nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_atdealer_32.png')} /> - At Dealer Location
                                        &nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_leftdealer_32.png')} /> - Left Dealer Location
                                        &nbsp;&nbsp;<img className="mapico1" src={require('../../assets/icons/truck_return_32.png')} /> - Return to Plant
                                        &nbsp;&nbsp;<img className="mapico" src={require('../../assets/icons/truck_empty_32.png')} /> - Empty Truck Outside Plant
                                        */}
                                    </span>                                
                                </h5>
                            </div>
                            <div className="crm-numbers pb-0">
                                    <div className="row">
                                    <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"totalRakes")}>
                                            <span className="f13" style={{color:"#4099FF"}}>Total Rakes</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.totalRakes.length)?this.state.totalRakes.length:0}/>
                                                </span>
                                            </h4>
                                        </div> 
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"allrowData")}>
                                            <span className="f13" style={{color:"#FF5370"}}>GPS Inactive Rakes</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.allrowData.length)?this.state.allrowData.length:0}/>
                                                </span>
                                            </h4>
                                        </div>                         
                                    </div>                               
                                </div>
                            <div className="card-body row">
                               {/* <form className={" theme-form col-xl-12 col-lg-12 "+ (this.state.showform)} onSubmit={this.formHandler}>
                                    <div className="row">
                                        <div className="form-group  col-xl-3 col-lg-3">
                                            <label className="c-lbl">Radius *:</label>
                                            <input type="text" name="radius" id="radius" value={this.state.radius} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Radius" required />
                                        </div>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label className="c-lbl">Latitude *:</label>
                                            <input type="text" name="latitude" id="latitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Latitude" required />
                                        </div>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label className="c-lbl">Longitude *:</label>
                                            <input type="text" name="longitude" id="longitude" onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Longitude" required />
                                        </div>
                                        <div className="form-group col-xl-3 col-lg-3">
                                            <label className="c-lbl"></label>
                                            <button type="submit" style={{marginTop:"35px"}} className="btn btn-success">Submit</button>
                                        </div>
                                    </div>
                                   
               
                                </form>
                                <div className={"col-xl-12 col-lg-12 "+(this.state.showrefresh)}>
                                        <button type="button" style={{marginRight:"30px"}} className={"btn btn-danger float-right"} onClick={this.goBack} >Back</button>
                                    </div> */}
                                <div className="row col-xl-12 col-lg-12">
                                   
                                    <div className={"dataLoadpage " +(this.state.loadshow)}>
                                    </div>
                                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                                    </div>
                                   
                                    <div id="map_canvas" className={this.state.mwidth} style={dStyles}></div>
                                    {
                                    (this.state.coordinates.length > 0)?
                                        <div className="col-xl-5 col-lg-5" style={{height:"500px"}}>
                                           

                                            <div id="myGrid" style={{ height: "532px",width: "100%"}}  className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={this.state.columnDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={false}
                                                    enableRangeSelection={true}
                                                    autoGroupColumnDef={this.state.autoGroupColumnDef}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                    // enableRangeSelection={true}
                                                    //onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
                                                    onCellClicked={this.popmarker}
                                                />
                                            </div>
                                        </div>
                                        : ""
                                    }
                                </div>
                                   
                            </div>
                        </div>  
                    </div>
                 </div>

                 
                 <div className={"slide-r "+(this.state.sliderTranslate)} >
                   
                   
                    <div className="slide-r-body" style={{position:"relative"}}>
                     <div className={"dataLoadpage " +(this.state.loadshow)}>
                    </div>
                    <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                        <div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
                    </div>
                        <div className="col-xl-12 col-lg-12">
                             {this.displayData}
                        </div>
                    </div>
                 </div>


            </div>
             
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
   
     var len = geoFencingArea.getPath().getLength();
     console.log(len);
     var htmlStr = "";
     var coordinates=[];
     for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
         var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
         var spliting = strlatlng.split(",");
         var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
         coordinates.push(latlngd);
      // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
       //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
       //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
     }
    // console.log("htmlStr", coordinates);
     
}
function initOMS(){
    // Initializing OMS
    oms = new oms.OverlappingMarkerSpiderfier(map, {
      //markersWontMove: true,
      //keepSpiderfied: true,
      //nearbyDistance: 10,
      //circleFootSeparation: 60,
      //legWeight: 1.5
      markersWontMove: true,
    markersWontHide: true,
    keepSpiderfied: true,
    basicFormatEvents: true
    });
  }

  function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}