'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
// import Consignmentforceclose from '../m';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
//import DeleteButton from "./deleteshipmentDSR";
import $ from 'jquery';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {verifyext, verifycsvdata} from "../common/verifyext";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY} from '../common/utils';
import { func } from 'prop-types';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");




export default class ActualRakeAvailabilty extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
        //   UpdateButton:UpdateButton,
         // DeleteButton:DeleteButton,
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        bulkslide1:"",
        bulkslide2:"",
        file:"",
        uploadFile:"",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        bulkslide:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        rowSelection:'',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        overly:"show-n",
        loadshow:"show-n",
        holidayEvents : [],
        showCalendar : "show-m",
        showGridData : "show-m",
        vehicleCap : [],
        statesList : [],
        rowIds : [],
        freight_type : 1,
        comp_code : "",
        lpNames : [],
        loading_port_code : "",
        cluster_yard  : [],
        rail_yard : [],
        state :[],
        loading_cluster : [],
        unloading_cluster : [],
        baseStations : [],
        rake_no:"",
        origin:"",
        loadingyard:"",
        destinationyard:"",
        category:"",
        rakenodetails : [],
        origindetails:[],
        selectloadingcluster:"",
        selectunloadingcluster:"",
        selectbasestation:"",
        showloadinginputTab :"",
        showunloadinginputTab :"",
        showbasestationinputTab :"",
        loadingyarddetails :[],
        unloadingyarddetails:[],
        categorydetails:[],
        railyard_name:"",
        latitude:"",
        longitude:"",
        service_provider:"",
        serviceproviderdetails:[],
        destinationyarddetails:[],
        rowId:"",
        approvalList:["Yes","No"],
        unloadingyard:"",
        rake_number:"",
        unloading_yard:"",
        loading_yard:"",
        transporter_details:"",
        transporter_names:[],
        loading_plant:"",
        rake_type:"",
        categories:[],
        clusters:[],
        cluster:""
        //components : {datepicker:getDatePicker()}
    }
    this.changeFileHandler = this.changeFileHandler.bind(this);
    // this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
    this.onCellClicked = this.onCellClicked.bind(this);
    this.checkFileData = this.checkFileData.bind(this);
  }

  logPageView = () => {
      try {
          if (googleAnalytics.page.enableGA) {
              googleAnalytics.logPageView();
              let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
              let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
              let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
              this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
          } else {
              console.log("GA disabled...");
          }
      } catch(error) {
          console.error("Error occured while logging to GA, error = ", error);
      }
  }  
  
  async componentDidMount(){
    this.logPageView();
    loadDateTimeScript();
    var params ={}
    var transporter_code = localStorage.getItem("transportercode")
    if(transporter_code != undefined && transporter_code != null && transporter_code != ""){
        params.transporter_code = transporter_code
    }
    await redirectURL.post("/arms/getActualRakeavailabilitydetails",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
})    
    .then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
        var  rake_no = response.data.rakeNo;
        var  loading_cluster = response.data.loading_yards;
        var unloading_cluster = response.data.unloading_yards;
        var transporters = response.data.transporter_details;
        var category_details = response.data.category_details;
        var cluster_details = response.data.cluster_details;
        var loadingyarddetails = []
        var rakenodetails = []
        var unloadingyarddetails = []
        var transporter_names = []
        var categories = []
        var clusters = []
        rake_no.map((e)=>{
            rakenodetails.push({
                value : e,
                label : e
            })
        })
        loading_cluster.map((e)=>{
            loadingyarddetails.push({
                label : e,
                value : e
            })
        })
        unloading_cluster.map((e)=>{
            unloadingyarddetails.push({
                label : e,
                value : e
            })
        })
        transporters.map((e)=>{
            transporter_names.push({
                label : e.transporter_short_name +" - "+e.transporter_name,
                value : e.transporter_code
            })
        })
        category_details.map((e)=>{
            categories.push({
                value:e,
                label:e
            })
        })
        cluster_details.map((e)=>{
            clusters.push({
                value :e,
                label :e
            })
        })
        console.log(rakenodetails,"233")
        this.setState({
            rakenodetails : rakenodetails,
            loadingyarddetails : loadingyarddetails,
            unloadingyarddetails : unloadingyarddetails,
            transporter_names:transporter_names,
            categories:categories,
            clusters : clusters
        })
    }
    }).catch((e)=>{
        console.log(e);
    })
    await this.onLoadPageData();
}

async onLoadPageData(){
    var transporter_code = localStorage.getItem("transportercode")
    var params ={}
    this.setState({
        loadshow : "show-m",
        overly:"show-m"
    })
    if(transporter_code != undefined && transporter_code != null && transporter_code != ""){
        params.transporter_code = transporter_code
    }
    await redirectURL.post("/arms/getactualRakeavailabilitydata",params,{"headers":
    {											
        'Content-Type': 'application/json;charset=UTF-8', 
        'Authorization': `Bearer ${localStorage.getItem("token")}`
}
}).then(async(response)=>{
    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
        this.setState({
            show:true,
            basicTitle:"Session Expired",
            basicType:"warning",
            overly: "show-n",
            loadshow: "show-n"
        })
    }else{
        console.log(response.data);
        var resp =response.data
        if(resp.length > 0){
           await this.setState({
                rowData : resp,
                overly :"show-n",
                loadshow : "show-n"
            })
        }else{
            await this.setState({
                rowData : [],
                overly :"show-n",
                loadshow : "show-n"
            })
        }
    }
    })
}
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}



closeAlert = () => {
    if(this.state.basicTitle == "Session Expired"){
        window.location.href = "/logout"
    }else{
        this.setState({
            show: false
        });
    }
}

onCellClicked(e)
{
    if(e.colDef.field == "delete")
    {
        if(e.data.status != 3 || e.data.status != "3"){
            var rowId = e.data._id;
        console.log(e.data._id);
        var params = {
            rowId:rowId,
            username: localStorage.getItem('username'),
        }
        //var confirm = confirm("Are You Sure! Do you really want to delete?");
            if (window.confirm("Are You Sure! Do you really want to cancel?"))
            {
                redirectURL.post("/arms/deleteactualrakeavailabilitydata",params,{"headers":
                {											
                    'Content-Type': 'application/json;charset=UTF-8', 
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
            }).then((response)=>{
                if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                    this.setState({
                        show:true,
                        basicTitle:"Session Expired",
                        basicType:"warning",
                        overly: "show-n",
                        loadshow: "show-n"
                    })
                }else{
                    console.log(response.data);
                    if(response.data.message == "success")
                    {
                        this.setState({
                            basicTitle:"Cancelled Successfully.",
                            basicType : "success",
                            show : true
                        })
                        this.onLoadPageData();
                    }
                    else
                    {
                        this.setState({
                            basicTitle:"Failed to Cancel",
                            basicType : "warning",
                            show : true
                        })
                    }
                }
                }).catch((e)=>{
                    console.log(e);
                })
            }    
        }else{
            this.setState({
                basicTitle:"Cannot Cancel Planning Once Approved",
                basicType : "warning",
                show : true
            })
        }
            
    }
    if(e.colDef.field == "update_data"){
       
        console.log(e.data,"339")
        var params ={
            "unloading_circuit":e.data.unloading_circuit
        }
        var rake_no = e.data.rake_name
        var loading_yard = e.data.loading_yard
        var unloading_yard = e.data.unloading_yard
        var start_time = moment.parseZone(new Date(e.data.start_time)).format("DD-MM-YYYY")
        redirectURL.post("/arms/getunloadingYardDetails",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var unloadingyarddetails = []
            var getDestinationyards = response.data.getDestinationyards
            getDestinationyards.map((e)=>[
                unloadingyarddetails.push({
                    value:e,
                    label:e
                })
            ])
            console.log(response.data.getDestinationyards)
            this.setState({
                unloadingyarddetails:unloadingyarddetails,
                rake_no:{"label":rake_no,"value":rake_no},
                loadingyard :{"label":loading_yard,"value":loading_yard},
                unloadingyard:{"label":unloading_yard,"value":unloading_yard},
                overly :"show-m",
                bulkslide1 : "slider-translate",
                rowId : e.data._id
            })
            $("#transporter_availability").val(start_time)
        }
        })
    }
    if(e.colDef.field == "revert"){
        if(e.data.status != 3 || e.data.status != "3"){
            var params = {
                rowId : e.data._id,
            }
            this.setState({
                loadshow :"show-m",
                overly:"show-m"
            })
            redirectURL.post("/arms/revertfieldsinrakeavailability",params,{"headers":
            {											
                'Content-Type': 'application/json;charset=UTF-8', 
                'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
        }).then((response)=>{
            if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                this.setState({
                    show:true,
                    basicTitle:"Session Expired",
                    basicType:"warning",
                    overly: "show-n",
                    loadshow: "show-n"
                })
            }else{
                if(response.data.message =="success"){
                    this.setState({
                        show:true,
                        basicType : "success",
                        basicTitle: "Data Reverted Successfully",
                        loadshow :"show-n",
                        overly:"show-n"
                    })
                    this.onLoadPageData()
                }else{
                    this.setState({
                        show:true,
                        basicType : "warning",
                        basicTitle: "Data Not Reverted",
                        loadshow :"show-n",
                        overly:"show-n"
                    })
                }
            }
            })
        }else{
            this.setState({
                basicTitle:"Cannot Revert Planning Once Approved",
                basicType : "warning",
                show : true
            })
        }
        
    }
    if(e.colDef.field == "approval_status"){
        var params = {
            rowId : e.data._id,
            approved_by : localStorage.getItem("username")
        }
        this.setState({
            loadshow :"show-m",
            overly:"show-m"
        })
        redirectURL.post("/arms/sendmsilapprovalvalue",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            if(response.data.message =="success"){
                this.setState({
                    show:true,
                    basicType : "success",
                    basicTitle: "Approved Successfully",
                    loadshow :"show-n",
                    overly:"show-n"
                })
                this.onLoadPageData()
            }else{
                this.setState({
                    show:true,
                    basicType : "warning",
                    basicTitle: "Approval Unsuccessful",
                    loadshow :"show-n",
                    overly:"show-n"
                })
            }
        }
        })
    }
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    rake_no:"",
    loadingyard:"",
    unloadingyard:"",
    rake_number:"",
    transporter_details:"",
    loading_plant:"",
    unloading_yard:"",
    loading_yard:"",
    startDate:"",
    endDate:"",
    category:"",
    rake_type:""
    });
    $("#transporter_availability").val("")
    $("#no_of_cars").val("")
    $("#msil_reck_no").val("")
}
onClickHideAll2(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslide:"",
    bulkslide1:"",
    bulkslide2:"",
    rake_no:"",
    loadingyard:"",
    unloadingyard:"",
    rake_number:"",
    transporter_details:"",
    loading_plant:"",
    unloading_yard:"",
    loading_yard:"",
    startDate:"",
    endDate:"",
    category:"",
    rake_type:""
    });
    $("#transporter_availability").val("")
    $("#no_of_cars").val("")
    $("#msil_reck_no").val("")
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{station_code:"",state:"",cluster_name:"",railyard_name:"",latitude:"",longitude:"",loading_cluster:"",unloading_cluster:"",base_station:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}
onClickDataExport(){
    var params = {
        columnKeys: ['station_code_1', 'state_1', 'cluster_name_1', 'railyard_name_1', 'latitude_1', 'longitude_1','loading_cluster_1','unloading_cluster_1','base_station_1', 'bcacbm_eligible_1']
    };
    this.gridApi.exportDataAsCsv(params);
}
showBulkUpload(){
    this.setState({
        overly :"show-m",
        bulkslide : "slider-translate",
        
    })
}
resetUpload = () => {
    this.setState({
        bulkslide:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upform").reset();
}
changeFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            // { 
            //     name: 'id',
            //     inputName: 'id',
            //     required: false,
            // },
            { 
                name: 'station_code',
                inputName: 'station_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'state',
                inputName: 'state',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'cluster_name',
                inputName: 'cluster_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'railyard_name',
                inputName: 'railyard_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'latitude',
                inputName: 'latitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'longitude',
                inputName: 'longitude',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'loading_cluster',
                inputName: 'loading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'unloading_cluster',
                inputName: 'unloading_cluster',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'base_station',
                inputName: 'base_station',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'bcacbm_eligible',
                inputName: 'bcacbm_eligible',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
        ]
    }
                var data = CSVFileValidator(e.target.files[0], config)
                .then((csvData) => {
                    console.log('csvData', csvData)
                    // if (csvData.inValidMessages.length > 0) {
                    //     this.setState({
                    //         show: true,
                    //         basicTitle : 'dont upload other fields',
                    //         basicType : 'danger'
                    //     });
                    // }else{
                    //     this.setState({
                    //         csvcontent: csvData.data
                    //     });
                    // }
                    const aplhanumeichypen = new RegExp(/^[a-zA-Z0-9- ._,&#]*$/); // define allowed characters using regex
                    let keys = Object.keys(csvData.data[0])
                    let items  = ['station_code','state','cluster_name','railyard_name','latitude','longitude','loading_cluster','unloading_cluster','base_station','bcacbm_eligible']
                    let check = false
                     if (csvData.inValidMessages.length === 0) {       
                                for (let i in keys){
                                    if(items.includes(keys[i])){
                                        check = true                 
                                    }else{
                                        check = false
                                        break
                                    }
                            }
                        }     
                            else{
                                check = false
                            }  
                            
                            let csvalues = Object.keys(csvData.data[0])
                            if(csvalues.length > 0){
                                for(let c in csvalues){
                                    if(aplhanumeichypen.test(csvalues[c]) === false)
                            {
                                check = false;
                                break;
                            }
                                }
                            }
                     console.log("check", check)
                    if (check === false) {
                        $("#bulkUploadID").val("")
                        // document.getElementById("appednCSVData").innerHTML = ''
                        this.setState({
                            show: true,
                            basicTitle: 'dont upload other fields',
                            basicType: 'danger'
                        });
                       
                    } else {
                        this.setState({
                            csvcontent: csvData.data
                        });
                    }
                  

                }).catch(err => { })
            // console.log("e.target.files[0]",e.target.result);
            var contentList = []



            var out = new Promise(function (reject, resolve) {
                var reader = new FileReader();
                reader.onload = async function (e) {
                    var contents = await e.target.result;
                    // document.getElementById("appednCSVData").innerHTML = contents
                    resolve(contents);
                };
                var tt = reader.readAsText(e.target.files[0]);
            });
            // console.log(contentList);
            this.setState({
                file: e.target.files[0]
            });
            // console.log("e.target.files[0].type ", e.target.files[0]);



            if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
                // console.log(fileData);
                // console.log(typeof(fileData))



            }
            else {
                e.target.value = null;
                this.setState({
                    bulkUploadID:'',
                    uploadFile: '',
                    file: "",
                    show: true,
                    basicType: 'danger',
                    basicTitle: 'Please upload file having extensions .csv only.',
                });
            }  
}

checkFileData()
{
   
    var vdata = verifycsvdata(JSON.stringify(this.state.csvData));
    //console.log("verifycsvdata ", vdata)
    if(vdata == 0)
    {
        this.setState({
            csvData:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'File contains invalid data',
            uploadFile:'',
            bulkUploadID:'',
            file:""
        });
    }
}
onclickslider(e){
    this.setState({
        overly :"show-m",
        bulkslide1 : "slider-translate"
    })
    
}
onclickslider2(e){
    this.setState({
        overly :"show-m",
        bulkslide2 : "slider-translate"
    })
    
}
selectserviceprovider =(e)=>{
    this.setState({
        service_provider: e,
    })
}
selectrakeno = (e) => {
    this.setState({
        rake_no: e,
        rake_number:e
    })
}
selectraketype =(e) =>{
    this.setState({
        rake_type : e
    })
}
selectloadingplant =(e) =>{
    this.setState({
        loading_plant:e
    })
}
selectloadingyard = (e) => {
    this.setState({
        loadingyard: e,
        loading_yard:e
    })
}
selectunloadingyard = (e) => {
    this.setState({
        unloadingyard: e,
        unloading_yard:e
    })
}
selectcluster = (e) =>{
    this.setState({
        cluster : e
    })
}
selecttransporter = (e) =>{
    this.setState({
        transporter_details : e
    })
}
selectcategory = (e) =>{
    this.setState({
        category : e
    })
}
handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
  
    var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    if(this.state.endDate !=undefined && this.state.endDate !="")
    {
        console.log(startdate,"startdate")
        console.log(this.state.endDate,"endDate")
        var startDate = new Date(startdate);
        var endDate = new Date(this.state.endDate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        console.log(startTime,endTime);
        if(startTime > endTime)
        {
          this.setState({
            startDate:""
          });
          alert("End TIme Date should be greater than Start Time");
        }
        else
        { 	
          this.setState({
            startDate:startdate
          });
        }
    }
    else
    {
      this.setState({
        startDate:startdate
      });
    }
      //let value = event.target.value;
    //this.setState({'startDate':value});
}
handlerEndDateTime  = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);    
    var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())

    if(this.state.endDate !=undefined && this.state.endDate !="")
    {
        console.log(this.state.startDate,"startdate")
        console.log(edate,"endDate")
        var startDate = new Date(this.state.startDate);
        var endDate = new Date(edate);
        var startTime = startDate.getTime();
        var endTime = endDate.getTime();
        console.log(startTime,endTime);
        if(startTime > endTime)
        {
          this.setState({
            endDate:""
          });
          alert("End Time should be greater than Start Time");
        }
        else
        { 
          this.setState({
            endDate:edate
          });
        }
    }
    else
    {
        this.setState({
          endDate:edate
        });
    }
}
changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
validateInput = (event) => {
    const inputValue = event.target.value;
    console.log(inputValue, "inputValue")
    const characterRegex = /^[a-zA-Z\s]+$/;
    console.log(!characterRegex.test(inputValue),"910")
    if (!characterRegex.test(inputValue)) {
        if(inputValue == ""){
            this.setState({
                railyard_name: "",
              });
        }
     
    } else {
      this.setState({
        railyard_name: inputValue,
      });
    }
  };
  validatelatitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    console.log(inputValue,"inputValue")
    const floatRegex = /^\d{1,}(\.\d{0,4})?$/;
    console.log(!floatRegex.test(inputValue))
    if (!floatRegex.test(inputValue)) {
     
    } else {
      this.setState({
        latitude: inputValue,
      });
    }
  };
  validatelongitudeInput = (event) => {
    const inputValue = event.target.value.trim();
    const floatRegex = /^\d{1,}(\.\d{0,4})?$/;

    if (!floatRegex.test(inputValue)) {
     
    } else {
      this.setState({
        longitude: inputValue,
      });
    }
  };
  changeHandler = (event) => {
    console.log("Select", event.target.value);

    var name = event.target.name;
    var value = event.target.value;
    const re = /^[A-Za-z]+$/;
    // if (name == 'deptName') {
    //     if (value === "" || re.test(value)) {
    //         console.log("name succ")
    //         alert('hi')
    //         //   this.setState(value);
    //     }else{
    //         alert('dont enter the numerals')
    //     }
    // }

    console.log(name);
}
submitactualrakeavailabilitydetails=(event)=>{
    event.preventDefault();
    this.setState({
        overly:"show-m",
        loadshow:"show-m"
    })
    var transporter_rake_no = this.state.rake_no.value
    var transporter_loading_yard = this.state.loadingyard.value
    var transporter_availability = $("#transporter_availability").val()
    var transporter_unloading_yard = this.state.unloadingyard.value
    console.log(transporter_unloading_yard,'transporter_unloading_yard')
    var params ={
        transporter_rake_no : transporter_rake_no,
        transporter_loading_yard : transporter_loading_yard,
        transporter_availability : transporter_availability,
        rowId : this.state.rowId,
        username : localStorage.getItem("username"),
        
    }
    if(transporter_unloading_yard != undefined && transporter_unloading_yard != null && transporter_unloading_yard != ""){
        params.transporter_unloading_yard=transporter_unloading_yard
    }
        if(transporter_rake_no != undefined && transporter_rake_no != null && transporter_rake_no != ""){
            if(transporter_loading_yard != undefined && transporter_loading_yard != null && transporter_loading_yard != ""){
                if(transporter_availability != undefined && transporter_availability != null && transporter_availability != ""){
                    redirectURL.post("/arms/sendrakeavailabilitydetails",params,{"headers":
                    {											
                        'Content-Type': 'application/json;charset=UTF-8', 
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
                }).then((response)=>{
                    if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                        this.setState({
                            show:true,
                            basicTitle:"Session Expired",
                            basicType:"warning",
                            overly: "show-n",
                            loadshow: "show-n"
                        })
                    }else{
                        if(response.data.message == "success"){
                            this.setState({
                                loadshow:"show-n",
                                overly:"show-n",
                                show:true,
                                basicType:"success",
                                basicTitle:"Updated Successfully",
                                rake_no:"",
                                loadingyard:"",
                                unloadingyard:"",
                                bulkslide1:""
                            })
                            $("#transporter_availability").val("")
                            this.onLoadPageData()
                        }else{
                            this.setState({
                                loadshow:"show-n",
                                overly:"show-n",
                                show:true,
                                basicType:"warning",
                                basicTitle:"Data Not Updated",
                                rake_no:"",
                                loadingyard:"",
                                unloadingyard:"",
                                bulkslide1:""
                            })
                            $("#transporter_availability").val("")
                        }
                    }
                    })
                
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please Update Transporter Availability",
                        basicType :'warning',
                        loadshow :"show-n",
                        overly:"show-n"
                    })
                }
                
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please Select Loading Yard",
                    basicType :'warning',
                    loadshow :"show-n",
                    overly:"show-n"
                })
            }
        }else{
            this.setState({
                show:true,
                basicTitle:"Please Select Rake No",
                basicType :'warning',
                loadshow :"show-n",
                overly:"show-n"
            })
        }
    
}
onRowSelection(event){
    var rowIds=[];
    var rwCount = event.api.getSelectedNodes();
    rwCount.map((item) => {
        rowIds.push(item.data._id)
    });
    
    this.setState({
        rowIds:rowIds
    })
}
submitmanualentrydetails=(event)=>{
    event.preventDefault()
    this.setState({
        loadshow:"show-m",
        overly:"show-m"
    })
    var rake_name = this.state.rake_number.value
    var transporter_code = this.state.transporter_details.value
    var transporter_name = this.state.transporter_details.label
    if(transporter_name != undefined && transporter_name != null && transporter_name != ""){
        var transporter_short_name = transporter_name.split("-")[0]
    }
    var loading_plant = this.state.loading_plant.value
    var loading_yard = this.state.loading_yard.value
    var unloading_yard = this.state.unloading_yard.value
    var start_time =  this.state.startDate
    var end_time = this.state.endDate
    var unloading_circuit = this.state.cluster.value
    var no_of_cars = $("#no_of_cars").val()
    var msil_reck_no = $("#msil_reck_no").val()
    var transaction_id = moment.parseZone(new Date()).format("YYYYMMDDHHmmss-1")
    var category = this.state.category.value
    var rake_type = this.state.rake_type.value
    var startTime = new Date(this.state.startDate)
    var endTime  = new Date(this.state.endDate)
    var startDate = startTime.getTime()
    var endDate = endTime.getTime()
    // console.log(startDate,endDate,"1194")
    var formData = {
        "rake_name":rake_name,
        "transporter_code":transporter_code,
        "transporter_short_name":transporter_short_name,
        "loading_plant":loading_plant,
        "loading_yard":loading_yard,
        "unloading_yard":unloading_yard,
        "unloading_circuit":unloading_circuit,
        "start_time":start_time,
        "end_time":end_time,
        "no_of_cars":no_of_cars,
        "transaction_id":transaction_id,
        "category":category,
        "rake_type":rake_type,
        "created_by":localStorage.getItem("username")
    }
    if(msil_reck_no != undefined && msil_reck_no != null && msil_reck_no != ""){
        formData.msil_reck_no = msil_reck_no
    }
    console.log(formData,"1183")
    // console.log(startDate < endDate)
    if(startDate < endDate){
        console.log("chcekc-1")
        if(rake_name != undefined && rake_name != null && rake_name != ""){
            if(transporter_code != undefined && transporter_code != null && transporter_code != "" && transporter_short_name != undefined && transporter_short_name != null && transporter_short_name != "" ){
                if(loading_plant != undefined && loading_plant != null && loading_plant != ""){
                  if(loading_yard != undefined && loading_yard != null && loading_yard != ""){
                    if(unloading_yard != undefined && unloading_yard != "" && unloading_yard != null){
                        if(unloading_circuit != undefined && unloading_circuit != "" && unloading_circuit != null){
                            if(start_time != undefined && start_time != "" && start_time != null){
                                if(end_time != undefined && start_time != "" && start_time != null){
                                    if(no_of_cars != undefined && no_of_cars != "" && no_of_cars != null){
                                            if(category != undefined && category != ""  && category != null){
                                                if(rake_type != undefined && rake_type != "" && rake_type != null){
                                                    redirectURL.post("/arms/addmanualactualrakeavailability",formData,{"headers":
                                                    {											
                                                        'Content-Type': 'application/json;charset=UTF-8', 
                                                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                                                    }
                                                    }).then((response)=>{
                                                        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
                                                            this.setState({
                                                                show:true,
                                                                basicTitle:"Session Expired",
                                                                basicType:"warning",
                                                                overly: "show-n",
                                                                loadshow: "show-n"
                                                            })
                                                        }else{
                                                            if(response.data.message =="success"){
                                                                this.setState({
                                                                    show:true,
                                                                    basicType : "success",
                                                                    basicTitle: "Date Updated Successfully",
                                                                    loadshow :"show-n",
                                                                    overly:"show-n",
                                                                    rake_number:"",
                                                                    transporter_details:"",
                                                                    loading_plant:"",
                                                                    unloading_yard:"",
                                                                    loading_yard:"",
                                                                    startDate:"",
                                                                    endDate:"",
                                                                    category:"",
                                                                    rake_type:"",
                                                                    bulkslide2:"",
                                                                    loadshow:"show-n",
                                                                    overly:"show-n"
                                                                })
                                                                $("#no_of_cars").val("")
                                                                $("#msil_reck_no").val("")
                                                                this.onLoadPageData()
                                                            }else{
                                                                this.setState({
                                                                    show:true,
                                                                    basicType : "warning",
                                                                    basicTitle: "Data Update Unsuccessful",
                                                                    loadshow :"show-n",
                                                                    overly:"show-n",
                                                                    rake_number:"",
                                                                    transporter_details:"",
                                                                    loading_plant:"",
                                                                    unloading_yard:"",
                                                                    loading_yard:"",
                                                                    startDate:"",
                                                                    endDate:"",
                                                                    category:"",
                                                                    rake_type:"",
                                                                    bulkslide2:"",
                                                                    loadshow:"show-n",
                                                                    overly:"show-n"
                                                                })
                                                                $("#no_of_cars").val("")
                                                                $("#msil_reck_no").val("")
                                                            }
                                                        }
                                                    })
                                                }else{
                                                    this.setState({
                                                        show:true,
                                                        basicTitle:"Please Select Rake Type",
                                                        basicType:"warning",
                                                        loadshow:"show-n",
                                                        overly:"show-n"
                                                    })
                                                }
                                            }else{
                                                this.setState({
                                                    show:true,
                                                    basicTitle:"Please Select Category",
                                                    basicType:"warning",
                                                    loadshow:"show-n",
                                                    overly:"show-n"
                                                })
                                            }
                                    }else{
                                        this.setState({
                                            show:true,
                                            basicTitle:"Please Enter No of Cars",
                                            basicType:"warning",
                                            loadshow:"show-n",
                                            overly:"show-n"
                                        })
                                    }
                                }else{
                                    this.setState({
                                        show:true,
                                        basicTitle:"Please Select End Time",
                                        basicType:"warning",
                                        loadshow:"show-n",
                                        overly:"show-n"
                                    })
                                }
                            }else{
                                this.setState({
                                    show:true,
                                    basicTitle:"Please Select Start Time",
                                    basicType:"warning",
                                    loadshow:"show-n",
                                    overly:"show-n"
                                })
                            }
                        }else{
                            this.setState({
                                show:true,
                                basicTitle:"Please Select Cluster",
                                basicType:"warning",
                                loadshow:"show-n",
                                overly:"show-n"
                            })
                        }
                    }else{
                        this.setState({
                            show:true,
                            basicTitle:"Please Select Unloading Plant",
                            basicType:"warning",
                            loadshow:"show-n",
                            overly:"show-n"
                        })
                    }
                  }
                }else{
                    this.setState({
                        show:true,
                        basicTitle:"Please Select Loading Plant",
                        basicType:"warning",
                        loadshow:"show-n",
                        overly:"show-n"
                    })
                }
            }else{
                this.setState({
                    show:true,
                    basicTitle:"Please Select Transporter",
                    basicType:"warning",
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }else{
            this.setState({
                show:true,
                basicTitle:"Please Select Rake Number",
                basicType:"warning",
                loadshow:"show-n",
                overly:"show-n",
                startDate:"",
                endDate:""
            })
        }
    }else{
        this.setState({
            show:true,
            basicTitle:"End time Should be greater then Start time",
            basicType:"warning",
            loadshow:"show-n",
            overly:"show-n"
        })
    }
    
   
}

  render() {
    
    var columnwithDefs = [  
        {
            pinned:"left",
            headerName : "Cancel",
            field:"delete",
            width:80,
            resizable : true,
            editable : false,
            cellRenderer:function(params){       
                return '<i class="icofont icofont-ui-delete"></i>';
            },
            filter:true,
        },
        {
            pinned:"left",
            headerName : "Revert",
            field:"revert",
            width:130,
            resizable : true,
            editable : false,
            cellRenderer:function(params){ 
                if(params.data.status == 1 || params.data.status == "1"){
                    return ""
                }
                else if(params.data.approval_status == 1 || params.data.approval_status == "1" || params.data.status == 4 || params.data.status == "4"){
                    var rendComponent = '<button class ="btn btn-danger disabled" disabled="true" id="buttonStyling">Revert</button>'
                    return rendComponent;
                }
                 else{
                    var rendComponent = '<button class ="btn btn-danger" id="buttonStyling">Revert</button>'
                    return rendComponent;
                }     
                
            },
            filter:true,
        },
        {
            pinned:"left",
            headerName : "Update",
            field:"update_data",
            width:130,
            resizable : true,
            editable : false,
            cellRenderer:function(params){ 
                
                if(params.data.status == 1 || params.data.status == "1"){
                    var rendComponent = '<button class ="btn btn-arms"   id="buttonStyling">Update</button>'
                    return rendComponent;
                } else{
                    if(params.data.status == 4 || params.data.status == "4" || params.data.status == 2 || params.data.status == "2" || params.data.status == 3 || params.data.status == "3"){
                        var rendComponent = '<button class ="btn btn-arms disabled" disabled="true" id="buttonStyling">Update</button>'
                    return rendComponent;
                    }
                   
                }     
                
            },
            filter:true,
        },
        
        {
            headerName: "Service Provider",
            field: "transporter_code",
            width:170,
            filter: true,
            resizable: true,
            editable:true,            
        },
        {
            headerName: "Rake No",
            field: "rake_name",
            width:170,
            filter: true,
            resizable: true,
            editable:true,
            cellEditor: 'agSelectCellEditor',
        },
        {
            headerName: "Origin",
            field: "loading_plant",
            width:170,
            filter: true,
            resizable: true,
            editable:true,
        },
        {
            headerName: "Loading Yard",
            field: "loading_yard",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
        },
        {
            headerName: "Category",
            field: "category",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Destination Yard",
            field: "unloading_yard",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
        },
        {
            headerName: "Cars Count",
            field: "no_of_cars",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Staus",
            field: "status",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter:function(params){
                if(params.data.status != undefined && params.data.status != null && params.data.status != ""){
                    if(params.data.status == 1 || params.data.status == "1"){
                        return "Planning Yet to Start"
                    }
                    if(params.data.status == 2 || params.data.status == "2"){
                        return "Rake Available for Planning"
                    }
                    if(params.data.status == 3 || params.data.status == "3"){
                        return "Planning Initiated"
                    }
                    if(params.data.status == 4 || params.data.status == "4"){
                        return "Cancelled"
                    }
                }else{
                    return ""
                }
            }
        },
        {
            headerName: "Transporter Rake",
            field: "transporter_rake_no",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"boxbgblue",
        },
        {
            headerName: "Transporter Loading Yard",
            field: "transporter_loading_yard",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"boxbgblue",
        },
        {
            headerName: "Transporter Availability ETA",
            field: "transporter_availability_date",
            width:170,
            filter: true,
            resizable: true,
            editable:false,
            cellClass:"boxbgblue",
            valueGetter:function(params){
                if(params.data.transporter_availability_date != undefined && params.data.transporter_availability_date != null && params.data.transporter_availability_date != ""){
                    return getHyphenDDMMMYYYYHHMM(params.data.transporter_availability_date)
                }else{
                    return ""
                }
            }
        },
        {
            headerName: "GPS Available ? (Last 24hrs)",
            field: "gps_flag",
            width:170,
            filter: true,
            resizable: true,
            editable:true,
            valueGetter:function(params){
                if(params.data.gps_flag == 1 || params.data.gps_flag == "1"){
                    return "Yes"
                }else{
                    return "No"
                } 
            },
            cellClass:function(params){
                if(params.data.gps_flag == 1 || params.data.gps_flag == "1"){
                    return 'bgColorSuccessLight'
                }else{
                    return 'bgColorDangerLight'
                }
            }
        }, 
        {
            headerName: "Distance To Loading Yard",
            field: "distance_flag",
            width:170,
            filter: true,
            resizable: true,
            editable:true,
        },     
    ]
    if(localStorage.getItem("user_type") != "TRANSPORTER"){
     columnwithDefs.push(
        {
            pinned:"left",
            headerName : "Approval By MSIL Team",
            field:"approval_status",
            width:150,
            resizable : true,
            editable : false,
            cellRenderer:function(params){ 
                if(params.data.status == 1 || params.data.status == "1"){
                    return "";
                } 
                else{
                    if(params.data.approval_status == 1 || params.data.approval_status == "1"){
                        var rendComponent = '<button class ="btn btn-success disabled" disabled="true" id="buttonStyling">Approved</button>'
                        return rendComponent;
                    }
                    else if(params.data.status == 4 || params.data.status == "4"){
                        var rendComponent = '<button class ="btn btn-success disabled" disabled="true" id="buttonStyling">Approval</button>'
                        return rendComponent;
                    }
                    else{
                        var rendComponent = '<button class ="btn btn-success" id="buttonStyling">Approval</button>'
                        return rendComponent;
                    }
                }     
            },
            filter:true,
        },
     )

    }

    
    return (
      <div class="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>

        <div className="col-xl-12 col-lg-12">
        <div className="card-arms">
            <div className="card-header-arms">
                <h5>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>LSP Actual Rake Availability</span>
                    </h5>
                    </div>
                    <h5>
                </h5>
                <div>
                <button className="btn btn-arms" style={{float:"right",borderRadius:"5px"}} onClick={this.onclickslider2.bind(this)}>Add Manual Data</button>
                </div>
                <div className="grid-container">
            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
            {/* <div className="card-body col-xl-12 col-lg-12">
                <div id="myGrid" style={{width:"100%",height:"478px"}} className={"ag-theme-balham "+this.state.showGridData}> */}
                <AgGridReact
                    modules={this.state.modules}
                    columnDefs={columnwithDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={this.state.rowData}
                    enableCharts={false}
                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                    onGridReady={this.onGridReady}
                    onGridState={this.onGridState}
                    frameworkComponents={this.state.frameworkComponents}
                    statusBar={this.state.statusBar}
                    sideBar={this.state.sideBar}
                    stopEditingWhenGridLosesFocus= {true}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    gridOptions={{
                        context: { componentParent: this }
                    }}
                    // components={this.state.components}
                    enableRangeSelection= {true}
                    onCellClicked={this.onCellClicked}
                    //rowSelection={false}
                    //onRowSelected={this.onRowSelection.bind(this)}
                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                    />
            </div>
            </div>
        </div>
        </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
        {/* <div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
            <h3 className="subHarms">Bulk Upload
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
            </h3>
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>
                    
                    <div className="form-group mt-20p">
                        <label className="">Upload File</label> 
                        <input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="btn btn-arms" style={{borderRadius:"5px"}}>Submit</button>
                        
                    </div>
                    </form>
                    <div className="form-group">
                        <a className="btn btn-primary" href={require('../../assets/json/rail_yard_details.csv')} target="_blank">Sample Template</a>
                    </div>		
                </div>
            </div>
        </div> */}
        <div className={"slide-r "+(this.state.bulkslide1)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Transporter Rake Availability
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>
            </h3>
            
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitactualrakeavailabilitydetails}>
                            
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Rake No:</label>
                                    <Select
                                                    placeholder={"Select Rake No"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_no}
                                                    onChange={this.selectrakeno.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.rakenodetails} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Transporter Loading Yard:</label>
                                    <Select
                                                    placeholder={"Select Loading Yard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loadingyard}
                                                    onChange={this.selectloadingyard.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loadingyarddetails} required
                                                />
                                </div>
                                {(this.state.unloadingyarddetails.length>0)?
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Transporter Unloading Yard:</label>
                                <Select
                                                placeholder={"Select Unloading Yard"}
                                                closeMenuOnSelect={true}
                                                value={this.state.unloadingyard}
                                                onChange={this.selectunloadingyard.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.unloadingyarddetails} required
                                            />
                                </div>:""
                                }
                                
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label className="col-xl-12 col-lg-12"> Transporter Availability : </label>
                                            <input type="text" name="transporter_availability" id="transporter_availability" className="datetimepicker_date form-control" readOnly />
                                        </div>
                                
                    <div className="form-group">
                        <button type="submit"  className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"slide-r "+(this.state.bulkslide2)} style={{overflow:"auto"}}>
            <h3 className="subHarms">Add Manual Data
            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll2.bind(this)} >X</span>
            </h3>
            
            <div className="slide-r-body" style={{position:"relative"}}>
                
                <div className="container-fluid">
                    <form method="POST" id="upform" className="theme-form" onSubmit={this.submitmanualentrydetails}>
                            
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Rake No:</label>
                                    <Select
                                                    placeholder={"Select Rake No"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_number}
                                                    onChange={this.selectrakeno.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.rakenodetails} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Rake Type:</label>
                                    <Select
                                                    placeholder={"Select Rake Type"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.rake_type}
                                                    onChange={this.selectraketype.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{"value":"BCACBM","label":"BCACBM"},{"label":"NMG","value":"NMG"}]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Loading Plant:</label>
                                    <Select
                                                    placeholder={"Select Loading Plant"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loading_plant}
                                                    onChange={this.selectloadingplant.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={[{"value":"Haryana","label":"Haryana"},{"value":"Gujarat","label":"Gujarat"},{"value":"Bidadi","label":"Bidadi"}]} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Loading Yard:</label>
                                    <Select
                                                    placeholder={"Select Loading Yard"}
                                                    closeMenuOnSelect={true}
                                                    value={this.state.loading_yard}
                                                    onChange={this.selectloadingyard.bind(this)}
                                                    style={{ borderRadius: "0px" }}
                                                    options={this.state.loadingyarddetails} required
                                                />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Unloading Yard:</label>
                                <Select
                                                placeholder={"Select Unloading Yard"}
                                                closeMenuOnSelect={true}
                                                value={this.state.unloading_yard}
                                                onChange={this.selectunloadingyard.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.unloadingyarddetails} required
                                            />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Cluster:</label>
                                <Select
                                                placeholder={"Select Cluster"}
                                                closeMenuOnSelect={true}
                                                value={this.state.cluster}
                                                onChange={this.selectcluster.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.clusters} required
                                            />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Transporter:</label>
                                <Select
                                                placeholder={"Select Transporter"}
                                                closeMenuOnSelect={true}
                                                value={this.state.transporter_details}
                                                onChange={this.selecttransporter.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.transporter_names} required
                                            />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                <label>Category:</label>
                                <Select
                                                placeholder={"Select Category"}
                                                closeMenuOnSelect={true}
                                                value={this.state.category}
                                                onChange={this.selectcategory.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={this.state.categories} required
                                            />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                            <label>No of Cars:</label>
                                            <input type="number" name="no_of_cars" id="no_of_cars" className="form-control" onChange={this.changeHandler} required />
                                        </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>Start Time:</label>
                                    <Datetime
                                    value={this.state.startDate} 
                                    disableCloseOnClickOutside={false} 
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'Start Time', name: 'startDate', autoComplete:'off' }}
                                    dateFormat="YYYY-MM-DD" 
                                    name="startDate" 
                                    onChange={this.handlerStartDateTime.bind(this)} />
                                </div>  
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>End Date:</label>
                                    <Datetime 
                                    value={this.state.endDate} 
                                    disableCloseOnClickOutside={false}
                                    closeOnSelect={true} 
                                    inputProps={{ placeholder: 'End Time', name: 'endDate', autoComplete:'off'}} 
                                    dateFormat="YYYY-MM-DD"
                                    name="endDate"
                                    onChange={this.handlerEndDateTime.bind(this)} />
                                </div>
                                <div className="col-xl-12 col-lg-12 form-group">
                                    <label>MSIL Reck Number:</label>
                                    <input type="number" name="msil_reck_no" id="msil_reck_no" className="form-control" onChange={this.changeHandler} />
                                </div>
                                
                    <div className="form-group">
                        <button type="submit"  className="btn btn-arms">Submit</button>
                        {/* <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button> */}
                    </div>
                    </form>		
                </div>
            </div>
        </div>
        <div className={"dataLoadpage " +(this.state.loadshow)}>
        </div>
        <div className={"dataLoadpageimg " +(this.state.loadshow)}>
            <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
        </div>
        <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
      </div>

    );
  }
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}
$(document).on("input", ".mobnum-cls", function() {
    this.value = this.value.replace(/^\d{11}$/g,'');
})  
function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
        closeOnDateSelect: true,
        scrollMonth: false,
        scrollInput: false
    });

    $('.cargo_date').datetimepicker({
        //mask:'39-19-9999',
        format: 'd-m-Y',
        minDate: true,
        timepicker: false,
    });

    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    script.crossOrigin = "anonymous"
    index.parentNode.insertBefore(script, index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker:false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};