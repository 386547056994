import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMMYYYY} from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import Modal from 'react-responsive-modal';
import $ from 'jquery';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class Apiabokplandata extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],
            frameworkComponents : {
            // UpdateButton:UpdateButton,
               // DeleteButton:DeleteButton,
              },

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            bulkslide:'',
            bulkslide2:''

        };
    }
   
    componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       
        redirectURL.post('/arms/getapiabokplandata',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            console.log(response.data,"108")
            var records = response.data;
            if(records.length > 0){
                this.setState({
                    rowData:records,
                    overly: "show-n",
                    loadshow: "show-n"
                });
            }
        }
          })
    };
   

        
    
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }
    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            bulkslide:"",
            bulkslide2:"",
           
        });
    }
   
    onCellClicked(e){
        // if(e.colDef.field == "delete"){
        //     var qry = {
        //         rowId:e.data._id,
        //         email:localStorage.getItem("email"),
        //                         // designation: localStorage.getItem('designation'),
        //         // user_role: localStorage.getItem('roles'),
        //     }
        //     console.log(qry,"243")
        //     var confirm = window.confirm("Are You Sure! Do you really want to delete?")
        //     if (confirm == true)
        //     {
        //         redirectURL.post("/arms/deletebulkTransitTimesDetails",qry).then((response)=>{
        //             console.log(response.data);
        //             if(response.data.message == "success")
        //             {
        //                 this.setState({
        //                     basicTitle:"Deleted Successfully.",
        //                     basicType : "success",
        //                     show : true,
        //                     rowIds : []
        //                 })
        //                 //window.location.reload();
        //                 this.componentDidMount();
        //             }
        //             else
        //             {
        //                 this.setState({
        //                     basicTitle:"Failed to Delete.",
        //                     basicType : "warning",
        //                     show : true
        //                 })
        //             }
        //         }).catch((e)=>{
        //             console.log(e);
        //         })
        //     } 
        // }
        // if(e.colDef.field == "edit"){
        //     var data = e.data;
        //     var plant_code2 = $("#plant_code2").val(data.plant_code)
        //     var map_code2 = $("#map_code2").val(data.plant_map_code)
        //     var plant_name2 = $("#plant_name2").val(data.plant_name)
        //     var msil_code2  =  $("#msil_code2").val(data.msil_code)
        //     this.setState({
        //         bulkslide2:"slider-translate-30p",
        //         rowId :data._id
        //     })
           
        // } 
        
        
    }
    addNewRow()
{
    // console.log(this.gridApi);
    // this.gridApi.insertItemsAtIndex(0,[{plant_code:"",plant_map_cod:"",msil_code:"",}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
    this.setState({
        bulkslide:"slider-translate-30p",
        // rowId : rowId
    })
    
    // this.setState({
    //     bulkslide2:"slider-translate-30p",
    //     // rowId : rowId
    // })

}
 
render(){
    var showDelete = true;
    if(this.state.pageAccess == 2)
    {
        var showActions = true;
    }
    if(this.state.pageAccess == 3)
    {
        var showDelete = false;
    }
    const columnwithDefs = [
        {
            headerName: "VIN No ",
            field: "vin_no",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Body Colo",
            field: "body_colo",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Cell No",
            field: "cell_no",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Chas Model",
            field: "chas_model",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        
        {
            headerName: "Chas Prefix",
            field: "chas_prefix",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Chassis",
            field: "chassis",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Colour",
            field: "colour",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        }, 
        {
            headerName: "Country ",
            field: "country",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Engine Model",
            field: "eng_model",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Engine",
            field: "engine",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Family Code",
            field: "family_code",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
        {
            headerName: "Model Desc",
            field: "mod_desc",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter",
            // valueGetter:function(params){
            //     if(params.data.plant_code == "F"){
            //         return "Gurgoan"
            //     }
            //     else if(params.data.plant_code == "M"){
            //         return "Manesar"
            //     }
            //     else if(params.data.plant_code == "G"){
            //         return "Gujarat"
            //     }
            //     else if(params.data.plant_code == "B"){
            //         return "Banglore"
            //     }
            //     else{
            //         return ""
            //     }
            // }
        },
        {
            headerName: "Oper Date",
            field: "oper_date",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter",
            valueGetter:function(params){
                if(params.data.oper_date != "" && params.data.oper_date != undefined && params.data.oper_date != null){
                    return getHyphenDDMMMYYYY(params.data.oper_date)
                }else{
                    return ""
                }
            }
        },
        {
            headerName: "Order No",
            field: "ord_no",
            width: 170,
            editable:false,
            filter: "agSetColumnFilter"
        },
            {
                headerName: "Parking Zone",
                field: "parking_zone",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Path Code",
                field: "path_code",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Plant",
                field: "plant",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Ppnt",
                field: "ppnt",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Remarks",
                field: "remarks",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Shift",
                field: "shift",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Status",
                field: "status",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Supplier Colo",
                field: "supp_colo",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
            {
                headerName: "Vehicle Model",
                field: "veh_model",
                width: 170,
                editable:false,
                filter: "agSetColumnFilter"
            },
           
           
            // {
            //     pinned:"left",
            //     headerName : "",
            //     field:"edit",
            //     width:150,
            //     resizable : true,
            //     editable : false,
            //     cellRenderer:function(params){       
            //         var rendComponent = '<button class="btn btn-success">Edit</button>'
            //         return rendComponent;
            //     },
            //     filter:false,
            // },
            // {
            //     pinned:"left",
            //     headerName : "",
            //     field:"delete",
            //     width:80,
            //     resizable : true,
            //     editable : false,
            //     cellRenderer:function(params){       
            //         return '<i class="icofont icofont-ui-delete"></i>';
            //     },
            //     filter:false,
            // },       
            // {
            //     headerName: "Dom Exp Flag",
            //     field: "dom_exp_flag",
            //     width: 170,
            //     editable:false,
            //     filter: "agSetColumnFilter",
            //     valueGetter:function(params){
            //         if(params.data.dom_exp_flag == "E"){
            //             return "Exports"
            //         }
            //         else if(params.data.dom_exp_flag == "D"){
            //             return "Domestic"
            //         }
            //         else{
            //             return ""
            //         }
            //     }
            // },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>ABOK Plan Data </span>
                            </h5>
                        </div>
                    

                        <div className="grid-container">
                            <div id="myGrid" style={{ height: "400px",width: "100%"}}  className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    onCellClicked={this.onCellClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>  
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

            

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}