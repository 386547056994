/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { withRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
import download from 'js-file-download';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { forEach, functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;




class RailLoadConfiguratorSummary extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            remarks_data:[],
            target_summary_data:[],
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            rowData2:[],
            rowData3:[],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month_target: "",
            month_rakescheduling:"",
            plan_month_rail: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            stockRowData:[],
            orderRowData:[],
            rowData2:[],
            rowData3:[],
            rowData4:[],
            rowData5:[],
            rowData6:[],
            option:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "option1" },
            targetoption:{label: "PPC + Stock", value: "3"},
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : [],
            pinnedRowData3:[],
            pinnedRowData2:[],
            pinnedRowData4: [],
            plan_month:"",
            plan_month_upload:"",
            inprocessTab:"btn-arms",
            closedTab:"",
            inprocessShow:"show-m",
            closedShow:"show-n",
            pagetitle :"Plan Summary",
            csvcontent1:"",
            file1:"",
            week_upload:"",
            csvfilename :"",
            revisionoptions:[],
            revisionoptionsforTP:[],
            revisionoptionsforRS:[],
            revision:"",
            revisionforTP:"",
            revisionforRS:"",
            targetvalue:"",
            approval_status:"",
            approval_by:"",
            approval_on:"",
            plantwisetabactive:"btn-arms",
            modewisetabactive:"",
            locationwisetabactive:"",
            plantwiseshow:"show-m",
            modewiseshow:"show-n",
            locationwiseshow:"show-n",
            plan_type:{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
            location:{label: "Haryana", value: "H" },
            // source:{label: "PPC + Stock", value: "3"},
            colHeader:"",
            mode:{label:"Overall",value:"overall"},
            mode_header:"",
            detailCellRendererParams:{},
            remarks_history_cols:[],
            storage_plan_id:"",
            columnwithDefs3:[],
            apiData:[],
            main_restricted_models:[]
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    async componentDidMount() {
        await loadDateTimeScript();
        var storage_plan_id = window.atob(this.props.match.params.storage_plan_id)
         console.log(storage_plan_id,"307")
         this.setState({
            storage_plan_id : storage_plan_id,
            loadshow:"show-m",
            overly:"show-m"
         })
         this.onClickTabone()
         this.onClickTabtwo()
    }
    onClickTabone =()=>{
        var params ={
            "transaction_id":this.state.storage_plan_id
         }
        redirectURL.post("/arms/getrakeplanstorageData",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(async (response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var apiData = response.data;
            //apiData = apiData.sort(SortByName);
            if(apiData.length>0){
                apiData.map(function(e){
                    e.sr_no = parseInt(e.sr_no);
                });
                await this.setState({
                    apiData : apiData,
                    loadshow:"show-n",
                    overly:"show-n"
                });
                await this.createGridColumns();
            }else{
                this.setState({
                    apiData:[],
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }
         })
    }
    onClickTabtwo = ()=>{
        var params ={
            "transaction_id":this.state.storage_plan_id
         }
        redirectURL.post("/arms/getrailloadconfiguratorsummarydata",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then(async(response1)=>{
        if(response1.data.message == "Token has expired"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var response = response1.data.data
            var restricted_models = JSON.stringify(response1.data.restricted_models)
            localStorage.setItem("restricted_models",restricted_models)
            if(response.length>0){
                
                var inputCars = [];
                var recoShow = "show-m";
                var recOpt = 0;
                if(response1.data.carPlanDetails.length > 0)
                {
                    inputCars = JSON.parse(response1.data.carPlanDetails[0].carDetails);
                    console.log(inputCars,"319")
                    try{
                        if(response.data.carPlanDetails[0].processed == 0)
                        {
                            console.log("check-1")
                            recoShow = "show-m";
                            recOpt = 0;
                        }
                        else
                        {
                            console.log("check-2")
                            recOpt = 1;
                        }
                    }
                    catch(e){
        
                    }
                }
                var summaryDetails = response;
                var carData = [];
                var totalInput = 0;
                var totalOriginal = 0;
                var totalReco = 0;
                var totalLoadFactor = 0;
                if(summaryDetails.length > 0)
                {
                    var groupData = groupBy(summaryDetails, rdata => rdata.car_name);
                    console.log(groupData,"345")
                    groupData.forEach(async (gData, gKey) => {
                        console.log(gData,"347")
                        var recoType = groupBy(gData, rdata1 => rdata1.remarks);
                        console.log(recoType,"348")
                        var originalLength = 0;
                        var recoLength = 0;
                        recoType.forEach(async (gData1, gKey1) => {
                            console.log(gKey1,"check-3")
                            console.log(gData1,"358")
                            if(gKey1 == "ORIGINAL")
                            {
                                originalLength = gData1.length;
                                //console.log(gKey,gKey1,gData1.length,"original");
                            }
                            if(gKey1 == "RECOMMENDED")
                            {
                                recoLength = gData1.length;
                                totalReco = parseInt(totalReco)+parseInt(gData1.length);
                                //console.log(gKey,gKey1,gData1.length,"RECOMMENDED");
                            }
                        })
                        var load_factor = parseFloat(((gData[0].load_factor)*(gData.length))).toFixed(2);
                        var input_cars = 0;
                        inputCars.map(function(ic){
                            if(ic.car_model == gKey)
                            {
                                input_cars = ic.total_cars;
                            }
                        });
                        carData.push({
                            "car_name" : gKey,
                            "car_code" : gData[0].car_code,
                            "cars_count" : gData.length,
                            "input_cars" : input_cars,
                            "cars_original_count" : originalLength,
                            "cars_reco_count" : recoLength,
                            "car_load_factor" : gData[0].load_factor,
                            "load_factor" : load_factor,
                        });
                        recoLength = 0;
                        totalLoadFactor = parseFloat(totalLoadFactor)+parseFloat(load_factor);
                        totalOriginal = parseInt(totalOriginal)+parseInt(originalLength);
                        totalInput = parseInt(totalInput)+parseInt(input_cars);
                    });
                }
                carData = carData.filter(e => e.car_name !="EMPTY");
                var ids = new Set(carData.map(({ car_code }) => car_code));
                inputCars = inputCars.filter(({ family_code }) => !ids.has(family_code));
                //console.log(inputCars,"inputCars");
                inputCars.map(function(np){
                    carData.push({
                        "car_name" : np.car_model,
                        "car_code" : np.family_code,
                        "cars_count" : 0,
                        "input_cars" : np.total_cars,
                        "cars_original_count" : 0,
                        "cars_reco_count" : 0,
                        "car_load_factor" : np.load_factor,
                        "load_factor" : 0,
                    });
                    totalInput = parseInt(totalInput)+parseInt(np.total_cars)
                })
                var pinnedBottomRowData = {}
                pinnedBottomRowData['car_code'] = "";
                pinnedBottomRowData['car_name'] = "";
                pinnedBottomRowData['input_cars'] = totalInput;
                pinnedBottomRowData['cars_original_count'] = totalOriginal;
                pinnedBottomRowData['cars_reco_count'] = totalReco;
                pinnedBottomRowData['car_load_factor'] = "";
                pinnedBottomRowData['load_factor'] = (totalLoadFactor).toFixed(2);
                
                var summaryDetails = response1.data.data;
                summaryDetails = summaryDetails.filter(e => e.car_name !="EMPTY");
               
                this.setState({
                    totalCarsPlanned : summaryDetails,
                    carPlanDetails : response1.data.carPlanDetails,
                    recoShow : recoShow,
                    rowData2:carData,
                    // main_restricted_models:restricted_models,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }else{
                this.setState({
                    rowData2:[],
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        }
     })
    }
    onClickHideAll1() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",
            bulkslide3:"",
            bulkslide4:"",
            week_upload:"",
            plan_month_upload:"",
        });
        $("#bulkUploadID").val("")
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }
    

    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
    }
    
    onClickShowTabView(tab){
        if(tab == "inprocess")
        {
            this.setState({
               inprocessTab:"btn-arms",
                closedTab:"",
                storageTab:"",
                inprocessShow:"show-m",
                closedShow:"show-n",
                storageShow:"show-n",
                pagetitle:"Plan Summary",
            })
            this.onClickTabone()
        }
        if(tab == "closed")
        {
            this.setState({
                inprocessTab:"",
                closedTab:"btn-arms",
                inprocessShow:"show-n",
                closedShow:"show-m",
                pagetitle:"Rake Storage Plan",
            })
            this.onClickTabtwo()
        }
        
        
    }
    onClickBack =()=>{
        window.location.href="/railloadconfigurator"
    }
    onClickGetRecommendations=()=>{
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        var storage_plan_id  = this.state.storage_plan_id
        var params ={
            storage_plan_id : storage_plan_id
        }
        redirectURL.post("/arms/sendrecommendationforstorageplanid",params,{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response)=>{
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            if(response.data.message == "success"){
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Recommendation Successfully Updated",
                    basicType:"success",
                    show:true
                })
            }else{
                this.setState({
                    loadshow:"show-n",
                    overly:"show-n",
                    basicTitle:"Recommendation Not Updated",
                    basicType:"success",
                    show:true
                })
            }
        }
        })
    }
    onClickRecommendationsFileDownload=()=>{}
    createGridColumns = async() =>{
        var apiData = this.state.apiData;
        console.log(apiData,"apiData")
        var columns = []; 
        if(apiData.length > 0)
        {
            var keys = Object.keys(apiData[0]);
            await keys.forEach(async (z, k) => {
                if(z == "sr_no" || z == "Wagon Type" || z == "Upper Deck height" || z == "Lower Deck height")
                {          
                    var keyVal=z;
                    var hwid = 70;
                    var pinnedLeft = "";
                    if(z == "sr_no")
                    {
                        keyVal="S.No";
                        hwid = 80;
                        pinnedLeft = "left";
                    }
                    if(z == "Wagon Type")
                    {
                        hwid = 120;
                    }
                    if(z == "Upper Deck height" || z == "Lower Deck height")
                    {
                        keyVal=z.replace("Deck","");
                        hwid = 100;
                    }
                    columns.push({
                        headerName:keyVal,
                        colId:z,
                        field:z,
                        width:hwid,
                        pinned:pinnedLeft,
                        editable: false,
                        resizable:true,
                        filter:true,
                        suppressMenu:true,
                        sortable:false,
                        cellStyle:function(params){
                            if(z == "Wagon Type")
                            {
                                if(params.data.sr_no <= 8)
                                {
                                    return {backgroundColor: '#ff8181'};
                                }
                                else if(params.data.sr_no > 8 && params.data.sr_no <= 17)
                                {
                                    return {backgroundColor: '#c1b6b6'};
                                }
                                else
                                {
                                    return {backgroundColor: '#7a5e5e'};
                                }
                            }
                        },
                        valueGetter:function(params){
                            if(z == "Wagon Type")
                            {
                                var wType = params.data[z];
                                return wType.split("_")[1];
                            }
                            else if(z == "sr_no")
                            {
                                return parseInt(params.data.sr_no)+1
                            }
                            else
                            {
                                return params.data[z];
                            }
                        }
                    })
                }
            })
            var defs = {};
            defs.headerName = "Lower deck";
            defs.headerClass = ["cellstylegridB","cellTopHeader"];
            var cdefs = [];
            await keys.forEach(async (b, t) => {
                if(b.includes("type") == false)
                {
                    if(b.includes("lower") == true)
                    {
                        var childdefs = {};
                        childdefs.headerName = b.split("_")[1];
                        childdefs.field = b;
                        childdefs.width = 50;
                        childdefs.cellStyle = function(params){
                            if(params.data[b+"_type"] == "ORIGINAL")
                            {
                                return {backgroundColor: '#26c6da',borderLeft: '1px solid #ccc'};
                            }
                            else if(params.data[b+"_type"] == "EMPTY")
                            {
                                return {backgroundColor: '#8b8080',borderLeft: '1px solid #ccc'};
                            }
                            else{
                                return {backgroundColor: 'orange',borderLeft: '1px solid #ccc'};
                            }
                        }
                        childdefs.valueGetter = function(params){
                            if(params.data[b] !="EMPTY")
                            {
                                return params.data[b]
                            }
                            else
                            {
                                return "";
                            }
                        }
                        cdefs.push(childdefs)
                    }
                }
            })
            defs.children = cdefs
            columns.push(defs)

            
            var defs1 = {};
            defs1.headerName = "Upper deck";
            defs1.headerClass = ["cellstylegridB","cellTopHeader"];
            var cdefs1 = [];
            await keys.forEach(async (c, k) => {
                if(c.includes("type") == false)
                {
                    if(c.includes("upper") == true)
                    {
                        var childdefs1 = {};
                        childdefs1.headerName = c.split("_")[1];
                        childdefs1.field = c;
                        childdefs1.width = 50;
                        childdefs1.cellStyle = function(params){
                            if(params.data[c+"_type"] == "ORIGINAL")
                            {
                                return {backgroundColor: '#26c6da',borderLeft: '1px solid #ccc'};
                            }
                            else if(params.data[c+"_type"] == "EMPTY")
                            {
                                return {backgroundColor: '#8b8080',borderLeft: '1px solid #ccc'};
                            }
                            else{
                                return {backgroundColor: 'orange',borderLeft: '1px solid #ccc'};
                            }
                        }
                        childdefs1.valueGetter = function(params){
                            if(params.data[c] !="EMPTY")
                            {
                                return params.data[c]
                            }
                            else
                            {
                                return "";
                            }
                        }
                        cdefs1.push(childdefs1)
                    }
                }
            })
            defs1.children = cdefs1
            columns.push(defs1);

            console.log(columns,"columns")
            await this.setState({
                columnwithDefs3:columns,
                rowData3:apiData,
                loadshow : "show-n",
                overly : "show-n",
            });
        }
    }
    onClickDataExport=()=>{}
    render() {
        
        const columnwithDefs2 =[
              
                {
                    headerName: "Car Code",
                    field: "car_code",
                    width:80,
                    filter: true,
                    resizable: true,
                    editable:false,      
                },    
                {
                    headerName: "Car Model",
                    field: "car_name",
                    width:130,
                    filter: true,
                    resizable: true,
                    editable:false      
                },   
                {
                    headerName: "Planned Cars",
                    field: "cars_original_count",
                    width:100,
                    filter: true,
                    resizable: true,
                    editable:false,      
                },  
                {
                    headerName: "Recomended Cars",
                    field: "cars_reco_count",
                    width:120,
                    filter: true,
                    resizable: true,
                    editable:false,      
                },
                {
                    headerName: "Load Factor",
                    field: "car_load_factor",
                    width:100,
                    filter: true,
                    resizable: true,
                    editable:false,
                },
                {
                    headerName: "Total Load Factor",
                    field: "load_factor",
                    width:100,
                    filter: true,
                    resizable: true,
                    editable:false,
                },
            
            
        ]
        var basestring = this.state.storage_plan_id;
        console.log(basestring, "basestring")
        var encryptedstring = window.btoa(basestring);
        var htmloption = "/carsplanning/"+encryptedstring;
        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
            <div className="row">
            {/* <a href={htmloption} className="btn btn-info" style={{ padding: "6px 11px", marginRight: "2%",borderRadius:"5px",left:"61%",position:"relative" }} >Edit Recommendation</a>
            <button className="float-right f15 custom-btn btn-warning" style={{ padding: "6px 11px", marginRight: "2%",borderRadius:"5px",left:"61%",position:"relative" }} onClick={this.onClickGetRecommendations.bind(this)}>
                           Recommendations
                            </button> */}
                          
                            
                
                <button className="float-right f15 custom-btn btn-arms" style={{ padding: "6px 11px", marginRight: "2%",borderRadius:"5px",left:"61%",position:"relative" }} onClick={this.onClickBack.bind(this)}>
                           Back
                            </button>
          
                <div className="col-xl-12 col-lg-12 tabsontop">
                        <button type="button" className={"btn "+(this.state.inprocessTab)} onClick={this.onClickShowTabView.bind(this,"inprocess")}>Plan Summary</button>
                        <button type="button" className={"btn "+(this.state.closedTab)} onClick={this.onClickShowTabView.bind(this,"closed")}>Rake Storage Plan</button>
                        
                </div>
                <div className={"col-xl-12 col-lg-12 "+(this.state.inprocessShow)}>
                    <div className="card-arms col-xl-12">
                    <div className="card-header-arms">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle+" - "+this.state.storage_plan_id}</span>
                        </h5>
                        <div className="col-sm-2">
                        
                    </div>
                    </div>
                        <div className="grid-container col-xl-12 col-lg-12">   
                               
                         
                    <div id="myGrid" style={{ width: "100%", height: "500px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs2}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData2}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                           
                        </div>
                    </div>
                </div> 
                <div className={"col-xl-12 col-lg-12 "+(this.state.closedShow)}>
                    <div className="card-arms col-xl-12">
                    <div className="card-header-arms">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>{this.state.pagetitle+" - "+this.state.storage_plan_id}</span>
                        </h5>
                        {/* <button className="btn btn-info" style={{float:"right",marginRight:"10px",borderRadius:"5px"}} onClick={this.onClickDataExport.bind(this)}>Export Data</button> */}
                            
                    </div>
                        <div className="grid-container col-xl-12 col-lg-12">                       
                                
                            <div id="myGrid" style={{ width: "100%", height: "500px"}} className={"ag-theme-balham grid-card"}>
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={this.state.columnwithDefs3}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData3}
                                    enableCharts={false}
                                    // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    frameworkComponents={this.state.frameworkComponents}
                                    statusBar={this.state.statusBar}
                                    sideBar={this.state.sideBar}
                                    stopEditingWhenGridLosesFocus={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={false}
                                    // pinnedBottomRowData={this.state.pinnedRowData}
                                    gridOptions={{
                                        context: { componentParent: this },
                                        getRowStyle: function (params) {
                                            if (params.node.rowPinned) {
                                            return { 'font-weight': 'bold','font-size':'16px' };
                                            }
                                        },
                                    }}
                                    // components={this.state.components}
                                    enableRangeSelection={true}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                
                
                
				
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll1.bind(this)}></div>
            </div>

        );
    }
}

export default withRouter(RailLoadConfiguratorSummary)

function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});