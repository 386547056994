/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
// 'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
// import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
// import Consignmentforceclose from '../manage/consignmentforceclose';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
// import 'jquery-ui-multidatespicker';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import $ from 'jquery';
// import ColumnGroup from './armscolumngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY, getHyphenYYYYMMDDHHMMSS, getHyphenDDMMYYYYHHMMSS, getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils';
import { verifyext, verifycsvdata } from "../common/verifyext";
import { functionsIn } from 'lodash';
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent = "";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;
var colHeader = "";



export default class TargetSummary extends Component {
    state = {
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: false,
            },
            eventAction: null,
            show: false,
            frameworkComponents: {
                // consignmentforceclose: Consignmentforceclose,
                // consignmentActions: ConsignmentActions,
                // UpdateButton: UpdateButton,
                commentActions: CommentActions
            },
            modified_by: "",
            basicTitle: '',
            reasonforceclose: "",
            bulkslide: "",
            file: "",
            uploadFile: "",
            basicType: "default",
            loadshow: 'show-n',
            forceCloseRowNode: "",
            forceclosedata: "",
            csvcontent: "",
            sliderForceCloseTranslate: "",
            overly: '',
            rowData: [],
            bulkslide: "",
            showDiv: "",
            uploadDivWidth: "",
            mapinfo: "",
            dealer: "",
            sliderCommentTranslate: "",
            commentsRowData: "",
            consignment_code: "",
            sliderRouteTranslate: "",
            maptruckno: "",
            routeTruck: "",
            rownode: "",
            gridTitle: "",
            file: "",
            rowSelection: 'multiple',
            sliderForceCloseTranslate: "",
            maptruckno: "",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent",
                    statusPanelParams: {
                        // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                        aggFuncs: ['sum', 'avg', "count", "min", "max"],
                      }, }
                ],
            },
            paginationPageSize: 200,
            geofencelist: [],
            geoFenceData: "",
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            animateRows: true,
            dept_code: "",
            pagetitle: "",
            markDeliverRowNode: "",
            markDeliverData: "",
            sliderMarkDeliverTranslate: "",
            csvData: 0,
            overly: "show-n",
            loadshow: "show-n",
            holidayEvents: [],
            showCalendar: "show-m",
            showGridData: "show-m",
            plan_month: "",
            selectedDays: [],
            month_target: 0,
            working_days: 0,
            daily_target: 0,
            balance_days: 0,
            compledted_till_date: 0,
            actual_till_date: 0,
            achievement: 0,
            balance_to_be_planned: 0,
            achievementColor: "red",
            target_revised: 0,
            total_dispatches: 0,
            rail_share: 0,
            current_dispatch_fy: 0,
            current_rail_dispatch_fy: 0,
            current_rail_share: 0,
            vechicles_possible : 0,
            vechicles_planned : 0,
            current_vechicles_possible : 0,
            current_vechicles_planned : 0,
            vehClr : "white",
            vehPoss : 0,
            vehPlanned : 0,
            incoming_rakes : 0,
            load_capacity : 0,
            rake_capacity : 0,
            rakeClr : "white",
            no_of_rakes:0,
            total_trips:0,
            trips_per_rake:0,
            allrowData:[],
            allData:"activet", 
            hData:"",
            sData:"",
            rowData1 : [],
            option:{label: "nth month of previous year", value: "2" },
            location:{label: "Haryana", value: "H" },
            source:{label: "Last Year Trend", value: "targetplanshare"},
            colHeader:"Location",
            newVehiclesPossible : 0,
            vinCountLength : 0,
            rakesvehiclesPossible: 0,
            rakesVinCountLength : 0,
            total_monthly_dispatchesStringValue: 0,
            vehiclesPlanned: 0,
            freshIncomingRakes: {},
            rakesPlannedToday: {},
            rakesCarriedForward: {},
            pinnedRowData : []
            //components : {datepicker:getDatePicker()}
        }
        // this.changeFileHandler = this.changeFileHandler.bind(this);
        // this.handleDayClick = this.handleDayClick.bind(this);


    componentDidMount() {
        loadDateTimeScript();
        this.onLoadPageData()
        // var currentMonth = moment.parseZone().format("YYYY-MM");
        // //console.log(currentMonth,"currentMonth")
        // const monthNames = ["January", "February", "March", "April", "May", "June",
        //                     "July", "August", "September", "October", "November", "December"
        //                     ];
        // const year_month = `${new Date().getFullYear()}-${monthNames[new Date().getMonth()]}`;
        
        // this.setState({
        //     plan_month: currentMonth,
        //     gridTitle: year_month
        // })
        // setTimeout(() =>{
        //     this.onLoadPageData();
        // }, 400)
    }
    onLoadPageData = () => {
        this.setState({
            loadshow : "show-m",
            overly :"show-m",
        })
        var selected_option = this.state.option.value
        var source_option = this.state.location.value
        var source_option_label = this.state.location.label
        var source = this.state.source.value
        var params = {}
       
        if(selected_option != undefined && selected_option != null && selected_option != ""){
            params.option = selected_option
        }
        if(source != undefined && source != null && source != ""){
            params.source = source
        }
        console.log(params,"240")
        var carrierCnt = 0;
        var railCnt = 0;
        var roadCnt = 0;
        var totalCnt = 0;
      
           redirectURL.post("/arms/gettargetsummarydata", params).then((response) => {
                    console.log("response", response.data);
                    let responseData = JSON.parse(response.data.data)
                    console.log(responseData,"250")
                    let directresponse = eval(responseData.summary)
                     var respData = []
                     var haryanaData = []
                     var gujaratData = []
                     var bidadiData = []
                     if(source_option == "G"){
                        gujaratData = directresponse.filter((e)=>e.consigner_code == "gujarat")
                        respData = gujaratData
                     }
                     if(source_option == "H"){
                        haryanaData = directresponse.filter((e)=>e.consigner_code == "msil")
                        respData = haryanaData
                     }
                     if(source_option == "B"){
                        bidadiData = directresponse.filter((e)=>e.consigner_code == "bidadi")
                        respData = bidadiData
                     }
                    //  console.log(gujaratData,haryanaData,bidadiData,respData,"268")
                    respData.map((e)=>{
                        carrierCnt = e.target_carrier + carrierCnt
                    })
                    respData.map((f)=>{
                        railCnt = f.target_rail + railCnt 
                    })
                    respData.map((g)=>{
                        roadCnt = g.target_road + roadCnt 
                    })
                    respData.map((h)=>{
                        totalCnt = h.target_total + totalCnt 
                    })
                    // console.log(respData,carrierCnt,railCnt,roadCnt,totalCnt,"281")
                    var pinnedBottomRowData = {}
                    pinnedBottomRowData["type"] = ""
                    pinnedBottomRowData["route_id"] = ""
                    pinnedBottomRowData["cluster"] = "Total"
                    pinnedBottomRowData["target_carrier"] = carrierCnt
                    pinnedBottomRowData["target_rail"] = railCnt
                    pinnedBottomRowData["target_road"] = roadCnt
                    pinnedBottomRowData["target_total"] = totalCnt
                    this.setState({
                        rowData : respData,
                        colHeader:source_option_label,
                        loadshow : "show-n",
                        overly :"show-n",
                        pinnedRowData : [pinnedBottomRowData]
                    })
                })
            
           
        
           
            
    }
    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };
    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    onFirstDataRendered = (event) => {
        event.api.addCellRange({
          rowStartIndex: 0,
          rowEndIndex: 4,
          columns: ['trip_per_rake'],
        });
      };

    closeAlert = () => {
        this.setState({
            show: false
        });
    }
    selectlocation = (e) => {
        this.setState({
            location: e,
        })
    }
    selectsource = (e) => {
        this.setState({
            source: e,
        })
    }

    selectoption = (e) => {
        this.setState({
            option: e,
        })
    }
    
    onClickHideAll() {
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            bulkslide: "",

        });

    }




    render() {
        const columnwithDefs = [
            { 
                headerName: "",
                headerClass: ["cellstylegrid", "cellTopHeader"],
                field: "",
                cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 100,
                chartDataType: "excluded",
                children:[
                    {
                        headerName: "Type",
                        field: "type",
                        width: 140,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "Route",
                        field: "route_id",
                        width: 140,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "Cluster",
                        field: "cluster",
                        width: 170,
                        filter: true,
                        resizable: true,
                        // pinned:"left",
                        valueGetter:function(params){
                            if(params.data.cluster != undefined && params.data.cluster != null && params.data.cluster != "" && params.data.cluster != 0){
                                return params.data.cluster
                            }else {
                                return ""
                            }
                        },
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    }
                ]
            },
            {
                headerName: this.state.colHeader,
                headerClass: ["cellstylegridTPFM", "cellTopHeader"],
                field: "",
                // cellClass: ["cellTopHeaderTemplate16", "cellstylegridG", "cpointer"],
                // chartDataType: 'series',
                width: 200,
                chartDataType: "excluded",
                children: [
                    {
                        headerName: "CARRIER",
                        headerClass:"cellstylegridTPFM",
                        field: "target_carrier",
                        width: 120,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "RAIL",
                        headerClass:"cellstylegridTPFM",
                        field: "target_rail",
                        width: 120,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "ROAD",
                        headerClass:"cellstylegridTPFM",
                        field: "target_road",
                        width: 120,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    },
                    {
                        headerName: "Total",
                        headerClass:"cellstylegridTPFM",
                        field: "target_total",
                        width: 120,
                        filter: true,
                        resizable: true,
                        cellClass:"cellstylegridTPFM",
                        cellStyle:function(params){
                            if(params.data.for_code == "Total"){
                                return { background: "#f7f305", color: "#333" };
                            }
                        }
                    }                                                
                ]
            },
            
        ]

        return (
            <div class="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>

                <div className="card col-xl-12">
                    <div className="card-header">
                        <h5>
                            <i className="icofont icofont-train cus-i"></i> <span>Target Summary</span>
                        </h5>
                    </div>

                    <div className="row col-xl-12 col-lg-12" style={{ marginTop: "2%" }}>
                        <form method="POST" className="form-theme col-xl-12 col-lg-12 row">
                        <div className="col-xl-3 col-lg-3 form-group">
                                <label>Location:</label>
                                <Select
                                                placeholder={"Select Location"}
                                                closeMenuOnSelect={true}
                                                value={this.state.location}
                                                onChange={this.selectlocation.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Haryana", value: "H" },
                                                { label: "Gujarat", value: "G" },
                                                { label: "Bidadi", value: "B" }]} required
                                            />
                            </div>
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>Source:</label>
                                <Select
                                                placeholder={"Select source"}
                                                closeMenuOnSelect={true}
                                                value={this.state.source}
                                                onChange={this.selectsource.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "Last Year Trend", value: "targetplanshare" },
                                                { label: "PPC", value: "ppctargetplanshare" }]} required
                                            />
                            </div>
                            <div className="col-xl-3 col-lg-3 form-group">
                                <label>Target:</label>
                                <Select
                                                placeholder={"Select option"}
                                                closeMenuOnSelect={true}
                                                value={this.state.option}
                                                onChange={this.selectoption.bind(this)}
                                                style={{ borderRadius: "0px" }}
                                                options={[{ label: "(n+(n-1)+(n+1))/3 months of previous year", value: "1" },
                                                { label: "nth month of previous year", value: "2" }]} required
                                            />
                            </div>
                            <div className="form-group col-xl-2 col-lg-2">
                                <label>&nbsp;</label><br />
                                <button type="button" className="btn btn-success" style={{ padding: "5px 10px", marginRight: "2%" }} onClick={this.onLoadPageData.bind(this)}>Submit</button>

                            </div>
                        </form>
                    </div>

                    <div className="card-body col-xl-12 col-lg-12">   
                            {/* <div className="card-header" style={{ padding: '10px' }}>
                                <h5>{this.state.gridTitle}</h5>
                            </div>                        */}
                        <div id="myGrid" style={{ width: "100%", height: "930px"}} className={"ag-theme-balham"}>
                            <AgGridReact
                                modules={this.state.modules}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus={true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                pinnedBottomRowData={this.state.pinnedRowData}
                                gridOptions={{
                                    context: { componentParent: this },
                                    getRowStyle: function (params) {
                                        if (params.node.rowPinned) {
                                          return { 'font-weight': 'bold','font-size':'16px' };
                                        }
                                      },
                                }}
                                // components={this.state.components}
                                enableRangeSelection={true}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)} style={{top:"20%"}}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            </div>

        );
    }
}

function dateComparator1(date1, date2) {
    //console.log(date1,date2);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}

function monthToComparableNumber1(date) {
    console.log(date.length);
    console.log(date);
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    console.log(yearNumber, monthNumber, dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}


function loadDateTimeScript() {
    // alert("timeDate");
    $('.datetimepicker_mask').datetimepicker({
        //mask:'39-19-9999 29:59',
        format: 'd-m-Y'
    });
    $('.datetimepicker_date').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        timepicker: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}

function DateEditor() { }

// gets called once before the renderer is used
DateEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        timepicker: false,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function () {
    // console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function () {
    this.value = this.value.replace(/\D/g, '');
});