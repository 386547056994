import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from "lodash";
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD, getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS, getDDMMMYYYYHHMMDefault, getHyphenDDMMMYYYY} from '../common/utils';
import Select from 'react-select';
// import UpdateItem from './updaterow';
// import DeleteItem from './deleterow';
import CSVFileValidator from 'csv-file-validator';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css'
import axios from 'axios';
import CountUp from 'react-countup';
import Modal from 'react-responsive-modal';
import { func } from 'prop-types';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");
var moment = require('moment');


export default class ApiRunLogs extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentDepartmentTitle: null,
            modules: AllModules,
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
                resizable: true
            },
            rowData: [],

            editType: "fullRow",
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            animateRows: true,
            debug: true,
            showToolPanel: false,
            rowSelection: "single" ,
            context: { componentParent: this },
           
            loadingCellRenderer: "customLoadingCellRenderer",
            loadingCellRendererParams: { loadingMessage: "One moment please..." },
         
            // frameworkComponents: {
            //  updateItem:UpdateItem,
            //     deleteItem:DeleteItem
            //  //statusChange:StatusChange
            // },

            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            transporters:[],
            sliderRso:"",
            sliderUpdate:"",
            transporter:"",
            truck_no:"",
            trucktype:"",
            deptcode:"",
            sources:[],
            destinations:[],
            sourceopt:"",
            oem_code:"",
            oem_name:"",
            industry_type:"",
            officer_mobile:"",
            officer_alteratemobile:"",
            officer_locations:[],
            officertype:"",
            rowid:"",
            sliderBulk:"",
            file:"",            
            csvcontent:[],
            billing_month:"",
            activerecords :[],
            nonactiverecords:[],
            allrowData:[],
            sideBar: {
                toolPanels: [
                  {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel"
                  },
                  {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel"
                  }
                ]
              },
        };
    }
   
    componentDidMount(){
        // if (googleAnalytics.page.enableGA) {
        //  this.logPageView(); // Log page view to GA
        // let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
        // this.setState({eventAction: eventAction});

        // }
       
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
       
        redirectURL.post('/arms/getapirunlogs',{},{"headers":
        {											
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': `Bearer ${localStorage.getItem("token")}`
    }
    }).then((response) => {
        if(response.data.message == "Token has expired" || response.data.message == "No token provided" || response.data.message == "Invalid Token"){
            this.setState({
                show:true,
                basicTitle:"Session Expired",
                basicType:"warning",
                overly: "show-n",
                loadshow: "show-n"
            })
        }else{
            var records = JSON.parse(response.data.data);
            var resp = JSON.parse(records.api_details)
            console.log(resp,"107")
            console.log(records,"106")
            var activerecords  = resp.filter((e)=>e.last_api_response == "Success")
            var nonactiverecords = resp.filter((f)=>f.last_api_response == "Failure")
                this.setState({
                    allrowData:resp,
                    rowData:resp,
                    overly: "show-n",
                    loadshow: "show-n",
                    activerecords:activerecords,
                    nonactiverecords: nonactiverecords,
                });
            }
          })
          .catch(function (error) {
            console.log(error);
        });
       
    };
   
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    closeAlert = () => {
        if(this.state.basicTitle == "Session Expired"){
            window.location.href = "/logout"
        }else{
            this.setState({
                show: false
            });
        }
   }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", this.gridOptionsWrapper);
        this.gridColumnApi = params.columnApi;
   
    };
    onGridState = () =>{
        //console.log(this.gridApi);
       
        /*Get  Current Columns State and Store in this.colState */
          this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
          this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
          this.pivotColumns = this.gridColumnApi.getPivotColumns();
                   
        /*Get Current Filter State and Store in window.filterState */
          window.filterState = this.gridApi.getFilterModel();
          this.gridApi.setFilterModel(window.filterState);    
    }
    onClickHideAll(){
        this.setState({
            loadshow:'show-n',
            overly:'show-n',
            sliderRso:"",
            sliderUpdate:"",
            sliderBulk:""
        });
       
    }
    onClickTab = (counterKey) => {
        this.setState({
            rowData : this.state[counterKey]
        });
    }

render(){
    const columnwithDefs = [
        // {
        //     headerName: "Dept Code",
        //     field: "dept_code",
        //     width: 150,
        //     filter: true,
        //     resizable: true,
        //     editable: false,
        //   },
        //   {
        //     headerName: "Feild Names",
        //     field: "fields_names",
        //     width: 150,
        //     filter: true,
        //     resizable: true,
        //     editable: false,
        //   },
          {
            headerName: "API Description",
            field: "api_description",
            width: 250,
            filter: true,
            resizable: true,
            editable: false,
          },
          {
            headerName: "API URL",
            field: "api_url",
            width: 250,
            filter: true,
            resizable: true,
            editable: false,
          },
          {
            headerName: "API Payload",
            field: "api_payload",
            width: 250,
            filter: true,
            resizable: true,
            editable: false,
            hide: true,
          },
          {
            headerName: "Last API Response",
            field: "last_api_response",
            width: 150,
            filter: true,
            resizable: true,
            editable: false,
            valueGetter:function(params){
                if(params.data.api_frequency == "Every 15th Minute"){
                    if(params.data.last_record_received_on != null && params.data.last_record_received_on != undefined && params.data.last_record_received_on != ""){
                        var recordTime = new Date(params.data.last_record_received_on)
                        var failureTime = moment(Date.now()).subtract(2,"h")._d
                        console.log(recordTime > failureTime)
                        if(recordTime > failureTime){
    
                            return "Success"
                        }else{
                            return "Failure"
                        }
                    }
                }
                else if(params.data.api_frequency == "Every 24th Hour"){
                    if(params.data.last_record_received_on != null && params.data.last_record_received_on != undefined && params.data.last_record_received_on != ""){
                        var recordTime = new Date(params.data.last_record_received_on)
                        var failureTime = moment(Date.now()).subtract(24,"h")._d
                        console.log(recordTime > failureTime)
                        if(recordTime > failureTime){
    
                            return "Success"
                        }else{
                            return "Failure"
                        }
                    }
                }
                else{
                    if(params.data.last_record_received_on != null && params.data.last_record_received_on != undefined && params.data.last_record_received_on != ""){
                        var recordTime = new Date(params.data.last_record_received_on)
                        var failureTime = moment(Date.now()).subtract(2,"h")._d
                        console.log(recordTime > failureTime)
                        if(recordTime > failureTime){
    
                            return "Success"
                        }else{
                            return "Failure"
                        }
                }
                
                }
            }
          },
          {
            headerName: "Average API Duration In Seconds",
            field: "average_api_duration_in_seconds",
            width: 140,
            filter: true,
            resizable: true,
            editable: false,
            hide: true,
          },
          {
            headerName: "API Frequency",
            field: "api_frequency",
            width: 140,
            filter: true,
            resizable: true,
            editable: false,
          },
          {
            headerName: "Last Record Received On",
            field: "last_record_received_on",
            width: 140,
            filter: true,
            resizable: true,
            editable: false,
            valueGetter: function(params) {
                try {
                  return getHyphenDDMMMYYYYHHMM(params.data.last_record_received_on);
                } catch (e) {
                  return "";
                }
              },
          },
    
          {
            headerName: "Last Processed On",
            field: "last_processed_on",
            width: 150,
            filter: true,
            resizable: true,
            editable: false,
            valueGetter: function(params) {
              try {
                return getHyphenDDMMMYYYYHHMM(params.data.last_processed_on);
              } catch (e) {
                return "";
              }
            },
          },
    ];
    return(
        <div className="container-fluid">
        <SweetAlert
            show={this.state.show}
            type={this.state.basicType}
            title={this.state.basicTitle}
            onConfirm={this.closeAlert}
        >
        </SweetAlert>
            {this.state.alert}
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                {/* <div className="card-body" id="counterCardBody">
                                <div className="crm-numbers pb-0">
                                    <div className="row">
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"allrowData")}>
                                            <span className="f13" style={{color:"#4099FF"}}>Total</span>
                                            <h4 class="txt-info f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.allrowData.length)?this.state.allrowData.length:0}/>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"activerecords")}>
                                            <span className="f13" style={{color:"#00C292"}}>Success</span>
                                            <h4 class="txt-success f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.activerecords.length)?this.state.activerecords.length:0}/>
                                                </span>
                                            </h4>
                                        </div> 
                                        <div className="col cirlce-d cpointer" onClick={this.onClickTab.bind(this,"nonactiverecords")}>
                                            <span className="f13" style={{color:"#FF5370"}}>Failure</span>
                                            <h4 class="txt-danger f40">
                                                <span class="counter">
                                                <CountUp end={(this.state.nonactiverecords.length)?this.state.nonactiverecords.length:0}/>
                                                </span>
                                            </h4>
                                        </div>                         
                                    </div>                               
                                </div>
                            </div> */}
                <div className="card-arms">
                        <div className="card-header-arms">
                            <h5>
                                <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>ARMS API Run Logs</span>
                            </h5>
                        </div>
                            
                        <div className="grid-container">
                            <div id="myGrid" className="ag-theme-balham grid-card">
                                <AgGridReact
                                    modules={this.state.modules}
                                    columnDefs={columnwithDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    rowSelection={this.state.rowSelection}
                                    enableCharts={false}
                                    //  paginationAutoPageSize={true}
                                    paginationPageSize={this.state.paginationPageSize}
                                    pagination={true}
                                    enableRangeSelection={true}
                                    onGridReady={this.onGridReady}
                                    onGridState={this.onGridState}
                                    deleteRowHandler={this.deleteRowHandler}
                                    // onCellClicked={this.onRowClicked.bind(this)}
                                    // onSelectionChanged={this.onSelectionChanged.bind(this)}
                                    frameworkComponents={this.state.frameworkComponents}
                                    //editType={this.state.editType}
                                    stopEditingWhenGridLosesFocus={true}
                                    // floatingFilter={true}
                                    enableCellChangeFlash={true}
                                    suppressCellFlash={true}
                                    gridOptions={{
                                        context: { componentParent: this }
                                    }}
                                    sideBar={this.state.sideBar}
                                    //updateRowHandler={this.updateRowHandler}
                                    //onCellEditingStopped={this.updateCellData.bind(this)}
                                />
                            </div>
                        </div>
                        </div>
                </div>
                </div>
           
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
       

        </div>
           
    );
}
}
function GetSortDescOrder(prop) {    
    return function(a, b) {    
        if (a[prop] < b[prop]) {    
            return 1;    
        } else if (a[prop] > b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
}